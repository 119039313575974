import React from "react";

// Customizable Area Start
import { styled, Box, Typography, Card, CardMedia, Button } from "@mui/material";
import Loader from "../../../components/src/AppLoader.web";
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
// Customizable Area End

import ReverseAuctionController, { Props } from "./ReverseAuctionController";

export default class ReverseAuction extends ReverseAuctionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { isLoading } = this.state;
    return (
      // Customizable Area Start
      <HeaderFooterLayout navigation={this.props.navigation}>
        <Loader isLoading={isLoading} />
        <StyleWrapper>
          <Box width={"100%"} borderBottom={"1px solid #BFBBB9"} padding={"15px 0px"}>
            <Typography className="mainTitle">Reverse Auction</Typography>
          </Box>

          <Box padding={"15px 0px"}>
            <Typography className="description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Typography>
          </Box>

          <Box padding={"4%"}>
            <Card>
              <CardMedia
                component="video"
                src="https://videos.pexels.com/video-files/4017060/4017060-uhd_2560_1440_30fps.mp4"
                title="Video Title"
                controls
              />
            </Card>
          </Box>
          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            <Button onClick={this.openReverseAuctionform} className="continueButton">Continue</Button>
          </Box>
        </StyleWrapper>
      </HeaderFooterLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyleWrapper = styled(Box)({
  width: "100%",
  padding: "3%",
  "& .mainTitle": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#292524",
  },
  "& .description": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#44403C",
  },
  "& .continueButton": {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "capitalize",
    background: "#7F1187",
    padding: "10px 0px",
    borderRadius: "30px",
    width: "227px",
    cursor: "pointer",
    "&:hover": {
      background: "#7F1287"
    }
  }
});
// Customizable Area End
