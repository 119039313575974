// Customizable Area Start
import React, { Component } from "react";
import { Box, styled, Typography } from "@mui/material";

import MainTitle from "./MainTitle.web";
import { IWhoWeAre } from "../LandingPageController";

interface Props {
  whoWeAreData: IWhoWeAre[];
}

interface State {}

export default class WhoWeAreSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { whoWeAreData } = this.props;
    return (
      <WhoWeAreStyle>
        <MainTitle title={whoWeAreData && whoWeAreData.length > 0 && whoWeAreData[0].attributes.title || 'Who we are'} />
        {whoWeAreData.map((data) => (
          <React.Fragment key={data.id}>
            <WhoAreSection component="section" id="who-are">
              <Box  className="image-with-overlay">
                <Box/>
                {data.attributes.img_video.type === "image" && (
                  <img
                    src={data.attributes.img_video.url}
                    className="who-we-are-image"
                  />
                )}
                {data.attributes.img_video.type === "video" && (
                  <video controls autoPlay muted className="who-we-are-video">
                    <source
                      src={data.attributes.img_video.url}
                      type="video/mp4"
                    />
                    <source
                      src={data.attributes.img_video.url}
                      type="video/ogg"
                    />
                  </video>
                )}
              </Box>
            </WhoAreSection>
            <PassionateContainer component="section" id="passionate">
              <Typography className="passionate-main-text">
                {data.attributes.heading}
              </Typography>
              <Typography className="passionate-second-main-text">
                {data.attributes.description}
              </Typography>
            </PassionateContainer>
          </React.Fragment>
        ))}
      </WhoWeAreStyle>
    );
  }
}
const WhoWeAreStyle = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  padding: "30px 0",
});

const WhoAreSection = styled(Box)({
  "& .image-with-overlay": {
    marginTop: "32px",
    height: "490px",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginBottom: "60px",
    overflow: "hidden",
    position: "relative",
  },
  "& .who-we-are-overlay": {
    position: "absolute",
    inset: "0",
    background: "linear-gradient(#00000073,#00000073)",
    zIndex: 1,
  },
  "& .who-we-are-image,.who-we-are-video": {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
});

const PassionateContainer = styled(Box)({
  textAlign: "center",
  "& .passionate-main-text": {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: "500",
    lineHeight: "37px",
    textAlign: "center",
    color: "#1C1917",
    margin: "auto",
    marginBottom: "16px",
    maxWidth: "664px",
  },
  "& .passionate-second-main-text": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "26px",
    textAlign: "center",
    color: "#57534E",
  },
});
// Customizable Area End
