import React from "react";

// Customizable Area Start
import {
  Box,
  Divider, styled,
  TextField, InputAdornment,
  Typography
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
const images = require('./assets')
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";
import moment from "moment";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderChatBox = () => {
    const { chatValue, user, messageCollection } = this.state;
    return (
      <Box className="container">
        <Box className="chatHead">
          <Box>
            <img src={images.logoImage} alt="logo" />
            <Typography className="headerText">{configJSON.chatFirstText}<br />{configJSON.chatSecondText}<br />{configJSON.chatThirdText}<span className="partyText">{configJSON.privacyText}</span></Typography>
          </Box>
          <ClearIcon style={{ cursor: "pointer" }} onClick={this.props.handleChatClose} />
        </Box>
        <Divider />
        <Box className="messageContainer">
          <Box className="chatSection">
            <Box>
              <img style={{ width: "32px", height: "32px", objectFit: "cover" }} src={images.support} />
            </Box>
            <Box className="showChatSection">
              <Typography className="showName">Support</Typography>
              <Typography className="showChat">Hi ! {user?.full_name}</Typography>
              <Typography className="showChat">How may I help you ?</Typography>
            </Box>
          </Box>
          {messageCollection.length > 0 &&
            messageCollection.map((item, index) => {
              const data = item.attributes;
              let previousDate: any;
              let currentDate: any;
              let showDate = true;

              if (index > 0) {
                previousDate = moment(messageCollection[index - 1].attributes.created_at);
                currentDate = moment(data.created_at);
                showDate = !currentDate.isSame(previousDate, "day");
              }
              return (
                <>
                  {showDate && <Box key={data.id} width={"100%"} textAlign={"center"} margin={"10px 0px"}>
                    <Typography className="chatMessageTime">{moment(data.created_at).format("DD MMM, yyyy")}</Typography>
                  </Box>}
                  <Box className={data.account_id ? "sentMessage" : "receivedMessage"}>
                    <Typography className="chatMessageText">{data.message}</Typography>
                    <Typography className="chatMessageTime">{moment(data.created_at).format("h:m A")}</Typography>
                  </Box>
                </>
              )
            })
          }
          <Box ref={this.messagesEndRef} />
        </Box>
        <TextField placeholder="Write here..." className="chatField" data-test-id="handleChatBox" onChange={this.handleChatBox}
          value={chatValue}
          onKeyPress={this.handleKeyDown}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end" className="inputIcons">
                {chatValue && <ClearIcon data-test-id="handleChatClear" onClick={this.handleChatClear} />}
                <SendIcon onClick={this.handleSendMessage} data-test-id="chatSendButton" />
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <img style={{ width: "32px", height: "32px", objectFit: "cover", borderRadius: "50%" }}
                  src={user?.profile_picture || "https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg"} />
              </InputAdornment>
            )
          }} />
        <Box className="text">
          <Typography className="thirdPartyText">by <span className="partyText">Third party</span></Typography>
        </Box>
      </Box>
    )
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <CustomBox >
        {this.renderChatBox()}
      </CustomBox>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CustomBox = styled(Box)({
  width: "481px",
  height: "685px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "18px",
  boxShadow: "0px 0px 12px 0px #00000040",
  zIndex: 4,
  "@media (max-width:500px)": {
    width: "350px",
    marginLeft: "20%"
  },
  "& .container": {
    width: "90%",
    height: "95%",
    display: 'flex',
    flexDirection: "column",
    gap: '30px',
    position: "relative",
  },
  "& .chatField": {
    border: "1px solid #E7E5E4",
    width: "100%",
    position: "absolute",
    bottom: "45",
    borderRadius: "8px",
    outline: "none !important",
    "& .MuiOutlinedInput-input": {
      color: "#A8A29E !important",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "24px",
      "&:focus-visible": {
        outline: "none !important",
      }
    },
  },
  "& .chatHead": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .partyText": {
    textDecoration: "underline",
    cursor: "pointer"
  },
  "& .thirdPartyText": {
    color: "#A8A29E",
    fontFamily: "Poppins",
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  "& .text": {
    display: 'flex',
    justifyContent: "center",
    width: '100%',
    position: "absolute",
    bottom: "0",
  },
  '& .headerText': {
    color: "#57534E",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    paddingLeft: "7%",
    width: "100%"

  },
  "& .messageContainer": {
    width: "100%",
    height: "330px",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  "& .sentMessage": {
    width: "max-content",
    maxWidth: "70%",
    background: "#FBE8FC82",
    borderRadius: "8px",
    padding: "10px",
    alignSelf: "end",
    textAlign: "right",
    marginBottom: "10px",
  },
  "& .receivedMessage": {
    width: "max-content",
    maxWidth: "70%",
    background: "#F5F5F4",
    borderRadius: "8px",
    padding: "10px",
    alignSelf: "start",
    marginBottom: "10px",
  },
  "& .chatMessageText": {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
  },
  "& .chatMessageTime": {
    color: "#78716C",
    fontFamily: "Poppins",
    fontSize: "11px",
    fontWeight: 400,
    lineHeight: "18px",
    paddingTop: "5px",
  },
  "& .chatSection": {
    display: "flex",
    gap: "8px",
    paddingLeft: "3%",
  },
  "& .showName": {
    color: "#000000",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "18px",
    paddingBottom: "12px"
  },
  "& .showChat": {
    color: "#000000",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    paddingBottom: "12px"
  },
  "& .inputIcons": {
    display: 'flex',
    gap: "10px",
    cursor: 'pointer'
  },
  "& .showChatSection": {
    paddingTop: '5px'
  }
})
// Customizable Area End
