import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils";
import { Message } from "../../../framework/src/Message";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}
interface RealWeddingData{
   id: number,
  type: string,
  attributes: {
      id: number,
      title: string,
      description: string
  }}
  interface RealWeddingProfileData {
    data: [
        {
            id: string,
            type:string,
            attributes: {
                id: number,
                title: string,
                description: string,
                "image": {
                  "id":number,
                  "date": string,
                  "url":string,
                  "type": string
              }
            }
        },
    ],
}
interface S {
  // Customizable Area Start
  isLoading: boolean;
  hasMore: boolean;
  realWeddingData: RealWeddingData;
  realWeddingProfileData: RealWeddingProfileData["data"]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class RealWeddingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getRealWeddingHeadingCallId:string ="";
  getRealWeddingProfileCallId:string ="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      hasMore: true,
      realWeddingData:{
        id: 0,
        type: "",
        attributes: {
          id: 0,
          title: "",
          description: ""
        }
      },
      realWeddingProfileData:[
          {
            "id": "",
            "type": "",
            "attributes": {
                "id": 2,
                "title": "",
                "description": "",
                "image": {
                  "id":0,
                  "date": "",
                  "url":"",
                  "type": ""
              }
            }
        }
        ]
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (webApiRequestCallId) {
        case this.getRealWeddingHeadingCallId:
          this.setState({
            isLoading: false,
            realWeddingData: webResponseJson.data[0],
          });
          break;
          case this.getRealWeddingProfileCallId:
           
            this.setState({
              isLoading: false,
              realWeddingProfileData: webResponseJson.data
            });
            break;
          default:
        }
      }
    }
  // Customizable Area Start
  redirectToRealWeddingItemsPage = (id: number) => {
    setStorageData("realWeddingId", id)
    this.props.navigation.navigate("RealWeddingItems", { id });
  };
  async componentDidMount(): Promise<void> {
    this.getRealWeddingHeadingapi()
    this.getRealWeddingProfile()
  }
  getRealWeddingHeadingapi= async() => {
    this.setState({
      isLoading: true,
    });
    this.getRealWeddingHeadingCallId = await apiCall({
      endPoint: "bx_block_content_management/real_events",
      method: "GET",
    });
  }
  getRealWeddingProfile= async() => {
    this.setState({
      isLoading: true,
    });
    this.getRealWeddingProfileCallId = await apiCall({
      endPoint: "bx_block_content_management/real_event_profiles",
      method: "GET",
    });
  }
  // Customizable Area End
}
