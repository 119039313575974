import React from "react";

// Customizable Area Start
import { Grid, Box, Typography, Card, CardActionArea, CardContent, Rating, CardActions, Button, } from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// Customizable Area End

import ProductSummaryController, { Props } from "../ProductSummaryController.web";

export default class ProductSuggestionSection extends ProductSummaryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {   
        return (
            // Customizable Area Start
            <Grid item xs={12} width={"100%"}>
                <Typography className="productSectionTitle">You might also like this</Typography>
                <Grid container spacing={3} padding={"0px"}>
                    {this.state.productSuggestions.map((item, index) => {
                        const product = item.attributes;
                        return (
                            <Grid style={{ flexGrow: 1, overflow: 'hidden', borderRadius: '12px', }} item xs={12} sm={6} md={4} xl={3} lg={3}>
                                <Card
                                    className="productCard"
                                    key={index}
                                    data-test-id={`handleRedirectProduct${index}`}
                                    onClick={() => this.handleRedirectProduct(product.id)}
                                    sx={{ boxShadow: 3, marginBottom: "10px", overflow: "hidden", borderRadius: "12px" }}
                                >
                                    <Box className="likeIconBox">
                                        <FavoriteBorderIcon sx={{ color: 'white' }} />
                                    </Box>
                                    <CardActionArea>
                                        <img className='cardsImg' alt={product.name} src={this.getProductCardImage(product.media_files)} style={{ height: '450px', width: '100%', objectFit: 'cover' }} />
                                        <CardContent>
                                            <Box className="productTitleRatingBox" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                                                <Typography variant="h6" className="productTitleEllipsis productTitle" >
                                                    {product.name}
                                                </Typography>
                                                {product.average_score > 0 &&
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }} className="productRatingBox">
                                                        <Rating size="small" value={5} precision={1} max={1} readOnly />
                                                        <Typography className="ratingText" sx={{ fontSize: '0.875rem', marginLeft: '4px' }}>
                                                            {product.average_score} ({product.total_ratings} Ratings)
                                                        </Typography>
                                                    </Box>
                                                }
                                            </Box>
                                            <Typography color="textSecondary" className="productLocation" style={{ minHeight: "25px" }}>
                                                {this.handleMultipleLocations(product.service_locations)}
                                            </Typography>
                                        </CardContent>
                                        <CardActions style={{ padding: '0 8px 15px 15px' }}>
                                            <Typography variant="h6" className="productPriceText">
                                                ₹ {product.price}
                                            </Typography>
                                        </CardActions>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} marginTop={"50px"}>
                    <Button data-test-id="view-all-button" onClick={this.handleViewAllRedirection} className="secondaryButton" sx={{ width: "207px" }}>View all</Button>
                </Box>
            </Grid>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
