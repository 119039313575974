import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import DocumentPicker, {
  DocumentPickerResponse,
} from "react-native-document-picker";
import { Platform } from "react-native";
export const baseURL = require("../../../framework/src/config.js").baseURL;

interface IUploadedFile {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      account_id: number;
      files:
        | {
            id: number;
            file_name: string;
            file_url: string;
          }[]
        | null;
      status: string;
    };
  };
  meta: {
    message: string;
  };
}

interface IFile {
  fileCopyUri: string;
  name: string;
  size: number;
  type: string;
  uri: string;
}

interface IDownloadResponse {
  jobId: number;
  statusCode: number;
  bytesWritten: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  formData: {
    reference_id: string;
    email: string;
    password: string;
    fullName: string;
    gstNumber:string;
    businessName:string;
    businessCategory:string;
    primaryContact:string;
    secondaryNumber:string;
    showPassword: boolean;
    isChecked: boolean;
    isTermsChecked: boolean;
    isPrivacyChecked:boolean;
    day: string, month: string, year: string ;attendee:string;roombooked:string;Category:string;vedorname:string;events:string;venue:string;
    phoneId:string;eventdatestart:string;eventdateend:string;
    evetsite:string;
    subeventsite:string;
    eventtype:string;
    subventtype:string;
    phoneIdNo:string;
    selectedService:string;
   
   
    
  };
  isLoading: boolean;
  image:string
 
 
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PlannedeventdesignController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createBulkUploadCallId: string = "";
  getBulkUploadCallId: string = "";
  deleteBulkUploadCallId: string = "";
  userDataListCallid:string = "";
  userEventtypeCallid:string="";
  userEventSiteCallid:string="";
  userEventsubSiteCallid:string="";
  maxFileSize = 5e6;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      image:"",
      isLoading: false,
      formData: {
        email: "",
        password: "",
        fullName: "",
        businessCategory:"",
        gstNumber:"",
        businessName:"",
        primaryContact:'',
        secondaryNumber:"",
        showPassword: false,
       
        reference_id: "",
        isChecked: false,
        day: "", month: "", year: "" ,eventtype:"",subventtype:"",evetsite:"",subeventsite:"",attendee:"",roombooked:"",Category:"",vedorname:"",events:"",
        isTermsChecked: false,venue:"",eventdatestart:"",eventdateend:"",
        isPrivacyChecked:false,
        phoneId:'+91',
        phoneIdNo:'',
        selectedService: "",
      },
    
     
    
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

  

    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    this.getToken();
    this.getPlaneventDetails()
    this.getEventTypeDetails()
    this.getEventSiteDetails()
    this.getEventsubSiteDetails()
  };

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };
  getPlaneventDetails = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userDataListCallid = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userListUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getEventTypeDetails = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userEventtypeCallid = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userListUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getEventSiteDetails = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userEventSiteCallid = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userListUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getEventsubSiteDetails = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userEventsubSiteCallid = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userListUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
isError = (
    touched: boolean | undefined,
    errors: string | undefined,
    isValid: boolean = false
  ) => {
    return isValid ? isValid : touched && !!errors;
  };
 
  helperText = (
    touched: boolean | undefined,
    errors: string | undefined,
    isValid: boolean = false,
    errorMessage = ""
  ) => {
    return isValid ? errorMessage : touched && errors;
  }
  handleChange = (event:any) => {
    const category = event.target?.value;
    let vendors = [];
    
    if (category === 'electronics') {
      vendors = ['ElectroVendor', 'TechSuppliers'];
    } else if (category === 'furniture') {
      vendors = ['FurnitureWorld', 'HomeDecor'];
    } else if (category === 'clothing') {
      vendors = ['FashionHub', 'TextileMasters'];
    }
  };
  handleImageChange = (event:any) => {
    const file = event.target?.files[0];
    if (file) {
      this.setState({ image: URL.createObjectURL(file) });
    }
  };
  signUpWithVendorEmail = (values:any) => {

    if (!values.events) {
        return;
    }
}

  handleErrorText = (touched: boolean | undefined, errors: string | undefined) => {
    return touched && errors
  }
 
 
 

 

 

 

 
  // Customizable Area End
}
