// Customizable Area Start
import React, { Component } from "react";
import {
  Box,
  Typography,
  styled,
  Button,
  FormControlLabel,
  Checkbox,
  Container,
} from "@mui/material";
import Carousel from "react-multi-carousel";
import SearchIcon from "@mui/icons-material/Search";
import Select, { components } from "react-select";
import {
  uncheckImage,
  checkImage,
  leftArrow,
  rightArrow,
  categoryIcon,
  locationIcon,
  workImage,
  dropdownIcon,
} from "../assets";
import { ICarouselItems } from "../LandingPageController";

const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const ButtonGroup = ({ next, previous }: any) => {
  return (
    <Box className="carousel-button-group">
      <CustomContainer maxWidth="lg">
        <Box className="carousel-button-group-container">
          <Button onClick={previous} className="previous-button">
            <img src={leftArrow} alt="left" />
          </Button>
          <Button onClick={next} className="next-button">
            <img src={rightArrow} alt="right" />
          </Button>
        </Box>
      </CustomContainer>
    </Box>
  );
};

const ControlWhere = (props: any) => {
  return (
    <components.Control {...props}>
      <img src={locationIcon} alt="location" className="location-image" />
      {props.children}
    </components.Control>
  );
};

const ControlCategory = (props: any) => {
  return (
    <components.Control {...props}>
      <img src={categoryIcon} alt="category" className="location-image" />
      {props.children}
    </components.Control>
  );
};

const checkUserLogin = (): boolean => {
  let isUserLoggedIn = false;
  const token = localStorage.getItem("authToken");
  const role = localStorage.getItem("role");
  if (token && role) {
    isUserLoggedIn = true;
  }
  return isUserLoggedIn;
}

interface Props {
  carouselItem: ICarouselItems[];
  title: string;
  description: string;
  redirectToVendorPage: () => void;
  navigateToWeddingFun: () => void;
}

interface State {}

export default class DoNotSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { props } = this;
    return (
      <CarouselStyle component="section" className="carousel" id="carousel">
        <Carousel
          data-test-id="carousel"
          className="carousel-container"
          swipeable={false}
          draggable={false}
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={10000}
          arrows={false}
          keyBoardControl={true}
          partialVisible={true}
          customTransition="all 0.5"
          transitionDuration={500}
          customButtonGroup={<ButtonGroup />}
        >
          {props.carouselItem.map((data) => {
            return (
              <div className="slider-image" key={data.id}>
                {data.attributes.img_vid_url.type === "image" && (
                  <img src={data.attributes.img_vid_url.url} alt="movie" />
                )}
                {data.attributes.img_vid_url.type === "video" && (
                  <video
                    autoPlay
                    muted
                    loop
                    className="coach-landing-page-video"
                  >
                    <source
                      src={data.attributes.img_vid_url.url}
                      type="video/mp4"
                    />
                    <source
                      src={data.attributes.img_vid_url.url}
                      type="video/ogg"
                    />
                  </video>
                )}
              </div>
            );
          })}
        </Carousel>
        <Box className="carousel-text-container" />
        <Box className="filter-item-text-main-container">
          <CustomContainer maxWidth="lg">
            <Box className="filter-item-text-container">
              <Typography className="main-heading" component="h2">
                {this.props.title}
              </Typography>
              <Typography className="second-heading" component="h3">
                {this.props.description}
              </Typography>
              <Box className="search-container">
                <Box className="select-dropdown-container">
                  <SelectDropdown
                    data-test-id="location-select"
                    classNamePrefix="select-dropdown"
                    placeholder="Where"
                    components={{
                      Control: ControlWhere,
                      DropdownIndicator: () => (
                        <img
                          src={dropdownIcon}
                          alt="dropdown"
                          className="dropdown"
                        />
                      ),
                    }}
                    NoOptionsMessage="No city found"
                    options={[{ label: "Gujarat", value: "Gujarat" }]}
                  />
                  <SelectDropdown
                    placeholder="Category"
                    data-test-id="category-select"
                    NoOptionsMessage="No category found"
                    components={{
                      Control: ControlCategory,
                      DropdownIndicator: () => (
                        <img
                          src={dropdownIcon}
                          alt="dropdown"
                          className="dropdown"
                        />
                      ),
                    }}
                    classNamePrefix="select-dropdown"
                    options={[{ label: "Destination", value: "Destination" }]}
                  />
                </Box>
                <Button className="search-button" data-test-id="navigateToWeddingFun" onClick={()=>this.props.navigateToWeddingFun()}>
                  <SearchIcon /> Search
                </Button>
              </Box>
              <Typography component="h4" className="third-heading">
                Search over 230,000 local professionals with review, pricing and
                availability
              </Typography>
              <Box className="check-box-controller">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isChecked"
                      icon={
                        <img
                          src={uncheckImage}
                          alt="unchecked"
                          className="checked-unchecked-image"
                        />
                      }
                      checkedIcon={
                        <img
                          src={checkImage}
                          alt="unchecked"
                          className="checked-unchecked-image"
                        />
                      }
                    />
                  }
                  label="Full Event planning / Service"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isChecked"
                      icon={
                        <img
                          src={uncheckImage}
                          alt="unchecked"
                          className="checked-unchecked-image"
                        />
                      }
                      checkedIcon={
                        <img
                          src={checkImage}
                          alt="unchecked"
                          className="checked-unchecked-image"
                        />
                      }
                    />
                  }
                  label="Customized Vendor"
                />
              </Box>
            </Box>
          </CustomContainer>
        </Box>
      </CarouselStyle>
    );
  }
}

const CarouselStyle = styled(Box)(({ theme }) => ({
  height: "855px",
  width: "100%",
  position: "relative",
  flex: "0 0 auto",
  "& .carousel-container": {
    height: "100%",
    "& ul,& li": { height: "100%" },
    "& .slider-image": {
      height: "100%",
      width: "100%",
      "& img, & video": {
        height: "100%",
        width: "100%",
        objectFit: "cover",
        objectPosition: "center",
      },
      "& video": {
        height: "100%",
        width: "100%",
        objectFit: "cover",
        objectPosition: "center",
      },
    },
    "& .carousel-button-group": {
      position: "absolute",
      inset: "0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box",
      "& .carousel-button-group-container": {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      },
      "& .previous-button,& .next-button": {
        height: "44px",
        width: "44px",
        minWidth: "unset",
        padding: "0",
        borderRadius: "50%",
        backgroundColor: "#ffffff",
        zIndex: 1,
      },
    },
  },
  "& .carousel-text-container": {
    position: "absolute",
    inset: "0",
    backgroundColor: "#00000099",
  },
  "& .vendor-button-container": {
    position: "absolute",
    top: "31px",
    right: "0",
    left: "0",
  },
  "& .vendor-button": {
    zIndex: "1",
    border: "1px solid #ffffff",
    borderRadius: "30px",
    padding: "11px",
    gap: "8px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    color: "#ffffff",
    textTransform: "none",
    float: "right",
  },
  "& .filter-item-text-main-container": {
    inset: "0",
    position: "absolute",
    display: "flex",
    alignItems: "center",
  },
  "& .filter-item-text-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  "& .main-heading": {
    fontFamily: "Poppins",
    fontSize: "40px",
    fontWeight: "600",
    lineHeight: "52px",
    textAlign: "center",
    color: "#ffffff",
    maxWidth: "545px",
    marginBottom: "8px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 30px",
      maxWidth: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "36px",
      padding: "0 10px",
      lineHeight: "48px",
    },
  },
  "& .second-heading": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "34px",
    textAlign: "center",
    color: "#ffffff",
    maxWidth: "679px",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
      maxWidth: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      padding: "0 14px",
      lineHeight: "32px",
    },
  },
  "& .search-container": {
    backgroundColor: "#ffffff",
    padding: "9.5px 8px",
    borderRadius: "30px",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      borderRadius: "10px",
      flexDirection: "column",
      width: "75%",
    },
  },
  "& .select-dropdown-container": {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .search-button": {
    gap: "8px",
    backgroundColor: "#7f1187 !important",
    borderRadius: "30px",
    fontFamily: "Poppins",
    fontSizeVendorTeamContainer: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    padding: "10px 33.21px 10px 8px",
    color: "#FFF",
    textTransform: "none",
  },
  "& .third-heading": {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#ffffff",
    marginTop: "8px",
    marginBottom: "10pxs",
  },
  "& .check-box-controller": {
    display: "flex",
    flexDirection: "column",
    width: "300.55px",
    "& label": {
      margin: "0",
    },
    "& .MuiTypography-root": {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
      color: "#ffffff",
    },
    "& .Mui-checked + .MuiTypography-root": {
      fontWeight: "700",
    },
    "& .checked-unchecked-image": {
      height: "20px",
      width: "20px",
    },
  },
}));

const CustomContainer = styled(Container)({
  flex: "1 1 auto",
  "@media(min-width:1408px)": {
    maxWidth: "1408px !important",
  },
  "@media(min-width:1280px)": {
    maxWidth: "100%",
  },
});

const SelectDropdown = styled(Select)(({ theme }) => ({
  width: "100%",
  "& .select-dropdown__control": {
    width: "198px",
    padding: "4px 8px",
    border: "none !important",
    boxShadow: "none",
    "@media(max-width:630px)": {
      width: "180px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .location-image": {
    height: "16px",
    width: "16px",
  },
  "& .dropdown": {
    height: "20px",
    width: "20px",
  },
  "*": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#292524 !important",
  },
  "& .select-dropdown__indicator-separator": {
    display: "none",
  },
  "& .select-dropdown__option--is-selected": {
    backgroundColor: "#7f1187",
    color: "#ffffff !important",
  },
}));
// Customizable Area End
