// Customizable Area Start
import React, { Component } from "react";
import { Box, Typography, styled, Button } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import TextInputFiled from "../../../../components/src/TextInputField.web";
import { isError, helperText } from "../../../../components/src/utils";

interface Props {
  newSletterApiIntegration: (values: { name: string; email: string }) => void;
  formikNewSletterRef: any;
  isEmailAlreadyUsed: boolean;
  handleChangeIsEmailAlreadyUsed: () => void;
}

interface State {}

export default class NewSletterSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { props } = this;
    return (
      <NewSletterContainer component="section" id="new-sletter">
        <Box className="new-sletter-white-box">
          <Typography component="h2" className="new-sletter-title">
            Newsletter
          </Typography>
          <Typography component="p" className="new-sletter-description">
            The best of inspirations and ideas, delivered direct to your inbox.{" "}
          </Typography>
          <Formik
            data-test-id="new-sletter-formik"
            innerRef={props.formikNewSletterRef}
            initialValues={{
              email: "",
              name: "",
            }}
            validationSchema={validationNewSletterSchema}
            onSubmit={(values) => {
              props.newSletterApiIntegration(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <Form onSubmit={handleSubmit} noValidate>
                  <TextInputFiled
                    name="email"
                    placeholder="Enter your email"
                    label="Email"
                    data-test-id="email-input"
                    value={values.email}
                    handleChange={(event) => {
                      handleChange(event);
                      props.handleChangeIsEmailAlreadyUsed();
                    }}
                    handleBlur={handleBlur}
                    isRequired
                    type="text"
                    error={isError(
                      touched.email,
                      errors.email,
                      props.isEmailAlreadyUsed
                    )}
                    helperText={helperText(
                      touched.email,
                      errors.email,
                      props.isEmailAlreadyUsed,
                      "This email is already used for newsletter"
                    )}
                  />
                  <TextInputFiled
                    name="name"
                    sx={{ marginBottom: "40px" }}
                    placeholder="Enter your name"
                    label="Name"
                    data-test-id="name-input"
                    value={values.name}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isRequired
                    type="text"
                    error={isError(touched.name, errors.name)}
                    helperText={helperText(touched.name, errors.name)}
                  />
                  <Box className="button-container">
                    <SubscribeButton
                      type="submit"
                      disabled={props.isEmailAlreadyUsed}
                    >
                      Subscribe
                    </SubscribeButton>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </NewSletterContainer>
    );
  }
}

const validationNewSletterSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .trim()
    .matches(/^[a-zA-Z\s]+$/, "Name can only contain letters")
    .test("not-only-digits", "Name cannot contain only digits", (value) => {
      return !/^\d+$/.test(value);
    })
    .max(50, "Maximum 50 characters are allowed"),
  email: Yup.string()
    .email("Email should be in a valid email format (user@example.com)")
    .required("Email is required")
    .trim(),
});

const NewSletterContainer = styled(Box)(({ theme }) => ({
  padding: "20px 0px",
  "& .new-sletter-white-box": {
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px 0px #00000040",
    padding: "44px 68px 85px 63px",
    maxWidth: "1130px",
    borderRadius: "8px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 35px 60px 35px",
    },
  },
  "& .new-sletter-title": {
    fontFamily: "Poppins",
    fontSize: "32px",
    fontWeight: "500",
    lineHeight: "50px",
    textAlign: "center",
    color: "#1C1917",
  },
  "& .new-sletter-description": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "34px",
    textAlign: "center",
    color: "#57534E",
    margin: "18px 0 40px",
  },
  "& .button-container": {
    textAlign: "center",
  },
}));

const SubscribeButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "24px",
  color: "#FFFFFF !important",
  backgroundColor: "#801187 !important",
  textTransform: "none",
  padding: "10px 71px",
  borderRadius: "30px",
});
// Customizable Area End
