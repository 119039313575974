import React from "react";

// Customizable Area Start
import { Grid, Typography, Box, } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
// Customizable Area End

import ProductSummaryController, { Props } from "../ProductSummaryController.web";

export default class FaqsSection extends ProductSummaryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const {
            productFaqs
        } = this.state;
        return (
            // Customizable Area Start
            <Grid item xs={12} width={"100%"}>
                <Typography className="faqHeading">Frequently asked questions</Typography>
                {productFaqs.length > 0 ?
                    <Box width={"100%"} height={"auto"} borderRadius={"12px"} padding={"25px"} style={{ display: "flex", flexDirection: "column", background: "#FBE8FC" }}>
                        {productFaqs.map((faq, index) => (
                            <Box marginBottom={"25px"}>
                                <Box data-test-id={`faqToggle${index}`} onClick={() => this.handleFaqToggle(faq.id)} padding={"25px 15px"} borderRadius={"8px"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} style={{ background: "#FFF", cursor: "pointer" }}>
                                    <Typography className="faqTitle" style={faq.open ? { color: "#7F1187" } : { color: "#000" }}>{faq.question} </Typography>
                                    <Typography className="faqTitle"> {faq.open ? <RemoveIcon /> : <AddIcon />} </Typography>
                                </Box>
                                {faq.open &&
                                    <Box marginTop={"-25px"} padding={"25px 15px"} borderRadius={"8px"} style={{ background: "#FFF" }}>
                                        <Typography className="accordianData"> {faq.answer}</Typography>
                                    </Box>
                                }
                            </Box>
                        ))
                        }
                    </Box>
                    :
                    <Box width={"100%"} height={"150px"} borderRadius={"12px"} padding={"25px"} style={{ display: "flex", justifyContent: "center", alignItems: "center", background: "#FBE8FC" }}>
                        <Typography>No questions added!</Typography>
                    </Box>
                }
            </Grid>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
