import React from "react";

// Customizable Area Start
import { Box, Typography, styled, Container, Button, InputLabel } from "@mui/material";
import { backgroundImage, contactUsImage, questionSideImage } from "./assets";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
import TextInputFiled from "../../../components/src/TextInputField.web";
import SelectField from "../../../components/src/SelectField.web";
import TextAreaFiled from "../../../components/src/TextAreaField.web";
import Loader from "../../../components/src/AppLoader.web";
import HeaderVendorFooter from "../../../components/src/HeaderVendorFooter";
import SelectCountryCode from "../../../components/src/SelectCountryCode";
// Customizable Area End

import NewContactUsController, {
  Props,
  configJSON,
} from "./NewContactUsController.web";

export default class ContactUs extends NewContactUsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  isError = (touched: boolean | undefined, errors: string | undefined) => {
    return touched && !!errors;
  };

  helperText = (touched: boolean | undefined, errors: string | undefined) => {
    return touched && errors;
  };

  contactMainBox = () => {
    return (
      <>
        <Loader isLoading={this.state.isLoading} />
        <ContactUsStyle data-test-id="ContactUsStyle">
          <Box className="contact-us-image-container">
            <img
              src={contactUsImage}
              alt="contact-us"
              className="contact-us-background-image"
            />

            <Box className="image-overlay">
              <Typography component="h3" className="contact-us-header">
                Contact us
              </Typography>
            </Box>
          </Box>
          <Box className="contact-us-details-container">
            <CustomContainer maxWidth="lg">
              <Typography className="contact-us-title">
                Get in touch with us
              </Typography>
              <Box className="contact-us-form-image-container">
                <Box className="form-container">
                  <Formik
                    initialValues={{
                      email: "",
                      name: "",
                      phone_number: "",
                      subject_id: "",
                      description: "",
                      phoneId: "+91"
                    }}
                    innerRef={this.formikContactRef}
                    data-test-id="contact-us-formik"
                    validationSchema={validationContactUsSchema}
                    onSubmit={(values) => {
                      this.contactUsApiIntegration(values);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => {
                      return (
                        <Form onSubmit={handleSubmit} noValidate>
                          <TextInputFiled
                            name="name"
                            placeholder="Enter your name"
                            label="Your name"
                            data-test-id="full-name-input"
                            value={values.name}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            isRequired
                            type="text"
                            error={this.isError(touched.name, errors.name)}
                            helperText={this.helperText(
                              touched.name,
                              errors.name
                            )}
                          />
                          <TextInputFiled
                            name="email"
                            placeholder="Enter your email"
                            label="Your email"
                            data-test-id="email-input"
                            value={values.email}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            isRequired
                            type="text"
                            error={this.isError(touched.email, errors.email)}
                            helperText={this.helperText(
                              touched.email,
                              errors.email
                            )}
                          />
                          <InputLabel className="labelClass" style={{ fontSize: "14px", fontWeight: 500, color: "rgb(51, 65, 85)", display: "block", fontFamily: "poppins" }}>Your mobile number<span style={{ color: "red" }}>*</span></InputLabel>
                          <Box
                            className="numberField" sx={{ display: "flex", alignItems: "center" }}
                          >                         
                            <SelectCountryCode data-test-id="selectCountryCode" getCountryCode={(code: string) => { setFieldValue("phoneId", code); }} />
                            <TextInputFiled
                              name="phone_number"
                              sx={{
                                marginLeft: { xs: "0", sm: "3rem" },
                                marginTop: "1rem",
                                width: { xs: "100%", sm: "60vw", md: "45vw", lg: "40vw" },
                              }}
                              placeholder="Enter your number"
                              data-test-id="mobile-number-input"
                              value={values.phone_number}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              isRequired
                              type="text"
                              error={this.isError(touched.phone_number, errors.phone_number)}
                              helperText={this.helperText(touched.phone_number, errors.phone_number)}
                            />
                          </Box>
                          <SelectField
                            error={this.isError(
                              touched.subject_id,
                              errors.subject_id
                            )}
                            isRequired
                            touched={touched.subject_id}
                            placeholder="Select your subject"
                            handleBlur={handleBlur("subject_id")}
                            helperText={this.helperText(
                              touched.subject_id,
                              errors.subject_id
                            )}
                            label="Subject"
                            name="subject_id"
                            options={this.state.subjectList.map((data) => ({
                              label: data.name,
                              value: data.id,
                            }))}
                            setFieldValue={setFieldValue}
                            value={values.subject_id}
                          />
                          <TextAreaFiled
                            sx={{ marginBottom: "40px" }}
                            name="description"
                            placeholder="Write your message..."
                            label="Your message"
                            data-test-id="description"
                            handleChange={handleChange}
                            value={values.description}
                            handleBlur={handleBlur}
                            isRequired
                            type="text"
                            error={this.isError(
                              touched.description,
                              errors.description
                            )}
                            helperText={this.helperText(
                              touched.description,
                              errors.description
                            )}
                          />
                          <Box className="button-container">
                            <Button
                              className="contact-us-button"
                              data-test-id="contact-us-button"
                              type="submit"
                            >
                              {configJSON.sendButtonText}
                            </Button>
                          </Box>
                        </Form>
                      );
                    }}
                  </Formik>
                </Box>
                <Box className="image-text-container">
                  <img
                    src={questionSideImage}
                    className="contact-us-side-image"
                    alt="contact"
                  />
                  <Box className="text-container">
                    <Typography className="text-container-text">
                      +91 0000000000
                    </Typography>
                    <Typography className="text-container-text">
                      happiffie@gmail.com
                    </Typography>
                    <Typography className="text-container-text">
                      A-903, Hamster Tower Bengaluru - 79
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </CustomContainer>
          </Box>
        </ContactUsStyle>
      </>
    );
  }

  // Customizable Area End

  render() {
    const { role } = this.state;
    return (
      // Customizable Area Start
      <>
        {role === 'vendor' &&
          <HeaderVendorFooter navigation={this.props.navigation} isFooterShow={false}>
            {this.contactMainBox()}
          </HeaderVendorFooter>
        }
        {(role === 'user' || !role) &&
          <HeaderFooterLayout navigation={this.props.navigation} isFooterShow={false}>
            {this.contactMainBox()}
          </HeaderFooterLayout>
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const validationContactUsSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .trim()
    .matches(/^[a-zA-Z\s]+$/, "Name can only contain letters")
    .test("not-only-digits", "Name cannot contain only digits", (value) => {
      return !/^\d+$/.test(value);
    })
    .max(50, "Maximum 50 characters are allowed"),
  email: Yup.string()
    .email("Email should be in a valid email format (user@example.com)")
    .required("Email is required")
    .trim(),
  phoneId: Yup.string()
    .required('Country code is required'),
  phone_number: Yup.string().required("Mobile number is required")
    .min(10, "Phone number must be at least 10 characters")
    .max(10, "Phone number must be at most 10 characters").trim(),
  subject_id: Yup.string().required("Subject is required"),
  description: Yup.string()
    .required("Message is required")
    .max(500, "Maximum 500 characters are allowed")
    .trim(),
});

const ContactUsStyle = styled(Box)(({ theme }) => ({
  "& .contact-us-image-container": {
    overflow: "hidden",
    height: "425.27px",
    width: "100%",
    position: "relative",
  },
  "& .contact-us-background-image": {
    objectPosition: "center",
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  "& .image-overlay": {
    background: "#00000070",
    inset: "0",
    position: "absolute",
    paddingTop: "122.33px",
  },
  "& .contact-us-header": {
    fontFamily: "Poppins",
    fontSize: "48px",
    color: "#FFFFFF",
    fontWeight: "600",
    lineHeight: "56px",
    letterSpacing: "-0.015em",
    textAlign: "center",
  },
  "& .contact-us-details-container": {
    padding: "35px 0",
    backgroundImage: `url(${backgroundImage})`,
  },
  "& .contact-us-title": {
    fontFamily: "Poppins",
    fontSize: "40px",
    fontWeight: "500",
    lineHeight: "50px",
    textAlign: "center",
    color: "#1C1917",
  },
  "& .contact-us-side-image": {
    width: "100%",
    maxHeight: "540px",
    borderRadius: "16px",
    objectFit: "cover",
  },
  "& .contact-us-form-image-container": {
    display: "flex",
    marginTop: "60px",
    gap: "115px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: "60px",
      alignItems: "center",
    },
  },
  "& .image-text-container": {
    width: "460px",
    minWidth: "460px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      width: "100%",
    },
  },
  // "& .numberField":{
  //   display:'flex',
  //   gap:'10px',
  //   alignItems:"center",
  //   width:"100%",

  // },
  "& .text-container": {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    marginTop: "32px",
    gap: "4px",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
  "& .text-container-text": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "right",
    color: "#78716C",
    maxWidth: "190px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "unset",
    },
  },
  "& form": { margin: "0" },
  "& .form-container": {
    width: "100%",
    paddingTop: "54px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "600px",
      paddingTop: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
    },
  },
  "& .button-container": {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  "& .contact-us-button": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    textAlign: "left",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#801187 !important",
    borderRadius: "32px",
    padding: "9px 55px",
  },
}));

const CustomContainer = styled(Container)({
  "@media(min-width:1408px)": {
    maxWidth: "1408px !important",
  },
  "@media(min-width:1280px)": {
    maxWidth: "100%",
  },
});
// Customizable Area End
