import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React, { ChangeEvent, RefObject } from "react";
import { apiCall } from "../../../components/src/utils";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { ToastContentProps, toast } from "react-toastify";

// Customizable Area Start
export interface ColorOption {
  id: number,
  label: string;
  value: string;
}
export interface Item {
  title: string;
  description: string;
}
export interface Size {
  size: string;
  measurements: string;
}
export interface ColorSet {
  colorName: string;
  uploadImage: File | null;
  colorSize: string;
  availableQty: string
}
export interface QuesSet {
  Questions: string;
  answer: string;
  id: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
export interface FormDataOption {
  productName: string;
  skuCode: string;
  productCategory: string;
  productDescription: string;
  fabricDetails: string;
  price: string,
  availableQuantity: string,
  coupons: string,
  selectFunction: string[] | string,
  selectedLocation: string,
  selectedFileName: File | null,
  selectedFileNames: string[];
  sizeChart: string;
  productImages: any;
  uploadImage: File | null,
  items: Item[];
  addQues: QuesSet[]
}

interface StateType {
  selectedColors: ColorOption[];
  selectedFunctions: ColorOption[];
  selectedLocations: ColorOption[];
  isLoading: boolean;
  token: string;
  locationData: ColorOption[];
  functionList: ColorOption[];
  productCategory: ColorOption[];
  availableSize: ColorOption[];
  availableColor: ColorOption[];
  couponData: ColorOption[];
  formData: FormDataOption;
  productVariant: number[];
  IsEditing: boolean;
  sizeLabel: string;
  sizeLabelError: boolean;
  sizeLabelMsg: string;
  sizeMeasurement: string;
  sizeMeasurementError: boolean;
  sizeMeasurementMsg: string;
  colorName: string;
  colorNameError: boolean;
  colorNameMsg: string;
  colorImage: any;
  colorImageError: boolean;
  colorImageMsg: string;
  colorPopupAnchor: any;
  openColorPopup: boolean;
  displayColorImage: string;
  variantSize: string;
  variantSizeError: boolean;
  variantSizeMsg: string;
  variantColor: string;
  variantColorError: boolean;
  variantColorMsg: string;
  variantQuantity: string;
  variantQuantityError: boolean;
  variantQuantityMsg: string;
  availableVariants: any[];
  // Customizable Area Start
  // Customizable Area End
}
export interface ServiceLocationData {
  id: string,
  type: string,
  attributes: {
    id: number,
    location_name: string
  }
}
interface FunctionAttributes {
  id: number;
  name: string;
  function_image: string | null;
}

interface Function {
  id: string;
  type: string;
  attributes: FunctionAttributes;
}

interface ServiceLocationAttributes {
  id: number;
  location_name: string;
}

interface ServiceLocation {
  id: string;
  type: string;
  attributes: ServiceLocationAttributes;
}

interface ServiceLocations {
  data: ServiceLocation[];
}

interface CustomFields {
  color: string;
  material: string;
}
export interface CountriesList {
  Countries: Array<Countries>;
}interface ProductAttributes {
  id: number;
  name: string;
  SKU: string;
  description: string;
  fabric_details: string;
  price: string;
  is_published: boolean;
  product_faqs: any[];
  size_chart: string | null;
  is_available: boolean;
  is_deleted: boolean;
  available_quantity: number;
  media_files: any[];
  functions: Function[];
  service_locations: ServiceLocations;
  product_variants: any[];
  custom_fields: CustomFields;
  created_at: string;
}
export interface Product {
  id: string;
  type: string;
  attributes: ProductAttributes;
}

interface Countries {
  name: string;
  dial_code: string;
  code: string;
}

export interface Gender {
  label: string;
  value: string;
}

export interface Industry {
  label: string;
  value: string;
}
export interface Country {
  label: string;
  value: string;
}
interface SSType {
  SSTypeid: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddCatalogueFormController extends BlockComponent<
  Props,
  StateType,
  SSType
> {
  // Customizable Area Start
  submitTransactionApiCallId: string = "";
  getOrderApiCallId: string = "";
  serviceLocationApiCallId: string = "";
  functionDataApiCallId: string = "";
  productCategoryApiCallId: string = "";
  createSizeApiCallId: string = "";
  deleteSizeApiCallId: string = "";
  deleteColorApiCallId: string = "";
  availableSizeApiCallId: string = "";
  createColorApiCallId: string = "";
  availableColorApiCallId: string = "";
  createProductApiCallId: string = "";
  getProductListApiCallId: string = "";
  editProductApiCallId: string = "";
  getCouponListApiCallID: string = "";
  createVariantApiCallId: string = "";
  availableVariantApiCallId: string = "";
  deleteVariantApiCallId: string = "";
  fileInputRef: RefObject<HTMLDivElement[]>
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.fileInputRef = React.createRef();
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      selectedColors: [],
      selectedFunctions: [],
      selectedLocations: [],
      isLoading: false,
      token: "",
      locationData: [],
      functionList: [],
      productCategory: [],
      availableSize: [],
      availableColor: [],
      couponData: [],
      formData: {
        productName: "",
        skuCode: "",
        productCategory: "",
        productDescription: "",
        fabricDetails: "",
        price: "",
        availableQuantity: "",
        coupons: "",
        selectFunction: "",
        selectedFileName: null,
        selectedLocation: "",
        selectedFileNames: [],
        sizeChart: "",
        productImages: [],
        uploadImage: null,
        items: [{ title: "", description: "" }],
        addQues: [{ Questions: "", answer: "", id: '' }],
      },
      // Customizable Area Start
      productVariant: [],
      IsEditing: false,
      sizeLabel: '',
      sizeLabelError: false,
      sizeLabelMsg: '',
      sizeMeasurement: '',
      sizeMeasurementError: false,
      sizeMeasurementMsg: '',
      colorName: '',
      colorNameError: false,
      colorNameMsg: '',
      colorImage: null,
      colorImageError: false,
      colorImageMsg: '',
      colorPopupAnchor: null,
      openColorPopup: false,
      displayColorImage: '',
      variantSize: '',
      variantSizeError: false,
      variantSizeMsg: '',
      variantColor: '',
      variantColorError: false,
      variantColorMsg: '',
      variantQuantity: '',
      variantQuantityError: false,
      variantQuantityMsg: '',
      availableVariants: [],
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Api response
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.serviceLocationApiCallId:
          const mappedLocations = responseJson.data.map((location: any) => ({
            value: location.id,
            label: location.attributes.location_name
          }));
          this.setState({ locationData: mappedLocations });
          break;

        case this.functionDataApiCallId:
          const mappedFunction = responseJson.data.map((location: any) => ({
            value: location.id,
            label: location.attributes.name
          }));
          this.setState({ functionList: mappedFunction });
          break;

        case this.productCategoryApiCallId:
          const mappedCategory = responseJson.data.map((location: any) => ({
            value: location.id,
            label: location.attributes.name
          }));
          this.setState({ productCategory: mappedCategory });
          break;

        case this.availableSizeApiCallId:
          const availableSize = responseJson?.data?.map((size: any) => ({
            id: size.attributes.id,
            value: size.attributes.measurement,
            label: size.attributes.size_label,
          }));
          this.setState({ availableSize: availableSize });
          break;

        case this.availableColorApiCallId:
          const availableColor = responseJson?.data?.map((color: any) => ({
            id: color.attributes.id,
            value: color.attributes.color_image,
            label: color.attributes.name,
          }));
          this.setState({ availableColor: availableColor });
          break;

        case this.availableVariantApiCallId:
          const availableVariants = responseJson?.data?.map((item: any) => {
            const variant = item.attributes;
            return {
              id: variant.id,
              size: variant.product_size.attributes,
              color: variant.product_color.attributes,
              quantity: variant.available_count,
            }
          });
          console.log("availableVariants: ", availableVariants);
          this.setState({ availableVariants: availableVariants });
          break;

        case this.createSizeApiCallId:
          if (!responseJson.errors) {
            toast.success("Size Created Sucessfully");
            this.setState({
              sizeLabel: '',
              sizeMeasurement: '',
            })
          } else {
            toast.error("Failed to add size");
          }
          this.getAvailableSizeApi()
          break;

        case this.createColorApiCallId:
          if (!responseJson.errors) {
            toast.success("Color Created Sucessfully");
            this.setState({
              colorName: '',
              colorImage: null,
            })
          } else {
            toast.error("Failed to add color");
          }
          this.getAvailableColorApi();
          break;

        case this.createVariantApiCallId:
          if (!responseJson.errors) {
            toast.success("Variant Created Sucessfully");
            this.setState({
              variantSize: '',
              variantColor: '',
              variantQuantity: '',
            })
          } else {
            toast.error("Failed to create variant");
          }
          this.getAvailableVariantApi();
          break;

        case this.getProductListApiCallId:
          this.setEditProductData(responseJson)
          this.setState({ isLoading: false });
          break;

        case this.getCouponListApiCallID:
          const couponData = responseJson?.data?.map((size: any) => ({
            value: size.id,
            label: size.attributes.coupon_code
          }));
          this.setState({ couponData: couponData });
          break;

        case this.createProductApiCallId:
          this.setCreateProductApi(responseJson)
          this.setState({ isLoading: false });
          break;

        case this.editProductApiCallId:
          this.setEditProductApi(responseJson)
          this.setState({ isLoading: false });
          break;

        case this.deleteSizeApiCallId:
          if (!responseJson.errors) {
            toast.success("Size Deleted Sucessfully");
          }
          this.getAvailableSizeApi();
          break;

        case this.deleteColorApiCallId:
          if (!responseJson.errors) {
            toast.success("Color Deleted Sucessfully");
          }
          this.getAvailableColorApi()
          break;
        case this.deleteVariantApiCallId:
          if (!responseJson.errors) {
            toast.success("Variant Deleted Sucessfully");
          }
          this.getAvailableVariantApi()
          break;

        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    this.serviceLoationApi();
    this.getToken();
    this.functionDataApi()
    this.getProductCategoryApi()
    this.getAvailableSizeApi()
    this.getAvailableColorApi()
    this.getAvailableVariantApi()
    this.getProductListApiCall()
    this.getCouponList()
    const isEditing = await getStorageData('IsEditing');
    if (isEditing == "true") {
      this.setState({ IsEditing: true })
    }
  };
  setCreateProductApi = (responseJson: { error: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | ((props: ToastContentProps<unknown>) => React.ReactNode) | null | undefined; }) => {
    if (responseJson.error) {
      toast.error(responseJson.error)
    }
    else {
      toast.success("Catalogue Created Sucessfully");
      const addCatalogueForm: Message = new Message(getName(MessageEnum.NavigationMessage));
      addCatalogueForm.addData(getName(MessageEnum.NavigationTargetMessage), "Catalogue");
      addCatalogueForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(addCatalogueForm);
    }
  }
  setEditProductApi = (responseJson: { error: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | ((props: ToastContentProps<unknown>) => React.ReactNode) | null | undefined; }) => {
    if (responseJson.error) {
      toast.error(responseJson.error)
    }
    else {
      toast.success("Edit Catalogue Sucessfully");
      removeStorageData('isEditing')
      const editForm: Message = new Message(getName(MessageEnum.NavigationMessage));
      editForm.addData(getName(MessageEnum.NavigationTargetMessage), "Catalogue");
      editForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(editForm);
    }
  }
  urlToFile = async (url: RequestInfo, filename: string, mimeType: string) => {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    return new File([arrayBuffer], filename, { type: mimeType });
  };
  setEditProductData = async (responseJson: { data: any[]; }) => {
    let editId = await getStorageData("editId");
    if (this.state.IsEditing) {
      let editFilterData = responseJson.data.filter((data: Product) => {
        return data.attributes.id == editId
      })
      const sizeChartUrl = editFilterData[0]?.attributes?.size_chart
      const locationData = editFilterData[0]?.attributes?.service_locations?.data?.map((location: any) => ({
        value: location.id,
        label: location.attributes.location_name
      }));
      const functionData = editFilterData[0]?.attributes?.functions?.map((location: any) => ({
        value: location.attributes.id,
        label: location.attributes.name
      }));
      const attributes = editFilterData[0]?.attributes || {};

      const items = Object.entries(attributes.custom_fields || {}).map(
        ([key, value]) => ({
          title: key,
          description: value,
        })
      );

      const addQues = await Promise.all(
        (attributes.product_faqs || []).map(async (data: any) => {

          return {
            Questions: data.attributes.question || '',
            answer: data.attributes.answer || '',
            id: data.attributes.id
          };
        })
      );

      const sizeChartFile = await this.urlToFile(sizeChartUrl, "size_chart.png", "image/png");
      this.setState((prevState: any) => {
        return {
          formData: {
            ...prevState.formData,
            productName: attributes.name || '',
            skuCode: attributes.SKU || '',
            productCategory: attributes.category?.id || '',
            productDescription: attributes.description || '',
            fabricDetails: attributes.fabric_details || '',
            price: attributes.price || 0,
            availableQuantity: attributes.available_quantity || 0,
            selectedFileName: sizeChartFile,
            sizeChart: sizeChartFile?.name || '',
            selectedLocation: locationData,
            selectFunction: functionData,
            coupons: attributes.coupon.id,
            items,
            addQues,
            productImages: [
              ...(prevState.formData.productImages || []),
              ...(attributes.media_files || [])
            ]
          },
        };
      });

      this.setState({ selectedLocations: locationData || [], selectedFunctions: functionData || [] })
    }

  }
  handleFunctionSelectChange = (event: React.ChangeEvent<{}>, newValue: ColorOption[], setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    this.setState({ selectedFunctions: newValue });
    setFieldValue('selectFunction', newValue);
  };
  handleDeleteFunctionChip = (optionToDelete: ColorOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedFunctions = this.state.selectedFunctions.filter(
      (item) => item.value != optionToDelete.value
    );
    this.setState({ selectedFunctions: newSelectedFunctions });
    setFieldValue('selectFunction', newSelectedFunctions);
  };

  toggleFunctionOption = (optionToToggle: ColorOption) => {
    Array.isArray(this.state.selectedFunctions) && this.setState((prevState) => {
      const { selectedFunctions } = prevState;
      const isSelected = Array.isArray(selectedFunctions) && selectedFunctions.some(option => option.value == optionToToggle.value);
      if (isSelected) {
        return {
          selectedFunctions: selectedFunctions.filter(option => option?.value != optionToToggle?.value),
        };
      } else {
        return {
          selectedFunctions: [...selectedFunctions, optionToToggle],
        };
      }
    });
  };
  handleFunctionOption = (option: ColorOption, isSelected: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedFunctions = isSelected
      ? this.state.selectedFunctions.filter(
        (selected) => selected.value != option.value
      )
      : [...this.state.selectedFunctions, option];
    this.setState({ selectedFunctions: newSelectedFunctions });
    setFieldValue('selectFunction', newSelectedFunctions);
  }
  handleSelectLocationChange = (event: React.ChangeEvent<{}>, newValue: ColorOption[], setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    this.setState({ selectedLocations: newValue });
    setFieldValue('selectedLocation', newValue);
  };

  handleDeleteLocationChip = (optionToDelete: ColorOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedLocation = this.state.selectedLocations.filter(
      (item) => item.value != optionToDelete.value
    );
    this.setState({ selectedLocations: newSelectedLocation });
    setFieldValue('selecredLocation', newSelectedLocation);
  };
  toggleLocationOption = (optionToToggle: ColorOption) => {
    this.setState((prevState) => {
      const { selectedLocations } = prevState;
      const isSelected = selectedLocations.some(option => option?.value === optionToToggle?.value);
      if (isSelected) {
        return {
          selectedLocations: selectedLocations.filter(option => option?.value !== optionToToggle?.value),
        };
      } else {
        return {
          selectedLocations: [...selectedLocations, optionToToggle],
        };
      }
    });
  };
  handleLocationOption = (option: ColorOption, isSelected: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedLocation = isSelected
      ? this.state.selectedLocations.filter(
        (selected) => selected.value !== option.value
      )
      : [...this.state.selectedLocations, option];
    this.setState({ selectedLocations: newSelectedLocation });
    setFieldValue('selectedLocation', newSelectedLocation);
  }
  handleErrorText = (touched: boolean | undefined, error: any): string => {
    return touched && error ? error : '';
  };
  handleIconClick = () => {
    const inputElement = document.getElementById('hiddenFileInput') as HTMLInputElement;
    if (inputElement) inputElement.click();
  };
  handleProductIconClick = () => {
    const inputElement = document.getElementById('hiddenProductFileInput') as HTMLInputElement;
    if (inputElement) inputElement.click();
  };
  handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 9.9 * 1024 * 1024) {
        setFieldValue('selectedFileName', null);
        setFieldValue('sizeChart', '');
      } else {
        setFieldValue('selectedFileName', file);
        setFieldValue('sizeChart', file.name);
      }
    }
  };
  handleColorFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      if (file.size <= 2 * 1024 * 1024) {
        this.setState({ colorImage: file, colorImageError: false, colorImageMsg: '' });
      } else {
        alert("Please select a file smaller than 2MB.");
        this.setState({ colorImage: null });
      }
    }
  };

  serviceLoationApi = async () => {
    this.serviceLocationApiCallId = await apiCall({
      endPoint: "bx_block_multipageforms2/service_locations",
      method: "GET",
      token: this.state.token,
    });
  }
  functionDataApi = async () => {
    this.functionDataApiCallId = await apiCall({
      endPoint: "bx_block_multipageforms2/functions",
      method: "GET",
      token: this.state.token,
    });
  }
  getToken = async () => {
    const token = await getStorageData("authToken");
    if (token) {
      this.setState({
        token,
      });
    } else {
      this.props.navigation.goBack();
    }
  };
  getProductCategoryApi = async () => {
    this.productCategoryApiCallId = await apiCall({
      endPoint: "bx_block_categories/categories",
      method: "GET",
      token: this.state.token,
    });
  }
  createSizeApi = async (sizeName: string, measurement: string) => {
    let isValid = true;
    if (!sizeName) {
      this.setState({ sizeLabelError: true, sizeLabelMsg: 'Size label is required' });
      isValid = false;
    }
    if (!measurement) {
      this.setState({ sizeMeasurementError: true, sizeMeasurementMsg: 'Size measurement is required' });
      isValid = false;
    }
    if (!isValid) {
      return false;
    }

    const formData = new FormData();
    formData.append("size_label", sizeName);
    formData.append("measurement", measurement);
    this.createSizeApiCallId = await apiCall({
      endPoint: "bx_block_multipageforms2/product_sizes",
      method: "POST",
      token: this.state.token,
      body: formData,
    });
  }

  getAvailableSizeApi = async () => {
    const isEditing = await getStorageData('IsEditing');
    let editId = await getStorageData("editId");
    let apiUrl = 'bx_block_multipageforms2/product_sizes';

    if (isEditing && editId) {
      apiUrl = `bx_block_multipageforms2/product_sizes?product_id=${editId}`;
    }

    this.availableSizeApiCallId = await apiCall({
      endPoint: apiUrl,
      method: "GET",
      token: this.state.token,
    });
  }

  getAvailableColorApi = async () => {
    const isEditing = await getStorageData('IsEditing');
    let editId = await getStorageData("editId");
    let apiUrl = 'bx_block_multipageforms2/product_colors';

    if (isEditing && editId) {
      apiUrl = `bx_block_multipageforms2/product_colors?product_id=${editId}`;
    }

    this.availableColorApiCallId = await apiCall({
      endPoint: apiUrl,
      method: "GET",
      token: this.state.token,
    });
  }

  handleSizeDeleteImage = (setFieldValue: (field: string, value: any) => void) => {
    setFieldValue('selectedFileName', null);
    setFieldValue('sizeChart', '');
  };
  handleFileChangeProduct = (event: React.ChangeEvent<HTMLInputElement>, values: FormDataOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const files = Array.from(event.target.files || []);
    const newFiles = files.map((file) => ({ file, filename: file.name, url: URL.createObjectURL(file) }));
    setFieldValue("productImages", [...(values.productImages || []), ...newFiles]);
  };
  handleDeleteImage = (index: number, values: FormDataOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const updatedImages = values.productImages.filter((_: any, imgIndex: number) => imgIndex !== index);
    setFieldValue("productImages", updatedImages);
  };

  createAddColor = async (name: string, image: any) => {
    let isValid = true;
    if (!name) {
      this.setState({ colorNameError: true, colorNameMsg: 'Color name is required' });
      isValid = false;
    }
    if (!image) {
      this.setState({ colorImageError: true, colorImageMsg: 'Image is required' });
      isValid = false;
    }
    if (!isValid) {
      return false;
    }

    const formData = new FormData();
    formData.append("name", name);
    if (image) {
      formData.append("color_image", image);
    }
    this.createColorApiCallId = await apiCall({
      endPoint: "bx_block_multipageforms2/product_colors",
      method: "POST",
      token: this.state.token,
      body: formData,
    });
  }

  createVariant = async (size: string, color: string, quantity: string) => {
    console.log("size: ", size);
    console.log("color: ", color);
    console.log("quantity: ", quantity);

    let isValid = true;
    if (!size) {
      this.setState({ variantSizeError: true, variantSizeMsg: 'Size is required' });
      isValid = false;
    }
    if (!color) {
      this.setState({ variantColorError: true, variantColorMsg: 'Color is required' });
      isValid = false;
    }
    if (!quantity) {
      this.setState({ variantQuantityError: true, variantQuantityMsg: 'Quantity is required' });
      isValid = false;
    }
    if (!isValid) {
      return false;
    }

    const formData = new FormData();
    formData.append("product_size_id", size);
    formData.append("product_color_id", color);
    formData.append("available_count", quantity);

    this.createVariantApiCallId = await apiCall({
      endPoint: "bx_block_multipageforms2/product_variants",
      method: "POST",
      token: this.state.token,
      body: formData,
    });
  }

  getAvailableVariantApi = async () => {
    const isEditing = await getStorageData('IsEditing');
    let editId = await getStorageData("editId");
    let apiUrl = 'bx_block_multipageforms2/product_variants';

    if (isEditing && editId) {
      apiUrl = `bx_block_multipageforms2/product_variants?product_id=${editId}`;
    }

    this.availableVariantApiCallId = await apiCall({
      endPoint: apiUrl,
      method: "GET",
      token: this.state.token,
    });
  }

  handleProceedApiCAll = async (values: FormDataOption) => {

    if (this.state.IsEditing) {
      this.handleEditCatalogueApi(values)
    }
    else {
      this.handleCreateCatalogoueApi(values)
    }
  }
  handleCreateCatalogoueApi = async (values: FormDataOption) => {
    this.setState({ isLoading: true });
    const variants = this.state.availableVariants.map((item) => item.id);
    if (variants.length === 0) {
      return false;
    }

    let formData = new FormData();
    formData.append("product[name]", values.productName);
    formData.append("product[description]", values.productDescription);
    formData.append("product[fabric_details]", values.fabricDetails);
    formData.append("product[price]", values.price);
    formData.append("product[available_quantity]", values.availableQuantity);
    formData.append("product[SKU]", values.skuCode);
    formData.append("product[category_id]", values.productCategory);
    formData.append("product[coupon_id]", values.coupons);
    formData.append("product[product_variants]", JSON.stringify(variants));
    if (Array.isArray(values.selectFunction)) {
      values.selectFunction.forEach((spec: any) => {
        formData.append("product[function_ids][]", spec.value);
      });
    }
    if (Array.isArray(values.selectedLocation)) {
      values.selectedLocation.forEach((spec: any) => {
        formData.append("product[service_location_ids][]", spec.value);
      });
    }
    values.items.forEach((item: Item, index: number) => {
      formData.append(`product[custom_fields][${item.title}]`, item.description);
    });
    values.addQues.forEach((item: QuesSet, index: number) => {
      formData.append(`product[product_faqs_attributes][${index}][question]`, item.Questions);
      formData.append(`product[product_faqs_attributes][${index}][answer]`, item.answer);
    })
    if (values.selectedFileName) {
      formData.append("product[size_chart]", values.selectedFileName);
    }
    values.productImages.forEach((image: any, index: number) => {
      if (image?.file) {
        formData.append("product[media_files][]", image.file);
      }
    });
    this.createProductApiCallId = await apiCall({
      endPoint: "bx_block_multipageforms2/products",
      method: "POST",
      token: this.state.token,
      body: formData,
    });
  }
  appendProductImagesToFormData = async (values: FormDataOption, formData: FormData) => {
    await Promise.all(
      values.productImages?.map(async (image: any) => {
        const sizeChartFile = await this.urlToFile(image.url, "size_chart.png", "image/png");
        if (sizeChartFile) {
          formData.append("product[media_files][]", sizeChartFile);
        }
      })
    );
  }
  handleEditCatalogueApi = async (values: FormDataOption) => {
    this.setState({ isLoading: true });
    const variants = this.state.availableVariants.map((item) => item.id);
    if (variants.length === 0) {
      return false;
    }

    let formData = new FormData();
    let editId = await getStorageData("editId");

    formData.append("product[name]", values.productName);

    formData.append("product[available_quantity]", values.availableQuantity);
    formData.append("product[category_id]", values.productCategory);
    formData.append("product[description]", values.productDescription);
    formData.append("product[fabric_details]", values.fabricDetails);
    formData.append("product[SKU]", values.skuCode);
    formData.append("product[price]", values.price);
    formData.append("product[product_variants]", JSON.stringify(variants));
    formData.append("product[coupon_id]", values.coupons);

    if (Array.isArray(values.selectFunction)) {
      values.selectFunction.forEach((spec: any) => {
        formData.append("product[function_ids][]", spec.value);
      });
    }
    if (values.productImages) {
      await this.appendProductImagesToFormData(values, formData);
    }
    if (Array.isArray(values.selectedLocation)) {
      values.selectedLocation.forEach((spec: any) => {
        formData.append("product[service_location_ids][]", spec.value);
      });
    }
    values.items.forEach((item: Item, index: number) => {
      formData.append(`product[custom_fields][${item.title}]`, item.description);
    });
    values.addQues.forEach((item: QuesSet, index: number) => {
      if (item.id) {
        formData.append(`product[product_faqs_attributes][${index}][id]`, item.id);
      }
      formData.append(`product[product_faqs_attributes][${index}][question]`, item.Questions);
      formData.append(`product[product_faqs_attributes][${index}][answer]`, item.answer);
    })
    if (values.selectedFileName) {
      formData.append("product[size_chart]", values.selectedFileName);
    }
    this.editProductApiCallId = await apiCall({
      endPoint: `bx_block_multipageforms2/products/${editId}`,
      method: "PATCH",
      token: this.state.token,
      body: formData,
    });
  }
  getProductListApiCall = async () => {
    this.setState({ isLoading: true });
    this.getProductListApiCallId = await apiCall({
      endPoint: "bx_block_multipageforms2/products",
      method: "GET",
      token: this.state.token,
    });
  }
  getCouponList = async () => {
    this.getCouponListApiCallID = await apiCall({
      endPoint: "bx_block_discount/checkout_addition_costs",
      method: "GET",
      token: this.state.token,
    });
  }

  handleInputChange = (event: any) => {
    const { name, value } = event.target;

      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
      }), () => {
        switch (name) {
          case 'sizeLabel':
            this.setState({ sizeLabelError: false, sizeLabelMsg: '' });
            break;

          case 'sizeMeasurement':
            this.setState({ sizeMeasurementError: false, sizeMeasurementMsg: '' });
            break;

          case 'colorName':
            this.setState({ colorNameError: false, colorNameMsg: '' });
            break;

          case 'variantQuantity':
            this.setState({ variantQuantityError: false, variantQuantityMsg: '' });
            break;

          default:
            break;
        }
      });
  }

  handleDeleteSize = async (id: number) => {
    this.deleteSizeApiCallId = await apiCall({
      endPoint: `bx_block_multipageforms2/product_sizes/${id}`,
      method: "DELETE",
    });
  }

  handleMouseEnter = (event: any, color: ColorOption) => {
    event.preventDefault();
    this.setState({ colorPopupAnchor: event.currentTarget, openColorPopup: true, displayColorImage: color.value });
  };

  handleMouseLeave = (event: any) => {
    event.preventDefault();
    this.setState({ colorPopupAnchor: null, openColorPopup: false });
  };

  handleDeleteColor = async (id: number) => {
    this.deleteColorApiCallId = await apiCall({
      endPoint: `bx_block_multipageforms2/product_colors/${id}`,
      method: "DELETE",
    });
  }

  handleDropdownChange = (event: any) => {
    const { name, value } = event.target;
    if (value) {
      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
      }), () => {
        switch (name) {
          case 'variantSize':
            this.setState({ variantSizeError: false, variantSizeMsg: '' });
            break;

          case 'variantColor':
            this.setState({ variantColorError: false, variantColorMsg: '' });
            break;

          default:
            break;
        }
      });
    }
  }

  handleDeleteVariant = async (id: number) => {
    this.deleteVariantApiCallId = await apiCall({
      endPoint: `bx_block_multipageforms2/product_variants/${id}`,
      method: "DELETE",
    });
  }
  // Customizable Area End
}
