import React from "react";
import { Field } from "formik";
import {
  TextField,
  Typography,
  Box,
  styled,
  Theme,
  SxProps,
} from "@mui/material";

interface TextInputFiledProps {
  name: string;
  label?: string;
  placeholder?: string;
  value: string;
  error?: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (event: React.FocusEvent) => void;
  type: string;
  InputProps?: React.ReactNode;
  helperText: string | boolean | undefined;
  sx?: SxProps<Theme>;
  isRequired?: boolean;
  disabled?: boolean;
}

const StyledInputTextField = styled(Box)({
  marginBottom: "16px",
  "& .common-form-label": {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "4px",
    display: "block",
    "& span": {
      color: "#DC2626",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "22px",
    },
  },
  "& .MuiInputBase-input": {
    padding: "12px 8px",
    fontFamily: "Poppins", // Forces uniform width
    fontFeatureSettings: "'tnum' on", // Forces tabular numbers
    fontSize: "18px", // Bigger font ensures better alignment
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
    color: "#0F172A",
     // Forces uniform spacing
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    display: "flex",
    alignItems: "center", // Ensures vertical alignment
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
  "& .MuiInputBase-root": {
    borderRadius: "0px",
    backgroundColor: "#FFFFFF",
  },
  "& .MuiFormHelperText-root": {
    color: "#DC2626",
    margin: "8px 0 0",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
  },
  "& .Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171 !important",
    borderWidth: "1px !important",
    borderStyle: "solid",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderLeft: "0px",
    borderBottom: "1px",
    borderRight: "0px",
    borderTop: "0px",
    borderStyle: "solid",
    borderColor: "#E2E8F0",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
    borderRadius: "8px",
    borderWidth: "1px",
  },
});

const TextInputFiled: React.FC<TextInputFiledProps> = ({
  name,
  label,
  placeholder = "",
  InputProps,
  value,
  error,
  handleChange,
  handleBlur,
  helperText,
  type,
  sx,
  isRequired,
  disabled,
}) => {
  return (
    <StyledInputTextField sx={sx}>
      {label && (
        <Typography component="label" className="common-form-label">
          {label}
          {isRequired && <Typography component="span">*</Typography>}
        </Typography>
      )}

      <Field
        as={TextField}
        name={name}
        type={type}
        placeholder={placeholder}
        fullWidth
        disabled={disabled}
        value={value}
        sx={{
          "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "& input[type=number]": {
            "-moz-appearance": "textfield",
          },
        }}
        onChange={handleChange}
        onBlur={handleBlur}
        error={error}
        helperText={helperText}
        InputProps={InputProps}
      />
    </StyledInputTextField>
  );
};

export default TextInputFiled;
