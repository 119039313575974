import React from "react";

// Customizable Area Start
import HeaderFooterLayout from "../../../../components/src/HeaderFooterLayout";
import { Box, Typography, styled, CardActionArea, CardContent, CardActions, Card, Rating, Button, Grid } from "@mui/material";
import { productDefaultImage } from "../assets";

// Customizable Area End
import VendorsByCategoryVendorController, { Props } from "./VendorsByCategoryController";

export default class VendorsByCategory extends VendorsByCategoryVendorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderVendorCard = (vendor: any, index: number) => {
    return (
      <Grid item xs={12} sm={12} md={3} lg={4} xl={4}>
        <Card key={index} className="photographerCard" data-test-id={`photographerCard-${index}`}>
          <CardActionArea>
            <img
              className="cardsImg"
              src={vendor.profile_picture || productDefaultImage}
              alt={vendor.business_name}
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
              data-test-id={"photographerImage"}
            />
            <CardContent>
              <Box
                className="photographerTitileRatingBox"
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <Typography className="photographerTitle" variant="h6" data-test-id={`photographerTitle-${index}`}>
                  {vendor.business_name}
                </Typography>
                <Box className="ratingBox" sx={{ display: "flex", alignItems: "center" }}>
                  <Rating value={1} max={1} precision={1} readOnly size="small" />
                  <Typography className="ratingText" sx={{ marginLeft: "4px", fontSize: "0.875rem" }} data-test-id={`photographerRating-${index}`}>
                    {"7.5 (15 Ratings)"}
                  </Typography>
                </Box>
              </Box>
              <Typography className="photographerLocation" variant="body2" color="textSecondary" data-test-id={`photographerLocation-${index}`}>
                {vendor.vendor_address?.attributes.city}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions style={{ width: "100%", display: "flex", justifyContent: "space-between", borderRadius: "8px", padding: "8px 16px" }}>
            <Typography className="photographerPackage" variant="h4" data-test-id={`photographerPackage-${index}`}>
              <span className="photographerPackageText">Package start @</span>{1000}
            </Typography>
            <Button className="getEquiryButton" data-test-id={`getEnquiryButton-${index}`}>Get Enquiry</Button>
          </CardActions>
        </Card>
      </Grid>
    )
  }

  getRandomVendors = (vendors: any[]) => {
    const shuffled = [...vendors].sort((a, b) => {
      return a.id.localeCompare(b.id);
    });
    return shuffled.slice(0, 3);
  };
  //Customizable Area End
  render() {
    const {
      isLoading,
      vendors,
      pageTitle,
      showAllPremiumVendors,
      showAllTopRatedVendors,
      showAllRecommendedVendors,
      errorMessage,
    } = this.state;
    return (
      <>
        {!isLoading ? (
          <HeaderFooterLayout navigation={this.props.navigation}>
            <CustomBox style={{ margin: "0 20px" }}>
              {vendors.length > 0 ?
                <>
                  <Typography
                    style={{
                      fontSize: "36px",
                      lineHeight: "50px",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                    data-test-id="weddingPhotographersTitle"
                  >
                    {pageTitle}
                  </Typography>

                  <Typography className="description" data-test-id="weddingPhotographersDescription">
                    At Pixel Spot Photography Business, we believe that every moment is a masterpiece waiting to be captured. Founded with a passion for storytelling through imagery, our Business specializes in creating timeless memories with a creative touch. Whether it’s the joy of a wedding, the elegance of a portrait, or the spontaneity of a candid moment, we aim to preserve the emotions, beauty, and essence of every experience.
                  </Typography>

                  <Box display={"flex"} justifyContent={"space-between"} marginBottom={"20px"}>
                    <Typography className="vendorTypeTitle">Premium Vendors</Typography>
                    <Typography className="seeAllTitle" onClick={this.toggleShowAllPremiumVendors}>{showAllPremiumVendors ? 'View Less' : 'See All'}</Typography>
                  </Box>
                  <Grid container spacing={3} marginBottom={"50px"}>
                    {(showAllPremiumVendors ? vendors : this.getRandomVendors(vendors)).map((item, index) => {
                      const vendor = item.attributes;
                      return this.renderVendorCard(vendor, index);
                    })}
                  </Grid>

                  <Box display={"flex"} justifyContent={"space-between"} marginBottom={"20px"}>
                    <Typography className="vendorTypeTitle">Top Rated</Typography>
                    <Typography className="seeAllTitle" onClick={this.toggleShowAllTopRatedVendors}>{showAllTopRatedVendors ? 'View Less' : 'See All'}</Typography>
                  </Box>
                  <Grid container spacing={3} marginBottom={"50px"}>
                    {(showAllTopRatedVendors ? vendors : this.getRandomVendors(vendors)).map((item, index) => {
                      const vendor = item.attributes;
                      return this.renderVendorCard(vendor, index);
                    })}
                  </Grid>

                  <Box display={"flex"} justifyContent={"space-between"} marginBottom={"20px"}>
                    <Typography className="vendorTypeTitle">Recommended Vendors</Typography>
                    <Typography className="seeAllTitle" onClick={this.toggleShowAllRecommendedVendors}>{showAllRecommendedVendors ? 'View Less' : 'See All'}</Typography>
                  </Box>
                  <Grid container spacing={3} marginBottom={"50px"}>
                    {(showAllRecommendedVendors ? vendors : this.getRandomVendors(vendors)).map((item, index) => {
                      const vendor = item.attributes;
                      return this.renderVendorCard(vendor, index);
                    })}
                  </Grid>
                </>
                :
                <Box width={"100%"} height={"60vh"} display={"flex"} justifyContent={"center"} alignItems={"center"} border={"1px solid #8C8C8C"} borderRadius={"8px"}>
                  <Typography>{errorMessage}</Typography>
                </Box>
              }

            </CustomBox>
          </HeaderFooterLayout>
        ) : (
          <></>
        )}
      </>
    );
  }
}

// Customizable Area Start
const CustomBox = styled(Box)({
  padding: "3%",
  '& .description': {
    fontSize: "20px",
    fontFamily: "Poppins",
    fontWeight: "400",
    textAlign: "center",
    lineHeight: "34px",
    margin: "40px 0px",
    color: "#57534E",
  },
  '& .vendorTypeTitle': {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "24px",
    color: "#292524",
    lineHeight: "24px",
  },
  '& .seeAllTitle': {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "16px",
    color: "#7F1287",
    lineHeight: "24px",
    cursor: "pointer",
  },
  '& .photographerCard': {
    position: 'relative',
    cursor: 'pointer',
    transition: 'opacity 0.3s ease',
    borderRadius: "8px",
    width: "100%",
    paddingBottom: "10px",
    overflow: "hidden",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  '& .cardsImg': {
    width: '100%',
    minHeight: "400px",
    height: 'auto',
    objectFit: 'cover',
    objectPosition: 'top',
    maxHeight: "400px",
  },
  '& .photographerTitileRatingBox': {
    justifyContent: "space-between",
    display: "flex",
  },
  '& .photographerTitle': {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: "14px",
    '@media (max-width: 768px)': { fontSize: '1rem' },
    '@media (max-width: 480px)': { fontSize: '0.9rem' },
  },
  '& .ratingText': {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: "10px",
    color: "#78716C",
    '@media (max-width: 768px)': { fontSize: '0.7rem' },
    '@media (max-width: 480px)': { fontSize: '0.6rem' },
  },
  '& .photographerLocation': {
    fontFamily: 'Poppins',
    color: "#78716C",
    paddingTop: "1rem",
    minHeight: "36px",
    '@media (max-width: 768px)': { paddingTop: "0.5rem" },
    '@media (max-width: 480px)': { fontSize: '0.8rem' },
  },
  '& .photographerPackage': {
    fontFamily: 'Poppins',
    fontSize: "12px",
    color: "#ff6d00",
    fontWeight: 500,
    '@media (max-width: 768px)': { fontSize: "14px" },
    '@media (max-width: 480px)': { fontSize: '0.9rem' },
  },
  '& .photographerPackageText': {
    fontFamily: 'Poppins',
    color: "#78716C",
    fontSize: "12px",
    '@media (max-width: 768px)': { fontSize: "14px" },
    '@media (max-width: 480px)': { fontSize: '0.9rem' },
  },
  '& .getEquiryButton': {
    fontSize: "12px",
    fontFamily: 'Poppins',
    fontWeight: 600,
    marginLeft: "50px",
    border: "2px solid #8a00c4",
    color: '#8a00c4',
    borderRadius: "8px",
    cursor: "pointer",
  },
})
// Customizable Area End
