import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { styled, Box, Typography, Button, Container, Modal, FormControlLabel, Checkbox ,IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { NavLink } from "react-router-dom";

const facebookLogo = require("../src/assets/facebook.svg");
const twitterLogo = require("../src/assets/twitter.svg");
const instagramLogo = require("../src/assets/instagram.svg");
const youtubeLogo = require("../src/assets/youtube.svg");
const pinterestLogo = require("../src/assets/pinterest.svg");
const footerLogo = require("../src/assets/footer_logo.png");
const checkedImg = require("../src/assets/checked.svg");
const uncheckedImg = require("../src/assets/unchecked.svg");
const linkIcon = require("../src/assets/link.svg");

const termsLink = [
  { label: "Home", path: "/" },
  { label: "About us", path: "/" },
  { label: "Terms & Conditions"},
  { label: "Privacy policy"},
  { label: "Contact us", path: "/contact-us" },
];

const getSocialIcon = (name: string) => {
  switch (name) {
    case "facebook":
      return facebookLogo;

    case "twitter":
      return twitterLogo;

    case "instagram":
      return instagramLogo;

    case "youtube":
      return youtubeLogo;

    case "pinterest":
      return pinterestLogo;

    default:
      return linkIcon;
  }
}

import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import Chat from "../../blocks/chat/src/Chat.web";
import { useBlockHelpers } from "../../../packages/blocks/utilities/src/hooks/useBlockHelpers";
import { useRunEngine } from "../../../packages/blocks/utilities/src/hooks/useRunEngine";
import { Message } from "../../../packages/framework/src/Message";
import MessageEnum, { getName } from "../../../packages/framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import { fonts } from "react-native-elements/dist/config";

interface IFooter {
  className?: string;
}

const Footer: React.FC<IFooter> = ({ className }) => {
  const year = new Date().getFullYear();

  const getCarouselCallId = useRef("");
  const getTermsAndConditionsCallId = useRef("");
  const getPrivacyPolicyCallId = useRef("");
  const getSocialLinks = useRef("");

  const { extractNetworkResponse } = useBlockHelpers();

  const {
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    debugLog,
    unsubscribeFromMessage,
  } = useRunEngine();

  const handleLinkClick = (url: string) => {
    const link = document.createElement("a");

    // Check if the URL is absolute (contains a protocol)
    const isAbsoluteUrl = /^(https?:\/\/|www\.)/.test(url);

    // If it's an absolute URL (external), set the href directly; else treat it as a relative path
    if (isAbsoluteUrl) {
      link.href = url.startsWith("http") ? url : "https://" + url;
    } else {
      link.href = window.location.origin + "/" + url;  // For relative paths
    }

    link.target = "_blank";
    document.body.appendChild(link);
    link.click();

    if (link.parentNode) {
      link.parentNode.removeChild(link);
    }
  };

  const handleAccept = () => {
    if (isModalChecked) {
      setOpenModal({ privacy: false, terms: false });
    } else {
      setErrors({ isModalChecked: true });
    }
  };

  const [openChatModal, setOpenChatModal] = useState(false);
  const [value, setValue] = useState<any>(null);
  const [isModalChecked, setIsModalChecked] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ isModalChecked: boolean }>({ isModalChecked: false });
  const [termsValue, setTermsValue] = useState<any>(null);
  const [privacyValue, setPrivacyvalue] = useState<any>(null);
  const [socialLinks, setSocialLinks] = useState<any>(null);


  const handleChatOpenClose = () => {
    setOpenChatModal((prevData) => !prevData);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setIsModalChecked(isChecked);
    setErrors({ isModalChecked: !isChecked });
  };

  const subscribedMessages = [
    MessageEnum.RestAPIResponceMessage,
    MessageEnum.SessionResponseMessage,
  ];

  const getCarouselData = () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": "application/json",
      token
    };
    sendNetworkRequest(
      getCarouselCallId,
      "GET",
      "/bx_block_landingpage2/why_choose_us/show_contact_info",
      header
    );
  };

  const getPrivacyPolicyData = () => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": "application/json",
      token,
    };
    sendNetworkRequest(
      getPrivacyPolicyCallId,
      "GET",
      "/bx_block_terms_and_conditions/privacy_policies",
      header
    );
  };

  const getTermsAndConditionsData = () => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": "application/json",
      token,
    };
    sendNetworkRequest(
      getTermsAndConditionsCallId,
      "GET",
      "/bx_block_terms_and_conditions/terms_and_conditions",
      header
    );
  };

  const getSocialLinksData = () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": "application/json",
      token
    };
    sendNetworkRequest(
      getSocialLinks,
      "GET",
      "/social_pages",
      header
    );
  };

  const [openModal, setOpenModal] = useState<{ privacy: boolean; terms: boolean }>({
    privacy: false,
    terms: false,
  });

  const handleModalOpen = (type: 'privacy' | 'terms') => {
    setOpenModal({
      privacy: type === 'privacy',
      terms: type === 'terms',
    });
    setIsModalChecked(false);
  };

  const handleModalClose = (event:any) => {
    event?.preventDefault();
    setOpenModal({
      privacy: false,
      terms: false,
    });
  };
  const handleClick = (data: any) => {
    if (data.label === "Terms & Conditions") {
      handleModalOpen("terms");
    } else if (data.label === "Privacy policy") {
      handleModalOpen("privacy");
    }
    if (data.label !== "Terms & Conditions" && data.label !== "Privacy policy") {
      if (data.path === location.pathname) {
        const scrollPosition = data.label === "About us" ? window.innerHeight * 0.7 : 0;
        window.scrollTo({ top: scrollPosition, behavior: "smooth" });
      }
    }
  };
  
  useEffect(() => {
    setReceiveCallback(receive);
    subscribedMessages.forEach((message) => subscribe(message));
    getCarouselData();
    getTermsAndConditionsData();
    getPrivacyPolicyData();
    getSocialLinksData();
    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
    };
  }, []);

  const receive = (from: string, message: Message) => {
    debugLog("API Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } =
        extractNetworkResponse(message);
      if (responseJson) {
        if (
          apiRequestCallId === getCarouselCallId.current &&
          responseJson?.data
        ) {
          setValue(responseJson.data)
        }
        if (apiRequestCallId === getTermsAndConditionsCallId.current && responseJson) {
          setTermsValue(responseJson);
        }
        if (apiRequestCallId === getPrivacyPolicyCallId.current && responseJson) {
          setPrivacyvalue(responseJson);
        }
        if (apiRequestCallId === getSocialLinks.current && responseJson) {
          setSocialLinks(responseJson);
        }
      }
    }

  };
  return (
    <>
    <CustomContainer className={className}>
      <FooterStyle>
        <HorizontalLine />
        <Box className="text-image-container">
          <Box className="left-text-container">
            <Box className="contact-us-section">
              <Typography variant="h6" className="footer-title">
                Contact us to get best deals
              </Typography>
              <Box className="vendor-user-container">
                <Box>
                  <Typography variant="h6" className="vendor-title">
                    For vendors
                  </Typography>
                  <Typography className="vendor-description" style={{ marginTop:"8px"}}>
                    {value?.attributes.vendor_email}
                  </Typography>
                  <Typography className="vendor-description" style={{ marginTop:"4px"}}>
                    {value?.attributes.vendor_country_code}-{value?.attributes.vendor_phone_number}
                  </Typography>
                </Box>
                <Box>
                  <VerticalLine />
                </Box>
                <Box>
                  <Typography variant="h6" className="vendor-title">
                    For users
                  </Typography>
                  <Typography className="vendor-description">
                    {value?.attributes.user_email}
                  </Typography>
                  <Typography className="vendor-description">
                  {value?.attributes.user_country_code}-{value?.attributes.user_phone_number}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="h6" className="footer-title" style={{ marginBottom:"16px" }}>
                Registered address
              </Typography>
              <Typography className="address">
                {value?.attributes.address}
              </Typography>
            </Box>
            <Box className="company-section">
              <Typography variant="h6" className="footer-title">
                Company
              </Typography>
              <Box className="link-container">
                {termsLink.map((data, index) => (
                  <Typography 
                  style={{cursor:"pointer"}}
                    key={index}
                    component="span"
                    className="company-link"
                    onClick={() => handleClick(data)}
                  >
                    {data.path ? (
                      <NavLink
                        to={data.path}
                        className="company-link"
                      >
                        {data.label}
                      </NavLink>
                    ) : (
                      data.label
                    )}
                  </Typography>
                ))}
              </Box>
            </Box>
            <Box className="follow-us-section">
              <Typography variant="h6" className="footer-title">
                Follow us on
              </Typography>
              <Box className="follow-link-container">
                {socialLinks && socialLinks.map((data: any) => (
                  <Typography
                    key={data.id}
                    className="follow-link"
                    onClick={() => handleLinkClick(data.url)}
                  >
                    <img
                      src={getSocialIcon(data.name.toLocaleLowerCase())}
                      alt={data.name}
                      className="social-icon"
                      style={{ width: "24px", height: "24px" }}
                    />
                    {data.name}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
          <Box className="right-image-container">
            <img src={footerLogo} alt="footer" className="footer-image" />
            <Box position="relative">
              <Button
                className="chat-with-us-button"
                onClick={handleChatOpenClose}
              >
                <ChatBubbleOutlineIcon /> Chat with us
              </Button>
              {openChatModal && (
                <Box className="chat-modal-design-container">
                  <Chat
                    id="chat"
                    handleChatClose={handleChatOpenClose}
                    data-test-id="handleChatClose"
                    navigation={undefined}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <HorizontalLine />
        <Typography className="copyright-text">© {year} Happiffie,  All rights reserved.</Typography>
      </FooterStyle>
    </CustomContainer>
      <Modal open={openModal.privacy} onClose={handleModalClose}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      padding: { xs: "1rem", sm: "0" },
                    }}
                  >
                    <Box
                      className="modal-content"
                      sx={{
                        width: { xs: "90vw", sm: "85vw" },
                        height: { xs: "auto", sm: "75vh" },
                        background: "white",
                        borderRadius: "25px",
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden",
                        position: "relative", 
                      }}
                    >
                      <IconButton
                        onClick={handleModalClose}
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          color: "#57534E",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <Box sx={{ display: "flex", padding: "1rem 3rem" }}>
                        <img
                          src={footerLogo}
                          style={{ width: "160px", height: "108px" }}
                          alt="Footer Logo"
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: { xs: "20px", sm: "22px" },
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          paddingLeft: "3rem"
                        }}
                      >
                        Privacy Policy
                      </Typography>
                      <Box
                        sx={{
                          margin: "auto",
                          fontSize: { xs: "14px", sm: "16px" },
                          padding: { xs: "1rem", sm: "1rem 3rem" },
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          lineHeight: "20px",
                          color: "#57534E",
                          overflowY: "auto",
                          maxHeight: "50vh",
                        }}
                        className="privacy-policy-description"
                        dangerouslySetInnerHTML={{
                          __html: privacyValue && privacyValue[0]?.description,
                        }}
                      />
                      <Box
                        className="privacy-policy-button-container"
                        sx={{
                          padding: { xs: "1rem", sm: "1rem 3rem" },
                          display: "flex",
                          flexDirection: { xs: "column", sm: "row" },
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >                 
                      </Box>
                    </Box>
                  </Box>
                </Modal>
                <Modal open={openModal.terms} onClose={handleModalClose}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      padding: { xs: "1rem", sm: "0" },
                    }}
                  >
                    <Box
                      className="modal-content"
                      sx={{
                        width: { xs: "90vw", sm: "85vw" },
                        height: { xs: "auto", sm: "75vh" },
                        background: "white",
                        borderRadius: "25px",
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden",
                        position: "relative", 
                      }}
                    >
                      <IconButton
                        onClick={handleModalClose}
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          color: "#57534E",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <Box sx={{ display: "flex", padding: "1rem 3rem" }}>
                        <img
                          src={footerLogo}
                          style={{ width: "160px", height: "108px" }}
                          alt="Footer Logo"
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: { xs: "20px", sm: "22px" },
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          paddingLeft: "3rem"
                        }}
                      >
                        Terms & Conditions
                      </Typography>
                      <Box
                        sx={{
                          margin: "auto",
                          fontSize: { xs: "14px", sm: "16px" },
                          padding: { xs: "1rem", sm: "1rem 3rem" },
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          lineHeight: "20px",
                          color: "#57534E",
                          overflowY: "auto",
                          maxHeight: "50vh",
                        }}
                        className="terms-condition-description"
                        dangerouslySetInnerHTML={{
                          __html: termsValue && termsValue[0]?.description,
                        }}
                      />
                      <Box
                        className="terms-condition-button-container"
                        sx={{
                          padding: { xs: "1rem", sm: "1rem 3rem" },
                          display: "flex",
                          flexDirection: { xs: "column", sm: "row" },
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >                     
                      </Box>
                    </Box>
                  </Box>
                </Modal>
                </>
  );
};

export default Footer;

const FooterStyle = styled("footer")(({ theme }) => ({
  padding: "40px 0",
  zIndex: "100",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    padding: "50px 0",
  },
  "& .chat-modal-design-container": {
    zIndex: 200,
    position: "absolute",
    bottom: "75px",
    right: "0px",
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      left: "-80%"
    },
  },
  "& .vendor-user-container": {
    display: "flex",
    gap: "30px",
    marginBottom: "64px",
    [theme.breakpoints.down("lg")]: {
      gap: "20px",
    },
  },
  "& .copyright-text": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#78716C",
    padding: "30px 27px 0px",
    textAlign:"center",
    [theme.breakpoints.down("md")]: {
      padding: "16px 0px 0px",
    }
  },
  "& .contact-us-section": {
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  "& .terms-condition-text": {
    fontSize: "20px",
    fontFamily: "poppins",
    fontWeight: 400,
  },
  "& .text-decoration": {
    fontSize: "20px",
    fontFamily: "poppins",
    fontWeight: 400,
  },
  "& .address": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#78716C",
    maxWidth: "253px",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "unset",
    },
  },
  "& .footer-title": {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#292524",
    marginBottom: "24px",
  },
  "& .chat-with-us-button": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#FFFFFF",
    backgroundColor: "#7F1187 !important",
    borderRadius: "30px",
    gap: "8px",
    padding: "10px 24.48px",
    textTransform: "none",
    width: "fit-content",
  },
  "& .text-image-container": {
    display: "flex",
    justifyContent: "space-between",
    gap: "30px",
    paddingBottom: "30px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  "& .right-image-container": {
    width:"100%",
    display: "flex",
    flexDirection: "column",
    gap: "28px",
    alignItems: "center",
    justifyContent:"center",
    [theme.breakpoints.down("md")]: {
      gap: "30px",
      alignItems: "center",
    },
    "& .footer-image": {
      width: "230px",
      height: "176px",
    },
  },
  "& .left-text-container": {
    padding: "40px 0 32px",
    display: "flex",
    gap: "120px",
    [theme.breakpoints.down("lg")]: {
      gap: "50px",
    },
    [theme.breakpoints.down("md")]: {
      gap: "30px",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  "& .link-container": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  "& .follow-link-container": {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  "& .company-link": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight:400,
    lineHeight: "24px",
    color: "#292524",
    textDecoration: "none",
    whiteSpace:"nowrap",
    "&:hover": {
      color: "rgb(127, 17, 135)",
    },
  },
  "& .follow-link": {
    fontFamily: "Poppins",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "rgb(41, 37, 36)",
    textDecoration: "none",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    paddingLeft: "16px",
    paddingTop: "8px",
    paddingBottom: "8px",
    "&:hover": {
      color: "rgb(127, 17, 135)",
    },
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "0px",
    },
  },
  "& .vendor-description": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#292524",
  },
  "& .vendor-title": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#292524",
  },
}));

const VerticalLine = styled(Box)({
  width: "1px",
  height: "100%",
  backgroundColor: "#A8A29E",
});

const HorizontalLine = styled("hr")({
  margin: "0",
  backgroundColor: "#A8A29E",
  borderBottom: "1px !important",
});

const CustomContainer = styled(Container)({
  "@media(min-width:1408px)": {
    maxWidth: "1800px !important",
  },
  "@media(min-width:1280px)": {
    maxWidth: "100%",
  },
});
