import React from "react";

// Customizable Area Start
import { Box, Grid, Skeleton } from "@mui/material";
// Customizable Area End

import ProductSummaryController, { Props } from "../ProductSummaryController.web";

export default class MultipleImageSection extends ProductSummaryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const {
            isLoading,
            product,
        } = this.state;
        return (
            // Customizable Area Start
            <>
                {!isLoading && product ?
                    <Grid container spacing={3} maxWidth={"90vw"} marginBottom={"30px"} display={"flex"} flexDirection={"row"} flexWrap={"nowrap"} overflow={"hidden"}>
                        {this.getProductImages(product.media_files).map((image) => (
                            <Grid item xs={3}>
                                <Box style={{ borderRadius: "12px", width: "100%", overflow: "hidden" }}>
                                    <img style={{ ...{ width: "100%", height: "100%", objectFit: "cover" }, ...product.available_quantity < 1 ? { filter: "grayscale(100%)" } : {} }} src={image.url} alt="Product image" />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    :
                    <Grid container spacing={3} marginBottom={"30px"}>
                        <Grid item xs={3}>
                            <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'100%'} height={'250px'} />
                        </Grid>
                        <Grid item xs={3}>
                            <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'100%'} height={'250px'} />
                        </Grid>
                        <Grid item xs={3}>
                            <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'100%'} height={'250px'} />
                        </Grid>
                    </Grid>
                }
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
