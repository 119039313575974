import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  toggle:boolean;
  SelectServicesList:string[];
  toggle2:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PlanController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      toggle:false,
      SelectServicesList:["Venue (₹ 46,000 )", "Photo & Video (₹ 40,000 )", "Makeup (₹ 30,000 )", "Invitations (₹ 30,000 )", "Caterer (₹ 2,00,000 )", "Entertainment (₹ 40,000 )", "Decor (₹ 40,000 )", "Mehndi artist (₹ 30,000 )", "DJ (₹ 46,000 )", "Wedding favors (₹ 40,000 )", "Cake (₹ 40,000 )", "Games & Activity (₹ 30,000 )"],
      toggle2:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

    // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      runEngine.debugLog("Message Recived", message);
    }


    // Customizable Area End
  }

  // Customizable Area Start
  navigateToSelectPlan = () => {
    this.props.navigation.navigate("SelectPlan");
  }

  handleSelectBudgetAndRecommended = () => {
    this.setState({ toggle:true });
  }

  handleServicesClose = () => {
    this.setState({ toggle:false });
  }

  handleSubscribeOpen = () => {
    this.handleServicesClose();
    this.setState({ toggle2:true });
  }

  handleSubscribeClose = () => {
    this.setState({ toggle2:false });
  }
  handlePageSubscribe =()=>{
    this.props.navigation.navigate("SubscribePage");
  }

  // Customizable Area End
}
