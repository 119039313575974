import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { apiCall } from "../../../../components/src/utils";
import { toast } from "react-toastify";

// Customizable Area Start
interface MyPurchasesResponse {
  data: OrderData[];
}

interface OrderData {
  id: string;
  type: string;
  attributes: OrderAttributes;
}

interface OrderAttributes {
  id: number;
  order_number: string;
  status: string;
  placed_at: string;
  total_mrp: number;
  total_items: number;
  total_tax: number;
  discount_on_mrp: number;
  total_amount: number;
  customer: any;
  address: any;
  order_items: OrderItemData[];
  coupon_details: null | any;
}

export interface OrderItemData {
  id: string;
  type: string;
  attributes: OrderItemAttributes;
}

interface OrderItemAttributes {
  price: number;
  status: string;
  quantity: number;
  taxable: boolean;
  taxable_value: number;
  other_charges: string | null;
  total_price: number;
  discounted_price: number;
  location: string;
  product: ProductData;
}

interface ProductData {
  id: string;
  type: string;
  attributes: ProductAttributes;
}

interface ProductAttributes {
  id: number;
  name: string;
  SKU: string;
  category: CategoryData;
  description: string;
  fabric_details: string;
  price: string;
  available_quantity: number;
  media_files: MediaFile[];
  functions: any[];
  service_locations: ServiceLocations;
  product_variants: ProductVariant;
  custom_fields: Record<string, unknown>;
  created_at: string;
  is_published: boolean;
  product_faqs: any[];
  size_chart: string | null;
  is_available: boolean;
  is_deleted: boolean;
  coupon: any;
  vendor: any;
}

interface ProductVariant {
  id: string;
  type: string;
  attributes: {
    id: number;
    product_size: ProductSize;
    product_color: ProductColor;
    available_count: number;
  };
}

interface ProductSize {
  id: string;
  type: string;
  attributes: {
    id: number;
    size_label: string;
    measurement: number;
  };
}

interface ProductColor {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    color_image: string;
  };
}

interface CategoryData {
  id: string;
  type: string;
  attributes: CategoryAttributes;
}

interface CategoryAttributes {
  id: number;
  name: string;
  description: string;
  dark_icon: string | null;
  dark_icon_active: string | null;
  dark_icon_inactive: string | null;
  light_icon: string | null;
  light_icon_active: string | null;
  light_icon_inactive: string | null;
  rank: number | null;
  created_at: string;
  updated_at: string;
  selected_sub_categories: string | null;
  image: string;
}

interface MediaFile {
  url: string;
  filename: string;
  content_type: string;
  byte_size: number;
}

interface ServiceLocations {
  data: ServiceLocationData[];
}

interface ServiceLocationData {
  id: string;
  type: string;
  attributes: ServiceLocationAttributes;
}

interface ServiceLocationAttributes {
  id: number;
  location_name: string;
}
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  token: string;
  currentPath: string;
  orders: OrderData[];
  openReviewModal: boolean;
  reviewComment: string;
  reviewCommentError: boolean;
  reviewCommentMsg: string;
  reviewRating: number;
  reviewRatingError: boolean;
  reviewRatingMsg: string;
  selectedProductId: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MyPurchasesController extends BlockComponent<Props, S, SS> {

  getMyPurchasesId: string = "";
  addProductReviewId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isLoading: false,
      currentPath: '',
      token: '',
      orders: [],
      openReviewModal: false,
      reviewComment: '',
      reviewCommentError: false,
      reviewCommentMsg: '',
      reviewRating: 0,
      reviewRatingError: false,
      reviewRatingMsg: '',
      selectedProductId: 0,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({ currentPath: location.pathname });
    this.getMyPurchasesData();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (webApiRequestCallId === this.getMyPurchasesId) {
        this.handleGetMyPurchasesResponse(webResponseJson);
      }

      if (webApiRequestCallId === this.addProductReviewId) {
        this.handleAddReviewsResponse(webResponseJson);
      }
    }

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token });
    }
    // Customizable Area End
  }
  
  // Customizable Area Start
  handleRatingChange = (event: any) => {
    const stars = parseInt(event.target.value);
    this.setState({ reviewRating: stars, reviewRatingError: false, reviewRatingMsg: '' });
  }

  getMyPurchasesData = async () => {
    this.setState({ isLoading: true });
    this.getMyPurchasesId = await apiCall({
      endPoint: `${configJSON.getMyPurchasesEndpoint}`,
      method: configJSON.httpGetMethod,
    });
  }

  handleGetMyPurchasesResponse = (response: MyPurchasesResponse) => {
    if (response.data && response.data.length > 0) {
      this.setState({ orders: response.data })
    } else {
      this.setState({ orders: [] });
    }
    this.setState({ isLoading: false });
  }

  handleGotoMyPurchases = () => {
    this.props.navigation.navigate("MyPurchases")
  }

  handleGotoMyBookings = () => {
    this.props.navigation.navigate("MyBookings")
  }

  handleRedirectProduct = (id: number) => {
    this.props.navigation.navigate("ProductSummary", { id: id });
  }

  handleOpenReviewModal = (productId: number) => {
    this.setState({ openReviewModal: true, selectedProductId: productId });
  }

  handleCloseReviewModal = () => {
    this.setState({ openReviewModal: false });
  }

  handleCommitInputChange = (event: any) => {
    this.setState({ reviewComment: event.target.value, reviewCommentError: false, reviewCommentMsg: '' });
  }

  handleSubmitReview = async () => {
    const { reviewComment, reviewRating, selectedProductId } = this.state;
    let isValid = true;
    if (!reviewComment) {
      this.setState({ reviewCommentError: true, reviewCommentMsg: "Comment is required." });
      isValid = false;
    }
    if (reviewRating === 0) {
      this.setState({ reviewRatingError: true, reviewRatingMsg: "Rating is required." });
      isValid = false;
    }
    if (!isValid) {
      return false;
    }

    this.setState({ isLoading: true });
    const requestBody = {
      review: {
        title: 'Product review',
        description: reviewComment,
        score: reviewRating
      }
    }

    this.addProductReviewId = await apiCall({
      endPoint: `${configJSON.reviewRatingEndpoint}?product_id=${selectedProductId}`,
      contentType: 'application/json',
      method: configJSON.httpPostMethod,
      body: JSON.stringify(requestBody),
    });
  }

  handleAddReviewsResponse = (response: any) => {
    if (response.data) {
      this.setState({ reviewComment: '', reviewRating: 0 })
      toast.success("Review added successfully");
      this.getMyPurchasesData();
      this.handleCloseReviewModal();
    }
    this.setState({ isLoading: false });
  }
  // Customizable Area End
}
