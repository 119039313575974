import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const baseURL = require("../../../framework/src/config.js").baseURL;
const Venueone = require("../assets/one.jpg");
const Venuetwo = require("../assets/two.svg");
const Venuethree = require("../assets/three.svg");
const Venuefour = require("../assets/four.svg");
const Venuefive = require("../assets/five.svg");
const Venuesix = require("../assets/six.svg");

export interface Vendor {
  imgURL: string,
  id: number;
  name: string;
  location: string;
  rating: string;
  price: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  vendors: Vendor[];
  selectedVendors: Vendor[];
  currentStage:number | string;
  propsvalue:number | string;  
  btnEnable:boolean;  
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserrecommendationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedVendors: [],
      currentStage:"Stage 2",
      btnEnable:true,
      propsvalue:0,
      
       vendors: [
        {
          imgURL: Venueone,
          id: 1,
          name: "Eventshoppe",
          location: "Juhu, Mumbai",
          rating: "7.5 (678 Ratings)",
          price: "₹ 46,000",
        },
        {
          imgURL: Venuetwo,
          id: 2,
          name: "Thakkar Decorators",
          location: "Juhu, Mumbai",
          rating: "7.5 (678 Ratings)",
          price: "₹ 46,000",
        },
        {
          imgURL: Venuethree,
          id: 3,
          name: "Yours Decorator",
          location: "Juhu, Mumbai",
          rating: "7.5 (678 Ratings)",
          price: "₹ 46,000",
        },
        {
          imgURL: Venuefour,
          id: 4,
          name: "Yours Decorator",
          location: "Juhu, Mumbai",
          rating: "7.5 (678 Ratings)",
          price: "₹ 46,000",
        },
        {
          imgURL: Venuefive,
          id: 5,
          name: "Yours Decorator",
          location: "Juhu, Mumbai",
          rating: "7.5 (678 Ratings)",
          price: "₹ 46,000",
        },
        {
          imgURL: Venuesix,
          id: 6,
          name: "Yours Decorator",
          location: "Juhu, Mumbai",
          rating: "7.5 (678 Ratings)",
          price: "₹ 46,000",
        },

      ]
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

    // Customizable Area Start

  async receive(from: string, message: Message) {   
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      runEngine.debugLog("Message Recived", message);
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const payloadMessage = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
        this.handleBtnText(payloadMessage.screenName)
    } 

    
    // Customizable Area End
  }

  // Customizable Area Start

 


  handleBtnText(propsvalue:any){
    if(propsvalue== "btnclose"){
      this.setState({ btnEnable: false});
      
    }
    else{
      this.setState({ currentStage: propsvalue});
     
    }
      
  }

  handleCompare = (vendor: Vendor) => {
    const { selectedVendors } = this.state;
    this.setState({ selectedVendors: [...selectedVendors, vendor] });
  };

  removeVendor = (id: number) => {
    this.setState({
      selectedVendors: this.state.selectedVendors.filter((vendor) => vendor.id !== id),
    });
  };

  handleNavigation(){
    let  value;
   if(this.state.currentStage==="Stage 2"){
        value= "Stage 2"
   }
   else{
       value="Stage 3"
   }
     this.handleMessgae(value)
    
  }
  
  handleMessgae(value:any){
    const navigationMessage: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), "SecondQuiz");
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {screenName:value});
    navigationMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props  );
    this.send(navigationMessage);  
  }
  // Customizable Area End
}
