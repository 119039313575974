import React from "react";



// Customizable Area Start

import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";

import { Box, Typography as Text, Card, CardMedia, styled, Button as Button1, FormControlLabel, Checkbox as Checkbox1 ,Dialog, DialogContent, IconButton} from '@mui/material';

import Loader from "../../../components/src/AppLoader.web";
import { quizcard, quizBanner, card, card1,star } from "./assets";
import { Check } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
// Customizable Area End


import CfservicerecommendationSecondQuizController, { Props } from "./CfservicerecommendationSecondQuizController";



export default class CfservicerecommendationSecondQuiz extends CfservicerecommendationSecondQuizController {
	
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderWeddingStyle = () => {

        return (
            <WeddingStyle>
                <Box className="main_box">
                    <Box className="heading_box">


                        <Text sx={{
                            textAlign: "center",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "36px",
                            lineHeight: "22px",
                            letterSpacing: "0%",
                            verticalAlign: "bottom",
                        }}>Venue Comparison</Text>

                        <Text sx={{
                            marginTop: "14px",
                            textAlign: "center",
                            color: "#57534E",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "22px",
                            letterSpacing: "0%",
                            verticalAlign: "bottom",

                        }}>
                            Showing Comparison Between 3 Venues
                        </Text>

                    </Box>

                    <Box className="card_box">
                        <Box
                            sx={{
                                maxWidth: 300,
                                border: 'none',
                                width: '23%',
                                display: { xs: "none", sm: "block" },
                            }}
                        >
                            <Box sx={{ padding: "16px", position: 'relative', top: '32%', display: 'flex', flexDirection: 'column', gap: '10px' }} display="flex">
                              
                                <Text  fontWeight={600} sx={{fontSize:'16px'}}>
                                    Venue Name
                                </Text>
                                <Text sx={{ color: "#1E1E1E" }}>
                                    Address
                                </Text>




                                <Text sx={{ color: "#1E1E1E", fontSize: '14px', fontWeight: 400 }}>
                                    Rating
                                </Text>


                              
                                <Text sx={{ color: "#1E1E1E", fontSize: '14px', fontWeight: 400 }}>
                                    price
                                </Text>

                                <Text sx={{ color: "#1E1E1E", fontSize: '14px', fontWeight: 400 }}>
                                    Property Type
                                </Text>

                                <Text sx={{ color: "#1E1E1E", fontSize: '14px', fontWeight: 400 }}>
                                    Venue type
                                </Text>
                                <Text sx={{ color: "#1E1E1E", fontSize: '14px', fontWeight: 400 }}>
                                    Located At
                                </Text>
                                <Text sx={{ color: "#1E1E1E", fontSize: '14px', fontWeight: 400 }}>
                                    Rates
                                </Text>
                                <Text sx={{ color: "#1E1E1E", fontSize: '14px', fontWeight: 400 }}>
                                    Event For
                                </Text>


                              

                            </Box>
                        </Box>



                        <Box className="box_card">
                            <Card
                                className="card_content"
                            >

                                <CardMedia
                                    component="img"
                                    alt="Venue Image"
                                    image={`${card1}`}
                                    sx={{ borderRadius: "10px 10px 0px 0px" }}
                                />


                                <Box className="card_text">

                                    <Text className="card_col_1_header" >
                                    Spara Boutique Resort
                                    </Text>
                                    <Text className="card_col_1">
                                        Juhu, Mumbai
                                    </Text>


                                    <Box display="flex" alignItems="center" sx={{gap:'10px'}}>
                                        <img src={star}/>
                                        <Text  className="card_col_1">
                                            7.5 (678 Ratings)
                                        </Text>
                                    </Box>


                                  
                                    <Text className="card_col_1">
                                        ₹ 46,000
                                    </Text>
                                    <Text className="card_col_1">
                                        Resort
                                    </Text>

                                    <Text className="card_col_1">
                                        Beach resorts
                                    </Text>

                                    <Text className="card_col_1">
                                        Beach Side
                                    </Text>


                                    <Text className="card_col_1">
                                        5000 -10,000
                                    </Text>


                                    <Text  >
                                        <Text className="card_col_1">
                                            Wedding Halls
                                        </Text>
                                        <Text className="card_col_1">
                                            Banquet halls
                                        </Text>
                                        <Text className="card_col_1">
                                            Small function hall
                                        </Text>
                                        <Text className="card_col_1">
                                            Confession meeting room
                                        </Text>

                                        <br />


                                    </Text>



                                </Box>
                                <Box display="flex" sx={{ width: '100%', marginBottom: '30px', justifyContent: 'center', alignItems: 'center', }}>
                                    <Button1
                                        data-test-id="btNxtPage"
                                        sx={{
                                            backgroundColor: "white",
                                            borderRadius: "30px",
                                            color: "#7F1187",
                                            height: "44px",
                                            width: "180px",
                                            border: "1px solid #7F1187",
                                            padding: "10px 16px",
                                            "&:hover": {
                                                backgroundColor: "#F5E6F7",
                                            },
                                        }}

                                    >
                                        <Text
                                            sx={{
                                                fontSize: "16px",
                                                textTransform: "none",
                                                color: "#7F1187",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,

                                            }}
                                        >
                                            View More
                                        </Text>
                                    </Button1>
                                </Box>

                            </Card>


                        </Box>

                        <Box className="box_card">
                            <Card
                                className="card_content"
                            >

                                <CardMedia
                                    component="img"
                                    sx={{ borderRadius: "10px 10px 0 0" }}
                                    alt="Venue Image"
                                    image={`${card}`}


                                />


                                <Box className="card_text">

                                    <Text className="card_col_2_header">
                                        The Tivoli Prime
                                    </Text>
                                    <Text className="card_col_2">
                                        Juhu, Mumbai
                                    </Text>


                                    <Box display="flex" alignItems="center" sx={{gap:'10px'}}>
                                        <img src={star}/>
                                        <Text  className="card_col_2">
                                            7.5 (678 Ratings)
                                        </Text>
                                    </Box>


                                    <Text className="card_col_2">
                                        ₹ 46,000
                                    </Text>
                                    <Text className="card_col_2">
                                        Resort
                                    </Text>

                                    <Text className="card_col_2">
                                        Beach resorts
                                    </Text>

                                    <Text className="card_col_2">
                                        Beach Side
                                    </Text>


                                    <Text className="card_col_2">
                                        5000 -10,000
                                    </Text>

                                    <Text  >
                                        <Text className="card_col_2">
                                            Wedding Halls
                                        </Text>
                                        <Text className="card_col_2" >
                                            Banquet halls
                                        </Text>
                                        <Text className="card_col_2">
                                            Small function hall
                                        </Text>
                                        <Text className="card_col_2">
                                            Confession meeting room
                                        </Text>

                                        <br />


                                    </Text>



                                </Box>
                                <Box display="flex" sx={{ marginBottom: '30px', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button1
                                        data-test-id="btnView"
                                        sx={{
                                            width: "180px",
                                            height: "44px",
                                            border: "1px solid #7F1187",
                                            backgroundColor: "white",
                                            "&:hover": {
                                                backgroundColor: "#F5E6F7",
                                            },
                                            borderRadius: "30px",
                                            padding: "10px 16px",
                                            color: "#7F1187",
                                        }}

                                    >
                                        <Text
                                            sx={{
                                                color: "#7F1187",
                                                fontSize: "16px",
                                                textTransform: "none",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,

                                            }}
                                        >
                                            View More
                                        </Text>
                                    </Button1>
                                </Box>

                            </Card>


                        </Box>


                        <Box className="box_card">
                            <Card
                                className="card_content"
                            >

                                <CardMedia
                                    component="img"
                                    image={`${quizcard}`}
                                    alt="Venue Image"
                                    sx={{ borderRadius: "10px 10px 0 0" }}
                                />


                                <Box className="card_text">

                                    <Text className="card_col_3_header">
                                        The Tivoli Prime
                                    </Text>
                                    <Text className="card_col_3">
                                        Juhu, Mumbai
                                    </Text>


                                    <Box display="flex" alignItems="center" sx={{gap:'10px'}}>
                                        <img src={star}/>
                                        <Text  className="card_col_3">
                                            7.5 (678 Ratings)
                                        </Text>
                                    </Box>


                                    <Text  className="card_col_3">
                                        ₹ 46,000
                                    </Text>
                                    <Text  className="card_col_3">
                                        Resort
                                    </Text >

                                    <Text className="card_col_3">
                                        Beach resorts
                                    </Text>

                                    <Text className="card_col_3">
                                        Beach Side
                                    </Text>


                                    <Text className="card_col_3">
                                        5000 -10,000
                                    </Text>


                                    <Text >
                                        <Text className="card_col_3">
                                            Wedding Halls
                                        </Text>
                                        <Text className="card_col_3">
                                            Banquet halls
                                        </Text>
                                        <Text className="card_col_3">
                                            Small function hall
                                        </Text>
                                        <Text className="card_col_3">
                                            Confession meeting room
                                        </Text>

                                        <br />


                                    </Text>

                                    {/* View More Button */}

                                </Box>
                                <Box display="flex" sx={{ marginBottom: '30px', width: '100%', justifyContent: 'center', alignItems: 'center', }}>
                                    <Button1
                                        data-test-id="btnViewMore"
                                        sx={{
                                            width: "180px",
                                            height: "44px",
                                            padding: "10px 16px",
                                            border: "1px solid #7F1187",
                                            color: "#7F1187",
                                            borderRadius: "30px",
                                            "&:hover": {
                                                backgroundColor: "#F5E6F7",
                                            },
                                            backgroundColor: "white",
                                        }}

                                    >
                                        <Text
                                            sx={{
                                                fontFamily: "Poppins",
                                                fontSize: "16px",
                                                color: "#7F1187",
                                                textTransform: "none",
                                                fontWeight: 500,
                                            }}
                                        >
                                            View More
                                        </Text>
                                    </Button1>
                                </Box>

                            </Card>


                        </Box>



                    </Box>
                    <Box className="btnNxt">
                        <Button1
                            data-test-id="btnNxt"
                            className="btnNxt_btn"
                            onClick={() => this.hanldePage(this.state.screen_page + 1)}
                        >
                            <Text
                                className="btnNxt_btn_text"
                            >
                                Next
                            </Text>
                        </Button1>
                    </Box>
                </Box>

            </WeddingStyle >
        )
    }

    renderBannerStlye = () => {
        return (
            <BannerStyle>
                <Box sx={{
                    position: "relative",
                    width: "100%",
                    overflow: "hidden",
                }}>
                    <img src={quizBanner} alt="quiz_banner" width="100%" ></img>
                    <Box

                        sx={{
                            position: "absolute",
                            bottom: "10%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            textAlign: "center",
                            zIndex: "2",
                            width: "90%",
                        }}
                    >
                        <Text sx={{ color: '#FFFFFF', fontSize: '40px', fontWeight: '600', }}>Your Dream, Our Plan,Forever Perfect</Text>
                        <Text sx={{ color: '#FFFFFF', fontSize: '20px', fontWeight: '500', }}> Creating Unforgettable Moments, One Wedding At A Time.</Text>
                    </Box>
                </Box>
                <Box className="Content_Area">
                    <Box sx={{ textAlign: 'center', marginTop: '30px' }}>
                        <Text sx={{ fontWeight: 500, fontSize: '36px', color: '#1C1917' }}>{this.state.title}</Text>
                        <Text sx={{ marginTop: '5px', fontSize: '16px', fontWeight: 400, color: '#57534E' }}>{this.state.sub_title}</Text>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            padding: "0px 10%",
                            gap: "20px",
                        }}
                    >
                        {this.state.checkbox_data.map((option, index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox1
                                        checked={this.state.checkedOptions[option] || false}
                                        onChange={() => this.handleCheckboxChange(option)}
                                        icon={
                                            <span
                                                style={{
                                                    width: 20,
                                                    height: 20,
                                                    borderRadius: 5,
                                                    border: "2px solid #999",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            />
                                        }
                                        checkedIcon={
                                            <span
                                                style={{
                                                    width: 20,
                                                    height: 20,
                                                    borderRadius: 4,
                                                    backgroundColor: "#6A1B9A",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    color: "white",
                                                }}
                                            >
                                                <Check fontSize="small" style={{ color: "white" }} />
                                            </span>
                                        }
                                    />
                                }
                                label={option}
                                sx={{
                                    color: "#333",
                                    flex: "1 1 calc(25% - 20px)",
                                    minWidth: "180px",
                                }}
                            />
                        ))}
                    </Box>



                    <Box className="btnNxt_box">
                        <Button1
                            data-test-id="btnReject"
                            className="btnNxt_btn_pre"
                            onClick={() => this.hanldePage(this.state.screen_page - 1)}
                        >
                            <Text
                                className="btnNxt_btn_pretext"
                            >
                                Previous
                            </Text>
                        </Button1>

                        <Button1
                            data-test-id="btnNxt1"
                            className="btnNxt_btn"
                            onClick={() => this.hanldePage(this.state.screen_page + 1)}
                        >
                            <Text
                                className="btnNxt_btn_text"
                            >
                                {this.state.button_content}
                            </Text>
                        </Button1>
                    </Box>
                </Box>
            </BannerStyle>
        )
    }
    // Customizable Area End

    render() {



        return (
            // Customizable Area Start
            <HeaderFooterLayout navigation={this.props.navigation}>
                <Loader isLoading={false} />
                {this.state.screen_page === 1 &&
                    this.renderWeddingStyle()
                }

                {this.state.screen_page !== 1 &&

                    this.renderBannerStlye()

                }

                <Box>

                    <Dialog
                        open={this.state.openModal}
                        
                       
                        maxWidth="sm"
                        fullWidth
                        className=""
                        sx={{
                            "& .MuiDialog-paper": {
                                borderRadius: "12px",
                                padding: "24px",
                                textAlign: "center",
                                width: "531px",
                                height: "304px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",

                            },
                        }}
                    >

                        <IconButton
                        data-test-id="btn_close2"
                            aria-label="close"
                            onClick={() => this.handleModalClose()}
                            sx={{ position: "absolute", right: 16, top: 16 }}
                        >
                            <CloseIcon />
                        </IconButton>

                        <DialogContent sx={{ marginTop: '40px' }}>

                            <Text variant="h6" fontWeight="600">
                                Thank you!
                            </Text>


                            <Text variant="h6" fontWeight="600">
                                Take a Quiz to find your style
                            </Text>


                            <Button1
                                data-test-id="btn_close"
                                className="btn_nxt"
                                onClick={() => this.handleModalClose()}
                                sx={{
                                    marginTop: '25px',
                                    width: "180px",
                                    height: "44px",
                                    padding: "10px 16px",
                                    border: "1px solidrgb(199, 182, 201)",
                                    backgroundColor: "#7F1187",
                                    color: "#7F1187",
                                    borderRadius: "30px",
                                    "&:hover": {
                                        backgroundColor: "7F1187",
                                    }
                                }}
                            >
                                <Text
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontSize: "16px",
                                        fontWeight: 500,
                                        color: "#fff",
                                        textTransform: "none"
                                    }}
                                    className="btnNxt_btn_text"
                                >
                                    Next
                                </Text>
                            </Button1>
                        </DialogContent>
                    </Dialog>

                </Box>

            </HeaderFooterLayout>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const WeddingStyle = styled(Box)({
    "& .main_box": {
        padding: '40px 60px',
        backgroundColor: "white",
    },
    "& .heading_box": {
       
    },
    "& .heading_box_header": {
        fontSize: "26px",
        fontWeight: 600,
        fontFamily: "Poppins",
    },
    "& .heading_box_content": {
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Poppins",
        color: '#0F172A',
        mt: 1,
    },

    "& .card_content": {
        width: '290px',
        fontSize: "36px",
        fontWeight: 400,
        fontFamily: "Poppins",
        color: '#0F172A',
        mt: 1,
    },

    "& .box_card": {
        maxwidth: '400px'
    },

    "& .card_text": {
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },

    "& .card_box": {
        marginTop: '25px',
        display: 'flex',
        gap: '20px',
        padding: '10px',
        flexWrap: 'wrap'
    },


    "& .btnNxt": {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30px'
    },

    "& .btnNxt_btn": {
        width: "180px",
        height: "44px",
        padding: "10px 16px",
        border: "1px solidrgb(199, 182, 201)",
        backgroundColor: "#7F1187",
        color: "#7F1187",
        borderRadius: "30px",
        "&:hover": {
            backgroundColor: "7F1187",
        },
    },

    "& .btnNxt_btn_text": {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        color: "#fff",
        textTransform: "none",
    },
    "& .card_col_1": {
        color: "#1E1E1E",
         fontSize: '14px', 
         fontWeight: 400 
    },
    "& .card_col_1_header": {
        color: '#292524',
        size:'16px',
        fontWeight: 600 
    },
    "& .card_col_2": {
        color: "#1E1E1E",
         fontSize: '14px', 
         fontWeight: 400 
    },
    "& .card_col_2_header": {
        color: '#292524',
        size:'16px',
        fontWeight: 600 
    },
    "& .card_col_3_header": {
        color: '#292524',
        size:'16px',
        fontWeight: 600 
    },
    "& .card_col_3": {
        color: "#1E1E1E",
         fontSize: '14px', 
         fontWeight: 400 
    },
});
const BannerStyle = styled(Box)({
    "& .content_area": {
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',

    },
    "& .btnNxt_box": {
        display: 'flex',
        justifyContent: 'center',
        gap: '10px'
    },
    "& .btnNxt_btn": {
        width: "180px",
        height: "44px",
        padding: "10px 16px",
        border: "1px solidrgb(199, 182, 201)",
        backgroundColor: "#7F1187",
        color: "#7F1187",
        borderRadius: "30px",
        "&:hover": {
            backgroundColor: "7F1187",
        },
    },
    "& .btnNxt_btn_text": {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        color: "#fff",
        textTransform: "none",
    },

    "& .btnNxt_btn_pre": {
        width: "180px",
        height: "44px",
        padding: "10px 16px",
        border: "1px solid #7F1187",
        backgroundColor: "#fff",
        color: "#7F1187",
        borderRadius: "30px",
        "&:hover": {
            backgroundColor: "#fff",
        },
    },

    "& .btnNxt_btn_pretext": {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        color: "#7F1187",
        textTransform: "none",
    },
});


// Customizable Area End
