import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { getStorageData } from "../../../../framework/src/Utilities";
import { apiCall } from "../../../../components/src/utils";

// Customizable Area Start
interface ServiceLocation {
  id: number;
  location_name: string;
  created_at: string;
  updated_at: string;
}

interface VendorAddress {
  id: string;
  type: string;
  attributes: {
    latitude: number | null;
    longitude: number | null;
    name: string | null;
    email: string | null;
    phone_number: string | null;
    address: string;
    address_type: string;
    country: string;
    city: string;
    state: string;
    pincode: string;
    service_locations: ServiceLocation[];
  };
}

interface VendorAttributes {
  activated: boolean;
  full_name: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string | null;
  profile_picture: string | null;
  business_name: string;
  portfolio_link: string | null;
  vendor_address: VendorAddress | null;
  role_id: string;
}

interface Vendor {
  id: string;
  type: string;
  attributes: VendorAttributes;
}

interface ServiceData {
  service_name: string;
  vendors: Vendor[];
}

interface Meta {
  message: string;
}

interface ServiceResponse {
  data: ServiceData;
  meta: Meta;
  errors: any;
}
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  categoryId: string;
  isLoading: boolean;
  errorMessage: string;
  pageTitle: string;
  vendors: Vendor[];
  showAllPremiumVendors: boolean;
  showAllTopRatedVendors: boolean;
  showAllRecommendedVendors: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CategoriessubcategoriesVendorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDiscoverVendorServicesId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      errorMessage: '',
      categoryId: "",
      vendors: [],
      pageTitle: "",
      showAllPremiumVendors: false,
      showAllTopRatedVendors: false,
      showAllRecommendedVendors: false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (requestCallId === this.getDiscoverVendorServicesId && responseJson) {
        this.handleVendorCategoryResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getVendorCategoryData = async (id: number) => {
    this.setState({ isLoading: true });
    const token = await getStorageData("authToken");

    // Ensure the API call to fetch the vendor category data is working
    this.getDiscoverVendorServicesId = await apiCall({
      endPoint: `${configJSON.vendorsEndpoint}/${id}`,
      method: configJSON.httpGetType,
      token: token,
    });
  };

  handleVendorCategoryResponse = (webResponseJson: ServiceResponse) => {
    if (webResponseJson?.data) {
      this.setState({ vendors: webResponseJson.data.vendors, pageTitle: webResponseJson.data.service_name })
    }
    if (webResponseJson.errors) {
      const errorMessage = webResponseJson.errors;
      this.setState({ errorMessage });
    }
    this.setState({ isLoading: false });
  };

  async componentDidMount(): Promise<void> {
    let categoryId = await this.props.navigation.getParam("id", "");
    if (categoryId) {
      this.setState({ categoryId: categoryId });
      this.getVendorCategoryData(categoryId);
    }
  }

  toggleShowAllPremiumVendors = () => {
    this.setState({ showAllPremiumVendors: !this.state.showAllPremiumVendors });
  }

  toggleShowAllTopRatedVendors = () => {
    this.setState({ showAllTopRatedVendors: !this.state.showAllTopRatedVendors });
  }

  toggleShowAllRecommendedVendors = () => {
    this.setState({ showAllRecommendedVendors: !this.state.showAllRecommendedVendors });
  }
  // Customizable Area End
}