import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  styled,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Loader from "../../../components/src/AppLoader.web";
import {
  backgroundImage,
  minusImage,
  faqBackgroundImage,
  leftArrow,
  plusImage,
  questionSideImage,
  rightArrow,
} from "./assets";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation } from "swiper";
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
import HeaderVendorFooter from "../../../components/src/HeaderVendorFooter";

const breakpoints = {
  480: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  600: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  900: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  1250: {
    slidesPerView: 4,
    spaceBetween: 40,
  },
};

// Customizable Area End

import FaqsController, { Props } from "./FaqsController";

export default class Faqs extends FaqsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  faqMainBox = () => {
    return (
      <FAQSStyle data-test-id="FAQSStyle">
        <Loader isLoading={this.state.isLoading} />
        <Box className="faq-image-container">
          <img
            src={faqBackgroundImage}
            alt="faq"
            className="faq-background-image"
          />

          <Box className="image-overlay">
            <Typography component="h3" className="faq-header">
              FAQs
            </Typography>
          </Box>
        </Box>
        <Box className="faq-details-container">
          <CustomContainer maxWidth="lg">
            <Typography component="h3" className="faq-title">
              You may still be wondering...
            </Typography>
            <Typography component="h3" className="faq-description">
              Answer to all your possible questions.
            </Typography>

            <Box className="swipe-container">
              <Swiper
                data-test-id="swiper"
                className="carousel-container"
                modules={[Navigation]}
                spaceBetween={96}
                breakpoints={breakpoints}
                navigation={{
                  prevEl: ".custom-prev",
                  nextEl: ".custom-next",
                }}
              >
                {this.state.faqIcons.map((data) => {
                  const isActive =
                    data.attributes.id === this.state.activeItem.id;
                  return (
                    <SwiperSlide key={data.id}>
                      <Box
                        data-test-id="faq-item-container"
                        className={`faq-item-container ${isActive ? "active" : ""
                          }`}
                        onClick={() =>
                          this.setActiveItem(
                            data
                          )
                        }
                      >
                        <Box className="faq-item-image-border">
                          <Box className="faq-item-image-container">
                            <img
                              src={data.attributes.unselect_img}
                              alt="icon"
                              className="not-active-image"
                            />
                            <img
                              src={data.attributes.select_img}
                              className="active-image"
                              alt="icon"
                            />
                          </Box>
                        </Box>
                        <Typography
                          className="faq-item-text"
                          title={data.attributes.title}
                        >
                          {data.attributes.title}
                        </Typography>
                      </Box>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <Button className="custom-prev" data-test-id="custom-prev">
                <img src={leftArrow} alt="left" />
              </Button>
              <Button className="custom-next" data-test-id="custom-next">
                <img src={rightArrow} alt="right" />
              </Button>
            </Box>
            <Typography variant="h5" className="faq-question-title">
              {this.state.activeItem.title}
            </Typography>
            <Box className="faq-question-image-container">
              <Box className="left-question-container">
                {this.state.faqQuestionAnswer.map((data) => (
                  <Accordion className="faq-accordion" key={data.id}>
                    <AccordionSummary
                      expandIcon={
                        <>
                          <img
                            src={plusImage}
                            className="plus-image"
                            alt="plus"
                          />
                          <img
                            src={minusImage}
                            className="cross-image"
                            alt="cross"
                          />
                        </>
                      }
                    >
                      <Typography variant="h4" className="accordion-heading">
                        {data.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="accordion-details">
                        {data.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
              <Box className="right-image-container">
                <img src={questionSideImage} />
              </Box>
            </Box>
          </CustomContainer>
        </Box>
      </FAQSStyle>
    );
  }
  // Customizable Area End

  render() {
    const { role } = this.state;
    return (
      // Customizable Area Start
      <>
        {role === 'vendor' &&
          <HeaderVendorFooter navigation={this.props.navigation} isFooterShow={false}>
            {this.faqMainBox()}
          </HeaderVendorFooter>
        }
        {(role === 'user' || !role) &&
          <HeaderFooterLayout navigation={this.props.navigation} isFooterShow={false}>
            {this.faqMainBox()}
          </HeaderFooterLayout>
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const FAQSStyle = styled(Box)(({ theme }) => ({
  "& .faq-image-container": {
    width: "100%",
    height: "425.27px",
    overflow: "hidden",
    position: "relative",
  },
  "& .faq-background-image": {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
  "& .image-overlay": {
    inset: "0",
    position: "absolute",
    background: "#00000070",
    paddingTop: "122.33px",
  },
  "& .faq-header": {
    fontFamily: "Poppins",
    fontSize: "48px",
    fontWeight: "600",
    lineHeight: "56px",
    letterSpacing: "-0.015em",
    textAlign: "center",
    color: "#FFFFFF",
  },
  "& .faq-details-container": {
    padding: "45.87px 0",
    backgroundImage: `url(${backgroundImage})`,
  },
  "& .faq-title": {
    fontFamily: "Poppins",
    fontSize: "40px",
    fontWeight: "500",
    lineHeight: "50px",
    textAlign: "center",
    color: "#1C1917",
    marginBottom: "15.29px",
  },
  "& .faq-description": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "34px",
    textAlign: "center",
    color: "#57534E",
  },
  "& .faq-item-container.active": {
    "& .not-active-image": { display: "none !important" },
    "& .active-image": { display: "block !important" },
    "& .faq-item-image-container": {
      background: "#80118726 !important",
    },
    "& .faq-item-text": {
      color: "#801187",
    },
    "& .faq-item-image-border": {
      borderColor: "#8011878A !important",
    },
  },
  "& .faq-item-container": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    width: "fit-content",
    "& .faq-item-image-border": {
      border: "1px dashed #78716C",
      width: "fit-content",
      height: "fit-content",
      borderRadius: "50%",
      padding: "7.50px",
      boxSizing: "border-box",
      "& .faq-item-image-container": {
        width: "184.01px",
        height: "184.01px",
        backgroundColor: "rgba(245, 245, 244, 0.6)",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
          height: "61px",
          width: "61px",
          objectFit: "cover",
        },
        "& .not-active-image": { display: "block" },
        "& .active-image": { display: "none" },
      },
    },
    "& .faq-item-text": {
      fontFamily: "Poppins",
      fontSize: "18px",
      fontWeight: "500",
      lineHeight: "18px",
      textAlign: "center",
      color: "#78716C",
      maxWidth: "236px",
      marginTop: "10px",
      letterSpacing: "0",
    },
  },
  "& .swiper": {
    height: "fit-content",
  },
  "& .swiper-slide": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .custom-next": { right: "-14px" },
  "& .custom-prev": { left: "-14px" },
  "& .custom-next,& .custom-prev": {
    height: "44px",
    position: "absolute",
    zIndex: 2,
    padding: "0",
    top: "0",
    boxShadow: "0px 4px 4px 0px #00000040",
    bottom: "0",
    margin: "auto",
    minWidth: "unset",
    width: "44px",
    backgroundColor: "#FFF",
    borderRadius: "50%",
    "&:disabled": {
      display: "none",
    },
  },
  "& .swipe-container": {
    position: "relative",
    margin: "80.55px 0 128px",
    padding: "0 21px",
    "& .swiper-wrapper": {
      height: "fit-content",
    },
  },
  "& .faq-question-title": {
    fontFamily: "Poppins",
    fontSize: "40px",
    fontWeight: "500",
    lineHeight: "44px",
    textAlign: "center",
    color: "#000000",
    margin: "0 0 80px",
  },
  "& .faq-question-image-container": {
    display: "flex",
    gap: "115px",
    [theme.breakpoints.down("lg")]: {
      gap: "80px",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: "60px",
      alignItems: "center",
    },
    "& .right-image-container": {
      width: "460px",
      minWidth: "460px",
      height: "593px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        minWidth: "100%",
      },
      "& img": {
        height: "100%",
        width: "100%",
        borderRadius: "16px",
        objectFit: "cover",
        objectPosition: "center",
      },
    },
    "& .left-question-container": {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      flex: "1 1 auto",
      width: "100%",
      "& .faq-accordion": {
        boxShadow: "none",
        backgroundColor: "#FFFFFF",
        borderRadius: "0px",
        borderBottom: "1px solid #E2E8F0",
        marginBottom: "0 !important",
        "&::before": {
          display: "none",
        },
        "& .accordion-heading": {
          fontFamily: "Poppins",
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "24px",
          textAlign: "left",
          color: "#000000",
        },
        "& .MuiAccordionSummary-content": {
          margin: "23px 0px",
        },
        "& .MuiAccordionSummary-root": {
          padding: "0 8px",
          "& .plus-image": {
            height: "24px",
            width: "24px",
            display: "block",
          },
          "& .cross-image": {
            height: "24px",
            width: "24px",
            display: "none",
          },
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
          minHeight: "unset",
          padding: "0 8px",
          "& .accordion-heading": {
            color: "#7F1187",
            fontWeight: "600",
          },
          "& .MuiAccordionSummary-content": {
            margin: "10px 0px 11.65px",
          },
          "& .cross-image": {
            display: "block",
            height: "24px",
            width: "24px",
          },
          "& .plus-image": {
            display: "none",
            height: "24px",
            width: "24px",
          },
        },
        "& .accordion-details": {
          color: "#57534E",
          fontFamily: "Poppins",
          fontSize: "16px",
          textAlign: "left",
          fontWeight: "400",
          lineHeight: "24px",
        },
        "& .MuiAccordionDetails-root": {
          padding: "8px 8px 13.23px",
        },
      },
    },
  },
}));

const CustomContainer = styled(Container)({
  "@media(min-width:1408px)": {
    maxWidth: "1408px !important",
  },
  "@media(min-width:1280px)": {
    maxWidth: "100%",
  },
});
// Customizable Area End
