// Customizable Area Start
import React, { Component } from "react";
import { Box, Typography, styled } from "@mui/material";
import {ExperienceData} from '../LandingPageController'
interface Props {
  experienceData:ExperienceData[]
}

interface State {
}

export default class StatSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {experienceData} = this.props;
    return (
      <StatsContainer component="section" id="stats-container">
        <Box className="starts-main-container">
          <Box className="stats-container">
            {experienceData.map((item, index) => {
              const data = item.attributes;
              return (
                <>
                  <Box key={data.id} className="stat-item">
                    <Typography component="h2">{data.value}+</Typography>
                    <Typography>{data.name}</Typography>
                  </Box>
                  {experienceData.length !== index + 1 &&
                    <Box className="divider-1">
                      <Box className="divider" />
                    </Box>
                  }
                </>
              )
            })}
          </Box> 
        </Box> 
      </StatsContainer>
    );
  }
}

const StatsContainer = styled(Box)(({ theme }) => ({
  zIndex: "2",
  position: "relative",
  marginTop: "-113px",
  marginBottom: "40px",
  "& .starts-main-container": {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .stats-container": {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "start",
    backgroundColor: "#ffffff",
    padding: "58px 55px",
    boxShadow: "0px 4px 4px 0px #00000040",
    maxWidth: "1150px",
    boxSizing: "border-box",
    rowGap: "10px",
    [theme.breakpoints.down("md")]: {
      padding: "55px 30px !important",
    },
    "@media(max-width: 700px)": {
      padding: "40px 15px !important",
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  "& .stat-item-fix-length": {
    flex: "0 0 176px !important",
    [theme.breakpoints.down("md")]: {
      flex: "1 !important",
    },
    "@media(max-width: 700px)": {
      padding: "21px 5px !important",
      flex: "0 0 33.33% !important",
    },
    [theme.breakpoints.down("xs")]: {
      flex: "0 0 49.8% !important",
    },
  },
  "& .divider-3": {
    "@media(max-width: 700px)": {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  "& .divider-4": {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  "& .divider-2": {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  "& .stat-item": {
    textAlign: "center",
    boxSizing: "border-box",
    flex: "1",
    padding: "31px 15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      padding: "21px 10px !important",
    },
    "@media(max-width: 700px)": {
      padding: "21px 5px !important",
      flex: "0 0 33%",
    },
    [theme.breakpoints.down("xs")]: {
      flex: "0 0 49.8% !important",
    },
    "& h2": {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: "500",
      lineHeight: "24px",
      color: "#44403C",
    },
    "& p": {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: "500",
      lineHeight: "24px",
      color: "#44403C",
      marginTop: "12px",
    },
  },
  "& .divider": {
    height: "150px",
    width: "1px",
    backgroundColor: "#44403C",
    alignSelf: "center",
  },
}));
// Customizable Area End
