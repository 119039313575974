import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  formData: {
    email: string;
    currentPassword: string;
    newPassword:string
    fullName: string;
    number:string;
    profile:string;
    showPassword: boolean,
    showNewPassword:boolean;
    contactNumber:string;
    phoneId:string;
    role_id:string;
    accountType:string;
  };
  isEditing: boolean;
  value: string;
  phoneNumberData:Array<object>
  showData:boolean;
  selectedImage: File | null;
  isEditingImage:boolean;
  role: string;
  showOtpInput: boolean;
  isChangePassword: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userProfileApiCallId:string="";
  getUserPofileCallId:string="";
  countryDataApiCallId :string="";
  isFormikValueSet:boolean=false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
       getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      formData: {
        email: "",
        currentPassword: "",
        newPassword:"",
        fullName: "",
        profile:"",
        showPassword: false,
        showNewPassword:false,
        contactNumber:"",
        number:"",
      phoneId:'',
      role_id:'',
      accountType: ''
      },
      isEditing: false,
      value: 'Initial Read-Only Value',
      selectedImage: null,
      phoneNumberData:[],
      showData:false,
      isEditingImage: false,
      role: '',
      showOtpInput: false,
      isChangePassword: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        
      );
      if(webApiRequestCallId === this.countryDataApiCallId){
        const mappedCountryCode = webResponseJson.data.map((country: any) => ({
          value: country && `+${country.numeric_code}`,
          label: country && `+${country.numeric_code} - ${country.country_full_name}`
        }));
        this.setState({phoneNumberData : mappedCountryCode})
      }
      if(webApiRequestCallId === this.getUserPofileCallId){
        if(webResponseJson && webResponseJson.data && webResponseJson.data.attributes.role_id === 'Vendor'){
          this.setState({
            formData: {
              email: webResponseJson.data.attributes.email,
              currentPassword: "",
              newPassword: "",
              fullName: webResponseJson.data.attributes.full_name,
              contactNumber: webResponseJson.data.attributes.primary_contact_phone_number,
              number: "",
              phoneId: `${webResponseJson.data.attributes.primary_contact_country_code}`,
              showPassword: false,
              showNewPassword: false,
              profile: webResponseJson.data.attributes.profile_picture,
              role_id: webResponseJson.data.attributes.role_id,
              accountType: webResponseJson.data.attributes.type
            },
            isEditing: false,
            showData: true
          },
            () => {
              localStorage.setItem("accountType", webResponseJson.data.attributes.type)
            })
        }
        else if(webResponseJson && webResponseJson.data && webResponseJson.data.attributes.role_id === 'User'){
          this.setState({
            formData: {
              email: webResponseJson.data.attributes.email,
              currentPassword: "",
              newPassword: "",
              fullName: webResponseJson.data.attributes.full_name,
              contactNumber: webResponseJson.data.attributes.phone_number,
              number: "",
              phoneId: `${webResponseJson.data.attributes.
                country_code}`,
              showPassword: false,
              showNewPassword: false,
              profile: webResponseJson.data.attributes.profile_picture,
              role_id: webResponseJson.data.attributes.role_id,
              accountType: webResponseJson.data.attributes.type
            }, isEditing: false, showData: true
          },
            () => {
              localStorage.setItem("accountType", webResponseJson.data.attributes.type)
            })
        }
        
      }
      if( webApiRequestCallId === this.userProfileApiCallId){

        if(webResponseJson.errors){
          toast.error(webResponseJson.errors[0])
        }
        else if(webResponseJson.error){
          toast.error(webResponseJson.error.primary_contact_no)
        }
        else{
          this.setState({ isEditing: false, isEditingImage: false });
          this.handleHideOtpInput(); 
          toast.success("Changes Updated Successfully");
          this.props.navigation.navigate("LandingPageWeb");
          this.props.navigation.navigate("UserProfileBasicBlock");
        }
        // this.getUserProfileData()
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getUserProfileData();
    this.getAllCountryCode()
    this.setUserRole();    
  }

  submitFormData = (values: any) => {
  this.updateUserProfileInfo(values);
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
            this.setState({ selectedImage: file,formData:{...this.state.formData,profile:URL.createObjectURL(file)} });
    }
};

handleEdit = () => {
  this.setState({ isEditing: true , isEditingImage:true})
};

handleCancel = () => {
  this.setState({ isEditing: false, isEditingImage: false, showOtpInput: false });
};

  handleClick = (setFieldValue: any, values: any) =>
    setFieldValue(
      "showPassword",
      !values.showPassword
    )

  handleClickNewPassword = (setFieldValue: any, values: any) =>
    setFieldValue(
      "showNewPassword",
      !values.showNewPassword
    )
getAllCountryCode = async () => {
      let token = localStorage.getItem("authToken")
      let header:any = {
        "Content-Type": "application/json",
        token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getCountryCodeEndpoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      ); this.countryDataApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    };
updateUserProfileInfo = async (values:any) => {
  let token = localStorage.getItem("authToken")
  let header:any = {
    ContentType:"multipart/form-data",
    token
  };
  const updatedFormData = new FormData();
  updatedFormData.append("full_name",values.fullName);
  updatedFormData.append("email",values.email);
  this.state.selectedImage && updatedFormData.append("profile_picture",this.state.selectedImage);
  updatedFormData.append("current_password",values.currentPassword);
  updatedFormData.append("new_password",values.newPassword);
  updatedFormData.append("primary_contact_country_code", values.phoneId)
  if(this.state.formData.role_id === 'Vendor'){
    updatedFormData.append("primary_contact_no", `${values.phoneId}${values.contactNumber}`)
  }
  else if(this.state.formData.role_id === 'User'){
    updatedFormData.append("full_phone_number",`${values.phoneId}${values.contactNumber}`)
  }

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.userProfileEndpoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  ); this.userProfileApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPatchMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    updatedFormData
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};

getUserProfileData = async () => {
  let token = localStorage.getItem("authToken")
  let header:any = {
    "Content-Type": "application/json",
    token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getUserProfileEndpoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  ); this.getUserPofileCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};


handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  const { name, value } = e.target;
  this.setState((prevState) => ({
    formData: {
      ...prevState.formData,
      [name]: value
    }
  }));
};

  setUserRole = async () => {
    let userRole: string = await getStorageData("role");
    if (userRole) {
      this.setState({ role: userRole.toLocaleLowerCase() });
    }
  }

  handleShowOtpInput = () => {
    this.setState({showOtpInput: true});
  }

  handleHideOtpInput = () => {
    this.setState({showOtpInput: false});
  }

  toggleShowChangePassword = () => {
    const { isChangePassword } = this.state;
    this.setState({ isChangePassword: !isChangePassword });
  }
  // Customizable Area End
}
