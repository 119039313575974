import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall } from "../../../components/src/utils";
interface Image {
  id: number;
  date: string;
  url: string;
  type: string;
}

interface Attributes {
  id: number;
  name: string;
  image: Image;
}

interface ServiceData {
  id: string;
  type: string;
  attributes: Attributes;
}


interface SubCatImage {
  id: number;
  date: string;
  url: string;
  type: string;
}

interface SubCatAttributes {
  id: number;
  title: string;
  description: string;
  image: SubCatImage;
}

interface SubCatServiceSubCategory {
  id: string;
  type: string;
  attributes: SubCatAttributes;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  categoryOpen:number | null;
  vanueCategory: ServiceData[];
  isLoading: boolean;
  subCategoryId : number;
  subCategoryData : SubCatServiceSubCategory[];
  showSubCategory: boolean;
  selectedEventCategoryId: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfservicesrecommendationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCategoryApiCallId : string = "";
  getSubCategoryApiCallId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedEventCategoryId: 0,
      showSubCategory : false,
      subCategoryData : [],
      subCategoryId: 0,
      vanueCategory:  [],
      isLoading: false,
      categoryOpen:null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );


      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (webApiRequestCallId) {
        case this.getCategoryApiCallId:
          this.setState({
            vanueCategory: webResponseJson.data,
            isLoading: false,
          });
          break;
          case this.getSubCategoryApiCallId:
          this.setState({
            subCategoryData: webResponseJson.data,
            isLoading: false,
          });
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    this.getExperienceData();
  }


  getSubCatId = (id: number) => {
    if (!id) return; 
    this.setState(prevState => ({
      showSubCategory: prevState.showSubCategory ? false : true,
      selectedEventCategoryId: id
    }));
    this.getSubCategoryData(id);
  };

  handleNavigate = () => {
    this.props.navigation.navigate("Planyourevents")
  }

    getExperienceData = async () => {
      this.setState({ isLoading: true });
      this.getCategoryApiCallId = await apiCall({
        endPoint: configJSON.getAllCategory,
        method: "GET",
      });
      
    };

    getSubCategoryData = async (id :number) => {
      this.setState({ isLoading: true });
      this.getSubCategoryApiCallId = await apiCall({
        endPoint: configJSON.getAllSubCategory + `?service_id=${id}`,
        method: "GET",
      });
      
    };


  // Customizable Area End
}
