import * as React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  IconButton,
  Menu,
  styled,
  MenuItem,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  useMediaQuery,
} from "@mui/material";
import Loader from "../../../../components/src/AppLoader.web";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreVertIcon from '@mui/icons-material//MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HeaderFooterLayout from "../../../../components/src/HeaderFooterLayout";
import HeaderVendorFooter from "../../../../components/src/HeaderVendorFooter";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const createCaseInsensitiveObject = (obj: Record<string, any>) => {
  return new Proxy(obj, {
    get(target, prop: string) {
      const key = Object.keys(target).find(k => k.toLowerCase() === prop.toLowerCase());
      return key ? target[key] : undefined;
    }
  });
};
// Customizable Area End

import ReverseAuctionController from "../ReverseAuctionController";


export default class ReverseAuctionList extends ReverseAuctionController {
  // Customizable Area Start
  getStatusBackground = (paid: string): string => {
    if (paid.toLocaleLowerCase() === 'completed') {
      return '#0596691C';
    } else {
      return '#DC26261A';
    }
  }

  getStatusTextColor = (paid: string): string => {
    if (paid.toLocaleLowerCase() === 'completed') {
      return '#059669';
    } else {
      return '#DC2626';
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const open = Boolean(this.state.anchorEl);
    return (
      //Merge Engine DefaultContainer

      <>
        {this.state.loginRole === "User" && (<HeaderFooterLayout navigation={this.props.navigation}>
          <Loader isLoading={this.state.isLoading} />
          <CustomBox>
            <Box className="mainBox">
              <Grid container className="mainGrid" spacing={6}>
                <Grid item xs={12}>
                  <Box className="innerBox">
                    <Box width={"100%"} borderBottom={"1px solid #BFBBB9"} padding={"15px 0px"}>
                      <Typography className="mainTitle">Reverse Auction</Typography>
                    </Box>

                    <Box padding={"15px 0px"}>
                      <Typography className="description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                      </Typography>
                    </Box>

                    <Box marginTop={"10px"} marginBottom={"15px"} width={"100%"} display={"flex"} flexDirection={"row"} columnGap={"10px"} justifyContent={"start"} alignItems={"center"}>
                      <Button onClick={this.openReverseAuctionform} className="addButton" type="submit">Add Reverse Auction</Button>
                    </Box>

                    <Box sx={{ width: "90vw", overflow: "auto", borderRadius: "12px", border: "1px solid #d3d3d3" }}>
                      <Table aria-label="simple table" className="table" style={{ border: "none" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" className="tableHeaderText">#</TableCell>
                            <TableCell align="center" className="tableHeaderText">City</TableCell>
                            <TableCell align="center" className="tableHeaderText">Venue</TableCell>
                            <TableCell align="center" className="tableHeaderText">Pincode</TableCell>
                            <TableCell align="center" className="tableHeaderText">Event</TableCell>
                            <TableCell align="center" className="tableHeaderText">Event Date</TableCell>
                            <TableCell align="center" className="tableHeaderText">Budget</TableCell>
                            <TableCell align="center" className="tableHeaderText" >Service Details</TableCell>
                            <TableCell align="center" className="tableHeaderText"  >Vendor Criteria</TableCell>
                            <TableCell align="center" className="tableHeaderText">Status</TableCell>
                            <TableCell align="center" className="tableHeaderText">Actions</TableCell>
                          </TableRow>
                        </TableHead>

                        {this.state.reverseAuctionData?.length > 0 ?
                          <TableBody>
                            {this.state.reverseAuctionData.map((item: { id:number, custom_fields: any; status: any }, index: number) => {
                              const row = createCaseInsensitiveObject(item.custom_fields);                             
                              return (
                                <TableRow key={index}>
                                  <TableCell align="center" className="tableRowText">{index + 1}</TableCell>
                                  <TableCell align="center" className="tableRowText">{row.City}</TableCell>
                                  <TableCell align="center" className="tableRowText">{row.Venue}</TableCell>
                                  <TableCell align="center" className="tableRowText">{row.Pincode}</TableCell>
                                  <TableCell align="center" className="tableRowText">{row.Event}</TableCell>
                                  <TableCell align="center" className="tableRowText">{row.Event}</TableCell>
                                  <TableCell align="center" className="tableRowText">{row.Budget}</TableCell>
                                  <TableCell align="center" className="tableRowText">{row.service_details}</TableCell>
                                  <TableCell align="center" className="tableRowText">{row.Vendor_criteria}</TableCell>
                                  <TableCell align="center" className="tableRowText">
                                    <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                                      <Box sx={{ textTransform:"capitalize", width: "max-content", borderRadius: "8px", padding: "10px 15px", background: this.getStatusBackground(item.status), color: this.getStatusTextColor("Active") }}>
                                        {item.status}
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center" className="tableRowText">
                                    <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                                      <Box sx={{ display: "flex", alignItems: "center" }}>
                                        {/* <ChatBubbleOutlineIcon /> */}
                                        <IconButton data-test-id={`ViewMoreIcon${index}`} onClick={this.handleClick} >
                                          <MoreVertIcon />
                                        </IconButton>
                                      </Box>
                                      <Menu
                                        anchorEl={this.state.anchorEl}
                                        open={open}
                                        onClose={this.handleClose}
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                          vertical: 'top',
                                          horizontal: 'right',
                                        }}
                                        sx={{
                                          '& .MuiPaper-root': {
                                            // backgroundColor: 'transparent', // Removes black background
                                            boxShadow: 'none', // Removes shadow if needed
                                          }
                                        }}
                                      >
                                        <MenuItem data-test-id={`EditOrderButton${index}`} onClick={()=>this.handleCloseUser(item.id)}><CancelIcon style={{ marginRight: "10px", color: "#78716C" }} /> Cancel</MenuItem>
                                      </Menu>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                          :
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={11} style={{ textAlign: "center", height: "300px" }}>
                                <Typography>No Reverse Auction Found!</Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        }
                      </Table>
                    </Box>

                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CustomBox>
        </HeaderFooterLayout>)}

        {this.state.loginRole === "Vendor" && (
          <HeaderVendorFooter navigation={this.props.navigation}>
            <Box sx={{ padding: '0px 41px', width: '100%' }}>
              <Box
                display="flex"
                alignItems="center"
                padding="10px 0px"
                sx={{ marginBottom: '10px' }}
              >
                {/* Back Button */}
                <IconButton sx={{ marginRight: "12px" }}>
                  <ArrowBackIosIcon sx={{ color: "black", fontSize: "24px", fontWeight: '600' }} onClick={this.openDashboard} />
                </IconButton>

                {/* Title */}
                <Typography variant="h5" fontWeight="bold" sx={{ fontSize: "24px", fontWeight: '600', lineHeight: '32px', paddingTop: '5px' }}>
                  Reverse Auction
                </Typography>
              </Box>


              <Box sx={{ width: "100%", overflowX: "auto", borderRadius: "12px", border: "1px solid #d3d3d3" }}>
                <Table>
                  <TableHead>
                    <TableRow >
                      <TableCell className="tableHeaderText" align="center" >#</TableCell>
                      <TableCell className="tableHeaderText" align="left" sx={{whiteSpace:"nowrap"}}>Client Name </TableCell>
                      <TableCell className="tableHeaderText" align="left" >Event</TableCell>
                      <TableCell className="tableHeaderText" align="left" sx={{whiteSpace:"nowrap"}}>Event Date </TableCell>
                      <TableCell className="tableHeaderText" align="left" >Venue</TableCell>
                      <TableCell className="tableHeaderText" align="left" >City</TableCell>
                      <TableCell className="tableHeaderText" align="left" >Budget</TableCell>
                      <TableCell className="tableHeaderText" align="left"  >Comments</TableCell>
                      <TableCell className="tableHeaderText" align="left" >Status</TableCell>
                      <TableCell className="tableHeaderText" align="left" >Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>                  
                    {this.state.vendorAuctionData?.length > 0 ? (
                      this.state.vendorAuctionData.map((item: { submitted_data: any, status: any, id:any }, index) => {
                       const row = createCaseInsensitiveObject(item.submitted_data);
                       const row2=  item?.submitted_data["Event_Date_(Date_range_can_be_selected)"]
                        // const row2 =item.submitted_data.Event_Date_(Date_range_can_be_selected
                       
                        return (

                          <TableRow key={index} data-test-id="tablerow">
                            <TableCell align="center" >{index}</TableCell>
                            <TableCell
                              data-test-id= "btnrow1"
                              align="left"
                              onClick={() => this.handleRowClick(item.id)}
                              sx={{ cursor: "pointer" }} // Optional: Changes cursor to indicate clickability
                            >
                              {row.Client_Name}
                            </TableCell>
                            <TableCell align="left"
                              data-test-id= "btnrow1"
                              onClick={() => this.handleRowClick(item.id)}
                              sx={{ cursor: "pointer" }}
                            >{row.Event}</TableCell>
                            <TableCell align="left" 
                              data-test-id= "btnrow1"
                             onClick={() => this.handleRowClick(item.id)}
                             sx={{ cursor: "pointer" }} 
                            >{row2}</TableCell>
                            <TableCell align="left" 
                              data-test-id= "btnrow1"
                             onClick={() => this.handleRowClick(item.id)}
                             sx={{ cursor: "pointer" }} 
                            >{row.Venue}</TableCell>
                            <TableCell align="left"
                              data-test-id= "btnrow1"
                             onClick={() => this.handleRowClick(item.id)}
                             sx={{ cursor: "pointer" }} 
                             >{row.City}</TableCell>
                            <TableCell align="left" 
                              data-test-id= "btnrow1"
                             onClick={() => this.handleRowClick(item.id)}
                             sx={{ cursor: "pointer" }} 
                            >{row.budget}</TableCell>
                            <TableCell align="left" 
                              data-test-id= "btnrow1"
                             onClick={() => this.handleRowClick(item.id)}
                             sx={{ cursor: "pointer" }} 
                            >{row.Comments}</TableCell>
                            <TableCell align="left" className="tableRowText">
                              <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                                <Box sx={{ textTransform:"capitalize", width: "max-content", borderRadius: "8px", padding: "10px 15px", background: this.getStatusBackground(item.status), color: this.getStatusTextColor("Active") }}>
                                  {item.status}
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell align="center" className="tableRowText">
                              <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                  <ChatBubbleOutlineIcon />
                                  <IconButton data-test-id={`ViewMoreIcon${index}`} onClick={(e)=>this.handleClick(e, item.id)} >
                                    <MoreVertIcon />
                                  </IconButton>
                                </Box>
                                <Menu
                                  anchorEl={this.state.anchorEl}
                                  open={open}
                                  onClose={this.handleClose}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  sx={{
                                    '& .MuiPaper-root': {
                                      // backgroundColor: 'transparent', // Removes black background
                                      boxShadow: 'none', // Removes shadow if needed
                                    }
                                  }}
                                >

                                  <MenuItem data-test-id={`EditOrderButton${index}`} onClick={()=>this.handleAccept()}><CheckBoxIcon style={{ marginRight: "10px", color: "#78716C" }} /> Accept </MenuItem>
                                  <MenuItem data-test-id={`EditOrderButton${index}`} onClick={()=>this.handleReject()}><CancelIcon style={{ marginRight: "10px", color: "#78716C" }} /> Reject </MenuItem>
                                </Menu>
                              </Box>
                            </TableCell>

                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={10} align="center">
                          <Typography>No Reverse Auction Found!</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Box>

          </HeaderVendorFooter>
        )}



      </>


      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
};

// Customizable Area Start
const CustomBox = styled(Box)({
  padding: "0 4% 4% 4%",
  overflow: "hidden",
  "& .mainTitle": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#292524",
  },
  "& .description": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#44403C",
  },
  "& .addButton": {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "capitalize",
    background: "#7F1187",
    padding: "10px 0px",
    borderRadius: "30px",
    width: "227px",
    cursor: "pointer",
    "&:hover": {
      background: "#7F1287"
    }
  },
  "& .mainContainer": {
    display: "flex",
    justifyConetent: "center",
    alignItems: "center",
    width: "100vw",
    height: "auto",
  },
  "& .tableHeaderText": {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "poppins",
    color: "#78716C", 
    whiteSpace: "nowrap",
  },
  "& .tableRowText": {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#44403C",
    borderBottom: "transparent"
  },
  "& .overviewBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90vw"
  },
  "& .overviewText": {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "poppins"
  },
  "& .overviewSelect": {
    width: "124px",
    height: "40px",
    color: "#6F767E"
  },
  "& .cardBox": {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    gap: "1rem",
    paddingTop: "2rem",
    flexWrap: "wrap",
    "@media (min-width: 768px)": {
      flexDirection: "row",
    },
    "@media (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  "& .mainBox": {
    flexWrap: "Wrap",
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    marginTop: "0px",
    "@media (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  "& .innerBox": {
    maxWidth: "100%",
    gap: "1rem",
    padding: "1rem 0rem",
    display: "flex",
    flexDirection: "column",
  },
  "& .orderManagementText": {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "poppins"
  },
  "& .reviewBox": {
    paddingTop: "1rem",
    "@media (max-width: 1510px)": {
      paddingLeft: "0rem",
      paddingRight: "0rem"
    },
    "@media (max-width: 768px)": {
      width: "90vw",
      paddingLeft: "2rem",
      paddingRight: "2rem"
    },
  },
  "& .reviewInnerBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .reviewText": {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "poppins"
  },
  "& .table": {
    border: "1px solid lightgrey",
    borderRadius: "12px",
  },
  "& .seeallBtn": {
    color: "#7F1187",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "poppins"
  },
  "& .reviewCard": {
    borderBottom: "1px solid #CBD5E1",
    paddingBottom: "1rem",
  },
  "& .reviewInnerCard": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "1rem"
  },
  "& .reviewCardname": {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#292524"
  },
  "& .reviewCardTime": {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#292524"
  },
  "& .commentBox": {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "1rem"
  },
  "& .commentText": {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#292524"
  },
  "& .rating": {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "poppins",
    color: "#78716C"
  },
  "& .reviewDescription": {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#A8A29E",
    padding: "0.7rem 0"
  }

})

function isWidthUp(arg0: string, width: Readonly<import("../ReverseAuctionController").Props> & Readonly<{ children?: React.ReactNode; }>) {
  throw new Error("Function not implemented.");
}
// Customizable Area End