import React from "react"; 
// Customizable Area Start
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
import { Box, Typography, Button, styled, Grid } from "@mui/material";

// Customizable Area End
import { BlockComponent } from "../../../framework/src/BlockComponent";

export interface Props {
  navigation: any;
  id: string;
  viewAll: boolean;
  viewAllHandler: any;
  services: any;
}

interface S {
  viewAll: boolean;
  viewAllHandler: Function;
  services: any;
}

interface SS {
  id: any;
}

export default class VendorCategories extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = {
      viewAll: props.viewAll,
      viewAllHandler: props.viewAllHandler,
      services: props.services,
    };
  }

  // Sample vendor categories array for testing
  render() {
    const { viewAll, services } = this.props;

    return (
      <HeaderFooterLayout navigation={this.props.navigation}>
        <CustomBox data-test-id="vendorCategoriesContainer">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "poppins",
              fontWeight: 500,
              textAlign: "center",
              marginBottom: "2rem",
              marginTop: "2rem",
            }}
            data-test-id="vendorCategoriesTitle"
          >
            Explore vendor by category
          </Typography>
          <Box className="categoryBox" sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
            <Box data-test-id="vendorCategoryList">
              <Grid container spacing={3}>
                {services.map((item: any) => (
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Box
                      key={item.id}
                      width={"100%"}
                      height={"100%"}
                      position={"relative"}
                      style={{ cursor: "pointer" }}
                      data-test-id={"vendorCategoryItems"}
                    >
                      <Box className="ImageOverlay" />
                      <Typography className="vendorTitle" component={"h2"}>
                        {item.attributes.name}
                      </Typography>
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "8px",
                        }}
                        src={item.attributes.image.url}
                        alt={item.name}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
          <div style={{ margin: "0 3rem" }} data-test-id="vendorCategoryDescription">
            <Typography className="vendorDescription">
              Planning a event is an exciting journey filled with countless details and memorable moments. At our Wedding Essentials Shop, we understand that every couple dreams of a perfect day that reflects their unique love story. That's why we're dedicated to providing a curated selection of high-quality products to make your event planning process smooth, stress-free, and, most importantly,
            </Typography>
          </div>
          <div className="buttonContainer" data-test-id="viewAllButtonContainer">
            <Button
              className="viewAllButton"
              onClick={this.props.viewAllHandler}
              data-test-id="viewAllButton"
            >
              {viewAll ? "View Less" : "View All"}
            </Button>
          </div>
        </CustomBox>
      </HeaderFooterLayout>
    );
  }
}


const CustomBox = styled(Box)({
  padding: "3%",
  '& .vendorCategoryContainer':{
      display: 'flex',
        flexWrap: 'wrap',
        gap: "1rem",
        justifyContent: 'center',
        margin : "0 20px"
  },
  '& .ImageOverlay' : {
    width: '100%',
    height: "100%",
    background: '#00000040',
    position: 'absolute',
    overflow: 'hidden',
    borderRadius: '8px',
    top : 0,
    bottom : 0,
  },
  '& .vendorTitle' : {
    fontSize: '30px',
    fontWeight: 600,
    fontFamily: "Poppins",
    color: '#FFF',
    position: 'absolute',
    bottom: '40px',
    left: '45px',
    textTransform: 'capitalize',
  },
  '& .vendorDescription':{
    fontFamily: "Poppins",
    fontSize: "16px", 
    fontWeight : "400",
    lineHeight: "26px",
    textAlign: "center",
    color: "rgb(87, 83, 78)", 
    margin: "16px 10px"
  },
  '& .buttonContainer' : {
        textAlign : "center",
        marginBottom : "20px"
    },
    '& .viewAllButton' :{
        fontSize : "12px",
        fontFamily: 'Poppins',
        padding: "5px 25px",
        textAlign: "center",
        color : '#8a00c4',
        border: "1px solid #8a00c4",
        cursor: "pointer",
        borderRadius: "30px"
    },
})


