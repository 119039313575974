// Customizable Area Start
import React, { Component } from "react";
import { Box, styled } from "@mui/material";

import MainTitle from "./MainTitle.web";
import { IWhyChooseUs } from "../LandingPageController";

interface Props {
  whyChooseUsData: IWhyChooseUs[];
}

interface State {}

export default class WhyChooseUsSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { whyChooseUsData } = this.props;
    return (
      <WhyChooseUsContainer component="section" id="why-choose-us">
        <MainTitle title={whyChooseUsData && whyChooseUsData.length > 0 && whyChooseUsData[0].attributes.title || "Why choose us"} />
        {whyChooseUsData.map((data) => (
          <Box className="text-image-container" key={data.id}>
            <ol>
              <li>{data.attributes.description_one}</li>
              <li>{data.attributes.description_two}</li>
              <li>{data.attributes.description_three}</li>
            </ol>
            <Box className="image-container">
              <img src={data.attributes.image.url} alt="why-choose" />
            </Box>
            <ol start={4}>
              <li>{data.attributes.description_four}</li>
              <li>{data.attributes.description_five}</li>
              <li>{data.attributes.description_six}</li>
            </ol>
          </Box>
        ))}
      </WhyChooseUsContainer>
    );
  }
}

const WhyChooseUsContainer = styled(Box)(({ theme }) => ({
  padding: "30px 0",
  "& .text-image-container": {
    display: "flex",
    gap: "30px",
    marginTop: "60px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    "& ol": {
      flex: "1 1 auto",
      margin: "0",
      paddingLeft: "20px",
      maxWidth: "50%",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        maxWidth: "unset",
        width: "unset",
      },
      "*": {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "26px",
        color: "#57534E",
        [theme.breakpoints.down("sm")]: {
          textAlign: "justify",
        },
      },
      "& li": {
        marginBottom: "30px",
        "&:last-child": {
          marginBottom: "0px",
        },
      },
    },
    "& .image-container": {
      flex: "0 0 339px",
    },
    "& img": {
      width: "100%",
      height: "100%",
      minHeight: "549px",
      overflow: "hidden",
      borderRadius: "8px",
      objectFit: "cover",
      backgroundPosition: "center center",
    },
  },
}));
// Customizable Area End
