import React from "react";
import { Typography, Box, styled, SxProps, Theme } from "@mui/material";
import Select from "react-select";

interface SelectFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  value: string;
  touched?: boolean;
  error?: boolean;
  handleBlur: (event: React.FocusEvent) => void;
  helperText: string | boolean | undefined;
  setFieldValue: (field: string, value: string) => void;
  options: { value: number | string; label: string }[];
  isRequired?: boolean;
  sx?: SxProps<Theme>;
  isDisabled?: boolean;
  disabled?:boolean;
  onChange?: (value: string) => void;
  onKeyDown?: (e : any ) => void
}

const StyledSelectField = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isError",
})(({ isError }: { isError: boolean | undefined }) => ({
  marginBottom: "16px",
  "& .common-form-label": {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#334155",
    display: "block",
    marginBottom: "4px",
    "& span": {
      lineHeight: "22px",
      color: "#DC2626",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
    },
  },
  "& *": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#0F172A",
  },
  "& .select-dropdown__indicator-separator": {
    display: "none",
  },
  "& .select-dropdown__value-container": {
    padding: "6px 8px 5px",
  },
  "& .select-dropdown__indicators": {
    padding: "0px 8px 0px 0px",
  },
  "& .select-dropdown__control": {
    borderTopWidth: isError ? "1px" : "0px",
    borderRightWidth: isError ? "1px" : "0px",
    borderLeftWidth: isError ? "1px" : "0px",
    borderColor: isError ? "#F87171 !important" : "#E2E8F0 !important",
    borderRadius: isError ? "8px" : "0px",
    boxShadow: "none",
  },
  "& .select-dropdown__control--is-focused": {
    borderRadius: "8px",
    borderWidth: "1px",
    borderColor: isError ? "#F87171 !important" : "rgb(25,18,210) !important",
  },
  "& .select-dropdown__placeholder": {
    color: "#A8A29E",
    marginLeft: "0px",
  },
  "& .error-text": {
    color: "#DC2626",
    margin: "8px 0 0",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
  },
  "& .select-dropdown__option--is-selected": {
    backgroundColor: "#801187",
  },
  "& .select-dropdown__menu-list": {
    maxHeight: "250px",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E2E8F0",
      borderRadius: "10px",
      margin: "0",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#801187",
      borderRadius: "10px",
    },
  },
}));

const SelectField: React.FC<SelectFieldProps> = ({
  name,
  label,
  placeholder = "",
  value,
  error,
  handleBlur,
  helperText,
  setFieldValue,
  options,
  touched,
  isRequired,
  sx,
  disabled,
  isDisabled,
  onChange,
  onKeyDown
}) => {
  return (
    <StyledSelectField isError={error} sx={sx}>
      <Typography component="label" className="common-form-label">
        {label}
        {isRequired && <Typography component="span">*</Typography>}
      </Typography>
      <Select
        classNamePrefix="select-dropdown"
        placeholder={placeholder}
        name={name}
        onChange={(selectedOption: any) => {
          setFieldValue(name, selectedOption.value);
          onChange && onChange(selectedOption.value);
        }}
        styles={{
          control: (base) => ({
            ...base,
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 400,
            color: "red",
          }),
          option: (base, { isSelected }) => ({
            ...base,
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 400,
            color: '#A8A29E' 
           
          }),
        }}
        onKeyDown={onKeyDown}
        onBlur={handleBlur}
        options={options}
        isDisabled={isDisabled}
        disabled={disabled}
        value={options.find((data) => String(data.value) == value) ?? null}
        components={{
          DropdownIndicator: () => {
            return (
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.42148 11.4131L12.0115 14.0031C12.4015 14.3931 13.0315 14.3931 13.4215 14.0031L16.0115 11.4131C16.6415 10.7831 16.1915 9.70312 15.3015 9.70312H10.1215C9.23148 9.70312 8.79148 10.7831 9.42148 11.4131Z"
                  fill="#0F172A"
                />
              </svg>
            );
          },
        }}
      />
      {touched && error && (
        <Typography className="error-text">{helperText}</Typography>
      )}
    </StyledSelectField>
  );
};

export default SelectField;
