import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
import { Box, Typography as Text } from '@mui/material';
import Loader from "../../../components/src/AppLoader.web";
import { styled } from '@mui/material';
const FunctionBanner = require("../assets/weddingBanner.png");
const arrowSigin = require("../assets/arrow-sigin.png");


// Customizable Area End

import CfservicesrecommendationController, {
  Props,
  configJSON,
} from "./CfservicesrecommendationController";

export default class Cfservicesrecommendation extends CfservicesrecommendationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <HeaderFooterLayout navigation={this.props.navigation}>
        <style>
          {/* ${this.state.categoryOpen ? this.state.categoryOpen + 2 : ""} */}
          {`
          .targetDiv:nth-child(${this.state.categoryOpen}):hover {
           transform:none;
          }
          `}
        </style>
        <Loader isLoading={false} />
        <BannerStyle>
          <Box>
            <Text className='our_mission'>Your vision, our mission!</Text>
            <Text className='trusted_reviews'>Find the best wedding vendors with thousands of trusted reviews</Text>
          </Box>
        </BannerStyle>
        <FunctionCelebrateDiv>
          <Box className='function_celebrate_heading_box'>
            <Text className='function_heading_celebrate_txt'>What function are you looking to celebrate?</Text>
          </Box>
          <Box className='function_celebrate_box'>
            {this.state.vanueCategory.map((item:any, i:any) => <>
            <Box key={item.id} data-test-id={"function-tex-" + i} onClick={()=>{ this.getSubCatId(item?.attributes?.id)}} className="function_box targetDiv" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${item?.attributes?.image.url}) no-repeat center center`, backgroundPosition: item.position, transform: item.toggle? "scaleX(1.07) scaleY(1.1)":"" }}>
              <Text className='function-text'>{item?.attributes?.name}</Text> <img src={arrowSigin} style={{ marginLeft: "2px", cursor: "pointer" }} />
            </Box>
            
          </>)}

            {this.state.showSubCategory && (
              <Box
                key={"24"}
                className="eventCategoryWithElementBox"
                style={{
                  gridRow: Math.ceil(this.state.selectedEventCategoryId / 2) + 1,
                  gridColumn: "1 / span 2"
                }}
              >
                <Text className='selectEventCategory'>Please select your event Category</Text>
                <Box className='eventCategoryBox'>
                  {this.state.subCategoryData.map((item) => (
                    <Text
                      className='eventCategoryElement'
                      data-test-id={"eventCategoryElement"}
                      onClick={this.handleNavigate}
                    >
                      {item.attributes?.title}
                    </Text>
                  ))}
                </Box>
              </Box>
            )}


          </Box>
        </FunctionCelebrateDiv>
      </HeaderFooterLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BannerStyle = styled(Box)({
  width: "100%",
  height: "500px",
  display: "flex",
  justifyContent: "center",
  alignItems: "end",
  background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 99.8%), url(${FunctionBanner}) no-repeat center center`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  "& .our_mission": {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "40px",
    lineHeight: "52px",
    letterSpacing: "0%",
    textAlign: "center",
    textTransform: "capitalize",
    color: "#FFFFFF"
  },
  "& .trusted_reviews": {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "34px",
    letterSpacing: "0%",
    textAlign: "center",
    textTransform: "capitalize",
    color: "#FFFFFF",
    marginBottom: "46px"
  }
});

const FunctionCelebrateDiv = styled(Box)({
  "& .function_celebrate_heading_box": {
    width: "903px",
    margin: "40px auto 28px auto"
  },
  "& .function_heading_celebrate_txt": {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "40px",
    lineHeight: "52px",
    letterSpacing: "0%",
    textAlign: "center",
    textTransform: "capitalize"
  },
  "& .function_celebrate_box": {
    margin: "0px 80px",
    display: "grid",
    gridTemplateRows: "200px 200px 200px 200px",
    gridTemplateColumns: "1fr 1fr",
    gridAutoRows: "200px",
    gap: "35px"
  },
  "& .function_box": {
    borderRadius: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover !important",
    height: "100%",
    width: "100%",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      transform: "scaleX(1.07) scaleY(1.1)"
    },
    "& .function-text": {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "32px",
      letterSpacing: "0%",
      color: "#FFFFFF",
      cursor: "pointer"
    },
  },
  "& .eventCategoryWithElementBox": {
    height: "216px",
    border: "1px solid rgba(127, 17, 135, 1)",
    borderRadius: "24px",
    padding: "27px",
    marginLeft: "3%",
    marginTop: "-10px"
  },
  "& .selectEventCategory": {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "34px",
    letterSpacing: "0%",
    textTransform: "capitalize"

  },
  "& .eventCategoryBox": {
    marginTop: "10px",
    display: "grid",
    gridTemplateRow: "40px 40px",
    gridTemplateColumns: "repeat(6, 1fr)",
    rowGap: "20px",
    columnGap: "10px"
  },
  "& .eventCategoryElement": {
    cursor: "pointer",
    borderRadius: "26px",
    padding: "6px 12px",
    border: "1px solid rgba(127, 17, 135, 1)",
    textAlign: "center",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "0%",
    color: "rgba(127, 17, 135, 1)",
    transition: "all 0.4s",
    "&:hover": {
      color: "rgba(255, 255, 255, 1)",
      backgroundColor: "rgba(127, 17, 135, 1)"
    }
  }
});
// Customizable Area End
