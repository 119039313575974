import { removeStorageData } from "../../framework/src/Utilities";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { toast } from "react-toastify";


export const apiCall = async (data: {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: any;
  token?: string;
}): Promise<any> => {
  
    const { contentType, method, endPoint, body, token } = data;

    const headers: Record<string, string> = {
      token: token || localStorage.getItem("authToken") || "",
      "Content-Type": contentType || "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body &&
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    
};



export const mockAPICall = (
  apiData: object,
  instance: any,
  apiCallID: string
) => {
  const msgSuccessRestAPI = new Message(
    getName(MessageEnum.RestAPIResponceMessage)
  );
  msgSuccessRestAPI.addData(
    getName(MessageEnum.RestAPIResponceDataMessage),
    msgSuccessRestAPI.messageId
  );
  msgSuccessRestAPI.addData(
    getName(MessageEnum.RestAPIResponceSuccessMessage),
    apiData
  );
  instance[apiCallID] = msgSuccessRestAPI.messageId;
  runEngine.sendMessage("Unit Test", msgSuccessRestAPI);
};

export const clearStorageData = async () => {
  await removeStorageData("authToken");
  await removeStorageData("id");
  await removeStorageData("role");
  await removeStorageData("user");
  await removeStorageData("email");
};

export const redirectToPage = (navigation: any, blockName: string) => {
  navigation.navigate(blockName);
};

export const isError = (
  touched: boolean | undefined,
  errors: string | undefined,
  isValid: boolean = false
) => {
  return isValid ? isValid : touched && !!errors;
};

export const helperText = (
  touched: boolean | undefined,
  errors: string | undefined,
  isValid: boolean = false,
  errorMessage = ""
) => {
  return isValid ? errorMessage : touched && errors;
};

export const scrollToTop = () => {
  window.scrollTo({ behavior: "smooth", top: 0 });
};

export const copyProductUrlToClipboard = (productId: number | null): void => {
  const host: string = window.location.host;
  let currentUrl: string = window.location.href;
  if (productId) {
    currentUrl = host + `/product/${productId}`
  }
  navigator.clipboard.writeText(currentUrl).then(() => {
    toast.success("URL copied to clipboard successfully");
  }).catch(err => {
    console.error("Failed to copy URL: ", err);
  });
}
