import React from "react";
import PhoneInput from 'react-phone-input-2';
import { Box } from "@mui/material";

interface myProps {
  getCountryCode: any;
  style?: any;
}

export default function SelectCountryCode(props: myProps) {
  return (
    <Box sx={props.style}>
      <PhoneInput
        data-test-id="countryId"
        country={"in"}
        disableSearchIcon={true}
        enableTerritories={false}
        enableSearch={true}
        enableAreaCodes={false}
        onChange={(phone) => {
          const formattedPhone = `+${phone}`;
          props.getCountryCode(formattedPhone);
        }}
        inputProps={{
          readOnly: true,
        }}
        containerStyle={{
          width: "auto",
        }}
        inputStyle={{
          display: "none",
        }}
        buttonStyle={{
          marginBottom: "3rem",
          width: "auto",
          backgroundColor: "#EFF6FF",
          border: "none",
        }}
        searchStyle={{
          border: "none",
          padding: "12px",
          width: "100%",
          borderBottom: "1px solid black",
        }}
        dropdownStyle={{
          width: "45vw",
          maxWidth: "300px",
          overflowX: "hidden"
        }}
      />
    </Box>
  );
}