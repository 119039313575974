import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/utils";
import { productDefaultImage } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import eventEmitter from "../../../components/src/EventEmitter";

// Customizable Area Start
export interface ProductData {
  id: string;
  type: string;
  attributes: ProductDataAttributes;
}

export interface ProductDataAttributes {
  id: number;
  name: string;
  SKU: string;
  category: Category;
  description: string;
  fabric_details: string;
  price: string;
  available_quantity: number;
  media_files: MediaFile[];
  functions: Function[];
  service_locations: ServiceLocations;
  product_variants: ProductVariant[];
  custom_fields: any;
  created_at: string;
  is_published: boolean;
  product_faqs: ProductFaqs[];
  size_chart: string;
  is_available: boolean;
  is_deleted: boolean;
  is_wishlisted: boolean;
  coupon?: Coupon;
  average_score: number;
  total_ratings: number;
}

export interface Category {
  id: string;
  type: string;
  attributes: CategoryAttributes;
}

export interface CategoryAttributes {
  id: number;
  name: string;
  description: string | null;
  dark_icon: string | null;
  dark_icon_active: string | null;
  dark_icon_inactive: string | null;
  light_icon: string | null;
  light_icon_active: string | null;
  light_icon_inactive: string | null;
  rank: number | null;
  created_at: string;
  updated_at: string;
  sub_categories: any[];
  selected_sub_categories: any | null;
  image: string;
}

export interface MediaFile {
  url: string;
  filename: string;
  content_type: string;
  byte_size: number;
}

export interface Function {
  id: string;
  type: string;
  attributes: FunctionAttributes;
}

export interface FunctionAttributes {
  id: number;
  name: string;
  function_image: string;
  description: string;
}

export interface ServiceLocations {
  data: ServiceLocation[];
}

export interface ServiceLocation {
  id: string;
  type: string;
  attributes: ServiceLocationAttributes;
}

export interface ServiceLocationAttributes {
  id: number;
  location_name: string;
}

export interface ProductVariant {
  id: string;
  type: string;
  attributes: ProductVariantAttributes;
}

export interface ProductVariantAttributes {
  id: number;
  product_size: ProductSize;
  product_color: ProductColor;
  available_count: number;
}

export interface ProductSize {
  id: string;
  type: string;
  attributes: ProductSizeAttributes;
}

export interface ProductSizeAttributes {
  id: number;
  size_label: string;
  measurement: number;
}

export interface ProductColor {
  id: string;
  type: string;
  attributes: ProductColorAttributes;
}

export interface ProductColorAttributes {
  id: number;
  name: string;
  color_image: string | null;
}

export interface Coupon {
  id: string;
  type: string;
  attributes: CouponAttributes;
}

export interface CouponAttributes {
  id: number;
  cost_type: string;
  coupon_code: string;
  exp_date: string;
  min_order_value: string;
  max_discount: string;
  discount_percentage: number;
  terms_policies: string;
  is_expired: boolean;
  amount: string | null;
}

export interface ProductFaqs {
  id: string;
  type: string;
  attributes: ProductFaqAttributes;
}

export interface ProductFaqAttributes {
  id: number;
  question: string;
  answer: string;
  open?: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  errorMessage: string;
  product: ProductDataAttributes | null;
  quantity: number;
  productSizes: ProductSizeAttributes[];
  productColors: ProductColorAttributes[];
  selectedSize: number;
  selectedColor: number;
  lowStockAlertValue: number;
  showButtonLoader: boolean;
  clickedButton: string;
  openSizeChartModal: boolean;
  productMainImage: {
    id: number,
    url: string,
  };
  productFaqs: ProductFaqAttributes[];
  wishlistLoader: boolean;
  productSuggestions: ProductData[];
  averageRating: number;
  totalRatings: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductSummaryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductDataById: string = "";
  addToCartId: string = "";
  addToWishlistId: string = "";
  getProductSuggestionsApiId: string = "";
  getProductAverageRatingId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      errorMessage: '',
      product: null,
      quantity: 1,
      productSizes: [],
      productColors: [],
      selectedSize: 0,
      selectedColor: 0,
      lowStockAlertValue: 10,
      showButtonLoader: false,
      clickedButton: '',
      openSizeChartModal: false,
      productMainImage: {
        id: 0,
        url: productDefaultImage
      },
      productFaqs: [],
      wishlistLoader: false,
      productSuggestions: [],
      averageRating: 0,
      totalRatings: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webApiRequestCallId === this.getProductDataById) {
        this.handleProductDataByIdResponse(webResponseJson);
      }

      if (webApiRequestCallId === this.addToCartId) {
        this.handelAddToCartIdResponse(webResponseJson);
      }

      if (webApiRequestCallId === this.addToWishlistId) {
        this.handelAddToWishlistResponse(webResponseJson);
      }

      if (webApiRequestCallId === this.getProductSuggestionsApiId) {
        this.handleProductSuggestionsDataResponse(webResponseJson);
      }

      if (webApiRequestCallId === this.getProductAverageRatingId) {
        this.handleProductAverageRatingResponse(webResponseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    let productId = await this.props.navigation.getParam("id", "");
    if (productId) {
      this.getProductById(productId);
      this.getProductAverageRating(productId);
    }
  }

  getProductById = async (id: string) => {
    this.setState({ isLoading: true });
    this.getProductDataById = await apiCall({
      endPoint: `${configJSON.getProductByIdEndpoint}/${id}`,
      method: configJSON.httpGetType,
    });
  };

  handleProductDataByIdResponse = (response: { data: ProductData, errors: string | Array<string> }) => {
    if (response?.data) {
      const { productColors, productSizes } = this.extractProductAttributes(response.data.attributes.product_variants);
      this.getProductMainImage(response.data.attributes.media_files);
      const Faqs = response.data.attributes.product_faqs;
      const productFaqs = Faqs.map((item) => {
        return {
          id: item.attributes.id,
          question: item.attributes.question,
          answer: item.attributes.answer,
          open: false,
        }
      })
      this.setState({ product: response.data.attributes, productSizes, productColors, productFaqs }, () => {
        this.getProductSuggestionsData(this.state.product?.category.attributes.name || '');
      });
    }
    if (response.errors) {
      const errorMessage = response.errors[0];
      this.setState({ errorMessage });
    }
    this.setState({ isLoading: false });
  };

  extractProductAttributes = (productVariants: ProductVariant[]) => {
    const productSizesMap: Map<number, ProductSizeAttributes> = new Map();
    const productColorsMap: Map<number, ProductColorAttributes> = new Map();

    productVariants.forEach(variant => {
      const { product_size, product_color } = variant.attributes;

      if (!productSizesMap.has(product_size.attributes.id)) {
        productSizesMap.set(product_size.attributes.id, product_size.attributes);
      }

      if (!productColorsMap.has(product_color.attributes.id)) {
        productColorsMap.set(product_color.attributes.id, product_color.attributes);
      }
    });

    const productSizes = Array.from(productSizesMap.values());
    const productColors = Array.from(productColorsMap.values());

    return { productSizes, productColors };
  }

  handleQuantityChange = (event: any) => {
    this.setState({ quantity: event.target.value as number });
  };

  getProductMainImage = (mediaFiles: MediaFile[]) => {
    if (mediaFiles.length > 0) {
      const image = {
        id: 1,
        url: mediaFiles[0].url
      };
      this.setState({ productMainImage: image });
    }
  }

  getProductImages = (mediaFiles: MediaFile[]): MediaFile[] => {
    let imageFiles: MediaFile[] = [{
      url: productDefaultImage,
      filename: 'default image',
      content_type: '',
      byte_size: 0,
    }];
    if (mediaFiles.length > 0) {
      imageFiles = mediaFiles.slice(0, 4);
    }
    return imageFiles;
  }

  handleSelectSize = (id: number) => {
    this.setState({ selectedSize: id });
  }

  handleSelectColor = (id: number) => {
    this.setState({ selectedColor: id });
  }

  handleAddToCart = async (productId: number) => {
    const token = await getStorageData("authToken");
    if (!token) {
      toast.error('Please login to add product to cart', { position: 'bottom-right' });
      return false;
    }

    const { selectedColor, selectedSize, quantity } = this.state;

    if (selectedColor && selectedSize) {
      const requestBody = {
        cart: {
          product_id: productId,
          product_size_id: selectedSize,
          product_color_id: selectedColor,
          quantity: quantity
        }
      }
      this.addToCartApiCall(requestBody);
    } else {
      toast.error('No product variant selected', { position: 'bottom-right' });
    }
  }

  addToCartApiCall = async (payload: any) => {
    this.setState({ showButtonLoader: true });
    this.addToCartId = await apiCall({
      endPoint: configJSON.addToCartEndpoint,
      method: configJSON.httpPostType,
      contentType: 'application/json',
      body: JSON.stringify(payload),
    });
  }

  handelAddToCartIdResponse = (response: { data: any, error: string, errors: any[] }) => {
    if (response?.data) {
      eventEmitter.emit('updateCartCounter', 1, []);
      const { clickedButton } = this.state;
      if (clickedButton === 'buy_now') {
        this.props.navigation.navigate("Cart");
      } else {
        toast.success('Product added to cart', { position: 'bottom-right' });
      }
    }
    if (response?.error) {
      toast.error(response.error, { position: 'bottom-right' });
    }
    if (response?.errors) {
      toast.error(response.errors[0]?.token || 'Failed to add product to cart', { position: 'bottom-right' });
    }
    this.setState({ showButtonLoader: false, clickedButton: '' });
  }

  handleOpenSizeChartModal = () => {
    this.setState({ openSizeChartModal: true });
  }

  handleCloseSizeChartModal = () => {
    this.setState({ openSizeChartModal: false });
  }

  handleLoadNextImage = () => {
    const { product, productMainImage } = this.state;
    const mediaFiles = product?.media_files || [];
    const currentImageIndex = mediaFiles.findIndex(mediaFile => mediaFile.url === productMainImage.url);
    const nextImageIndex = currentImageIndex + 1;
    if (nextImageIndex >= 0 && nextImageIndex < mediaFiles.length) {
      this.setState({
        productMainImage: {
          id: nextImageIndex + 1,
          url: mediaFiles[nextImageIndex].url,
        },
      });
    }
  }

  handleLoadPreviousImage = () => {
    const { product, productMainImage } = this.state;
    const mediaFiles = product?.media_files || [];
    const currentImageIndex = mediaFiles.findIndex(mediaFile => mediaFile.url === productMainImage.url);
    const nextImageIndex = currentImageIndex - 1;
    if (nextImageIndex >= 0 && nextImageIndex < mediaFiles.length) {
      this.setState({
        productMainImage: {
          id: nextImageIndex + 1,
          url: mediaFiles[nextImageIndex].url,
        },
      });
    }
  }

  handleFaqToggle = (id: number) => {
    const { productFaqs } = this.state;
    const updatedFaqs: ProductFaqAttributes[] = productFaqs.map(faq =>
      faq.id === id
        ? { ...faq, open: !faq.open }
        : faq
    );
    this.setState({ productFaqs: updatedFaqs });
  }

  handleAddToWishlist = async (id: number) => {
    const payload = {
      wishlist: { product_id: id }
    };
    this.setState({ wishlistLoader: true });
    this.addToWishlistId = await apiCall({
      endPoint: configJSON.addToWishlistEndpoint,
      method: configJSON.httpPostType,
      contentType: 'application/json',
      body: JSON.stringify(payload),
    });
  }

  handelAddToWishlistResponse = (response: { data: any, error: string, errors: any[], message: string }) => {
    if (response?.data) {
      const { product } = this.state;
      const updateProduct = { ...product, ...{ is_wishlisted: true } };
      this.setState({ product: updateProduct as ProductDataAttributes });
      toast.success('Product added to wishlist', { position: 'bottom-right' });
    }
    if (response?.message) {
      toast.warning(response.message, { position: 'bottom-right' });
    }
    if (response?.error) {
      toast.error(response.error, { position: 'bottom-right' });
    }
    if (response?.errors) {
      toast.error(response.errors[0]?.token || 'Failed to add product to wishlist', { position: 'bottom-right' });
    }
    this.setState({ wishlistLoader: false });
  }

  getProductSuggestionsData = async (categoryName: string) => {
    this.setState({ isLoading: true });
    this.getProductSuggestionsApiId = await apiCall({
      endPoint: `${configJSON.productSuggestionsEdpoint}`,
      method: configJSON.httpGetType,
    });
  };

  handleProductSuggestionsDataResponse = (response: { data: ProductData[], errors: string | Array<string> }) => {
    if (response.data) {
      console.log("response data: ", response.data);
      this.setState({ productSuggestions: response.data })
    }
    if (response.errors) {
      const errorMessage = response.errors[0];
      this.setState({ errorMessage });
    }
    this.setState({ isLoading: false });
  };

  handleViewAllRedirection = () => {
    const { product } = this.state;
    const categoryName = product?.category.attributes.name;
    this.props.navigation.navigate("ShopByOccassion", { type: "category", name: categoryName });
  }

  handleRedirectProduct = (id: number) => {
    this.props.navigation.navigate("Shop");
    setTimeout(() => {
      this.props.navigation.navigate("ProductSummary", { id: id });
    }, 100);
  }

  handleMultipleLocations = (service_locations: any) => {
    return service_locations.data
      .map((location: any) => location.attributes.location_name)
      .join(', ');
  }

  getProductCardImage = (mediaFiles: MediaFile[]) => {
    let image = productDefaultImage;
    if (mediaFiles.length > 0) {
      image = mediaFiles[0].url;
    }
    return image;
  }

  getProductAverageRating = async (id: string) => {
    this.setState({ isLoading: true });
    this.getProductAverageRatingId = await apiCall({
      endPoint: `${configJSON.productAverageRatingEndpoint}?product_id=${id}`,
      method: configJSON.httpGetType,
    });
  };

  handleProductAverageRatingResponse = (response: any) => {
    if (response?.average_score) {
      this.setState({
        averageRating: response.average_score.average_score,
        totalRatings: response.average_score.total_ratings,
      });
    }
    if (response.error) {
      const errorMessage = response.error;
      this.setState({ errorMessage });
    }
    this.setState({ isLoading: false });
  };
  // Customizable Area End
}
