import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils";
import { Message } from "framework/src/Message";
import { getStorageData } from "framework/src/Utilities";
import { toast } from "react-toastify";
import { productDefaultImage } from "../../sorting/src/assets";
// Customizable Area End

export const configJSON = require("./config");

interface MyCartData {
  total_mrp: number;
  status: string;
  total_tax: number;
  total_items: number;
  total_amount: number;
  discount_on_mrp: number;
  address: Address;
  order_items: OrderItem[];
  customer: Customer;
}

interface OrderData {
  type: string;
  id: string;
  attributes: {
    total_mrp: number;
    status: string;
    total_tax: number;
    total_items: number;
    total_amount: number;
    discount_on_mrp: number;
    address: Address;
    order_items: OrderItem[];
    customer: Customer;
    coupon_details: {
      attributes: AppliedCouponData,
      type: string,
      id: string,
    }
  };
}

interface AppliedCouponData {
  cost_type: string,
  id: number,
  exp_date: string,
  coupon_code: string,
  max_discount: string,
  min_order_value: string,
  terms_policies: string,
  discount_percentage: string,
  amount: string,
  is_expired: boolean,
}

interface Customer {
  type: string;
  attributes: CustomerAttributes;
  id: string;
}

interface CustomerAttributes {
  country_code: string | null;
  activated: boolean;
  email: string;
  full_name: string;
  full_phone_number: string;
  first_name: string | null;
  phone_number: string | null;
  last_name: string | null;
  created_at: string;
  type: string;
  device_id: string | null;
  updated_at: string;
  profile_picture: string | null;
  unique_auth_id: string;
  primary_contact_country_code: string | null;
  primary_contact_no: string | null;
  business_name: string;
  primary_contact_phone_number: string | null;
  secondary_contact_country_code: string | null;
  secondary_contact_no: string | null;
  GST_number: string;
  secondary_contact_phone_number: string | null;
  portfolio_link: string;
  service_provided: Service;
  bank_name: string;
  business_detail_description: string;
  ifsc_code: string;
  account_number: string;
  social_media_links: string[];
  branch_name: string;
  vendor_address: string | null;
  role_id: string;
  products_offered: string[];
}

interface Service {
  type: string;
  id: string;
  attributes: {
    name: string;
    id: number;
  };
}

interface Address {
  data: {
    id: string,
    type: string,
    attributes: AddressAttributes
  }
}

interface AddressAttributes {
  latitude: string | null,
  longitude: string | null,
  name: string,
  address: string,
  address_type: string,
  country: string,
  city: string,
  state: string,
  pincode: string,
  phone_number: string,
  email: string,
  service_locations: string[]
}

export interface OrderItem {
  type: string;
  attributes: OrderItemAttributes;
  id: string;
}

interface OrderItemAttributes {
  quantity: number;
  price: number;
  taxable_value: number;
  taxable: boolean;
  total_price: number;
  other_charges: any | null;
  location: any | null;
  discounted_price: number | null;
  discount?: number;
  product: Product;
}

interface Product {
  type: string;
  attributes: ProductAttributes;
  id: string;
}

export interface ProductAttributes {
  name: string;
  id: number;
  category: Category;
  SKU: string;
  fabric_details: string;
  description: string;
  available_quantity: number;
  price: string;
  functions: Function[];
  media_files: MediaFile[];
  service_locations: {
    data: ServiceLocation[];
  };
  custom_fields: CustomFields;
  product_variants: ProductVariant;
  is_published: boolean;
  created_at: string;
  size_chart: string;
  product_faqs: any[];
  is_deleted: boolean;
  coupon: any | null;
  is_available: boolean;
}

interface Category {
  type: string;
  id: string;
  attributes: {
    name: string;
    id: number;
    dark_icon: string | null;
    description: string | null;
    dark_icon_inactive: string | null;
    dark_icon_active: string | null;
    light_icon_active: string | null;
    light_icon: string | null;
    rank: number | null;
    light_icon_inactive: string | null;
    created_at: string;
    selected_sub_categories: any | null;
    image: string;
    updated_at: string;
  };
}

interface MediaFile {
  filename: string;
  url: string;
  byte_size: number;
  content_type: string;
}

interface Function {
  type: string;
  id: string;
  attributes: {
    name: string;
    id: number;
    description: string | null;
    function_image: string;
  };
}

interface ServiceLocation {
  type: string;
  id: string;
  attributes: {
    location_name: string;
    id: number;
  };
}

interface ProductVariant {
  type: string;
  id: string;
  attributes: {
    product_size: ProductSize;
    id: number;
    product_color: ProductColor;
    available_count: number;
  };
}

interface ProductSize {
  type: string;
  id: string;
  attributes: {
    size_label: string;
    measurement: number;
    id: number;
  };
}

interface ProductColor {
  type: string;
  id: string;
  attributes: {
    name: string;
    color_image: string;
    id: number;
  };
}

interface CustomFields {
  material: string;
  color: string;
}

interface UserAddressData {
  type: string,
  id: string,
  attributes: {
    longitude: number,
    latitude: number,
    address_type: string,
    address: string,
    city: string,
    country: string,
    pincode: string,
    state: null,
  }
}

export interface Props {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isLoading: boolean;
  id: number;
  myCartData: MyCartData | null;
  token: string;
  showAddress: string;
  quantity: number;
  cartOrderItems: OrderItem[];
  errorMessage: string;
  userAddressList: UserAddressData[];
  customerData: CustomerAttributes | null;
  paymentMethod: string;
  orderId: string;
  address: AddressAttributes | null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentScuccessPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  successOrderdetailsApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      id: 0,
      token: "",
      myCartData: null,
      showAddress: '',
      quantity: 1,
      cartOrderItems: [],
      errorMessage: '',
      userAddressList: [],
      customerData: null,
      paymentMethod: '',
      orderId: '',
      address: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (webApiRequestCallId !== null && webApiRequestCallId === this.successOrderdetailsApiId) {
        this.handleSuccessOrderdetailsResponse(webResponseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getToken();
    let orderId = await this.props.navigation.getParam("orderId", "");
    if (orderId) {
      this.getSuccessOrderdetails(orderId);
    }
  }

  getToken = async () => {
    const token = await getStorageData("authToken");
    if (token) {
      this.setState({
        token,
      });
    } else {
      toast.error("Please login to access cart.");
      this.props.navigation.goBack();
    }
  };

  getSuccessOrderdetails = async (orderId: string) => {
    this.setState({
      isLoading: true,
    });
    this.successOrderdetailsApiId = await apiCall({
      endPoint: `${configJSON.razorOrderDetailsEndpoint}?razorpay_order_id=${orderId}`,
      method: configJSON.getApiMethod,
    });
  };

  getLocationText = (serviceLocations: { data: ServiceLocation[] }): string => {
    let location = '';
    if (serviceLocations.data && serviceLocations.data.length > 0) {
      serviceLocations.data.forEach((item: any) => {
        location += item.attributes.location_name;
        if (serviceLocations.data.indexOf(item) != serviceLocations.data.length - 1) {
          location += ', ';
        }
      })
    }
    return location;
  }

  handleSuccessOrderdetailsResponse = (response: any) => {
    if (response?.data) {
      const orderResponse: OrderData = response.data.attributes.order;
      const myCartData = orderResponse.attributes;
      const cartOrderItems = myCartData.order_items;
      const customerData = myCartData.customer.attributes;
      const orderId = response.data?.attributes?.order_id;
      const paymentMethod = response.data?.attributes?.payment_method;
      const address = myCartData.address.data.attributes;

      this.setState({
        myCartData,
        cartOrderItems,
        customerData,
        orderId,
        paymentMethod,
        address,
      });
    }
    if (response.error) {
      this.setState({ errorMessage: response.error, myCartData: null, cartOrderItems: [] });
    }
    this.setState({ isLoading: false });
  }

  getProductImage = (file : string): string => {
    let productImage = productDefaultImage;
    if (file) {
      productImage = file;
    }
    return productImage;
  }

  handleBacktoHome = () => {
    this.props.navigation.navigate("LandingPageWeb");
  }
  // Customizable Area End
}
