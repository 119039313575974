import React from "react";

// Customizable Area Start
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography, } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// Customizable Area End

import ProductSummaryController, { Props } from "../ProductSummaryController.web";

export default class DescriptionSection extends ProductSummaryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const {
            isLoading,
            product,
        } = this.state;
        return (
            // Customizable Area Start
            <>
                {!isLoading && product &&
                    <Grid item xs={12} width={"100%"} marginTop={"30px"}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className="accordianTitle">Product Description</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="accordianData">
                                    {String(product.description)}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography className="accordianTitle">Fabric details</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="accordianData">
                                    {String(product.fabric_details)}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        {product.custom_fields && Object.entries(product.custom_fields).map(([key, value]) => (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={key}
                                    id={key}
                                >
                                    <Typography className="accordianTitle">{key}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordianData">
                                        {String(value)}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                }
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
