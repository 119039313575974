import React from "react";

// Customizable Area Start
import { Box, Typography, styled, Rating, Button, Grid } from "@mui/material";
// Customizable Area End

import MyBookingsController, { Props } from "./MyBookingsController";
import HeaderFooterLayout from "../../../../components/src/HeaderFooterLayout";
import { thumbnail } from "../assets";

export default class MyBookings extends MyBookingsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        const {
            pathName,
        } = this.state;

        return (
            // Customizable Area Start
            <HeaderFooterLayout navigation={this.props.navigation}>
                <StyleWrapper>
                    <Grid container width={"100%"} marginBottom={"40px"}>
                        <Grid item xs={12} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", columnGap: "40px", borderBottom: "1px solid #A8A29E" }}>
                            <Typography onClick={this.handleGotoMyBookings} className={pathName === '/mybookings' ? "titleActive" : "mainTitle"}>My Bookings</Typography>
                            <Typography onClick={this.handleGotoMyPurchases} className={pathName === '/mypurchases' ? "titleActive" : "mainTitle"}>My Purchases</Typography>
                        </Grid>
                    </Grid>
                    <Box width={"100%"} border={"1px solid #D9D9D9"} padding={"20px"} borderRadius={"12px"}>
                        <Grid container width={"100%"} spacing={3}>
                            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                                <img style={{ width: "100%", objectFit: "cover", borderRadius: "8px" }} src={thumbnail} alt="" />
                            </Grid>
                            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                <Box>
                                    <Typography className="productTitle">Sanjai & Deepika Weddings</Typography>
                                    <Typography className="locationText">Pixel Spot</Typography>
                                    <Typography className="productPrice">
                                        ₹ 46,000
                                        <Typography className="subscriptionPlan" component={"span"}>(Gold)</Typography>
                                    </Typography>
                                </Box>
                                <Box paddingBottom={"15px"}>
                                    <Typography className="reviewTitle">Write a Ratings</Typography>
                                    <Box display={"flex"} flexDirection={"row"} columnGap={"30px"} alignItems={"center"} width={"100%"}>
                                        <Rating value={2} max={10} precision={0.5} onChange={() => { }} size="medium" />
                                        <Typography className="outOfText">out of -/10</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={2} lg={2} md={2} sm={12} xs={12} display={"flex"} justifyContent={"end"}>
                                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "end" }}>
                                    <Box className="dateMainBox">
                                        <Typography className="dateStatus">Upcoming</Typography>
                                        <Typography className="dateText">30</Typography>
                                        <Typography className="monthYearText">Oct-2024</Typography>
                                    </Box>
                                    <Button className="buttonStyle">Book again</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </StyleWrapper >
            </HeaderFooterLayout>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const StyleWrapper = styled(Box)({
    padding: "4%",
    '& .mainTitle': {
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#292524",
        marginBottom: "20px",
        cursor: "pointer",
    },
    '& .titleActive': {
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#7F1187",
        marginBottom: "20px",
        cursor: "pointer",
    },
    '& .productTitle': {
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "32px",
        color: "#000000",
        marginBottom: "10px",
    },
    '& .locationText': {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "#000000",
        marginBottom: "20px",
    },
    '& .productPrice': {
        fontFamily: "Poppins",
        fontSize: "32px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#801188",
        marginBottom: "20px",
    },
    '& .subscriptionPlan': {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "18px",
        color: "#3A3A3A",
        marginBottom: "10px",
    },
    '& .reviewTitle': {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#78716C",
        marginBottom: "10px",
    },
    "& .dateMainBox": {
        position: "relative",
        width: "105px",
        height: "105px",
        border: "1px solid #CCCCCC",
        borderRadius: "12px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: "30px",
        paddingBottom: "15px",
        boxShadow: "0 8px 0px #CCCCCC",
    },
    '& .dateText': {
        fontFamily: "Poppins",
        fontSize: "48px",
        fontWeight: 600,
        lineHeight: "28px",
        color: "#801188",
    },
    '& .monthYearText': {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "#6A6A6A",
    },
    "& .outOfText": {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "34px",
        color: "#646464",
    },
    "& .dateStatus": {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#059669",
        background: "#FFE9D0",
        borderRadius: "8px",
        padding: "10px 15px",
        marginTop: "-40px"
    },
    "& .buttonStyle": {
        color: "#7F1187",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        textTransform: "capitalize",
        background: "#FFF",
        border: "1px solid #7F1187",
        padding: "10px 0px",
        borderRadius: "30px",
        width: "155px",
        cursor: "pointer",
        "&:hover": {
            background: "#FFF"
        },
        "&:disabled": {
            background: "#808080",
            color: "#ababab",
        }
    },
});
// Customizable Area End
