import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  IconButton,
  InputAdornment,
  Checkbox,
  styled,
  FormControlLabel,
  Dialog,
  DialogContent,
} from "@mui/material";
import sanitizeHtml from "sanitize-html";
import { Theme } from "@mui/material/styles";
import {
  checkImage,
  googlelogo,
  hidePassword,
  showPassword,
  uncheckImage,
  wedding_logo_2,
  WeddingLogo,
} from "./assets";

import { Formik, Form, FormikErrors } from "formik";
import * as Yup from "yup";
import TextInputFiled from "../../../components/src/TextInputField.web";
import SelectField from "../../../components/src/SelectField.web";
import LoginSideImageWrapper from "../../../components/src/LoginSideImageWrapper.web";
// Customizable Area End

import EmailAccountController, { Props } from "./EmailAccountController";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import SelectCountryCode from "../../../components/src/SelectCountryCode";

export default class EmailAccount extends EmailAccountController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  isError = (
    touched: boolean | undefined,
    errors: string | undefined,
    isValid: boolean = false
  ) => {
    return isValid ? isValid : touched && !!errors;
  };

  helperText = (
    touched: boolean | undefined,
    errors: string | undefined,
    isValid: boolean = false,
    errorMessage = ""
  ) => {
    return isValid ? errorMessage : touched && errors;
  };

  dialogWrapper = (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<{
      fullName: string;
      email: string;
      isTermsChecked: boolean;
      showPassword: boolean;
      reference_id: string;
      isPrivacyChecked: boolean;
      password: string;
    }>>,
    isTermsChecked: boolean,
    isPrivacyChecked: boolean
  ) => {
    return (
      <Formik
        initialValues={{
          isModalChecked:
            this.state.openTermsPrivacyModal.modalName === "terms"
              ? isTermsChecked
              : isPrivacyChecked,
        }}
        data-test-id="formik-dialog"
        validationSchema={validationModalSchema}
        onSubmit={(data) => {
          setFieldValue(
            this.state.openTermsPrivacyModal.modalName == "terms"
              ? "isTermsChecked"
              : "isPrivacyChecked",
            data.isModalChecked
          );
          this.openModal(false, this.state.openTermsPrivacyModal.modalName);
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <DialogContent>
              <Box className="text-image-container">
                <img
                  className="wedding-logo-3"
                  src={wedding_logo_2}
                  alt="Wedding Logo"
                />
                <Typography className="terms-conditions-text-1">
                  {this.state.openTermsPrivacyModal.modalName === "terms"
                    ? "Terms and Conditions"
                    : "Privacy Policy"}
                </Typography>
              </Box>
              <Box className="scroll-container">
                {this.state.openTermsPrivacyModal.modalName === "terms" &&
                  this.state.termsConditionData.length > 0 && (
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(
                          this.state.termsConditionData[0].description
                        ),
                      }}
                      className="terms-condition-description"
                    />
                  )}
                {this.state.openTermsPrivacyModal.modalName === "privacy" &&
                  this.state.privacyData.length > 0 && (
                    <Box
                      className="terms-condition-description"
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(
                          this.state.privacyData[0].description
                        ),
                      }}
                    />
                  )}
                <Box className="terms-condition-button-container">
                  <CheckBoxContainer
                    isError={errors.isModalChecked ? true : false}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isModalChecked"
                          checked={values.isModalChecked}
                          checkedIcon={<img src={checkImage} alt="unchecked" />}
                          icon={<img src={uncheckImage} alt="unchecked" />}
                          onChange={handleChange}
                        />
                      }
                      label=""
                    />
                    <Box component="div" className="terms-condition-text">
                      I have read and AGREE to the{" "}
                      <Typography component="span" className="text-decoration">
                        {this.state.openTermsPrivacyModal.modalName === "terms"
                          ? "terms and conditions"
                          : "privacy policy"}
                      </Typography>
                    </Box>
                  </CheckBoxContainer>
                  <AcceptButton
                    data-test-id="accept-button"
                    isChecked={values.isModalChecked}
                    type="submit"
                    disabled={!values.isModalChecked}
                  >
                    Accept
                  </AcceptButton>
                </Box>
              </Box>
            </DialogContent>
          </Form>
        )}
      </Formik>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <LoginSideImageWrapper isLoading={this.state.isLoading}
        carouselImages={this.state.crouslData}
      >
        <ScrollableContentBox data-test-id="ScrollableContentBox">
          <Box className="sign-up-form-container">
            <Formik
              data-test-id="Formik"
              initialValues={this.state.formData}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                this.signUpWithEmail(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} noValidate>
                    <img
                      data-test-id="wedding-logo"
                      src={WeddingLogo}
                      className="wedding-logo"
                      alt="Wedding Logo"
                      onClick={this.redirectFromSignUpToHomePage}
                    />
                    <Typography className="sign-up-form-container-text-1">
                      Sign Up
                    </Typography>
                    <Typography className="sign-up-form-container-text-2">
                      Get started for free
                    </Typography>
                    <SignUpGoogleButton fullWidth>
                      <Box>
                        <GoogleOAuthProvider clientId="916004841115-q5bgscuf0lmppktsm2u5olmgbdrmsdkh.apps.googleusercontent.com">
                          <GoogleLogin data-test-id='logingoogle'
                            onSuccess={this.googleSignupSuccess}
                            onError={this.googleSignupFailed}
                          />
                        </GoogleOAuthProvider>
                      </Box>
                    </SignUpGoogleButton>
                    <Box className="or-box-center">
                      <Typography className="or-style">- or -</Typography>
                    </Box>
                    <TextInputFiled
                      name="fullName"
                      label="Full Name"
                      data-test-id="full-name-input"
                      placeholder="Enter your name"
                      value={values.fullName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched.fullName, errors.fullName)}
                      helperText={this.helperText(
                        touched.fullName,
                        errors.fullName
                      )}
                    />
                    <TextInputFiled
                      name="email"
                      label="Email"
                      placeholder="Enter your email"
                      value={values.email}
                      data-test-id="email-input"
                      handleChange={(e) => {
                        handleChange(e);
                        this.handleChangeEmailValid();
                      }}
                      handleBlur={handleBlur}
                      type="email"
                      error={this.isError(
                        touched.email,
                        errors.email,
                        this.state.isInvalidEmail
                      )}
                      helperText={this.helperText(
                        touched.email,
                        errors.email,
                        this.state.isInvalidEmail,
                        "Email is already registered"
                      )}
                    />
                    <Box className="numberField">
                      <SelectCountryCode
                        data-test-id="selectCountryCode"
                        getCountryCode={(code: string) => { setFieldValue("phoneId", code); }}
                        style={touched.phone && errors.phone ? { marginBottom: "40px" } : { marginBottom: "15px" }}
                      />
                      <TextInputFiled
                        name="phone"
                        data-test-id="full-name-input"
                        placeholder="0000000000"
                        value={values.phone}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        type="tel"
                        error={this.isError(touched.phone, errors.phone)}
                        helperText={this.helperText(
                          touched.phone,
                          errors.phone
                        )}
                        sx={{
                          width: '100%',
                          paddingTop: "4px",
                          '@media (min-width: 600px)': {
                            flexGrow: 1
                          }
                        }}
                      />
                    </Box>
                    <TextInputFiled
                      name="password"
                      data-test-id="password-input"
                      placeholder="Enter your password"
                      label="Password"
                      handleChange={handleChange}
                      value={values.password}
                      type={values.showPassword ? "text" : "password"}
                      error={this.isError(touched.password, errors.password)}
                      handleBlur={handleBlur}              
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              data-test-id="icon-toggle"
                              onClick={() =>
                                setFieldValue(
                                  "showPassword",
                                  !values.showPassword
                                )
                              }
                              edge="end"
                            >
                              {values.showPassword ? (
                                <img
                                  src={showPassword}
                                  alt="show"
                                  className="password-images"
                                />
                              ) : (
                                <img
                                  src={hidePassword}
                                  alt="hide"
                                  className="password-images"
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText={this.helperText(
                        touched.password,
                        errors.password
                      )}
                    />
                    <SelectField
                      error={this.isError(
                        touched.reference_id,
                        errors.reference_id
                      )}
                      touched={touched.reference_id}
                      placeholder="How did you find us"
                      handleBlur={handleBlur("reference_id")}
                      helperText={touched.reference_id && errors.reference_id}
                      label="How did you find us?"
                      name="reference_id"
                      options={this.state.referenceData.map((data) => ({
                        label: data.name,
                        value: data.id,
                      }))}
                      setFieldValue={setFieldValue}
                      value={values.reference_id}
                    />
                    <CheckBoxContainer
                      data-test-id="CheckBoxContainer"
                      isError={
                        touched.isPrivacyChecked &&
                          errors.isPrivacyChecked &&
                          touched.isTermsChecked &&
                          errors.isTermsChecked
                          ? true
                          : false
                      }
                    >
                      <FormControlLabel
                        data-test-id="check-box-controller"
                        control={
                          <Checkbox
                            data-test-id="check-box"
                            checked={
                              values.isPrivacyChecked && values.isTermsChecked
                            }
                            name="isChecked"
                            icon={<img src={uncheckImage} alt="unchecked" />}
                            checkedIcon={
                              <img src={checkImage} alt="unchecked" />
                            }
                            onChange={(event) => {
                              setFieldValue(
                                "isTermsChecked",
                                event.target.checked
                              );
                              setFieldValue(
                                "isPrivacyChecked",
                                event.target.checked
                              );
                            }}
                          />
                        }
                        label=""
                      />
                      <Box className="terms-condition-text" component="div">
                        I agree to the{" "}
                        <Typography
                          component="span"
                          data-test-id="privacy-click"
                          onClick={() => this.openModal(true, "privacy")}
                          className="text-decoration"
                        >
                          privacy policy
                        </Typography>{" "}
                        and{" "}
                        <Typography
                          component="span"
                          data-test-id="terms-condition-click"
                          onClick={() => this.openModal(true, "terms")}
                          className="text-decoration"
                        >
                          terms and conditions
                        </Typography>
                      </Box>
                    </CheckBoxContainer>
                    <SignUpButton
                      type="submit"
                      fullWidth
                      data-test-id="sign-up-button"
                      disabled={this.state.isInvalidEmail || !values.isPrivacyChecked || !values.isTermsChecked}
                      isChecked={
                        values.isPrivacyChecked && values.isTermsChecked
                      }
                    >
                      Sign Up
                    </SignUpButton>

                    <Typography className="login-main-text">
                      Already have an account?{" "}
                      <Typography
                        data-test-id="login"
                        component="span"
                        onClick={() => this.goToLogin()}
                        className="login-text"
                      >
                        Log in
                      </Typography>
                    </Typography>
                    <TermsConditionDialog
                      open={this.state.openTermsPrivacyModal.open}
                      data-test-id="dialog-box"
                      onClose={() =>
                        this.openModal(
                          false,
                          this.state.openTermsPrivacyModal.modalName
                        )
                      }
                      fullWidth
                      maxWidth="lg"
                    >
                      {this.dialogWrapper(
                        setFieldValue,
                        values.isTermsChecked,
                        values.isPrivacyChecked
                      )}
                    </TermsConditionDialog>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </ScrollableContentBox>
      </LoginSideImageWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const validationSchema = Yup.object({
  fullName: Yup.string()
    .required("Full Name is required")
    .trim()
    .matches(/^[a-zA-Z\s]+$/, "Full Name should only contain letters and spaces")
    .test(
      "not-only-digits",
      "Full Name should only contain letters and spaces",
      (value) => {
        return !/^\d+$/.test(value);
      }
    )
    .max(50, "Maximum 50 characters are allowed"),
  email: Yup.string()
    .email("Please enter valid email address")
    .required("Email is required")
    .trim(),
  phoneId: Yup.string()
    .required('Phone number is required'),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password should be minimum 8 characters long and contain at least one uppercase letter, one lowercase letter, one numeric digit, and one special character"
    )
    .trim(),
  reference_id: Yup.string().required("How did you find us is required"),
  isTermsChecked: Yup.bool().oneOf(
    [true],
    "You must agree to Terms and Conditions"
  ),
  phone: Yup.string()
    .required('Contact number is required')
    .matches(/^\d+$/, 'Contact number must contain only digits')
    .min(10, 'Contact number must be at least 10 digits')
    .max(10, 'Contact number cannot exceed 10 digits'),
  isPrivacyChecked: Yup.bool().oneOf(
    [true],
    "You must agree to Privacy Policy"
  ),
});

const validationModalSchema = Yup.object({
  isModalChecked: Yup.bool().oneOf([true], "You must agree to Privacy Policy"),
});

const ScrollableContentBox = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "50%",
  padding: "30px 114.96px 68px 72.32px",
  boxSizing: "border-box",
  overflowY: "auto",
  [theme.breakpoints.down("md")]: {
    minHeight: "fit-content",
    width: "100%",
    height: "100%",
    overflowY: "unset",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "30px 30px 68px",
  },
  "& .sign-up-form-container": {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    "& form": {
      [theme.breakpoints.down("md")]: {
        maxWidth: "600px",
      },
      width: "100%",
    },
  },
  "& .numberField": {
    gap: '10px',
    display: 'flex',
    width: "100%",
    alignItems: "center",
    "& > *:nth-child(1)": {
      width: "5%",
    },
  },
  "& .wedding-logo": {
    marginBottom: "24px",
    height: "60px",
    width: "120.62px",
    cursor: "pointer",
    aspectRation: "1",
  },
  "& .sign-up-form-container-text-1": {
    fontSize: "22px",
    fontFamily: "Poppins",
    lineHeight: "24px",
    fontWeight: "600",
    marginBottom: "6px",
    color: "#000000",
  },
  "& .sign-up-form-container-text-2": {
    fontSize: "16px",
    fontFamily: "Poppins",
    lineHeight: "24px",
    fontWeight: "400",
    marginBottom: "24px",
    color: "#78716C",
  },
  "& .or-box-center": {
    justifyContent: "center",
    display: "flex",
    marginTop: "24px",
    marginBottom: "24px",
    alignItems: "center",
  },
  "& .or-style": {
    fontSize: "18px",
    fontFamily: "Inter",
    lineHeight: "24px",
    color: "#A8A29E",
    fontWeight: "300",
  },
  "& .common-form-label": {
    fontSize: "14px",
    fontFamily: "Poppins, sans-serif",
    lineHeight: "22px",
    fontWeight: 500,
    marginBottom: "4px",
    color: "#334155",
  },
  "& .login-main-text": {
    fontSize: "14px",
    fontFamily: "Poppins",
    lineHeight: "24px",
    fontWeight: "400",
    marginTop: "20px",
    color: "#78716C",
  },
  "& .login-text": {
    fontSize: "14px",
    fontFamily: "Poppins",
    lineHeight: "24px",
    fontWeight: "500",
    cursor: "pointer",
    color: "#801187",
  },
}));

const SignUpGoogleButton = styled(Button)({
  padding: "9px 16px",
  border: "1px solid #E7E5E4",
  borderRadius: "8px",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "24px",
  color: "#000000",
  textTransform: "none",
  gap: "8px",
});

const CheckBoxContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isError",
})(({ isError }: { isError: boolean }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  margin: "8px 0 30px",
  "& .terms-condition-text,& .terms-condition-text *": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: isError ? "#DC2626 !important" : "#292524",
  },
  "& .text-decoration": {
    textDecoration: "underline",
    cursor: "pointer",
  },
  "& .MuiFormControlLabel-root": {
    marginLeft: "-9px",
    marginRight: "0px",
  },
}));

const SignUpButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isChecked",
})(({ isChecked }: { isChecked: boolean }) => ({
  border: `1px solid ${isChecked ? "#801187" : "#7F128769"}`,
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  color: isChecked ? "#FFFFFF !important" : "#A8A29E !important",
  borderRadius: "8px",
  padding: "19px 20px",
  textTransform: "unset",
  backgroundColor: isChecked ? "#801187" : "transparent",
  "&:hover": {
    backgroundColor: isChecked ? "#801187" : "transparent",
  },
}));

const TermsConditionDialog = styled(Dialog)(({ theme }) => ({
  "& .wedding-logo-3": {
    width: "108px",
    height: "60px",
  },
  "& form": {
    display: "flex",
    margin: 0,
    overflow: "hidden",
    height: "100%",
  },
  "& .terms-conditions-text-1": {
    fontSize: "22px",
    fontFamily: "Poppins",
    fontWeight: "500",
    color: "#000000",
    lineHeight: "24px",
    marginBottom: "24px",
    marginTop: "17px",
  },
  "& .MuiPaper-root": {
    maxWidth: "1248px",
    borderRadius: "25px",
    overflow: "hidden",
  },
  "& .MuiDialogContent-root": {
    overflow: "hidden",
    padding: "25px 16px 40.48px 60px",
    flexDirection: "column",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      padding: "25px 16px 25px 25px",
    },
  },
  "& .terms-condition-description": {
    fontSize: "16px",
    fontFamily: "Poppins",
    lineHeight: "20px",
    fontWeight: "400",
    whiteSpace: "pre-wrap",
    color: "#57534E",
  },
  "& .terms-condition-button-container": {
    marginTop: "16px",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    "& > div": {
      margin: "0",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    "& .terms-condition-text,& .text-decoration": {
      fontSize: "20px",
      color: "#292524",
      lineHeight: "24px",
    },
  },
  "& .text-image-container": {
    flex: "0 0 auto",
  },
  "& .scroll-container": {
    paddingRight: "56px",
    overflow: "auto",
    flex: "1 1 auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      background: "#E2E8F0",
      margin: "0",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      background: "#801187",
    },
  },
}));
interface AcceptButtonProps {
  isChecked: boolean;
}
const AcceptButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isChecked",
})<AcceptButtonProps>(
  ({ theme, isChecked }: { theme: Theme; isChecked: boolean }) => ({
    fontFamily: "Poppins",
    border: `1px solid ${isChecked ? "#801187" : "#7F128769"}`,
    width: "260px",
    fontSize: "16px",
    fontWeight: "400",
    height: "64.54px",
    color: isChecked ? "#FFFFFF !important" : "#A8A29E !important",
    lineHeight: "24px",
    padding: "19px 20px",
    textTransform: "unset",
    borderRadius: "8px",
    backgroundColor: isChecked
      ? "#801187 !important"
      : "transparent !important",
    [theme.breakpoints.down("sm")]: {
      height: "54.54px",
      width: "100%",
    },
    "&:hover": {
      backgroundColor: isChecked ? "#801187" : "transparent",
    },
  })
);
// Customizable Area End
