// Customizable Area Start
import React, { Component } from "react";
import { Box, Typography, styled, SxProps, Button, Theme } from "@mui/material";

import ShoppingCard from "../../../../components/src/ShoppingCard.web";
import MainTitle from "./MainTitle.web";
import { rightArrowButton } from "../assets";
import { Category } from "../LandingPageController";
import { productDefaultImage } from "../../../../../packages/blocks/categoriessubcategories/src/assets";

interface Props {
  navigation: any;
  title: string;
  description: string;
  sx?: SxProps<Theme>;
  buttonText: string;
  listingData: Category[];
}

interface State {}

export default class ShopSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { props } = this;

    const openCategoryProducts = (name: string) => {
      this.props.navigation.navigate("ShopByOccassion", { type: 'category', name });
    }

    const viewAll = () => {
      this.props.navigation.navigate("Shop");
    }

    return (
      <ShopContainer sx={props.sx} component="section" id="shop">
        <MainTitle title={props.title} />
        <Typography className="shop-description">
          {props.description}
        </Typography>
        <Box className="shop-container">
          {this.props.listingData.map((item: Category) => {
            const category = item.attributes;
            return (
              <ShoppingCard
                id={category.id}
                image_url={category.image || productDefaultImage}
                title={category.name}
                description={category.description}
                onCardClick={() => openCategoryProducts(category.name)}
              />
            )
          })}
        </Box>
        <Box className="shop-button-container">
          <MoreItemsButton onClick={viewAll}>
            {props.buttonText}
            <img alt="right" src={rightArrowButton} />
          </MoreItemsButton>
        </Box>
      </ShopContainer>
    );
  }
}

const ShopContainer = styled(Box)(({ theme }) => ({
  padding: "30px 0",
  "& .shop-description": {
    margin: "18px 0 60px",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "34px",
    textAlign: "center",
    color: "#57534E",
  },
  "& .shop-container": {
    display: "flex",
    justifyContent: "center",
    padding: "0 0px 28px",
    flexWrap: "wrap",
    gap: "43px",
  },
  "& .shop-button-container": {
    textAlign: "center",
  },
}));

const MoreItemsButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "24px",
  textAlign: "left",
  color: "#7F1287",
  textTransform: "none",
  border: "1px solid #7F1287",
  borderRadius: "30px",
  padding: "9px 11px",
  gap: "8px",
  "& img": {
    height: "24px",
    width: "24px",
  },
});
// Customizable Area End
