import React, { useState, useRef, useEffect } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import InputAdornment from "@mui/material/InputAdornment";

const useStyles = makeStyles({
  dateWrapper: {
    "& .rmdp-container": {
      width: "100%",
      "& svg": {
        color: "#0F172A"
      }
    }
  },
  datePicker: {
    "& + div": {
      zIndex: "10000 !important",
      "& .rmdp-wrapper": {
        display: "flex",
        flexDirection: "column-reverse",
      },
    },
    "& .rmdp-week-day": {
      color: "#64748B",
    },
    "& .rmdp-day.rmdp-today span": {
      backgroundColor: "#7F1187"
    },
    "& .rmdp-day.rmdp-selected span:not(.highlight)":{
      backgroundColor: "#7F1187"
    },
    "& .rmdp-header-values": {
      color: "#0F172A",
      fontFamily: "Inter !important",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "20px",
      textAlign: "center",
    },
    "& .rmdp-arrow": {
      borderColor: "#94A3B8",
    },
    "& .rmdp-range": {
      fontFamily: "Poppins",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "22px",
      textAlign: "center",
      backgroundColor: "#fbe8fc",
      color: "#7F1187",
      boxShadow: "0 0 3px #f7d2f9",
    },
    "& .singleAndmultipleDate": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 10px 0px 10px",
      fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#0F172A",
    },
    "& .date-select-radio": {
      appearance: "none",
      width: "20px",
      height: "20px",
      border: "1px solid #64748B",
      borderRadius: "16px",
      cursor: "pointer",
      transition: "0.3s",
    },
    "& .date-select-radio:checked": {
      border: "6px solid #7F1187",
    },
  },
});

interface Props {
  onChangeData?: any;
  field_name?: string;
  dateValue?: any;
  required?: boolean;
  placeholder?:string;
  NotShowCalenderHeader?:boolean;
}

export default function CustomDatePicker(props: Props) {
  const classes = useStyles();
  const [singleAndMultiDateToggle, setSingleAndMultiDateToggle] = useState<string>("Date-Range");
  const pickerRef = useRef<any>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClose = () => pickerRef.current?.closeCalendar();
  const { field_name, onChangeData, dateValue, required, placeholder, NotShowCalenderHeader } = props;

  const formatDateRange = (dates: DateObject[]) => {
    if (dates?.length === 2) {
      const startDate = dates[0].format("DD-MM-YYYY");
      const endDate = dates[1].format("DD-MM-YYYY");
      return `${startDate} - ${endDate}`;
    }
    return "";
  };

  const handleSelectDate = (val: string) => {
    onChangeData("");
    setSingleAndMultiDateToggle(val);
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        handleClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div ref={wrapperRef} className={classes.dateWrapper}>
      <DatePicker
        ref={pickerRef}
        value={dateValue}
        onChange={(date) => {
          console.log("date", date);
          if (singleAndMultiDateToggle === "Date-Range") {
            if (Array.isArray(date) && date.length === 2) {
              onChangeData(date);
              handleClose();
            }
          } else {
            if (!Array.isArray(date)) {
              onChangeData(date);
              handleClose();
            } else {
              onChangeData(date[0]);
              handleClose();
            }
          }
        }}
        range={singleAndMultiDateToggle === "Date-Range"}
        className={classes.datePicker}
        render={(inputProps: any, openCalendar) => (
          <TextField
            {...(typeof inputProps === "object" ? inputProps : {})}
            onClick={openCalendar}
            value={
              singleAndMultiDateToggle === "Date-Range"
                ? formatDateRange(dateValue)
                : dateValue ? dateValue.format("DD-MM-YYYY") : ""
            }
            fullWidth
            margin="normal"
            style={{ marginTop: "0px" }}
            InputProps={{
              readOnly: true, // Prevent manual input
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarTodayIcon
                    style={{ cursor: "pointer" }}
                    onClick={openCalendar} // Open calendar on icon click
                  />
                </InputAdornment>
              ),
            }}
            required={required}
            placeholder={placeholder ? placeholder:`Enter ${field_name}`}
            data-test-id={`${field_name}`}
            InputLabelProps={{ shrink: true }}
            key={field_name}
          />
        )}
      >

        {!NotShowCalenderHeader && <div className="singleAndmultipleDate">
          <input type="radio" onChange={() => handleSelectDate("Select-Date")} checked={singleAndMultiDateToggle == "Select-Date"} className="date-select-radio" name="radio" value="HTML" />
          <label htmlFor="html" onClick={() => handleSelectDate("Select-Date")}>Select Date</label>
          <input type="radio" onChange={() => handleSelectDate("Date-Range")} checked={singleAndMultiDateToggle == "Date-Range"} className="date-select-radio" name="radio" value="CSS" />
          <label htmlFor="css" onClick={() => handleSelectDate("Date-Range")}>Date Range</label>
        </div>}
      </DatePicker>
    </div>);
}
