import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import React from 'react'
import { toast } from "react-toastify";
import { errors, touched } from "./Settings2.web";
import { FormikErrors, FormikTouched } from "formik";
export interface FormDataOption {
  reference_id: string;
  email: string;
  fullName: string;
  gstNumber: string;
  businessName: string;
  businessCategory: string;
  primaryContact: string;
  secondaryNumber: string;
  showPassword: boolean;
  pincode: string;
  phoneId: string;
  city: string;
  state: string;
  country: string;
  branchName: string;
  ifscCode: string;
  bankAccountDetails: string;
  paymentMethod: string;
  phoneIdNo: string;
  serviceProvided: string;
  serviceLocation: string;
  address: string;
  portfolioLink: string;
  primaryContactPersonName: string;
  description: string;
  selectedLocation: string,
  selectedService: string,
  addLink: Link[]
  productOffered: string;
  addProduct: Product[];
};
interface Link {
  link: string
}
interface Product {
  product: string
}
interface Product {
  product: string
}
interface CountryData {
  label: string;
  value: number;
}
interface StateData {
  label: string;
  value: string;
}
export interface ColorOption {
  label: string;
  value: number;
}
interface IReference {
  id: number;
  attributes: {
    name: string;
  }
  created_at: string;
  updated_at: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  formData: FormDataOption;
  isEditing: boolean;
  locationData: ColorOption[];
  serviceData: ColorOption[],
  selectedLocations: ColorOption[];
  selectedServices: ColorOption[]
  token: string;
  countryData: CountryData[];
  stateData: StateData[];
  cityData: StateData[];
  showName: string;
  uploadImage: File | null;
  phoneNumberData: Array<object>;
  searchTerm: string,
  isLoading: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateSettingApiCallId: string = "";
  serviceLocationApiCallId: string = "";
  countryDataApiCallId: string = "";
  stateDataApiCallId: string = "";
  cityDataApiCallId: string = "";
  settingDataApiCallId: string = "";
  serviceProvidedApicallId: string = "";
  getVendorProfileDataCallId: string = "";
  getProfileImageCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      formData: {
        email: "",
        fullName: "",
        businessCategory: "",
        serviceLocation: "",
        bankAccountDetails: "",
        branchName: "",
        gstNumber: "",
        ifscCode: "",
        businessName: "",
        paymentMethod: "",
        pincode: "",
        state: "",
        country: "",
        primaryContact: "",
        address: "",
        city: "",
        secondaryNumber: "",
        showPassword: false,
        reference_id: "",
        serviceProvided: "",
        productOffered: "",
        portfolioLink: "",
        primaryContactPersonName: "",
        phoneId: '',
        phoneIdNo: '',
        description: "",
        selectedLocation: "",
        selectedService: "",
        addLink: [{ link: "" }],
        addProduct: [{ product: "" }],
      },
      searchTerm: "",
      isEditing: false,
      locationData: [],
      serviceData: [],
      selectedLocations: [],
      selectedServices: [],
      token: "",
      countryData: [],
      stateData: [],
      cityData: [],
      showName: "",
      uploadImage: null,
      phoneNumberData: [],
      isLoading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId: string = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson: any = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      switch (apiRequestCallId) {
        case this.serviceLocationApiCallId:
          this.setState({ locationData: this.mapLocationData(responseJson) });
          break;
        case this.countryDataApiCallId:
          this.setState({
            countryData: this.mapCountryData(responseJson),
            phoneNumberData: this.mapPhoneNumberData(responseJson)
          });
          break;
        case this.stateDataApiCallId:
          this.setState({ stateData: await this.mapStateData(responseJson) });
          break;
        case this.serviceProvidedApicallId:
          this.setState({ serviceData: this.mapServiceData(responseJson) });
          break;
        case this.cityDataApiCallId:
          this.setState({ cityData: this.mapCityData(responseJson) });
          break;
        case this.settingDataApiCallId:
          this.handleSettingData(responseJson);
          break;
        case this.updateSettingApiCallId:
          this.handleUpdateSetting(responseJson);
          break;
        case this.getVendorProfileDataCallId:
          this.setState({ isLoading: false });
          break;
        case this.getProfileImageCallId:
          this.setState({ isLoading: false });
          break;
        default:
          break;
      }
    }
  }

  private mapLocationData(responseJson: any): ColorOption[] {
    let data = [];
    if (responseJson?.data) {
      data = responseJson.data.map((location: any) => ({
        value: location.id,
        label: location.attributes.location_name
      }));
    }
    return data;
  }

  private mapCountryData(responseJson: any): ColorOption[] {
    let data = [];
    if (responseJson?.data) {
      data = responseJson.data.map((country: any) => ({
        value: country.country_code,
        label: country.country_full_name
      }));
    }
    return data;
  }

  private mapPhoneNumberData(responseJson: any): ColorOption[] {
    let data = [];
    if (responseJson?.data) {
      data = responseJson.data.map((country: any) => ({
        value: `+${country.numeric_code}`,
        label: country.numeric_code
      }));
    }
    return data;
  }

  private async mapStateData(responseJson: any): Promise<StateData[]> {
    let data = [];
    if (responseJson?.data) {
      data = responseJson.data.map((state: { state_code: string; state_name: string }) => ({
        value: state.state_code,
        label: state.state_name
      }));
    }
    return data;
  }

  private mapServiceData(responseJson: any): ColorOption[] {
    let data = [];
    if (responseJson?.data) {
      data = responseJson.data.map((service: any) => ({
        value: service.attributes.id,
        label: service.attributes.name
      }));
    }
    return data;
  }

  private mapCityData(responseJson: any): StateData[] {
    let data = [];
    if (responseJson?.data) {
      data = responseJson.data.map((city: any) => ({
        value: city.city_name,
        label: city.city_name
      }));
    }
    return data;
  }

  private mapProductData(products: string[] | undefined): Promise<{ product: string }[]> {
    return Promise.resolve(
      products?.map((data) => ({ product: data || "" })) || []
    );
  }

  private mapLinkData(links: string[] | undefined): Promise<{ link: string }[]> {
    return Promise.resolve(
      links?.map((link) => ({ link: link || "" })) || []
    );
  }

  private mapVendorLocation(vendorAddress: any): ColorOption[] {
    return vendorAddress?.attributes?.service_locations?.map((location: any) => ({
      value: location?.id || "",
      label: location?.location_name || "",
    })) || [];
  }

  private async handleSettingData(responseJson: any) {
    const attributes = responseJson?.data?.attributes;
    const addProduct = await this.mapProductData(attributes?.products_offered);
    const addLink = await this.mapLinkData(attributes?.social_media_links);
    const locationData = this.mapVendorLocation(attributes?.vendor_address);
    const serviceData = this.mapServiceData(attributes?.service_provided);

    this.setState((prevState: any) => ({
      formData: {
        ...prevState.formData,
        email: attributes?.email,
        bankAccountDetails: attributes?.account_number,
        branchName: attributes?.branch_name,
        gstNumber: attributes?.GST_number,
        ifscCode: attributes?.ifsc_code,
        businessName: attributes?.business_name,
        paymentMethod: attributes?.bank_name,
        pincode: attributes?.vendor_address?.attributes?.pincode ?? null,
        state: attributes?.vendor_address?.attributes?.state ?? null,
        country: attributes?.vendor_address?.attributes?.country ?? null,
        primaryContact: attributes?.primary_contact_phone_number,
        address: attributes?.vendor_address?.attributes?.address ?? null,
        city: attributes?.vendor_address?.attributes?.city ?? null,
        secondaryNumber: attributes?.secondary_contact_phone_number,
        portfolioLink: attributes?.portfolio_link,
        primaryContactPersonName: attributes?.full_name,
        phoneId: attributes?.primary_contact_country_code,
        phoneIdNo: attributes?.secondary_contact_country_code,
        description: attributes?.business_detail_description,
        addLink,
        addProduct,
        selectedLocation: locationData,
        selectedService: serviceData,
      }
    }));

    if (this.state.formData?.country) {
      this.getAllStateApi(this.state.formData.country);
      if (this.state.formData.state) {
        this.getCityApiCall(this.state.formData.country, this.state.formData.state);
      }
    }

    const uploadImageFile = await this.urlToFile(attributes?.profile_picture, "size_chart.png", "image/png");

    const mappedService = responseJson?.data?.attributes?.service_provided.map((service: any) => {
      return {
        value: service?.attributes?.id || "",
        label: service?.attributes?.name || "",
      };
    });

    this.setState(
      {
        showName: attributes?.full_name,
        uploadImage: uploadImageFile,
        selectedLocations: locationData,
        selectedServices: mappedService
      }
    );

  }

  private handleUpdateSetting(responseJson: any): void {
    if (responseJson?.errors) {
      toast.error(responseJson.errors[0]);
    } else {
      toast.success("Business Settings Saved Successfully");
      this.setState({ isEditing: false });
    }
    this.setState({ isLoading: false });
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // Customizable Area Start
  componentDidMount = async () => {
    this.serviceLoationApi();
    this.getToken();
    this.getAllSettingData();
    this.getAllCountryApi();
    this.getServiceDataList();
    this.getVendorProfileData();
    this.getProfileImage();
  };

  urlToFile = async (url: RequestInfo, filename: string, mimeType: string) => {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], filename, { type: mimeType });
  }

  handleEdit = () => {
    this.setState({ isEditing: true })
    this.getAllSettingData()
  }

  handleEditApiCall = async (values: FormDataOption) => {
    this.setState({ isLoading: true });
    console.log(values, "VALUESSS")
    let formData = new FormData();
    formData.append("full_name", values.primaryContactPersonName);
    formData.append("business_name", values.businessName);
    formData.append("GST_number", values.gstNumber);
    formData.append("service_id", "1");
    formData.append("primary_contact_no", `${values.phoneId}${values.primaryContact}`);
    formData.append("secondary_contact_no", `${values.phoneIdNo}${values.secondaryNumber}`);
    formData.append("email", values.email);
    formData.append("address_params[address]", values.address);
    formData.append("address_params[country]", values.country);
    formData.append("address_params[state]", values.state);
    formData.append("address_params[city]", values.city);
    formData.append("address_params[pincode]", values.pincode);
    formData.append("portfolio_link", values.portfolioLink);
    formData.append("business_detail_description", values.description);
    formData.append("bank_name", values.paymentMethod);
    formData.append("account_number", values.bankAccountDetails);
    formData.append("ifsc_code", values.ifscCode);
    formData.append("branch_name", values.branchName);
    if (this.state.uploadImage) {
      formData.append("profile_picture", this.state.uploadImage);
    }
    if (Array.isArray(values.selectedLocation)) {
      values.selectedLocation.forEach((spec: any) => {
        formData.append("address_params[service_location_ids]", spec.value);
      });
    }
    Array.isArray(values.selectedService) && values.selectedService.forEach((spec: any) => {
      formData.append("service_ids", spec.value);
    });

    values.addLink.forEach((item: Link) => {
      formData.append(`social_media_links[]`, item.link);
    });
    values.addProduct.forEach((item: Product) => {
      formData.append(`products_offered[]`, item.product);
    });
    this.updateSettingApiCallId = await apiCall({
      endPoint: "account_block/accounts/update",
      method: "PATCH",
      body: formData,
    });
  }

  handleDeleteLocationChip = (optionToDelete: ColorOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    console.log(optionToDelete, "OOOOPPPTTION")
    const newSelectedLocation = this.state.selectedLocations.filter(
      (item) => item.value != optionToDelete.value
    );
    this.setState({ selectedLocations: newSelectedLocation });
    setFieldValue('selectedLocation', newSelectedLocation);
  };

  handleSelectLocationChange = (newValue: ColorOption[], setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    console.log(this.state.selectedLocations)
    console.log(newValue)
    this.setState({ selectedLocations: newValue });
    setFieldValue('selectedLocation', newValue);
  };

  toggleLocationOption = (optionToToggle: ColorOption) => {
    console.log(this.state.selectedLocations, "SEESESESELECTED")
    console.log(optionToToggle)
    this.setState((prevState) => {
      const { selectedLocations } = prevState;
      const isSelected = Array.isArray(selectedLocations) && selectedLocations.some(option => option && option.value == optionToToggle?.value);
      if (isSelected) {
        return {
          selectedLocations: selectedLocations.filter(option => option && option.value != optionToToggle?.value),
        };
      } else {
        return {
          selectedLocations: Array.isArray(selectedLocations) ? [...selectedLocations, optionToToggle] : [optionToToggle]
        };
      }
    });
  };

  handleLocationOption = (option: ColorOption, isSelected: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    console.log(option)
    console.log(this.state.selectedLocations)
    const currentSelectedLocations = Array.isArray(this.state.selectedLocations)
      ? this.state.selectedLocations
      : [];
    const newSelectedLocation = isSelected
      ? currentSelectedLocations.filter(
        (selected) => selected.value != option.value
      )
      : [...currentSelectedLocations, option];

    this.setState({ selectedLocations: newSelectedLocation });
    setFieldValue('selectedLocation', newSelectedLocation);
  }

  getToken = async () => {
    const token = await getStorageData("authToken");
    if (token) {
      this.setState({
        token,
      });
    } else {
      this.props.navigation.goBack();
    }
  };

  handleDeleteServiceChip = (optionToDelete: ColorOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    console.log(optionToDelete)
    const newSelectedService = this.state.selectedServices.filter(
      (item) => item.value !== optionToDelete.value
    );
    this.setState({ selectedServices: newSelectedService });
    setFieldValue('selectedService', newSelectedService);
  };

  toggleServiceOption = (optionToToggle: ColorOption) => {
    console.log(optionToToggle)
    this.setState((prevState) => {
      const { selectedServices } = prevState;
      const isSelected = Array.isArray(selectedServices) && selectedServices.some(option => option?.value === optionToToggle?.value);
      if (isSelected) {
        return {
          selectedServices: selectedServices.filter(option => option?.value !== optionToToggle?.value),
        };
      } else {
        return {
          selectedServices: Array.isArray(selectedServices) ? [...selectedServices, optionToToggle] : [optionToToggle]
        };
      }
    });
  };

  handleSelectServiceChange = (newValue: ColorOption[], setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    console.log(newValue)
    this.setState({ selectedServices: newValue });
    setFieldValue('selectedService', newValue);
  };

  handleServiceOption = (option: ColorOption, isSelected: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    console.log(option)
    console.log(isSelected)
    const currentSelectedServices = Array.isArray(this.state.selectedServices)
      ? this.state.selectedServices
      : [];

    const newSelectedService = isSelected
      ? currentSelectedServices.filter(
        (selected) => selected.value !== option.value
      )
      : [...currentSelectedServices, option];

    this.setState({ selectedServices: newSelectedService });
    setFieldValue('selectedService', newSelectedService);
  }

  getServiceDataList = async () => {
    this.serviceProvidedApicallId = await apiCall({
      endPoint: "account_block/services",
      method: "GET",
      contentType: "application/json",
    });
  };

  serviceLoationApi = async () => {
    this.serviceLocationApiCallId = await apiCall({
      endPoint: "bx_block_multipageforms2/service_locations",
      method: "GET",
      token: this.state.token,
    });
  }
  removeLink = (indexToRemove: number, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, values: FormDataOption) => {
    const updatedLinks = values.addLink.filter((_, index) => index !== indexToRemove);
    setFieldValue("addLink", updatedLinks);
  };

  getAllCountryApi = async () => {
    this.countryDataApiCallId = await apiCall({
      endPoint: "account_block/accounts/country_code_and_flags/fetch_countries",
      method: "GET",
      token: this.state.token,
    });
  }

  getAllStateApi = async (selectedCountry: string) => {
    this.stateDataApiCallId = await apiCall({
      endPoint: `account_block/accounts/country_code_and_flags/fetch_states?country_code=${selectedCountry}`,
      method: "GET",
      token: this.state.token,
    });
  }

  getAllSettingData = async () => {
    const token = await getStorageData("authToken");
    this.settingDataApiCallId = await apiCall({
      endPoint: "account_block/accounts/id",
      method: "GET",
      token: token,
    });
  }
  getCityApiCall = async (selectedCountry: string, selectedCity: string) => {
    this.cityDataApiCallId = await apiCall({
      endPoint: `account_block/accounts/country_code_and_flags/fetch_cities?country_code=${selectedCountry}&state_code=${selectedCity}`,
      method: "GET",
      token: this.state.token,
    });
  }

  removeproduct = (indexToRemove: number, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, values: FormDataOption) => {
    const updatedLinks = values.addProduct.filter((_, index) => index !== indexToRemove);
    setFieldValue("addProduct", updatedLinks);

  };

  handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      this.setState({ uploadImage: file });
    }
  };

  handleSearckKeyDown = (e: any) => {
    if (e.key === "Backspace") {
      this.setState({ searchTerm: this.state.searchTerm.slice(0, -1) });
    }
    else {
      this.setState({ searchTerm: this.state.searchTerm + e.key });
    }
  };

  getVendorProfileData = async () => {
    this.setState({ isLoading: true });
    this.getVendorProfileDataCallId = await apiCall({
      endPoint: `bx_block_categories/categories?sort=priority`,
      method: "GET",
    });
  };

  getProfileImage = async () => {
    this.setState({ isLoading: true });
    this.getProfileImageCallId = await apiCall({
      endPoint: `account_block/accounts/id`,
      method: "GET",
    });
  };

  handleOnChangeCountry = (value: any) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          country: value
        }
      }
    }, () => {
      this.getAllStateApi(value);
      this.setState({ searchTerm: "" })
    })
  }

  handleOnChangeCity = (value: any) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          state: value
        }
      }
    }, () => {
      this.getCityApiCall(this.state.formData.country, value);
      this.setState({ searchTerm: "" })
    })
  }

  handleSelectCityData = (value: any) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          city: value
        }
      }
    })
  }

  helperText = (
    touched: touched,
    errors: errors,
    isValid: boolean = false,
    errorMessage = ""
  ) => {
    return isValid ? errorMessage : touched && errors;
  }

  isError = (
    touched: touched,
    errors: errors,
    isValid: boolean = false
  ) => {
    return isValid ? isValid : touched && !!errors;
  };

  handleErrorText = (touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined, errors: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined,) => {
    return touched && errors
  }

  getHelperText = (isTouched: any, errors: any) => (isTouched && errors ? errors : "");
  //Customizable Area End
}