import React from "react";

// Customizable Area Start
import Loader from "../../../../components/src/AppLoader.web";
import HeaderFooterLayout from "../../../../components/src/HeaderFooterLayout";
import { styled, Box, TextField, MenuItem, FormControl, InputLabel, Select, Checkbox, OutlinedInput, Button, FormLabel, Grid, Typography } from "@mui/material";
// Customizable Area End

import ReverseAuctionController, { FormField, Props } from "../ReverseAuctionController";
import DateRangePicker from "../../../../components/src/datePicker";

export default class ReverseAuctionForm extends ReverseAuctionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderField = (field: FormField) => {
    const { field_name, field_type, options, required } = field;
    const { formData } = this.state;

    switch (field_type) {
      case "text":
        return (
          <Grid item xs={12}>
            <FormLabel>
              {field_name}
              {required && <Typography className="required" component={"span"}>*</Typography>}
            </FormLabel>
            <TextField
              key={field_name}
              placeholder={`Enter ${field_name}`}
              required={required}
              fullWidth
              margin="normal"
              value={formData[field_name] || ""}
              onChange={(e) => this.handleChange(field_name, e.target.value)}
              style={{ marginTop: "0px" }}
              data-test-id= {`${field_name}`}
            />
          </Grid>
        );
      case "dropdown":
        return (
          <Grid item xs={12}>
            <FormLabel>
              {field_name}
              {required && <Typography className="required" component={"span"}>*</Typography>}
            </FormLabel>
            <Select
              value={formData[field_name]}
              onChange={this.handleInputChange}
              error={false}
              style={{ width: "100%" }}
              renderValue={(selected) => this.renderStringValueDropdown(selected, []) || `Select ${field_name}`}
            >
              <MenuItem value="">{`Select ${field_name}`}</MenuItem>
              {options.map((item: any) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </Grid>
        );
      case "multiple_choice":
        return (
          <Grid item xs={12}>
            <FormLabel>
              {field_name}
              {required && <Typography className="required" component={"span"}>*</Typography>}
            </FormLabel>
            <Select
              multiple
              value={[formData[field_name]]}
              onChange={this.handleInputChange}
              error={false}
              style={{ width: "100%" }}
            // renderValue={(selected) => this.renderStringValueDropdown(selected, []) || `Select ${field_name}`}
            >
              <MenuItem value="">{`Select ${field_name}`}</MenuItem>
              {options.map((item: any) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </Grid>
        );
      case "datetime":
        return (
          <Grid item xs={12}>
            <FormLabel>
              {field_name}
              {required && <Typography className="required" component={"span"}>*</Typography>}
            </FormLabel>
            <div>
              <DateRangePicker field_name={field_name} required={required} dateValue={formData[field_name] || ""} onChangeData={(e:any) => this.handleChange(field_name, e)}/>
            </div>
          </Grid>
        );
      default:
        return null;
    }
  };
  // Customizable Area End

  render() {
    const { isLoading, formFields } = this.state;
    return (
      // Customizable Area Start
      <>
      <style>{` .ReverseAuction-UI-Background-disappear .body-data{ background: none; } `} </style>
      <div className="ReverseAuction-UI-Background-disappear">
      <HeaderFooterLayout navigation={this.props.navigation}>
        <Loader isLoading={isLoading} />
        <StyleWrapper>
          <Box width={"100%"} border={"1px solid #D6D3D1"} borderRadius={"8px"} padding={"20px"}>
            <Box width={"100%"} padding={"15px 0px"}>
              <Typography className="mainTitle">Reverse Auction</Typography>
            </Box>
            <Box>
              <form data-test-id = "handlesubmit" onSubmit={this.handleSubmit}>
                <Grid container spacing={2}>
                  {formFields.map(this.renderField)}
                </Grid>
                <Box marginTop={"30px"} marginBottom={"15px"} width={"100%"} display={"flex"} flexDirection={"row"} columnGap={"10px"} justifyContent={"end"} alignItems={"center"}>
                  <Button className="cancelButton" onClick={this.openReverseAuctionList} type="button">Cancel</Button>
                  <Button className="submitButton" type="submit">Submit</Button>
                </Box>
              </form>
            </Box>
          </Box>
        </StyleWrapper>
      </HeaderFooterLayout>
      </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyleWrapper = styled(Box)({
  width: "100%",
  padding: "3%",
  "& .mainTitle": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#292524",
  },
  "& .required": {
    color: "red",
  },
  "& .common-form-label": {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "4px",
    display: "block",
    "& span": {
      color: "#DC2626",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "22px",
    },
  },
  "& .MuiInputBase-input": {
    padding: "10.5px 8px ",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#0F172A",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#A8A29E",
    opacity: 1,
  },
  "& .MuiInputBase-root.Mui-error": {
    borderRadius: "8px",
  },
  "& .MuiInputBase-root": {
    borderRadius: "0px",
    backgroundColor: "#FFFFFF",
  },
  "& .MuiFormHelperText-root": {
    color: "#DC2626",
    margin: "8px 0 0",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
  },
  "& .Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171 !important",
    borderWidth: "1px !important",
    borderStyle: "solid",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderLeft: "0px",
    borderBottom: "1px",
    borderRight: "0px",
    borderTop: "0px",
    borderStyle: "solid",
    borderColor: "#E2E8F0",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
  {
    borderRadius: "8px",
    borderWidth: "1px",
  },
  "& .submitButton": {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "capitalize",
    background: "#7F1187",
    padding: "10px 0px",
    borderRadius: "30px",
    width: "227px",
    cursor: "pointer",
    "&:hover": {
      background: "#7F1287"
    }
  },
  "& .cancelButton": {
    color: "#7F1187",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "capitalize",
    background: "#FFFFFF",
    padding: "10px 0px",
    borderRadius: "30px",
    width: "227px",
    cursor: "pointer",
    border: "1px solid #7F1187",
    "&:hover": {
      background: "#FFFFFF"
    }
  }
});
// Customizable Area End
