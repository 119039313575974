import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start



interface CheckboxOption {
    id: number;
    page: string[]; 
    content_header:{
        title:string,
        sub_title:string,
        button:string
    }    
  }

  interface CheckedOptions {
    [key: string]: boolean;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  screen_page:number;  
  checkbox_data:string[];
  checkbox_options: CheckboxOption[];
  checkedOptions:  CheckedOptions
  title:string;
  sub_title:string;
  button_content:string;
  openModal: boolean,
  propvalue:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfservicerecommendationSecondQuizController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      screen_page:1,
      checkbox_data:[],
      checkbox_options:[
        {id:2,page:['Banquet Halls','Villas / Lawans','Beach Resort','Destination Resort','Marriage Hall','Convention Centre','Party / Mini Hall','House / Own Land'],content_header:{title:'Which Venue Settings Do You Like?',sub_title:'Choose 2 to 6 favourite venues that feel like you.',button:'Next'}},
        {id:3,page:['traditional Decor','Contemporary Decor','Minimalistic Decor','Conceptual Decor','Venue Transformation','Themed Decor','Royal Decor','House / Own Land'],content_header:{title:'Lovely! How About These Decor?',sub_title:'Choose 2 to 6  favourite Decore. We’ll show you a few more sets to help define your wedding vibe.',button:'Next'}},
        {id:4,page:['Standard Invitation','Stancil Cut Invitation','Laser Cut Invitation','Hand Made Invitati','Eco-Friendly Invitatior','Paper Invitation','Royal Invitation','Theme Based Invitation'],content_header:{title:'Now How About These Invitation?',sub_title:"Choose 2 to 6 favourite Invitations. By the way, you've got great taste!",button:'Next'}},
        {id:5,page:['Pre-wed Shoot','Wedding Shoot','Event Shoot','Couple portrait Shoot','Celebration Shoot','Emotions Shoot','Candid Shoot','Outdoor Couple shoot'],content_header:{title:'Which of the photography do you like best?',sub_title:"Choose 2 to 6 favourite Photography that set the tone you want for your wedding.",button:'Next'}},
        {id:6,page:['Live band','Celebrityperfomance','DJ','Dance & Choreo','Activity & Stalls','Couple Entry','Dhol / Baraat','Wedding Bazaar'],content_header:{title:'Which of the entertainment do you like best?',sub_title:"Choose 2 to 6 favourite Entertainment that set the tone you want for your wedding",button:'Next'}},
        {id:7,page:['Bridal Makeup','Groom Styling','Mehndi Artist','Nutrition & Dietitian','Spa','Hair care','Fragrance','Fitness'],content_header:{title:'Which of these do you like best?',sub_title:"Choose 2 to 6 favourite that set the tone you want for your wedding",button:'Submit'}},
    ],
    title:'',
    sub_title:'',
    checkedOptions: {},
    button_content:'Next',
    openModal: true,
    propvalue:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    
    // Customizable Area End
  }
  

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);


    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const payloadMessage = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );     
     this.setState({ propvalue: payloadMessage.screenName});
    }

  

    // Customizable Area Start  
   
    // Customizable Area End
  }

  
 
  

 

  
  // web events
 

  // Customizable Area Start
  componentDidUpdate(prevProps: any, prevState: { checkbox_data: string[]; }) {   
    if (prevState.checkbox_data !== this.state.checkbox_data) {
        this.resetCheckboxState();
    }
}

resetCheckboxState = () => {
  const checkedOptions: CheckedOptions = this.state.checkbox_data.reduce<CheckedOptions>((acc, option) => {
      acc[option] = false;
      return acc;
  }, {} as CheckedOptions);

  this.setState({ checkedOptions });
};


handleCheckboxChange = (option: string) => {
  this.setState((prevState) => ({
      checkedOptions: {
          ...prevState.checkedOptions,
          [option]: !prevState.checkedOptions[option],
      },
  }));
};


  hanldePage = (e:any)=>{  
 let value;
 if(this.state.screen_page===7){   
    if(this.state.propvalue == "Stage 2"){
         value= "Stage 3"        
    }
    else{
       value= "btnclose"      
    }
    this.handleNavigation(value)
 }
 else{
  this.setState({ screen_page:e          
})
    this.handleOptions(e)  
 }   
}

handleNavigation(value:any){
  const navigationMessage: Message = new Message(getName(MessageEnum.NavigationMessage));
  navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), "Userrecommendation");
  const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
  raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {screenName:value});
  navigationMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props );
  this.send(navigationMessage);
}

handleModalClose = () => {
  this.setState({ openModal: false });
};

handleOptions = (value:string|number)=>{
   
  let arr=   this.state.checkbox_options.filter((x)=>{
        return x.id== value
    })


this.setState({
    checkbox_data: arr[0]?.page || [],
    title:arr[0]?.content_header.title,
    sub_title:arr[0]?.content_header.sub_title,
    button_content:arr[0]?.content_header.button

});

}
  // Customizable Area End
}
