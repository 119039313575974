import React from "react";

import {
  
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
  
  InputLabel,
  Grid,
  Paper,
  IconButton,
  Avatar,
} from "@mui/material";

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,

  StyledEngineProvider,
} from "@mui/material/styles";

import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer.web";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Loader from "../../../components/src/AppLoader.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import * as Yup from "yup";
import { banner3,backimage } from "./assets";
import SelectField from "../../../components/src/SelectField.web";
import TextInputFiled from "../../../components/src/TextInputField.web";
const monthOptions = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 }
  ];
  const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z][A-Z\d]Z[A-Z\d]$/;
  
  const validationSchema = Yup.object({
    businessName: Yup.string()
      .required("Business Name is required")
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Business Name should only contain letters")
      .test(
        "not-only-digits",
        "Business name cannot contain only digits",
        (value) => {
          return !/^\d+$/.test(value);
        }
      )
      .max(50, "Maximum 50 characters are allowed"),
    fullName: Yup.string()
      .required("Full Name is required")
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Full Name should only contain letters and spaces")
      .test(
        "not-only-digits",
        "Full name cannot contain only digits",
        (value) => {
          return !/^\d+$/.test(value);
        }
      )
      .max(50, "Maximum 50 characters are allowed"),
    businessCategory: Yup.string()
      .required("Business Category is required")
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Business Category should only contain letters")
      .test(
        "not-only-digits",
        "Business Category cannot contain only digits",
        (value) => {
          return !/^\d+$/.test(value);
        }
      )
      .max(50, "Maximum 50 characters are allowed"),
    gstNumber: Yup.string()
      .matches(gstRegex, 'Invalid GST no.')
      .required('GST number is required'),
    email: Yup.string()
      .email("Email should be in a valid email format (user@example.com)")
      .required("Email is required")
      .trim(),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one numeric digit, and one special character"
      )
      .trim(),
    primaryContact: Yup.string()
      .required('Contact number is required')
      .matches(/^\d+$/, 'Contact number must contain only digits')
      .min(10, 'Contact number must be at least 10 digits')
      .max(10, 'Contact number cannot exceed 10 digits'),
  
    secondaryNumber: Yup.string()
      .matches(/^\d+$/, 'Contact number must contain only digits')
      .min(10, 'Contact number must be at least 10 digits')
      .max(10, 'Contact number cannot exceed 10 digits'),
    reference_id: Yup.string().required("This field is required"),
    day: Yup.number().required("Day is required").min(1).max(31),
    month: Yup.number().required("Month is required"),
    evetsite: Yup.number().required("Event site is required"),
    subventtype: Yup.number().required("Sub event type is required"),
    eventtype: Yup.number().required("Event type is required"),
    subeventsite: Yup.number().required("Sub event site is required"),
    attendee: Yup.number().required("Attendee is required"),
    roombooked: Yup.number().required("Room is required"),
    Category: Yup.number().required("Category is required"),
    vedorname: Yup.number().required("Vendor is required"),
    events: Yup.number().required("Room is required"),
    eventdatestart: Yup.number().required("Event date start is required"),
    eventdateend: Yup.number().required("Event date end is required"),
    venue: Yup.number().required("Vendor is required"),
   
  });
 
 
// Customizable Area End

import PlannedeventdesignController, {
  Props,
  

} from "./PlannedeventdesignController";
import { Form, Formik } from "formik";

export default class Plannedeventdesign extends PlannedeventdesignController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
       <Loader isLoading={this.state.isLoading} />
       <StyledEngineProvider injectFirst>
        
        <ThemeProvider theme={theme}>
            <Box sx={{ position: "fixed", top: 0, left: 0, width: "100%", zIndex: 1000, background: "white" }}>
                <Header navigation={navigator || []} />
            </Box>
            <Box sx={{ overflowY: "auto", height: "calc(100vh - 60px)", mt: "60px" }}>
                <Box sx={{ ...webStyle.parent }}>
                    <Box sx={{ ...webStyle.child }} />
                    <Box sx={{ ...webStyle.subChild }}>
                        <Typography sx={{ fontWeight: 600, fontSize: "40px", mb: 1, }}>
                            Seamless Planning, Timeless Memories
                        </Typography>
                        <Typography variant="h6" sx={{ fontSize: "20px", fontWeight: 600 }}>
                            Turning Your Vision Into A Celebration.
                        </Typography>
                    </Box>


                </Box>
                <Box sx={{ ...webStyle.setBackImage }}>
                   
                    <Formik
                        data-test-id="Formik"
                        initialValues={this.state.formData}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            this.signUpWithVendorEmail(values);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        }) => {
                            return (
                                <Form onSubmit={handleSubmit} noValidate>
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            padding: "20px",
                                            border: "1px solid #ddd",
                                            borderRadius: "8px",
                                            marginTop: "20px",
                                        }}
                                    >
                                        <Box sx={{ ...webStyle.uploadContainer }}>
                                            <Typography variant="h6" className="title" sx={{
                                                fontSize: "20px",
                                                fontWeight: 600,
                                                fontFamily: "Poppins",
                                                lineHeight: "28px",
                                                letterSpacing: "0%",
                                                verticalAlign: "middle",
                                                marginBottom: "1.5rem"
                                            }}>
                                                Plan Your New Events
                                            </Typography>
                                            <Box >
                                                <hr style={{ color: "#D6D3D1" }} />
                                            </Box>
                                            <Box className="image-upload" sx={{ ...webStyle.imageUpload }}>
                                                <input
                                                    accept="image/*"
                                                    type="file"
                                                    id="image-input"
                                                    style={{ display: "none" }}
                                                    data-test-id="fileUploadTest"
                                                    onChange={this.handleImageChange}

                                                />
                                                <label htmlFor="image-input" className="image-label">
                                                    <Avatar
                                                        src={this.state.image}
                                                        alt="Upload"
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            borderRadius: "50%",
                                                            objectFit: "cover",
                                                        }}
                                                    />
                                                    <Box className="overlay" sx={{ ...webStyle.overlay }}>
                                                        <IconButton component="span" className="icon-btn">
                                                            <PhotoCameraIcon className="icon" style={{ color: "white" }} />
                                                        </IconButton>
                                                        <Typography className="upload-text" style={{ fontSize: "14px", fontWeight: 700 }}>Upload Image</Typography>
                                                    </Box>
                                                </label>
                                            </Box>
                                        </Box>
                                        <Grid container spacing={2} sx={{ marginTop: "4rem" }}>
                                            <Grid item xs={12} sm={6}>
                                                <InputLabel className="labelClass" sx={{ ...webStyle.fontSizeget }}>
                                                    Event
                                                </InputLabel>
                                                <TextInputFiled
                                                    name="events"
                                                    data-test-id="full-name-input"
                                                    placeholder="Enter Event Name"
                                                    value={values.events}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    type="text"
                                                    error={this.isError(touched.events, errors.events)}
                                                    helperText={this.helperText(
                                                        touched.events,
                                                        errors.events
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <InputLabel className="labelClass" sx={{ ...webStyle.fontSizeget }}>
                                                    Venue
                                                </InputLabel>
                                                <TextInputFiled
                                                    name="venue"
                                                    data-test-id="full-name-input"
                                                    placeholder="Enter Venue"
                                                    value={values.venue}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    type="text"
                                                    error={this.isError(touched.venue, errors.venue)}
                                                    helperText={this.helperText(
                                                        touched.venue,
                                                        errors.venue
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <InputLabel className="labelClass" sx={{ ...webStyle.fontSizeget }}>
                                                    Event Start Date
                                                </InputLabel>
                                                <TextInputFiled
                                                    name="eventdatestart"
                                                    data-test-id="full-name-input"
                                                    placeholder="DD/MM/YYYY"
                                                    value={values.eventdatestart}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    type="date"
                                                    error={this.isError(touched.eventdatestart, errors.eventdatestart)}
                                                    helperText={this.helperText(
                                                        touched.eventdatestart,
                                                        errors.eventdatestart
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <InputLabel className="labelClass" sx={{ ...webStyle.fontSizeget }}>
                                                    Event End Date
                                                </InputLabel>
                                                <TextInputFiled
                                                    name="eventdateend"
                                                    data-test-id="full-name-input"
                                                    placeholder="DD/MM/YYYY"
                                                    value={values.eventdateend}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    type="date"
                                                    error={this.isError(touched.eventdateend, errors.eventdateend)}
                                                    helperText={this.helperText(
                                                        touched.eventdateend,
                                                        errors.eventdateend
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>


                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <InputLabel className="labelClass" sx={{ ...webStyle.fontSizeget }}>
                                                    Event Type
                                                </InputLabel>
                                                <Box className="dropdown-container">
                                                    <SelectField
                                                        name="eventtype"
                                                        data-test-id="monthCheck"
                                                        value={values.eventtype}
                                                        setFieldValue={setFieldValue}
                                                        handleBlur={handleBlur}
                                                        options={monthOptions}
                                                        placeholder="Select Event Type"
                                                        error={Boolean(this.handleErrorText(touched.eventtype, errors.eventtype))}
                                                        touched={Boolean(touched.eventtype)}
                                                        helperText={
                                                            this.handleErrorText(touched.eventtype, errors.eventtype)
                                                                ? errors.eventtype
                                                                : ""
                                                        }
                                                        label={""}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <InputLabel className="labelClass" sx={{ ...webStyle.fontSizeget }}>
                                                    Sub Event Type
                                                </InputLabel>
                                                <Box className="dropdown-container">
                                                    <SelectField
                                                        name="subventtype"
                                                        data-test-id="monthCheck"
                                                        value={values.subventtype}
                                                        setFieldValue={setFieldValue}
                                                        handleBlur={handleBlur}
                                                        options={monthOptions}
                                                        placeholder="Month"
                                                        error={Boolean(this.handleErrorText(touched.subventtype, errors.subventtype))}
                                                        touched={Boolean(touched.subventtype)}
                                                        helperText={
                                                            this.handleErrorText(touched.subventtype, errors.subventtype)
                                                                ? errors.subventtype
                                                                : ""
                                                        }
                                                        label={""}
                                                    />
                                                </Box>
                                            </Grid>

                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <InputLabel className="labelClass" sx={{ ...webStyle.fontSizeget }}>
                                                    Event site
                                                </InputLabel>
                                                <Box className="dropdown-container">
                                                    <SelectField
                                                        name="evetsite"
                                                        data-test-id="monthCheck"
                                                        value={values.evetsite}
                                                        setFieldValue={setFieldValue}
                                                        handleBlur={handleBlur}
                                                        options={monthOptions}
                                                        placeholder="Select Event Type"
                                                        error={Boolean(this.handleErrorText(touched.evetsite, errors.evetsite))}
                                                        touched={Boolean(touched.evetsite)}
                                                        helperText={
                                                            this.handleErrorText(touched.evetsite, errors.evetsite)
                                                                ? errors.evetsite
                                                                : ""
                                                        }
                                                        label={""}
                                                    />
                                                </Box>
                                            </Grid>
                                            {/* Business Name - 50% width */}
                                            <Grid item xs={12} sm={6}>
                                                <InputLabel className="labelClass" sx={{ ...webStyle.fontSizeget }}>
                                                    Sub Event site
                                                </InputLabel>
                                                <Box className="dropdown-containers">
                                                    <SelectField
                                                        name="subeventsite"
                                                        data-test-id="monthCheck"
                                                        value={values.subeventsite}
                                                        setFieldValue={setFieldValue}
                                                        handleBlur={handleBlur}
                                                        options={monthOptions}
                                                        placeholder="Select Event Site"
                                                        error={Boolean(this.handleErrorText(touched.subeventsite, errors.subeventsite))}
                                                        touched={Boolean(touched.subeventsite)}
                                                        helperText={
                                                            this.handleErrorText(touched.subeventsite, errors.subeventsite)
                                                                ? errors.subeventsite
                                                                : ""
                                                        }
                                                        label={""}
                                                    />
                                                </Box>
                                            </Grid>

                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <InputLabel className="labelClass" sx={{ ...webStyle.fontSizeget }}>
                                                    Attendee
                                                </InputLabel>
                                                <TextInputFiled
                                                    name="attendee"
                                                    data-test-id="full-name-input"
                                                    placeholder="Enter Attendee"
                                                    value={values.attendee}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    type="text"
                                                    error={this.isError(touched.attendee, errors.attendee)}
                                                    helperText={this.helperText(
                                                        touched.attendee,
                                                        errors.attendee
                                                    )}
                                                />
                                            </Grid>

                                            {/* Company Incorporation Date - 50% width */}
                                            <Grid item xs={12} sm={6}>
                                                <InputLabel className="labelClass" sx={{ ...webStyle.fontSizeget }}>
                                                    Room booked
                                                </InputLabel>
                                                <TextInputFiled
                                                    name="roombooked"
                                                    data-test-id="full-name-input"
                                                    placeholder="Enter Room Booked"
                                                    value={values.roombooked}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    type="text"
                                                    error={this.isError(touched.roombooked, errors.roombooked)}
                                                    helperText={this.helperText(
                                                        touched.roombooked,
                                                        errors.roombooked
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Box sx={{ marginTop: "2rem", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Box>
                                                <Typography sx={{ fontSize: "20px", color: "#334155", fontWeight: 700 }}>Vendors Details</Typography>
                                            </Box>
                                            <Box>
                                                <Typography sx={{ color: "#334155", fontWeight: 500, fontSize: "16px" }}>Add Vendors</Typography>
                                            </Box>
                                        </Box>
                                        <hr style={{ color: "#D6D3D1" }} />
                                        <Box style={{ marginTop: "12px" }}>
                                            <Typography>
                                                Vendors 1
                                            </Typography>
                                        </Box>
                                        <Grid container spacing={2} style={{ marginTop: "16px" }}>
                                            <Grid item xs={12} sm={6}>
                                                <InputLabel className="labelClass" sx={{ ...webStyle.fontSizeget }}>
                                                    Category
                                                </InputLabel>
                                                <Box className="dropdown-container" >
                                                    <SelectField
                                                        name="Category"
                                                        data-test-id="monthCheck"
                                                        value={values.Category}
                                                        setFieldValue={setFieldValue}
                                                        handleBlur={handleBlur}
                                                        options={monthOptions}
                                                        placeholder="Select Category"
                                                        error={Boolean(this.handleErrorText(touched.Category, errors.Category))}
                                                        touched={Boolean(touched.Category)}
                                                        helperText={
                                                            this.handleErrorText(touched.Category, errors.Category)
                                                                ? errors.Category
                                                                : ""
                                                        }
                                                        label={""}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <InputLabel className="labelClass" sx={{ ...webStyle.fontSizeget }}>
                                                    Vendor Name
                                                </InputLabel>
                                                <Box className="dropdown-container">
                                                    <SelectField
                                                        name="vedorname"
                                                        data-test-id="monthCheck"
                                                        value={values.vedorname}
                                                        setFieldValue={setFieldValue}
                                                        handleBlur={handleBlur}
                                                        options={monthOptions}
                                                        placeholder="Select Vendor Name"
                                                        error={Boolean(this.handleErrorText(touched.vedorname, errors.vedorname))}
                                                        touched={Boolean(touched.vedorname)}
                                                        helperText={
                                                            this.handleErrorText(touched.vedorname, errors.vedorname)
                                                                ? errors.vedorname
                                                                : ""
                                                        }
                                                        label={""}
                                                    />
                                                </Box>
                                            </Grid>

                                        </Grid>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            gap: '16px',
                                            marginTop: '32px'
                                        }}>
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    color: '#7F1187',
                                                    borderColor: '#7F1187',
                                                    '&:hover': {
                                                        borderColor: '#7F1187',
                                                        backgroundColor: 'rgba(127, 17, 135, 0.04)'
                                                    },
                                                    textTransform: 'none',
                                                    padding: '8px 24px',
                                                    borderRadius: '30px',
                                                    fontWeight: 600,
                                                    width: "180px"
                                                }}
                                                data-test-id="chooseVendorTest"

                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    width: "180px",
                                                    backgroundColor: '#7F1187',
                                                    color: '#ffffff',
                                                    '&:hover': {
                                                        backgroundColor: '#6a0e73'
                                                    },
                                                    textTransform: 'none',
                                                    padding: '8px 24px',
                                                    borderRadius: '30px',
                                                    fontWeight: 600
                                                }}
                                                type="submit"
                                            >
                                                Create Event
                                            </Button>
                                        </Box>

                                    </Paper>


                                </Form>
                            );
                        }}
                    </Formik>
                </Box>
                <Box>
                <Footer/>
            </Box>
            </Box>
           


        </ThemeProvider>
  </StyledEngineProvider>
      </>
    
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    fontSizeget:{
        fontSize: "14px",
        fontWeight: 500,
        fontFamily: "Poppins",
        lineHeight: "22px",
        letterSpacing: "0%",
        verticalAlign: "middle",
        color:"#334155"
    },
    uploadContainer: {
        textAlign: "left",
        marginLeft: "20px",
      },
      title: {
        fontWeight: "bold",
      },
      imageUpload: {
        position: "relative",
        width: "156px",
        height: "156px",
        top:"2rem"
      },
      imageLabel: {
        cursor: "pointer",
        position: "relative",
        display: "block",
        width: "100%",
        height: "100%",
      },
      avatar: {
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        objectFit: "cover",
      },
      overlay: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        background: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        opacity: 1,
        color: "white",
        textAlign: "center",
      },
      iconBtn: {
        color: "white",
      },
      uploadText: {
        fontSize: "14px",
      },
    setBackImage:{
        backgroundImage: `url(${backimage})`, /* Image ka path set karein */
        backgroundSize: "cover", /* Puri background cover kare */
        backgroundPosition: "center", /* Image center me ho */
        backgroundRepeat: "no-repeat",/* Image repeat na ho */
        padding: "20px", /* Thoda padding de */
        borderRadius: "8px", /* Rounded corners */
    },
  parent: {
        position: "relative",
        width: "100%",
        height: { xs: "500px", md: "500px" },
        backgroundImage: `url(${banner3})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        px: 2,
    },
  child: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.3)", 
  },
  subChild: {
    position: "absolute",
    bottom: 20,
    left: "50%",
    transform: "translateX(-50%)",
    color: "#fff",
    textAlign: "center",
  },
  
  
};
// Customizable Area End
