import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { apiCall } from "../../../components/src/utils";

export const baseURL = require("../../../framework/src/config.js");

const timer = 5 * 60;

type verifyOtpType = { errors: { otp: string }[] } & {
  errors: { token: string }[];
} & {
  errors: { pin: string }[];
} & {
  data: { id: number; attributes: { email: string } };
  meta: {
    message: string;
    token: string[];
  };
};
interface CaroselList {
  id: number;
  attributes: {
    id: number;
    img_vid_url: {
      id: 23,
      type: string;
      url:string;     
    }
}
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isLoading: boolean;
  email: string;
  timeLeft: number;
  canResend: boolean;
  otp_token: string;
  isInvalidOtp: boolean;
  isForgotPassword: boolean;
  listCarosel:CaroselList[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OtpController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  timerId: NodeJS.Timeout | null = null;
  otpSendApiCallId: string = "";
  verifyOtpApiCallId: string = "";
  otpSendForgotPasswordApiCallId: string = "";
  otpVerifyForgotPasswordApiCallId: string = "";
  getCaroselSliderCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isLoading: false,
      email: "",
      canResend: false,
      timeLeft: timer,
      otp_token: "",
      isInvalidOtp: false,
      isForgotPassword: false,
      listCarosel:[] 
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.timerId = null;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.carroselSliderData(message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiCallId) {
        case this.otpSendApiCallId:
          this.handleResendOtpResponse(webResponseJson);
          break;
        case this.verifyOtpApiCallId:
          this.handleVerifyOtpResponse(webResponseJson);
          break;
        case this.otpSendForgotPasswordApiCallId:
          this.handleForgotApiResponse(webResponseJson);
          break;
        case this.otpVerifyForgotPasswordApiCallId:
          this.handleVerifyOtpForgotResponse(webResponseJson);
          break;
        default:
          break;
      }
      this.setState({
        isLoading: false,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    this.caroselSliderApi();
    this.checkUserIsLoggedIn();
    this.getOtpToken();
    this.isForgotPassword();
    this.startTimer();
  };

  componentWillUnmount = async () => {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  };

  checkUserIsLoggedIn = async () => {
    const email = await getStorageData("email");
    if (email) {
      this.setState({
        email,
      });
    } else {
      this.props.navigation.goBack();
    }
  };

  isForgotPassword = () => {
    const id = this.props.navigation.getParam("id");
    if (id) {
      this.setState({
        isForgotPassword: true,
      });
    } else {
      this.getToken();
    }
  };

  getOtpToken = async () => {
    const otp_token = await getStorageData("otp_token");
    if (otp_token) {
      this.setState({
        otp_token,
      });
    } else {
      this.props.navigation.goBack();
    }
  };

  getToken = async () => {
    const token = await getStorageData("authToken");
    if (token) {
      this.setState({
        token,
      });
    } else {
      this.props.navigation.goBack();
    }
  };

  carroselSliderData= (message:Message)=>{
    if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.getCaroselSliderCallId &&
			this.getCaroselSliderCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson.data){
        this.setState({listCarosel:responseJson.data})
			}
		}
  }

  formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  startTimer = () => {
    this.timerId = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.timeLeft > 1) {
          return { timeLeft: prevState.timeLeft - 1, canResend: false };
        } else {
          if (this.timerId) {
            clearInterval(this.timerId);
          }
          return { timeLeft: 0, canResend: true };
        }
      });
    }, 1000);
  };

  caroselSliderApi = async () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.carouselGetApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); this.getCaroselSliderCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleResendClick = () => {
    this.resendOtpInEmail();
  };

  goToLogin = () => {
    this.props.navigation.navigate("LoginEmail");
  };

  resendOtpInEmail = async () => {
    this.setState({
      isLoading: true,
    });
    if (this.state.isForgotPassword) {
      const bodyDate = {
        type: "email_account",
        attributes: {
          email: this.state.email,
        },
      };
      this.otpSendForgotPasswordApiCallId = await apiCall({
        endPoint: "bx_block_forgot_password/otps",
        method: "POST",
        contentType: "application/json",
        body: JSON.stringify({ data: bodyDate }),
      });
    } else {
      this.otpSendApiCallId = await apiCall({
        endPoint: "account_block/accounts/send_otp",
        method: "POST",
        contentType: "application/json",
        token: this.state.token,
      });
    }
  };

  handleResendOtpResponse = async (webResponseJson: {
    message: string;
    otp_token: string[];
  }) => {
    if (webResponseJson.message && webResponseJson.otp_token) {
      toast.success("OTP send successfully.");
      this.setState({
        otp_token: webResponseJson.otp_token[0],
        timeLeft: timer,
        canResend: false,
      });
      this.startTimer();
      await setStorageData("otp_token", webResponseJson.otp_token[0]);
    }
  };

  handleForgotApiResponse = async (webResponseJson: {
    meta: { token: string; refresh_token: string };
  }) => {
    if (webResponseJson?.meta?.token) {
      toast.success("OTP send successfully.");
      this.setState({
        otp_token: webResponseJson.meta.token,
        timeLeft: timer,
        canResend: false,
      });
      this.startTimer();
      await setStorageData("otp_token", webResponseJson.meta.token);
    }
  };

  verifyEmailWithOtp = async (otp: string) => {
    this.setState({
      isLoading: true,
    });
    if (this.state.isForgotPassword) {
      const formData = new FormData();
      formData.append("data[token]", this.state.otp_token);
      formData.append("data[otp_code]", otp);

      this.otpVerifyForgotPasswordApiCallId = await apiCall({
        endPoint: "bx_block_forgot_password/otp_confirmations",
        method: "POST",
        body: formData,
      });
    } else {
      const formData = new FormData();
      formData.append("token", this.state.otp_token);
      formData.append("otp_code", otp);

      this.verifyOtpApiCallId = await apiCall({
        endPoint: "account_block/email_otp_confirmations",
        method: "POST",
        body: formData,
      });
    }
  };

  changeInvalidOtpValue = () => {
    this.setState({
      isInvalidOtp: false,
    });
  };

  handleVerifyOtpResponse = async (webResponseJson: verifyOtpType) => {
    if (Array.isArray(webResponseJson.errors)) {
      if (webResponseJson.errors[0].pin||webResponseJson.errors[0].otp) {
        toast.error(
          webResponseJson.errors[0].pin||webResponseJson.errors[0].otp  
        );
        this.setState({
          timeLeft: 0,
          isInvalidOtp: true,
          canResend: true,
        });
      }
      if (webResponseJson.errors[0].token) {
        toast.error(webResponseJson.errors[0].token);
        this.props.navigation.navigate("EmailAccount");
      }
    }
    if (webResponseJson.data && webResponseJson.meta) {
      toast.success(webResponseJson.meta.message);
      await setStorageData("email", webResponseJson.data.attributes.email);
      await setStorageData("authToken", webResponseJson.meta.token[0]);
      this.props.navigation.navigate("Welcome");
    }
  };

  handleVerifyOtpForgotResponse = async (
    webResponseJson: {
      messages: { otp: string }[];
    } & {
      errors: { pin: string }[];
    } & { errors: { otp: string }[] } & { errors: { token: string }[] }
  ) => {
    if (Array.isArray(webResponseJson.messages)) {
      if (webResponseJson.messages[0].otp === "OTP validation success") {
        toast.success("OTP verified successfully.");
        this.props.navigation.navigate("NewPasswordWeb");
      }
    }

    if (Array.isArray(webResponseJson.errors)) {
      if (webResponseJson.errors[0].otp || webResponseJson.errors[0].pin) {
        toast.error(
          webResponseJson.errors[0].otp || webResponseJson.errors[0].pin
        );
        this.setState({
          canResend: true,
          isInvalidOtp: true,
          timeLeft: 0,
        });
      }
      if (webResponseJson.errors[0].token) {
        toast.error(webResponseJson.errors[0].token);
        this.props.navigation.navigate("EmailAccount");
      }
    }
  };
  // Customizable Area End
}
