import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import HeaderFooterLayout from "../../../../components/src/HeaderFooterLayout";
import { Box, Typography as Text, Grid, Button as BTN, styled } from '@mui/material';
import Loader from "../../../../components/src/AppLoader.web";
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
const EssentialbudgetImage = require("../../assets/Image2.png");
const Recommended = require("../../assets/Image1.jpeg");
const correctImg = require("../../assets/correct.png");
const addIcon = require("../../assets/addItem-img.png");
const closeBtn = require("../../assets/close-Btn.png");
// Customizable Area End

import PlanController, {
  Props,
  configJSON,
} from "../PlanController.web";

export default class SelectPlan extends PlanController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <HeaderFooterLayout navigation={this.props.navigation}>
        <Loader isLoading={false} />
        <SelectPlanScreen>
          <Box className='select_plan_heading_box'>
            <Text className='Select_Plan_heading_Txt'>Please Select Plan</Text>
            <Text className="search_criteria_txt">Showing 2 Plans as per your search criteria</Text>
          </Box>
          <Box>
            <Grid container spacing={4} display={"flex"} justifyContent={"center"}>
              <Grid item xs={6}>
                <Box className="Essential-budget">
                  <Box className="Essential">
                    <Text className="Essential-Txt">Essential</Text>
                  </Box>
                  <Box className="Budget">
                    <Text className="Budget-In-Lakhs">Budget 10 Lakhs</Text>
                  </Box>
                  <Box className="Budget-In-Lakhs-subText">
                    <ul>
                      <li>Venue</li>
                      <li>Photo & Video</li>
                      <li>Makeup</li>
                      <li>Invitations</li>
                      <li>Caterer</li>
                      <li>Entertainment</li>
                      <li>Decor</li>
                      <li>Customise</li>
                    </ul>
                  </Box>
                  <Box>
                  </Box>
                  <Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="Recommended-budget">
                  <Box className="Recommended">
                    <Text className="Recommended-Txt">Recommended</Text>
                  </Box>
                  <Box className="Budget">
                    <Text className="Budget-In-Lakhs">Budget 15 Lakhs</Text>
                  </Box>
                  <Box className="Budget-In-Lakhs-subText">
                    <ul>
                      <li>Venue</li>
                      <li>Photo & Video</li>
                      <li>Makeup</li>
                      <li>Invitations</li>
                      <li>Caterer</li>
                      <li>Entertainment</li>
                      <li>Decor</li>
                      <li>Customise</li>
                    </ul>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} paddingTop={"24px !important"}>
                <Box style={{ width: "290px", marginLeft:"auto", display:"flex", justifyContent:"center" }}>
                  <BTN className="selectBtn" data-test-id="selectBtn1" onClick={()=>{this.handleSelectBudgetAndRecommended()}}>
                    Select
                  </BTN>
                </Box>
              </Grid>
              <Grid item xs={6} paddingTop={"24px !important"}>
                <Box style={{ width: "290px", marginRight:"auto", display:"flex", justifyContent:"center" }}>
                  <BTN className="selectBtn" data-test-id="selectBtn2" onClick={()=>{this.handleSelectBudgetAndRecommended()}}>
                    Select
                  </BTN>
                </Box>
              </Grid>
            </Grid>
          </Box> 
        </SelectPlanScreen>
        <Modal
          open={this.state.toggle}
          onClose={this.handleServicesClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          data-test-id="Modal-1"
        >
          <StyledModalBox>
            <Box id="Select-Services-Heading">
              <Text id="modal-modal-title" variant="h6" component="h2">
                Select Services
              </Text>
              <Box id="closeBtn">
                <img src={closeBtn}  width="18px"/>
              </Box>
            </Box>
            <Grid container rowGap={2}>
              {this.state.SelectServicesList.map((item)=>(<Grid item xs={3}><RoundedCheckbox checked={true} onChange={()=>{}} /><span className="SelectServicesItems">{item}</span></Grid>))}
            </Grid>
            <Box className="resetAndDoneBtnBox">
              <BTN className="resetBtn">
                Reset
              </BTN>
              <BTN className="doneBtn" data-test-id="Modal-1-handleSubscribeOpen" onClick={this.handleSubscribeOpen}>
                Done
              </BTN>
            </Box>
          </StyledModalBox>
        </Modal>
        <Modal
          open={this.state.toggle2}
          onClose={this.handleSubscribeClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <StyledModalBox style={{ width:"839px", padding:"38px" }}>
            <Box id="Select-Services-Heading" style={{ marginBottom: "30px" }}>
              <Box id="closeBtn">
                <img src={closeBtn} width="18px" data-test-id="CancelBtn2" onClick={()=>{this.handleSubscribeClose()}}/>
              </Box>
            </Box>
            <Text id="modal-modal-title" variant="h6" component="h2">
              Hey, We Found 137 Vendors On Your Preferred Budget
            </Text>
            <Text id="modal-modal-title" style={{ fontWeight: 400, fontSize: "14px", color:"#44403C", marginTop:"8px" }} variant="h6" component="h2">
              Please Subscribe to view our services.
            </Text>
            <Box className="resetAndDoneBtnBox" style={{ marginTop: "50px" }}>
              <BTN className="resetBtn" data-test-id="CancelBtn" style={{ width: "227px" }} onClick={()=>{this.handleSubscribeClose()}} >
                Cancel
              </BTN>
              <BTN className="doneBtn" data-test-id="SubscribeBtn" style={{ width: "227px" }} onClick={()=>{this.handlePageSubscribe()}}>
                Subscribe
              </BTN>
            </Box>
            <Text id="modal-modal-title" style={{ fontWeight: 400, fontSize: "14px", color:"#44403C", marginTop:"40px" }} variant="h6" component="h2">
              If you need any assistance you can reach out to us 
            </Text>
            <Box className="resetAndDoneBtnBox" style={{ marginTop: "8px" }}>
              <BTN className="doneBtn" style={{ width: "227px" }}>
               Support
              </BTN>
            </Box>
          </StyledModalBox>
        </Modal>
      </HeaderFooterLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const RoundedCheckbox: React.FC<{ checked: boolean, onChange: () => void }> = ({
  checked,
  onChange,
}) => {
  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      icon={<div style={uncheckedStyle} />}
      checkedIcon={
        <div style={checkedStyle}>
          <CheckRoundedIcon sx={{ fontSize: 16, color: "white" }} />
        </div>
      }
    />
  );
};

const uncheckedStyle = {
  width: "20px",
  height: "20px",
  borderRadius: "6px",
  border: "1px solid #64748B"
};

const checkedStyle = {
  width: "20px",
  height: "20px",
  borderRadius: "6px",
  backgroundColor: "#7F1187", // Purple shade
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};

const StyledModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1040,
  backgroundColor: "rgba(255, 255, 255, 1)",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  borderRadius: "12px",
  "& .resetBtn": {
    width: "186px",
    height: "44px",
    gap: "8px",
    paddingTop: "10px",
    paddingRight: "16px",
    paddingBottom: "10px",
    paddingLeft: "16px",
    borderRadius: "30px",
    border: "1px solid #7F1187",
    color: "#7F1287",
    cursor: "pointer",
    textTransform: "capitalize",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0%",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: " rgba(25, 118, 210, 0.04)"
    }
  },
  "& .doneBtn": {
    width: "180px",
    height: "44px",
    borderRadius: "30px",
    gap: "8px",
    paddingTop: "10px",
    paddingRight: "16px",
    paddingBottom: "10px",
    paddingLeft: "16px",
    background: "#7F1187",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0%",
    color:"white",
    textTransform: "capitalize",
    "&:hover": {
        backgroundColor: "#7F1187"
    }
  },
  "& #Select-Services-Heading": {
    position: "relative",
    textAlign: "center",
    marginBottom: "45px"
  },
  "& #closeBtn": {
    position: "absolute",
    right: "0px",
    top: "0px"
  },
  "& #modal-modal-title":{
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "24px",
    letterSpacing: "0%",
    textAlign: "center",
    color:"rgba(41, 37, 36, 1)"
  },
  "& .SelectServicesItems":{
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0%",
    verticalAlign: "middle"
  },
  "& .resetAndDoneBtnBox":{
    display: "flex",
    justifyContent: "center",
    columnGap: "24px",
    marginTop: "45px"
  }
}));

const SelectPlanScreen = styled(Box)({
  "& .select_plan_heading_box": {
    width: "903px",
    margin: "28px auto",
    position: "relative",
  },
  "& .Select_Plan_heading_Txt": {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "36px",
    lineHeight: "50px",
    letterSpacing: "0%",
    textAlign: "center",
    textTransform: "capitalize",
    verticalAlign: "middle",
    color: "#1C1917"
  },
  "& .search_criteria_txt": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "26px",
    letterSpacing: "0%",
    textAlign: "center",
    textTransform: "capitalize",
    color: "#57534E"
  },
  "& .Planning-txt": {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "28px",
    letterSpacing: "0%"
  },
  "& .Planning-details": {
    marginTop: "30px",
  },
  "& .Essential-budget": {
    width: "290px",
    height: "479px",
    borderRadius: "16px",
    borderWidth: "1px",
    gap: "5px",
    paddingTop: "37px",
    paddingRight: "32px",
    paddingBottom: "37px",
    paddingLeft: "32px",
    color: "#FFFFFF",
    background: `linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 72.41%), url(${EssentialbudgetImage}) no-repeat center center`,
    backgroundSize: "cover",
    marginLeft: "auto"
  },
  "& .Essential": {
    width: "107px",
    height: "32px",
    gap: "8px",
    borderRadius: "8px",
    padding: "8px",
    background: "#FFFFFF59",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .Essential-Txt": {
      fontFamily: "Poppins",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0%",
      color: "#FFFFFF"
    }
  },
  "& .Recommended": {
    width: "160px",
    height: "32px",
    gap: "8px",
    borderRadius: "8px",
    padding: "8px",
    background: "#FFFFFF59",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .Recommended-Txt": {
      fontFamily: "Poppins",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0%",
      color: "#FFFFFF"
    }
  },
  "& .Recommended-budget": {
    width: "290px",
    height: "479px",
    borderRadius: "16px",
    borderWidth: "1px",
    gap: "5px",
    paddingTop: "37px",
    paddingRight: "32px",
    paddingBottom: "37px",
    paddingLeft: "32px",
    background: `linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 72.41%), linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${Recommended}) no-repeat center center`,
    backgroundSize: "cover",
    color: "#FFFFFF"
  },
  "& .eventCategoryBox": {
    marginTop: "10px",
    display: "grid",
    gridTemplateRow: "40px 40px",
    gridTemplateColumns: "repeat(6, 1fr)",
    rowGap: "20px",
    columnGap: "10px"
  },
  "& .selectBtn": {
    width: "186px",
    height: "44px",
    gap: "8px",
    paddingTop: "10px",
    paddingRight: "16px",
    paddingBottom: "10px",
    paddingLeft: "16px",
    borderRadius: "30px",
    fontFamily: "Poppins",
    fontweight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0%",
    border: "1px solid #7F1187",
    color: "#7F1287",
    cursor: "pointer",
    textTransform:"capitalize",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: " rgba(25, 118, 210, 0.04)"
    }
  },
  "& .Budget": {
    marginTop: "17px"
  },
  "& .Budget-In-Lakhs": {
    fontFamily: "Roboto !important",
    fontWeight: "600",
    fontSize: "28px",
    lineHeight: "24px",
    letterSpacing: "0%",
    whiteSpace: "nowrap",
    color: "#FFFFFF"
  },
  "& .Budget-In-Lakhs-subText": {
    marginTop: "16px",
    "& ul": {
      listStyle: "none",
      lineHeight: "38.5px",
      padding: "0",
    },
    "& ul li": {
      position: "relative",
      paddingLeft: "40px",
      fontWeight: 500,
      fontSize: "12px"
    },
    "& ul li::before": {
      content: '""',
      position: "absolute",
      left: "0",
      top: "7px",
      width: "24px",
      height: "24px",
      backgroundImage: `url(${correctImg})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
    "& ul li:last-child::before": {
    backgroundImage: `url(${addIcon})`,
    width: "28px",
    height: "28px",
    left: "-2px"
    }
  }
});
// Customizable Area End
