import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { apiCall } from "../../../components/src/utils";
import { toast } from "react-toastify";
import { setStorageData } from "../../../framework/src/Utilities";

export interface ICarouselItems {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: string;
    position: number;
    img_vid_url: {
      id: number;
      date: string;
      url: string;
      type: string;
    };
  };
}

export interface ISubject {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface IFaqQuestionAnswer {
  type: string;
  id: string;
  attributes: {
    id: number;
    answer: string;
    question: string;
    admin_users_id: number;
  };
}

export interface IWhoWeAre {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    heading: string;
    description: string;
    img_video: {
      url: string;
      id: number;
      date: string;
      type: "image" | "video";
    };
  };
}


export type WebResponseJsonForContactUs = {
  errors: { contact: string[] }[];
} & {
  data: {
    id: string;
    type: string;
    attributes: {
      email: string;
      name: string;
      phone_number: string;
      description: string;
      subject_id: number;
      phoneId: string;
    };
  };
};

export type WebResponseJsonForNewSletter = {
  errors: { news_letter: string[] }[];
} & {
  data: {
    id: string;
    type: string;
    attributes: {
      email: string;
      name: string;
      subscribe: boolean;
    };
  };
};

export interface IWhyChooseUs {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description_one: string;
    description_two: string;
    description_three: string;
    description_four: string;
    description_five: string;
    description_six: string;
    image: {
      url: string;
    };
  };
}

export interface IHowItWorks {
  id: string;
  type: string;
  attributes: {
    id: number;
    heading: string;
    description: string;
    image: {
      url: string;
    };
  };
}
export interface RealWedding {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: string;
  };
}
export interface RealWeddingProfile {
  map(arg0: (data: any, index: any) => JSX.Element): React.ReactNode;
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: string;
    "image": {
      "id": number,
      "date": string,
      "url": string,
      "type": string
    }
  };
}

export interface ContactUsShowdata {
  "data": {
    "id": string,
    "type": string;
    "attributes": {
      "id": string;
      "vendor_email": string;
      "vendor_phone_number": string;
      "vendor_country_code": string;
      "vendor_full_phone_number": string;
      "user_email": string;
      "user_phone_number": string;
      "user_country_code": string;
      "user_full_phone_number": string;
      "address": string;
      "created_at": string;
      "updated_at": string;
    }
  }
}
export interface ExperienceData {
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "name": string,
    "value": number,
    "created_at": string,
    "updated_at": string,
  }
}

export interface CategoryAttributes {
  id: number;
  name: string;
  description: string;
  priority: string | null;
  trending_categories: boolean;
  dark_icon: string | null;
  dark_icon_active: string | null;
  dark_icon_inactive: string | null;
  light_icon: string | null;
  light_icon_active: string | null;
  light_icon_inactive: string | null;
  rank: number | null;
  created_at: string;
  updated_at: string;
  sub_categories: any[];
  selected_sub_categories: any[] | null;
  image: string;
}

export interface Category {
  id: string;
  type: string;
  attributes: CategoryAttributes;
}

interface ShopCategoryResponse {
  data: Category[];
}

interface Image {
  id: number;
  date: string;
  url: string;
  type: string;
}

interface InspirationAttributes {
  id: number;
  heading: string;
  description: string;
  image: Image;
}

export interface InspirationDetail {
  id: string;
  type: string;
  attributes: InspirationAttributes;
}

interface Meta {
  message: string;
}

interface InspirationResponse {
  data: InspirationDetail[];
  meta: Meta;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  carouselItem: ICarouselItems[];
  isLoading: boolean;
  subjectList: ISubject[];
  isEmailAlreadyUsed: boolean;
  faqQuestionAnswer: IFaqQuestionAnswer[];
  whoWeAreData: IWhoWeAre[];
  whyChooseUsData: IWhyChooseUs[];
  howItWorkData: IHowItWorks[];
  errorMessage: string;
  realWeddingData: RealWedding
  realWeddingProfileData: RealWeddingProfile[];
  experienceData: ExperienceData[];
  contactUs: ContactUsShowdata;
  carouselSectionTitle: string;
  carouselSectionDescription: string;
  inspirationData: InspirationDetail[];
  shopCategories: Category[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      carouselItem: [],
      isLoading: false,
      subjectList: [],
      isEmailAlreadyUsed: false,
      faqQuestionAnswer: [],
      whoWeAreData: [],
      whyChooseUsData: [],
      howItWorkData: [],
      errorMessage: "",
      realWeddingData: {
        id: "",
        type: "",
        attributes: {
          id: 0,
          title: "",
          description: ""
        }
      },
      contactUs: {
        data: {
          id: "",
          type: "",
          attributes: {
            id: "",
            vendor_email: "",
            vendor_phone_number: "",
            vendor_country_code: "",
            vendor_full_phone_number: "",
            user_email: "",
            user_phone_number: "",
            user_country_code: "",
            user_full_phone_number: "",
            address: "",
            created_at: "",
            updated_at: ""
          }
        }
      },
      realWeddingProfileData: [],
      experienceData: [],
      carouselSectionTitle: '',
      carouselSectionDescription: '',
      inspirationData: [],
      shopCategories: [],
    };
    this.formikContactRef = React.createRef();
    this.formikNewSletterRef = React.createRef();
    this.redirectToRealWeddingItemsPage = this.redirectToRealWeddingItemsPage.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (webApiRequestCallId) {
        case this.carouselItemApiCallId:
          this.setState({
            carouselItem: webResponseJson.data || [],
            isLoading: false,
          });
          break;
        case this.subjectApiCallId:
          this.setState({
            subjectList: webResponseJson,
            isLoading: false,
          });
          break;
        case this.contactUsApiCallId:
          this.handleContactUsApiResponse(webResponseJson);
          break;
        case this.newSletterApiCallId:
          this.handleNewSletterApiResponse(webResponseJson);
          break;
        case this.faqQuestionAnswerApiCallId:
          this.setState({
            isLoading: false,
            faqQuestionAnswer: webResponseJson.data,
          });
          break;
        case this.whoWeAreApiCallId:
          this.setState({
            isLoading: false,
            whoWeAreData: webResponseJson.data,
          });
          break;
        case this.whyChooseUsApiCallId:
          this.setState({
            isLoading: false,
            whyChooseUsData: webResponseJson.data,
          });
          break;
        case this.howItWorkApiCallId:
          this.setState({
            isLoading: false,
            howItWorkData: webResponseJson.data,
          });
          break;
        case this.getRealWeddingDataCallId:
          this.setState({
            isLoading: false,
            realWeddingData: webResponseJson.data[0] || [],
          });
          break;
        case this.getRealWeddingProfileDataCallId:
          this.setState({
            isLoading: false,
            realWeddingProfileData: webResponseJson.data,
          });
          break;
        case this.getExperienceDataCallId:
          this.setState({
            isLoading: false,
            experienceData: webResponseJson.data,
          });
          break;
        case this.getEventContentDataCallId:
          this.setState({
            isLoading: false,
            carouselSectionTitle: webResponseJson.title,
            carouselSectionDescription: webResponseJson.description,
          });
        case this.inspirationApiCallId:
          this.handleInspirationResponse(webResponseJson);
          break;
        case this.getShopCategoriesCallId:
          this.handleShopCategoryResponse(webResponseJson);
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  formikContactRef: any;
  formikNewSletterRef: any;
  carouselItemApiCallId: string = "";
  contactUsApiCallId: string = "";
  faqQuestionAnswerApiCallId: string = "";
  subjectApiCallId: string = "";
  howItWorkApiCallId: string = "";
  newSletterApiCallId: string = "";
  whoWeAreApiCallId: string = "";
  whyChooseUsApiCallId: string = "";
  getRealWeddingDataCallId: string = "";
  getRealWeddingProfileDataCallId: string = "";
  getExperienceDataCallId: string = "";
  getEventContentDataCallId: string = "";
  inspirationApiCallId: string = "";
  getShopCategoriesCallId: string = "";

  async componentDidMount() {
    this.getCarouselItems();
    this.getSubjectList();
    this.getFaqQuestionAnswer();
    this.getWhoWeAreData();
    this.getWhyChooseUsData();
    this.getHowItWorkData();
    this.getRealWeddingData();
    this.getRealWeddingProfileData()
    this.getExperienceData();
    this.getEventContentData();
    this.getInspirationsData();
    this.getShopCategories();
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  redirectToVendorPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "VendorSignup"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  getCarouselItems = async () => {
    this.setState({ isLoading: true });
    this.carouselItemApiCallId = await apiCall({
      endPoint: "bx_block_upload_media/carousel_items",
      method: "GET",
      contentType: "application/json",
    });
  };
  getExperienceData = async () => {
    this.setState({ isLoading: true });
    this.getExperienceDataCallId = await apiCall({
      endPoint: "bx_block_landingpage2/why_choose_us/show_experience_metrics",
      method: "GET",
    });
  };

  getSubjectList = async () => {
    this.setState({ isLoading: true });
    this.subjectApiCallId = await apiCall({
      endPoint: "bx_block_contact_us/subjects",
      method: "GET",
      contentType: "application/json",
    });
  };

  contactUsApiIntegration = async (values: {
    name: string;
    email: string;
    subject_id: string;
    phone_number: string;
    description: string;
    phoneId: string;
  }) => {
    const header = {
      "Content-Type": "application/json",
    };
    const httpBody = {
      data: {
        name: values.name,
        email: values.email,
        subject_id: values.subject_id,
        phone_number: values.phone_number,
        description: values.description,
        country_code: values.phoneId
      },
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_contact_us/contacts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.contactUsApiCallId;
  }

  handleContactUsApiResponse = (webResponseJson: WebResponseJsonForContactUs) => {
    if (webResponseJson.data) {
      toast.success("Thank you for contacting us! We will get back to you shortly.");
      if (this.formikContactRef.current) {
        this.formikContactRef.current.resetForm();
      }
      this.setState({ errorMessage: "" });
    }
    if (webResponseJson.errors) {
      const errorMessage = webResponseJson.errors[0].contact[0];
      this.setState({ errorMessage });
    }
    this.setState({ isLoading: false });
  };


  newSletterApiIntegration = async (values: {
    name: string;
    email: string;
  }) => {
    this.setState({ isLoading: true });
    this.newSletterApiCallId = await apiCall({
      endPoint: "bx_block_contact_us/news_letters",
      method: "POST",
      contentType: "application/json",
      body: JSON.stringify({
        data: {
          name: values.name,
          email: values.email,
          subscribe: true,
        },
      }),
    });
  };

  handleNewSletterApiResponse = (webResponseJson: WebResponseJsonForNewSletter) => {
    if (webResponseJson.data) {
      toast.success("Thank you for subscribing to our newsletter.");
      if (this.formikNewSletterRef.current) {
        this.formikNewSletterRef.current.resetForm();
      }
      this.setState({ isEmailAlreadyUsed: false, errorMessage: "" });
    }
    if (webResponseJson.errors) {
      const errorMessage = webResponseJson.errors[0].news_letter[0];
      if (errorMessage === "Email has already been taken") {
        this.setState({ isEmailAlreadyUsed: true, errorMessage: "This email is already used for newsletter" });
      } else {
        this.setState({ isEmailAlreadyUsed: false, errorMessage });
      }
    }
    this.setState({ isLoading: false });
  };

  handleChangeIsEmailAlreadyUsed = () => {
    this.setState({ isEmailAlreadyUsed: false });
  };

  getFaqQuestionAnswer = async () => {
    this.setState({ isLoading: true });
    this.faqQuestionAnswerApiCallId = await apiCall({
      endPoint: `bx_block_landingpage2/faqs`,
      method: "GET",
    });
  };

  getWhoWeAreData = async () => {
    this.setState({ isLoading: true });
    this.whoWeAreApiCallId = await apiCall({
      endPoint: `bx_block_landingpage2/who_we_ares`,
      method: "GET",
    });
  };

  getWhyChooseUsData = async () => {
    this.setState({ isLoading: true });
    this.whyChooseUsApiCallId = await apiCall({
      endPoint: `bx_block_landingpage2/why_choose_us`,
      method: "GET",
    });
  };

  getHowItWorkData = async () => {
    this.setState({ isLoading: true });
    this.howItWorkApiCallId = await apiCall({
      endPoint: `bx_block_landingpage2/how_it_works`,
      method: "GET",
    });
  };

  getRealWeddingData = async () => {
    this.setState({ isLoading: true });
    this.getRealWeddingDataCallId = await apiCall({
      endPoint: `bx_block_content_management/real_events`,
      method: "GET",
    });
  };

  getRealWeddingProfileData = async () => {
    this.setState({ isLoading: true });
    this.getRealWeddingProfileDataCallId = await apiCall({
      endPoint: `bx_block_content_management/real_event_profiles`,
      method: "GET",
    });
  };

  getEventContentData = async () => {
    this.setState({ isLoading: true });
    this.getEventContentDataCallId = await apiCall({
      endPoint: `event_contents`,
      method: "GET",
    });
  };

  getInspirationsData = async () => {
    this.setState({
      isLoading: true,
    });
    this.inspirationApiCallId = await apiCall({
      endPoint: "bx_block_content_management/inspiration_details",
      method: "GET",
    });
  };

  getShopCategories = async () => {
    this.setState({ isLoading: true });
    this.getShopCategoriesCallId = await apiCall({
      endPoint: `bx_block_categories/categories?sort=priority`,
      method: "GET",
    });
  };

  handleShopCategoryResponse = (response: ShopCategoryResponse) => {
    if (response.data) {
      const categories = response.data.filter(category => category.attributes.priority !== null);
      this.setState({ shopCategories: categories });
    } else {
      this.setState({ shopCategories: [] });
    }
    this.setState({ isLoading: false });
  }

  redirectToRealWeddingItemsPage = (id: number) => {
    setStorageData("realWeddingId", id)
    this.props.navigation.navigate("RealWeddingItems", { id });
  };

  goToRealWeddingPage = () => {
    const sentMsg: Message = new Message(getName(MessageEnum.NavigationMessage));
    sentMsg.addData(getName(MessageEnum.NavigationTargetMessage), "RealWedding");
    sentMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(sentMsg);
  }

  handleInspirationResponse = (response: InspirationResponse) => {
    if (response.data) {
      this.setState({
        inspirationData: response.data,
      });
    }
    this.setState({ isLoading: false });
  }

  navigateToWeddingFun = () => {
    this.props.navigation.navigate("weddingFunction");
  }
  // Customizable Area End
}
