import * as React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    IconButton,
    Menu,
    styled,
    MenuItem,
    Grid,
    Checkbox, FormControlLabel,
    Button,
    useMediaQuery,
} from "@mui/material";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Loader from "../../../../components/src/AppLoader.web";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreVertIcon from '@mui/icons-material//MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HeaderFooterLayout from "../../../../components/src/HeaderFooterLayout";
import HeaderVendorFooter from "../../../../components/src/HeaderVendorFooter";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// Customizable Area End

import ReverseAuctionController from "../ReverseAuctionController";
import { Check } from "@mui/icons-material";


export default class ReverseAuctionEnquiry extends ReverseAuctionController {
    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
       
        const { Full_Name, Event, Venue, City, Sub_Event, Session, Category, Number_of_Guest, Comments, Budget
        } = this.state.enquiryData2

        const enq_id = localStorage.getItem('enquiry_id')
        const BookedOn = this.state.BookedOn
        const status = this.state.enquiryData.status   


        return (
            //Merge Engine DefaultContainer

            <>



                <HeaderVendorFooter navigation={this.props.navigation}>
                    <CustomBox>
                        <Box sx={{ padding: '0px 41px', width: '100%' }}>
                            <Box
                                display="flex"
                                alignItems="center"
                                padding="10px 0px"
                                sx={{ marginBottom: '10px' }}
                            >
                               
                                <IconButton sx={{ marginRight: "12px" }}>
                                    <ArrowBackIosIcon sx={{ color: "black", fontSize: "24px", fontWeight: '600' }} onClick={this.openReverseAuctionList} />
                                </IconButton>

                               
                                <Typography variant="h5" fontWeight="bold" sx={{ fontSize: "24px", fontWeight: '600', lineHeight: '32px', paddingTop: '5px' }}>
                                    Enquiry
                                </Typography>
                            </Box>


                            <Box sx={{ width: "100%", overflowX: "auto", borderRadius: "12px", border: "1px solid #d3d3d3", borderColor: '#D2D2D2', padding: '30px',backgroundColor:'white' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '22px' }}>
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontWeight: 600,
                                            fontSize: "24px",
                                            lineHeight: "32px",
                                        }}
                                    >
                                        Enquires
                                    </Typography>
                                    <Button
                                     data-test-id="btnstatus"
                                        sx={{
                                          
                                            height: "32px",
                                            borderRadius: "8px",
                                            padding: "8px",
                                            backgroundColor: status === "pending" ? "#DC26261C" : "#0596691C",
                                            textTransform: "none",
                                            "&:hover": {
                                                backgroundColor: status === "pending" ? "#DC26261C" : "#0596691C" 
                                            },
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "16px",
                                                lineHeight: "normal",
                                               
                                                color: status === "pending" ? "red" : "#059669",
                                            }}
                                        >
                                            {status}
                                        </Typography>
                                    </Button>
                                </Box>

                              
                                <Box
                                    className="reviewDescription"
                                >
                                    <Box
                                        className="reviewDescription2"
                                        sx={{ width: { xs: "100%", sm: "23%" }, }}
                                    >
                                        <Typography
                                          className="typography1"
                                        >
                                            Buyer Name
                                        </Typography>
                                    </Box>
                                    <Box sx={{ height: "24px" }}>
                                        <Typography
                                           sx={{fontFamily:'poppins',fontWeight:'600',fontSize:'20px',lineHeight:'24px'}}
                                        >
                                            {Full_Name}
                                        </Typography>
                                    </Box>
                                </Box>

                               
                                <Box
                                    className="reviewDescription"
                                >
                                    <Box
                                        className="reviewDescription2"
                                        sx={{ width: { xs: "100%", sm: "23%" }, }}
                                    >
                                        <Typography
                                            className="typography1"
                                        >
                                            Event
                                        </Typography>
                                    </Box>
                                    <Box sx={{ height: "24px" }}>
                                        <Typography
                                           className="typography2"
                                        >
                                            {Event}
                                        </Typography>
                                    </Box>
                                </Box>
                               
                                <Box className="reviewDescription">
                                    <Box
                                        className="reviewDescription2"
                                        sx={{ width: { xs: "100%", sm: "23%" }, }}
                                    >
                                        <Typography
                                            className="typography1"
                                        >
                                            Event Date
                                        </Typography>
                                    </Box>
                                    <Box sx={{ height: "24px" }}>
                                        <Typography
                                           className="typography2"
                                        >
                                            {this.state.enquiryData2["Event_Date_(Date_range_can_be_selected)"]}
                                        </Typography>
                                    </Box>
                                </Box>

                              
                                <Box className="reviewDescription">
                                    <Box
                                        className="reviewDescription2"
                                        sx={{ width: { xs: "100%", sm: "23%" }, }}
                                    >
                                        <Typography
                                            className="typography1"
                                        >
                                            Venue
                                        </Typography>
                                    </Box>
                                    <Box sx={{ height: "24px" }}>
                                        <Typography
                                             className="typography2"
                                        >
                                            {Venue}
                                        </Typography>
                                    </Box>
                                </Box>

                             
                                <Box className="reviewDescription">
                                    <Box
                                        className="reviewDescription2"
                                        sx={{ width: { xs: "100%", sm: "23%" }, }}
                                    >
                                        <Typography
                                            className="typography1"
                                        >
                                            City
                                        </Typography>
                                    </Box>
                                    <Box sx={{ height: "24px" }}>
                                        <Typography
                                            className="typography2"
                                        >
                                            {City}
                                        </Typography>
                                    </Box>
                                </Box>

                              
                                <Box className="reviewDescription">
                                    <Box
                                        className="reviewDescription2"
                                        sx={{ width: { xs: "100%", sm: "23%" }, }}
                                    >
                                        <Typography
                                           className="typography1"
                                        >
                                            Sub Event
                                        </Typography>
                                    </Box>
                                    <Box sx={{ height: "24px" }}>
                                        <Typography
                                            className="typography2"
                                        >
                                            {Sub_Event}
                                        </Typography>
                                    </Box>
                                </Box>

                               
                                <Box className="reviewDescription">
                                    <Box
                                        className="reviewDescription2"
                                        sx={{ width: { xs: "100%", sm: "23%" }, }}
                                    >
                                        <Typography
                                            className="typography1"
                                        >
                                            Session
                                        </Typography>
                                    </Box>
                                    <Box sx={{ height: "24px" }}>
                                        <Typography
                                            className="typography2"
                                        >
                                            {Session}
                                        </Typography>
                                    </Box>
                                </Box>

                               
                                <Box className="reviewDescription">
                                    <Box
                                        className="reviewDescription2"
                                        sx={{ width: { xs: "100%", sm: "23%" }, }}
                                    >
                                        <Typography
                                           className="typography1"
                                        >
                                            Category
                                        </Typography>
                                    </Box>
                                    <Box sx={{ height: "24px" }}>
                                        <Typography
                                            className="typography2"
                                        >
                                            {Category}
                                        </Typography>
                                    </Box>
                                </Box>

                               
                                <Box className="reviewDescription">
                                    <Box
                                        className="reviewDescription2"
                                        sx={{ width: { xs: "100%", sm: "23%" }, }}
                                    >
                                        <Typography
                                            className="typography1"
                                        >
                                            Food & Beverages
                                        </Typography>
                                    </Box>
                                    <Box sx={{ height: "24px" }}>
                                        <Typography
                                           className="typography2"
                                        >
                                           South Indian , Jain foods
                                        </Typography>
                                    </Box>
                                </Box>


                               
                                <Box className="reviewDescription">
                                    <Box
                                        className="reviewDescription2"
                                        sx={{ width: { xs: "100%", sm: "23%" }, }}
                                    >
                                        <Typography
                                            className="typography1"
                                        >
                                            Number of Guest
                                        </Typography>
                                    </Box>
                                    <Box sx={{ height: "24px" }}>
                                        <Typography
                                            className="typography2"
                                        >
                                            {Number_of_Guest}
                                        </Typography>
                                    </Box>
                                </Box>


                               
                                <Box className="reviewDescription">
                                    <Box
                                        className="reviewDescription2"
                                        sx={{ width: { xs: "100%", sm: "23%" }, }}
                                    >
                                        <Typography
                                            className="typography1"
                                        >
                                            Comments
                                        </Typography>
                                    </Box>
                                    <Box sx={{ height: "24px" }}>
                                        <Typography
                                           className="typography2"
                                        >
                                            {Comments}
                                        </Typography>
                                    </Box>
                                </Box>

                               
                                <Box className="reviewDescription">
                                    <Box
                                        className="reviewDescription2"
                                        sx={{ width: { xs: "100%", sm: "23%" }, }}
                                    >
                                        <Typography
                                            className="typography1"
                                        >
                                            Booked On
                                        </Typography>
                                    </Box>
                                    <Box sx={{ height: "24px" }}>
                                        <Typography
                                            className="typography2"
                                        >
                                            {BookedOn}
                                        </Typography>
                                    </Box>
                                </Box>

                               
                                <Box className="reviewDescription">
                                    <Box
                                        className="reviewDescription2"
                                        sx={{ width: { xs: "100%", sm: "23%" }, }}
                                    >
                                        <Typography
                                            className="typography1"
                                        >
                                            Budget
                                        </Typography>
                                    </Box>
                                    <Box sx={{ height: "24px" }}>
                                        <Typography
                                            className="typography2"
                                        >
                                            {Budget}
                                        </Typography>
                                    </Box>
                                </Box>

                               
                                <Box className="reviewDescription">
                                    <Box
                                        className="reviewDescription2"
                                        sx={{ width: { xs: "100%", sm: "23%" }, }}
                                    >
                                        <Typography
                                           className="typography1"
                                        >
                                            Advance (25%)
                                        </Typography>
                                    </Box>
                                    <Box sx={{ height: "24px" }}>
                                        <Typography
                                             className="typography2"
                                        >
                                           75,5000
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box sx={{ marginTop: '24px' }}>
                                    <FormControlLabel
                                        control={                                           

                                            <Checkbox
                                            icon={
                                                <span
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                        borderRadius: 5,
                                                        border: "2px solid #999",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                />
                                            }
                                            checkedIcon={
                                                <span
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                        borderRadius: 4,
                                                        backgroundColor: "#6A1B9A",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        color: "white",
                                                    }}
                                                >
                                                    <Check fontSize="small" style={{ color: "white" }} />
                                                </span>
                                            }
                                        />
                                        }
                                        label={
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                    color: "#1E1E1E",
                                                }}
                                            >
                                                I agree with Terms and Conditions
                                            </Typography>
                                        }
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '24px' }}>
                                    <Button
                                     data-test-id="btnReject"
                                        sx={{
                                            width: "180px",
                                            height: "44px",
                                            padding: "10px 16px",
                                            border: "1px solid #7F1187",
                                            backgroundColor: "white",
                                            color: "#7F1187", 
                                            borderRadius: "30px", 
                                            "&:hover": {
                                                backgroundColor: "#F5E6F7", 
                                            },
                                        }}
                                        onClick={() => this.handleReject()}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: "Poppins",
                                                fontSize: "16px",
                                                fontWeight: 500,
                                                color: "#7F1187",
                                                textTransform: "none", 
                                            }}
                                        >
                                            Reject
                                        </Typography>
                                    </Button>
                                    <Button
                                       data-test-id="btnAccept"
                                        onClick={() => this.handleAccept()}
                                        sx={{
                                            width: "180px",
                                            height: "44px",
                                            padding: "10px 16px",
                                            border: "1px solid #7F1187",
                                            backgroundColor: "#7F1187", 
                                            color: "white", 
                                            borderRadius: "30px",
                                            "&:hover": {
                                                backgroundColor: "#65106D", 
                                            },
                                        }}

                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: "Poppins",
                                                fontSize: "16px",
                                                fontWeight: 500,
                                                color: "white", 
                                                textTransform: "none",
                                            }}
                                        >
                                            Accept
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </CustomBox>
                </HeaderVendorFooter>




            </>


            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
};

// Customizable Area Start
const CustomBox = styled(Box)({

    "& .reviewDescription": {
        display: "flex",
        flexDirection: { xs: "column", sm: "row" }, 
        alignItems: { xs: "flex-start", sm: "center" },
        marginTop: '16px'
    },
    "& .reviewDescription2": {
        width: { xs: "100%", sm: "23%" }, 
        display: "flex",
        height: "22px",
        color: "#78716C",
    },
    "& .typography1": {
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "16px", 
        color: "#78716C",
        lineHeight:'22px'
    },
    "& .typography2": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "24px",
        color: '#292524'
    }

})
// Customizable Area End