import React from "react";

import { Box, Typography, styled } from "@mui/material";
import Loader from "./AppLoader.web";
import Carousel from "react-multi-carousel";
const LoginImage = require("./assets/LoginImage_.jpg");
const image1 = require("./assets/image1.png");
const image5 = require("./assets/image5.png");
const image6 = require("./assets/image6.png");
const image10 = require("./assets/image10.png");
const image15 = require("./assets/image15.png");
const image18 = require("./assets/image18.png");
const image19 = require("./assets/image19.png");
const image20 = require("./assets/image20.png");

const LoginEmailBoxTextCustom = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "100%",
  minHeight: "100vh",
  [theme.breakpoints.down("md")]: {
    display:'block'
  },
}));

const LoginEmailBox = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "50%",
  height: "100%",
  overflow:"hidden",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  "& .react-multi-carousel-list":{
    height:"100%",
    alignItems:"normal"
  },
  "& .react-multi-carousel-dot-list":{
  bottom:"8%"
  },
  "& .react-multi-carousel-dot button":{
    borderColor:"transparent",
    background: "#78716C",
    zIndex:2,
    position:"relative"
  },
  "& .react-multi-carousel-dot--active button":{
    background:"rgb(255,255,255) !important",
    width:"17px",
    height:"17px",
    zIndex:2,
    position:"relative"
  },
  "& .login-main-image": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
  "& .login-overlay-image": {
    backgroundColor: "#0000007A",
    position: "absolute",
    inset: "0",
  },
  "& .forward-icon":{
   top:"50%",
   position:"absolute",
   backgroundColor:"white",
   borderRadius:"50%",
   padding:"10px"
  },
  "& .forward":{
    right:"5%",
  },
  "& .backward":{
    left:"5%",
  },
  "& .login-email-text-container": {
    position: "absolute",
    color: "#FFFFFF",
    textAlign: "center",
    zIndex: 1,
    left: "20px",
    bottom: "15%",
    right: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "& .login-email-main-text-1": {
      fontFamily: "Poppins, sans-serif",
      fontWeight: 700,
      fontSize: "38px",
      lineHeight: "46px",
      marginBottom: "18px",
      maxWidth: "550px",
      [theme.breakpoints.down("md")]: {
        fontSize: "34px",
        lineHeight: "44px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "30px",
        lineHeight: "38px",
        maxWidth: "430px",
      },
    },
    "& .login-email-main-text-2": {
      margin: 0,
      fontFamily: "Poppins, sans-serif",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "20px",
      color: "#ffffff",
      width: "100%",
      maxWidth: "360px",
    },
  },
}));
const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};
interface ILoginSideImageWrapperProps {
  children: React.ReactNode;
  isLoading: boolean;
  vendorLogin?: boolean;
  carouselImages?:any
}

const LoginSideImageWrapper: React.FC<ILoginSideImageWrapperProps> = ({
  children,
  isLoading,
  vendorLogin,
  carouselImages
}) => {
  
  return (
    <LoginEmailBoxTextCustom>
      <Loader isLoading={isLoading} />
      <LoginEmailBox>
        <Carousel
          data-test-id="carousel"
          className="carousel-container"
          swipeable={false}
          draggable={false}
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          arrows={false}
          keyBoardControl={true}
          partialVisible={true}
          customTransition="all 0.5"
          transitionDuration={500}
          showDots={true}
          dotListClass="custom-dot-list-style"
        >
          {carouselImages?.map((data:any,index:number) => {
            return (
              <>
                {data?.attributes.img_vid_url.type === "image" ?
                  <img src={data.attributes.img_vid_url.url} key={index} alt="wedding" className="login-main-image" />
                  :
                  <video autoPlay className="login-main-image" muted >
                    <source src={data.attributes.img_vid_url.url} type="video/mp4" />
                  </video>
                }
                <Box className="login-overlay-image" />
                <Box className="login-email-text-container">
                  <Typography className="login-email-main-text-1">
                    {data.attributes.title}
                  </Typography>
                  <Typography className="login-email-main-text-2">
                    {data.attributes.description}
                  </Typography>
                </Box>
              </>
            );
          })}
        </Carousel>
      </LoginEmailBox>
      {children}
    </LoginEmailBoxTextCustom>
  );
};

export default LoginSideImageWrapper;
