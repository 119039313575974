import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { CometChatUIKit, UIKitSettingsBuilder } from "@cometchat/chat-uikit-react";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  token: string;
  loggedInUser: any | null;
  role: string;
  cometChatUID: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: true,
      token: "",
      loggedInUser: null,
      role: "",
      cometChatUID: "",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let role: string = await getStorageData("role");
    const user: any = await getStorageData("user");
    const userData = JSON.parse(user);
    if (role && userData) {
      this.setState({ role: role.toLocaleLowerCase(), cometChatUID: userData.comet_chat_id }, () => {
        this.initializeCometChat();
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  async initializeCometChat() {
    try {
      const uiKitSettings = new UIKitSettingsBuilder()
        .setAppId(configJSON.APP_ID)
        .setRegion(configJSON.REGION)
        .setAuthKey(configJSON.AUTH_KEY)
        .subscribePresenceForAllUsers()
        .build();

      // Initialize the UI Kit
      await CometChatUIKit.init(uiKitSettings);
      console.log("CometChat UIKit Initialization completed successfully");
      this.checkAndLogin();

    } catch (error) {
      console.log("CometChat Initialization failed with error:", error);
      this.setState({ isLoading: false });
    }
  }

  checkAndLogin = async () => {
    this.setState({ isLoading: true });
    const uid = this.state.cometChatUID;

    const loggedInUser: any = await CometChatUIKit.login(uid);
    console.log("Login successful, loggedInUser:", loggedInUser);
    this.setState({ isLoading: false });
  };

  async componentWillUnmount() {
  }
  // Customizable Area End
}
