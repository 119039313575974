import React from "react";

import {
  Box,
  // Customizable Area Start
  Card, CardContent, Typography, Button, IconButton
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import CloseIcon from "@mui/icons-material/Close";
const MainBanner = require("../assets/Banner.svg");
import { ratingIcon, stackIcon } from "./assets";
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";

// Customizable Area End

import UserrecommendationController, {
  Props,
  configJSON,
} from "./UserrecommendationController";


export default class Userrecommendation extends UserrecommendationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <HeaderFooterLayout navigation={this.props.navigation}>
          <Box sx={webStyle.banner}>
            <Box sx={{ position: "relative", textAlign: "center", top: "133px" }}>
              <Typography sx={webStyle.bannerTitle}>{configJSON.bannerTitle}</Typography>
              <Typography sx={webStyle.bannerDescript}>{configJSON.bannerDescript}</Typography>
            </Box>
          </Box>
          <Box sx={webStyle.container}>
            <Typography sx={{fontSize:"36px"}} variant="h4" mt={4}>
              {configJSON.weddingCor}
            </Typography>
            <Typography sx={{fontSize:"16px"}}>
              {configJSON.weddingCorTwo} <span style={{ color: "#7F1187", fontWeight: 600, marginRight: "4px" }}> {configJSON.searchResult}</span>{configJSON.searchResultTwo}
            </Typography>


            <Box sx={webStyle.cardContainer}>
              {this.state.vendors.map((vendor: any, index: number) => (
                <Card key={index} sx={webStyle.card}>
                  <img src={vendor.imgURL} alt={vendor.name} />
                  <IconButton sx={webStyle.heartIcon}>
                    <FavoriteBorderIcon sx={{ color: "black" }} />
                  </IconButton>
                  <Button onClick={() => this.handleCompare(vendor)} sx={webStyle.compareButton} >
                    {configJSON.compareText}
                  </Button>


                  <CardContent sx={webStyle.cardContent}>

                    <Box sx={webStyle.nameRatingContainer}>
                      <Typography sx={webStyle.venueName}>{vendor.name}</Typography>
                      <Box sx={webStyle.ratingContainer}>
                        <img src={ratingIcon} style={webStyle.starIcon} />
                        <Typography sx={webStyle.ratingText}>{vendor.rating}</Typography>
                      </Box>
                    </Box>

                    <Box>
                      <Typography sx={webStyle.location}>{vendor.location}</Typography>
                    </Box>


                    <Box sx={webStyle.priceContainer}>
                      <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src={stackIcon} style={webStyle.starIcon} />
                        <Typography sx={webStyle.location}>
                          {configJSON.package}
                          <Typography component="span" sx={webStyle.amount}>
                            {vendor.price}
                          </Typography>
                        </Typography>
                      </span>

                      <Button sx={webStyle.chatButton}>{configJSON.chatNow}</Button>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>
          <Box sx={webStyle.loadMoreContainer}>
            {
              this.state.btnEnable && (
                                           <Button
                                                data-test-id="btn_click"
                                                sx={webStyle.button}
                                                
                                                onClick={()=>this.handleNavigation()}                                                
                                            >
                                                <Typography                                                  
                                                     sx={webStyle.button_text}
                                                >
                                                   {this.state.currentStage}
                                                </Typography>
                                            </Button>
               
              )
            }
           
          </Box>




        </HeaderFooterLayout>
        {this.state.selectedVendors.length > 0 && (
          <Box sx={webStyle.selectedVendorsContainer}>
            <Box sx={webStyle.vendorGrid}>
              {this.state.selectedVendors.map((vendor) => (
                <Box key={vendor.id} sx={webStyle.selectedVendorCard}>

                  <img style={{ width: "100%" }} src={vendor.imgURL} alt={vendor.name} />
                  <Typography sx={webStyle.selectedVendorName}>{vendor.name}</Typography>
                  <CloseIcon
                    sx={webStyle.closeIcon}
                    onClick={() => this.removeVendor(vendor.id)}
                  />

                </Box>
              ))}
            </Box>


            <Box sx={webStyle.compareSection}>
              <Typography sx={webStyle.compareTitle}>{configJSON.selectVenue} <br />{configJSON.toCompare}</Typography>
              <Box sx={webStyle.compareButtonContainer}>
                <Button sx={webStyle.compareButtonCheck}>{configJSON.compareText}</Button>
              </Box>

            </Box>
          </Box>
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    maxWidth: "1200px",
    margin: "auto",
    textAlign: "center",
    padding: "20px",
  },
  banner: {
    background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 99.8%), url(${MainBanner}) no-repeat center center`,
    position: "relative",
    height: "400px",
    backgroundColor: "#e0e0e0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    color: "ffffff"
  },
  subtitle: {
    color: "ffffff",
    fontSize: "1.2rem",
  },
  cardContainer: {
    display: "grid",
    gap: "20px",
    marginTop: "20px",
    gridTemplateColumns: "repeat(3, 1fr)",
    "@media (max-width:960px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media (max-width:600px)": {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  card: {
    position: "relative",
    paddingBottom: "10px",
    borderRadius: "10px",
  },
  cardMedia: (bgColor: string) => ({
    height: 200,
    backgroundColor: bgColor,
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  }),
  heartIcon: {
    position: "absolute",
    top: 10,
    left: 10,
    backgroundColor: "#fff",
    borderRadius: "50%",
    "&:hover": {
      background: "#fff",
    },
  },
  compareButton: {
    textTransform: "capitalize",
    color: "#000000",
    position: "absolute",
    top: 10,
    right: 10,
    backgroundColor: "#fff",
    padding: "5px 10px",
    fontSize: "12px",
    borderRadius: "30px",
    "&:hover": {
      background: "#fff",
    },
  },
  cardContent: {
    textAlign: "left",
    padding: "16px",
  },
  vendorName: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  location: {
    fontSize: "14px",
    color: "#757575",
  },

  nameRatingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
  },

  venueName: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#333",
  },

  ratingContainer: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },

  starIcon: {
    color: "#FFB400",
    fontSize: "18px",
  },

  ratingText: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#555",
  },

  ratingIcon: {
    fontSize: "16px",
    color: "#FFA000",
    marginRight: "4px",
  },
  price: {
    fontWeight: "bold",
    fontSize: "16px",
    marginTop: "8px",
  },
  chatButton: {
    backgroundColor: "ffff",
    color: "#7F1287",
    textTransform: "none",
    fontWeight: 700,
    fontSize: "14px",
    borderRadius: "8px",
    padding: "5px 12px",
    "&:hover": {
      backgroundColor: "#ffff",
    },
    border: "2px solid #7F1287",
  },

  priceContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "8px",
  },

  loadMoreContainer: {
    width: "91%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },

  loadMoreButton: {
    width: "11%",
    backgroundColor: "#7F1287",
    color: "#fff",
    textTransform: "none",
    fontSize: "14px",
    borderRadius: "25px",
    padding: "10px 24.8px"
  },

  selectedVendorsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "16px",
    background: "white",
    boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
    position: "sticky",
    bottom: 0,
    zIndex: 1000,
  },

  vendorGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "12px",
    flexGrow: 1,
  },

  selectedVendorCard: {
    position: "relative",
    background: "white",
    borderRadius: "8px",
    overflow: "hidden",
    padding: "12px", textAlign: "center",
  },


  selectedVendors: {
    display: "flex",
    gap: "10px",
    overflowX: "auto",
    padding: "10px 0",
  },

  selectedVendorImage: {
    width: "100%",
    height: "120px",
    objectFit: "cover",
    borderRadius: "8px",
  },

  selectedVendorName: {
    position: "relative",
    bottom: "47px",
    right: "0px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#FAFAF9",
    marginTop: "8px",
  },

  removeButton: {
    position: "absolute",
    top: "5px",
    right: "5px",
    backgroundColor: "white",
    border: "none",
    fontSize: "14px",
    padding: "2px 6px",
    cursor: "pointer",
    borderRadius: "50%",
    boxShadow: "0 1px 3px rgba(0,0,0,0.3)",
  },

  compareButtonBottom: {
    backgroundColor: "#800080",
    color: "#fff",
    padding: "10px 20px",
    borderRadius: "20px",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "bold",
    border: "none",
  },

  closeIcon: {
    position: "absolute",
    top: "22px",
    right: "22px",
    color: "#FFFFFF",
    cursor: "pointer",
    fontSize: "20px",
  },

  compareSection: {
    gap: "5rem",
    flexDirection: "column",
    width: "35%",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    textAlign: "center",
    padding: "12px",
    background: "white",
  },

  compareTitle: {
    fontSize: "25px",
    fontWeight: "600",
    color: "#333",
    textAlign: "left",
    marginBottom: "auto",
  },

  compareButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "auto",
  },

  compareButtonCheck: {
    width: "56%",
    borderRadius: "25px",
    background: "#800080",
    color: "white",
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "600",
    textTransform: "none",
    "&:hover": {
      background: "#660066",
    },
  },

  amount: {
    marginLeft: "4px",
    fontSize: "14px",
    fontWeight: 700,
    color: "#F87171"
  },

  bannerTitle: {
    color:"#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "40px",
    lineHeight: "52px",
    letterSpacing: "0%",
    textAlign: "center",
    textTransform: "capitalize",
  },
  bannerDescript: {
    color:"#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "34px",
    letterSpacing: "0%",
    textAlign: "center",
    textTransform: "capitalize",
  },
  button: {
    marginTop: '25px',
    width: "180px",
    height: "44px",
    padding: "10px 16px",
    border: "1px solidrgb(199, 182, 201)",
    backgroundColor: "#7F1187",
    color: "#7F1187",
    borderRadius: "30px",
    "&:hover": {
        backgroundColor: "7F1187",
    }
  },
button_text:{
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 500,
  color: "#fff",
  textTransform: "none"
}
  


};
// Customizable Area End
