Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfservicesrecommendation";
exports.labelBodyText = "cfservicesrecommendation Body";
exports.btnExampleTitle = "CLICK ME";
exports.bannerTitle = "Turning Moments Into Magical Memories";
exports.bannerDescript="Where Forever Begins in Style";
exports.searchResult="60 Decor Vendors";
exports.searchResultTwo="As Per Your Search Criteria";
exports.weddingCor="Wedding Decor";
exports.weddingCorTwo="Showing";
exports.compareText ="Compare";
exports.chatNow="Chat Now";
exports.stage ="Stage 2";
exports.package="Package start @";
exports.selectVenue ="Select Upto 3 Venues";
exports.toCompare="To Compare";
exports.viewMore="VIEW MORE"
exports.getAllCategory ="/account_block/services"
exports.getAllSubCategory = "/account_block/service_sub_categories"
// Customizable Area End