import React, { createContext, useReducer, ReactNode } from "react";
import { appReducer, defaultAppState } from "./appReducer";

export const AppContext = createContext({
    appState: defaultAppState,
    setAppState: (action: any) => { },
});

interface AppContextProviderProps {
    children: ReactNode;
}

export const AppContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {
    const [appState, setAppState] = useReducer(appReducer, defaultAppState);

    return (
        <AppContext.Provider
            value={{
                appState,
                setAppState,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
