import React from "react";

// Customizable Area Start
import { Box, Typography, styled, Rating, Button, Grid, Modal, InputLabel, TextField } from "@mui/material";
// Customizable Area End

import MyPurchasesController, { OrderItemData, Props } from "./MyPurchasesController";
import HeaderFooterLayout from "../../../../components/src/HeaderFooterLayout";
import { productDefaultImage } from "../../../../blocks/categoriessubcategories/src/assets";
import moment from "moment";
import { AiOutlineClose } from "react-icons/ai";
import Loader from "../../../../components/src/AppLoader.web";

export default class MyPurchases extends MyPurchasesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderProduct = (data: OrderItemData, orderData: string) => {
        const product = data.attributes.product.attributes;
        const variantImage = product.product_variants.attributes.product_color.attributes?.color_image;
        const variantColor = product.product_variants.attributes.product_color.attributes?.name;
        const variantSize = product.product_variants.attributes.product_size.attributes?.size_label;

        // set product image
        let productImage = productDefaultImage;
        if (variantImage) {
            productImage = variantImage;
        }


        return (
            <Grid width={"100%"} container spacing={3}>
                <Grid xl={2} lg={2} md={2} sm={12} xs={12} item>
                    <img style={{ width: "100%", height: "100%", maxHeight: "250px", borderRadius: "8px", objectFit: "cover" }} src={productImage} />
                </Grid>
                <Grid xl={8} lg={8} md={8} sm={12} xs={12} item flexDirection={"column"} display={"flex"} justifyContent={"space-between"}>
                    <Box>
                        <Typography className="productTitle">{product.name}</Typography>
                        {product.vendor &&
                            <Typography className="vendorText">
                                Kothari brothers
                                <Typography className="locationText" component={"span"}>Mumbai</Typography>
                            </Typography>
                        }
                        <Typography className="orderDate">
                            Order Date : {moment(orderData).format("DD MMM, YYYY")}
                        </Typography>
                        <Box marginBottom={"20px"} columnGap={"20px"} display={"flex"} flexDirection={"row"}>
                            <Typography className="productSize" component={"span"}>Size:
                                <Typography component={"span"} style={{ color: "#000", fontWeight: 600, marginLeft: "10px" }}>{variantSize}</Typography>
                            </Typography>
                            <Typography className="productSize" component={"span"}>Color:
                                <Typography component={"span"} style={{ color: "#000", fontWeight: 600, marginLeft: "10px" }}>{variantColor}</Typography>
                            </Typography>
                        </Box>
                        <Typography className="productPrice">
                            ₹ {product.price}
                        </Typography>
                    </Box>
                    <Box paddingBottom={"15px"}>
                        <Typography component={"h6"} className="reviewTitle">Write a Ratings</Typography>
                        <Box width={"100%"} display={"flex"} columnGap={"30px"} flexDirection={"row"} alignItems={"center"}>
                            <Rating onClick={() => this.handleOpenReviewModal(product.id)} value={0} precision={0.5} max={10} size="medium" />
                            <Typography component={"span"} className="outOfText">out of -/10</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid xl={2} lg={2} md={2} sm={12} xs={12} item display={"flex"} justifyContent={"end"}>
                    <Box className="buttonsGroup">
                        <Button onClick={() => this.handleRedirectProduct(product.id)} className="buttonStyle">Buy again</Button>
                    </Box>
                </Grid>
            </Grid>
        );
    }
    // Customizable Area End
    render() {
        const {
            isLoading,
            currentPath,
            orders,
            openReviewModal,
            reviewComment,
            reviewCommentError,
            reviewCommentMsg,
            reviewRating,
            reviewRatingError,
            reviewRatingMsg,
        } = this.state;

        return (
            // Customizable Area Start
            <HeaderFooterLayout navigation={this.props.navigation}>
                <StyleWrapper>
                    <Grid width={"100%"} container marginBottom={"40px"}>
                        <Grid item xs={12} className="headerGrid">
                            <Typography data-test-id="handleGotoMyBookings" component={"h3"} className={currentPath === '/mybookings' ? "titleActive" : "mainTitle"} onClick={this.handleGotoMyBookings} >My Bookings</Typography>
                            <Typography data-test-id="handleGotoMyPurchases" component={"h3"} className={currentPath === '/mypurchases' ? "titleActive" : "mainTitle"} onClick={this.handleGotoMyPurchases} >My Purchases</Typography>
                        </Grid>
                    </Grid>
                    {orders && orders.length > 0 ?
                        <>
                            {orders.map((item) => {
                                const products = item.attributes.order_items;
                                return (
                                    <Box key={item.id} border={"1px solid #D9D9D9"} width={"100%"} borderRadius={"12px"} padding={"20px"} marginBottom={"30px"}>
                                        {products.map((product, index) => {
                                            return (
                                                <Box key={product.id}>
                                                    {this.renderProduct(product, item.attributes.placed_at)}
                                                    {products.length - 1 !== index && <hr style={{ margin: "30px 0px", borderTop: "1px solid #D9D9D9" }} />}
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                )
                            })}
                        </>
                        :
                        <Box border={"1px solid #D9D9D9"} width={"100%"} height={"70vh"} borderRadius={"12px"} padding={"20px"} marginBottom={"30px"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Typography>No purchase history found.</Typography>
                        </Box>
                    }
                </StyleWrapper >

                {/* review modal */}
                <Modal
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    open={openReviewModal}
                    onClose={this.handleCloseReviewModal}
                    aria-labelledby="review-modal-title"
                    aria-describedby="review-modal-description"
                >
                    <Box style={modalStyle.mainContainer}>
                        <Box style={modalStyle.header}>
                            <Typography style={modalStyle.applySizeChartTitle}>Write a review </Typography>
                            <Typography datat-test-id="modalCloseButton" style={{ ...modalStyle.applySizeChartTitle, ...{ cursor: 'pointer' } }} onClick={() => this.handleCloseReviewModal()}><AiOutlineClose /> </Typography>
                        </Box>
                        <Box width={"100%"} overflow={"auto"}>
                            <CustomReviewForm>
                                <Grid container spacing={3} width={"100%"} padding={"30px"}>
                                    <Grid item xs={12}>
                                        <InputLabel sx={{ marginBottom: "10px", color: "#000", fontSize: "16px" }}>Rate product</InputLabel>
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                                            <Rating data-test-id="ratingStarChange" value={reviewRating} max={10} precision={0.5} onChange={this.handleRatingChange} size="medium" />
                                            <Typography className="outOfText">out of -/10</Typography>
                                        </Box>
                                        {reviewRatingError && <Typography style={{ color: "#d32f2f", fontSize: "12px" }}>{reviewRatingMsg}</Typography>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel sx={{ marginBottom: "10px", color: "#000", fontSize: "16px" }}>Comment</InputLabel>
                                        <TextField
                                            style={{ width: "100%" }}
                                            name="reviewComment"
                                            placeholder="Share about your experinece"
                                            data-test-id="commentInputChange"
                                            variant="outlined"
                                            value={reviewComment}
                                            onChange={this.handleCommitInputChange}
                                            error={reviewCommentError}
                                            helperText={reviewCommentMsg}
                                            multiline={true}
                                            rows={3}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button data-test-id="submitReviewButton" className="proceedBtn" type="button" onClick={this.handleSubmitReview}>
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CustomReviewForm>
                        </Box>
                    </Box>
                </Modal>
                <Loader isLoading={isLoading} />
            </HeaderFooterLayout>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const modalStyle = {
    mainContainer: {
        background: '#FFF',
        width: '600px',
        minHeight: '300px',
        borderRadius: '10px',
        overflow: 'hidden',
    },
    header: {
        height: "max-content",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "24px",
        borderBottom: "1px solid #D6D3D1",
    },
    applySizeChartTitle: {
        fontFamily: 'Poppins',
        fontSize: '20px',
        lineHeight: '22px',
        fontWeight: 600,
        color: '#292524',
    },
}

const CustomReviewForm = styled(Box)({
    marginBottom: "16px",
    "& .common-form-label": {
        fontFamily: "Poppins, sans-serif",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "22px",
        color: "#334155",
        marginBottom: "4px",
        display: "block",
        "& span": {
            color: "#DC2626",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "22px",
        },
    },
    "& .MuiInputBase-input": {
        padding: "10.5px 8px ",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        color: "#0F172A",
    },
    "& .MuiInputBase-input::placeholder": {
        color: "#A8A29E",
        opacity: 1,
    },
    "& .MuiInputBase-root.Mui-error": {
        borderRadius: "8px",
    },
    "& .MuiInputBase-root": {
        borderRadius: "0px",
        backgroundColor: "#FFFFFF",
    },
    "& .MuiFormHelperText-root": {
        color: "#DC2626",
        margin: "8px 0 0",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "18px",
    },
    "& .Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F87171 !important",
        borderWidth: "1px !important",
        borderStyle: "solid",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderLeft: "0px",
        borderBottom: "1px",
        borderRight: "0px",
        borderTop: "0px",
        borderStyle: "solid",
        borderColor: "#E2E8F0",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        borderWidth: "1px",
    },
    "& .proceedBtn": {
        color: "#FFFFFF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        textTransform: "capitalize",
        background: "#7F1187",
        padding: "10px 0px",
        borderRadius: "30px",
        width: "155px",
        cursor: "pointer",
        "&:hover": {
            background: "#7F1287"
        },
        "&:disabled": {
            background: "#808080",
            color: "#ababab",
        }
    },
});

const StyleWrapper = styled(Box)({
    padding: "4%",
    '& .headerGrid': {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        columnGap: "40px",
        borderBottom: "1px solid #A8A29E"
    },
    '& .mainTitle': {
        fontSize: "20px",
        fontFamily: "Poppins",
        lineHeight: "24px",
        fontWeight: 400,
        marginBottom: "20px",
        cursor: "pointer",
        color: "#292524",
    },
    '& .titleActive': {
        fontSize: "20px",
        fontFamily: "Poppins",
        lineHeight: "24px",
        fontWeight: 600,
        marginBottom: "20px",
        cursor: "pointer",
        color: "#7F1187",
    },
    '& .productTitle': {
        fontSize: "20px",
        fontFamily: "Poppins",
        lineHeight: "32px",
        fontWeight: 500,
        marginBottom: "10px",
        color: "#000000",
    },
    '& .orderDate': {
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "12px",
        color: "#000000",
        marginBottom: "5px",
        lineHeight: "24px",
    },
    '& .buttonsGroup': {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        columnGap: "15px",
    },
    '& .vendorText': {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "#000000",
        marginBottom: "10px",
    },
    '& .locationText': {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#000000",
        marginBottom: "10px",
        marginLeft: "15px",
    },
    '& .productPrice': {
        fontFamily: "Poppins",
        fontSize: "32px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#801188",
        marginBottom: "20px",
    },
    '& .subscriptionPlan': {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "18px",
        color: "#3A3A3A",
        marginBottom: "10px",
    },
    '& .reviewTitle': {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#78716C",
        marginBottom: "10px",
    },
    "& .outOfText": {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "34px",
        color: "#646464",
    },
    "& .buttonStyle": {
        color: "#7F1187",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textTransform: "capitalize",
        background: "#FFF",
        border: "1px solid #7F1187",
        padding: "10px 0px",
        borderRadius: "30px",
        width: "155px",
        cursor: "pointer",
        "&:hover": {
            background: "#FFF"
        },
        "&:disabled": {
            background: "#808080",
            color: "#ababab",
        }
    },
    "& .addReviewButton": {
        color: "#7F1187",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textTransform: "capitalize",
        background: "#FFF",
        border: "none",
        padding: "10px 0px",
        borderRadius: "30px",
        width: "155px",
        cursor: "pointer",
        "&:hover": {
            background: "#FFF"
        },
    }
});
// Customizable Area End
