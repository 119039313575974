import React from "react";
import "../../styles/CometChatDetails/CometChatThreadedMessages.css";
import { CometChatMessageComposer, CometChatMessageList, CometChatThreadedMessagePreview, CometChatUserEvents, localize } from "../../../../../blocks/chat/src/components/UiKitImports";
import { CometChat } from '../../../../../blocks/chat/src/components/UiKitImports'
interface ThreadProps {
    message: CometChat.BaseMessage;
    requestBuilderState?: CometChat.MessagesRequestBuilder;
    selectedItem: CometChat.User | CometChat.Group | CometChat.Conversation | CometChat.Call | undefined;
    onClose?: () => void;
    showComposer?: boolean;

}

export const CometChatThreadedMessages = (props: ThreadProps) => {
    const {
        message,
        requestBuilderState,
        selectedItem,
        onClose = () => { },
        showComposer = false
    } = props;

    function resolveUser(selectedItem: any): CometChat.User | undefined {
        if ((selectedItem as CometChat.Conversation)?.getConversationType?.() === "user") {
            return (selectedItem as CometChat.Conversation)?.getConversationWith() as CometChat.User;
        }
        if ((selectedItem as CometChat.User).getUid?.()) {
            return selectedItem as CometChat.User;
        }
        return undefined;
    }

    function resolveGroup(selectedItem: any): CometChat.Group | undefined {
        if ((selectedItem as CometChat.Conversation)?.getConversationType?.() === "group") {
            return (selectedItem as CometChat.Conversation)?.getConversationWith() as CometChat.Group;
        }
        if ((selectedItem as CometChat.Group).getGuid?.()) {
            return selectedItem as CometChat.Group;
        }
        return undefined;
    }

    return (
        <div className="cometchat-threaded-message">
            <div className="cometchat-threaded-message-header">
                <CometChatThreadedMessagePreview parentMessage={message} onClose={onClose} />
            </div>
            {requestBuilderState?.parentMessageId === message.getId() && (
                <>
                    <div className="cometchat-threaded-message-list">
                        <CometChatMessageList
                            parentMessageId={message.getId()}
                            user={resolveUser(selectedItem)}
                            group={resolveGroup(selectedItem)}
                            messagesRequestBuilder={requestBuilderState}
                        />
                    </div>
                    {showComposer ? (
                        <div className="cometchat-threaded-message-composer">
                            <CometChatMessageComposer
                                parentMessageId={message.getId()}
                                user={resolveUser(selectedItem)}
                                group={resolveGroup(selectedItem)}
                            />
                        </div>
                    ) : (
                        <div
                            className="message-composer-blocked"
                            onClick={() => {
                                if (selectedItem instanceof CometChat.User) {
                                    CometChat.unblockUsers([selectedItem?.getUid()]).then(() => {
                                        selectedItem.setBlockedByMe(false);
                                        CometChatUserEvents.ccUserUnblocked.next(selectedItem);
                                    });
                                }
                            }}
                        >
                            <div className="message-composer-blocked__text">
                                {localize("CANNOT_SEND_MESSAGE_TO_BLOCKED_USER")} <a>{localize("CLICK_TO_UNBLOCK")}</a>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}