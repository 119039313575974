import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import HeaderFooterLayout from "../../../../components/src/HeaderFooterLayout";
import { Box, Typography as Text, Grid, Button as BTN, styled } from '@mui/material';
import Loader from "../../../../components/src/AppLoader.web";
const Image1 = require("../../assets/Image1.png");
const Image2 = require("../../assets/Image20.png");
const Image3 = require("../../assets/Image3.png");
const Image4 = require("../../assets/Image4.png");
const Image5 = require("../../assets/Image5.png");
const Image6 = require("../../assets/Image6.png");
// Customizable Area End

import PlanController, {
  Props,
  configJSON,
} from "../PlanController.web";

export default class SubscribePage extends PlanController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <HeaderFooterLayout navigation={this.props.navigation}>
        <Loader isLoading={false} />
        <SubscribeWrapper>
          <Grid container spacing={3}>
            <Grid item xs={4}><Box className="subscriptionBox" id="VenueBox"><Box><Text className="txt">Venue</Text><Box className="count-box"><Text className="count">55</Text></Box></Box></Box></Grid>
            <Grid item xs={4}><Box className="subscriptionBox" id="PhotoAndVideoBox"><Box><Text className="txt">Photo / Video</Text><Box className="count-box"><Text className="count">150</Text></Box></Box></Box></Grid>
            <Grid item xs={4}><Box className="subscriptionBox" id="MakeupBox"><Box><Text className="txt">Makeup</Text><Box className="count-box"><Text className="count">200</Text></Box></Box></Box></Grid>
            <Grid item xs={4}><Box className="subscriptionBox" id="DecorBox"><Box><Text className="txt">Decor</Text><Box className="count-box"><Text className="count">60</Text></Box></Box></Box></Grid>
            <Grid item xs={4}><Box className="subscriptionBox" id="EntertainmentBox"><Box><Text className="txt">Entertainment</Text><Box className="count-box"><Text className="count">75</Text></Box></Box></Box></Grid>
            <Grid item xs={4}><Box className="subscriptionBox" id="CatererBox"><Box><Text className="txt">Caterer</Text><Box className="count-box"><Text className="count">100</Text></Box></Box></Box></Grid>
          </Grid>
        </SubscribeWrapper>
      </HeaderFooterLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const SubscribeWrapper = styled(Box)({
  margin: "50px 78px",
  "& .subscriptionBox": {
    height: "384px",
    borderRadius: "8px",
    position:"relative",
    "& .txt": {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "38px",
      lineHeight: "100%",
      letterSpacing: "0%",
      textAlign: "center",
      verticalAlign: "middle",
      color:"rgba(255, 255, 255, 1)",
      whiteSpace: "nowrap"
    },
    "& .count-box":{
     display:"flex",
     justifyContent:"center",
     alignContent:"center",
     marginTop:"20px"
    },
    "& .count":{
      width: 100,
      height: 40,
      gap: "4px",
      borderRadius: "26px",
      paddingTop: "9px",
      paddingRight: "8px",
      paddingBottom: "6px",
      paddingLeft: "12px",
      backgroundColor:"rgba(255, 255, 255, 1)",
      textAlign: "center"
    },
    "& div:first-child":{
     position:"absolute",
     top:"50%",
     left:"50%",
     transform:"translate(-50%,-5%)"
    }
  },
  "& #VenueBox": {
    background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${Image1}) no-repeat center`,
    backgroundSize:"cover"
  },
  "& #PhotoAndVideoBox": {
    background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${Image2}) no-repeat center`,
    backgroundSize:"cover"
  },
  "& #MakeupBox": {
    background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${Image3}) no-repeat center`,
    backgroundSize:"cover"
  },
  "& #DecorBox": {
    background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${Image4}) no-repeat center`,
    backgroundSize:"cover"
  },
  "& #EntertainmentBox": {
    background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${Image5}) no-repeat center`,
    backgroundSize:"cover"
  },
  "& #CatererBox": {
    background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${Image6}) no-repeat center`,
    backgroundSize:"cover"
  },
});
// Customizable Area End
