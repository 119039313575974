import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/utils";
import { logo1, logo2, logo3, logo4 } from "./assets";
import moment from "moment";


interface ReviewsResponse {
  id: string;
  type: string;
  attributes: ReviewsAttributes;
}

interface ReviewsAttributes {
  id: number;
  title: string;
  description: string;
  product_id: number;
  account_id: number;
  created_at: string;
  reviewer: {
    data: {
      id: string;
      type: string;
      attributes: ReviewerAttributes;
    };
  };
}

interface ReviewerAttributes {
  full_name: string;
  email: string;
  profile_picture: string;
}

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  cardsData:boolean;
  showCard:boolean;
  anchorEl: HTMLElement | null;
  overViewData:any;
  overViewtimeSpan:string
  recentReviews: any[];
  ordersData: any[];  
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiDashboardOverviewCallId: string = "";
  getOrdersDataCallId: string = "";
  getRecentReviewsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      cardsData:false,
      showCard:false,
      anchorEl: null,
      overViewtimeSpan:"for_this_week",
      recentReviews: [],
      ordersData: [],     
      overViewData:{
        catalogues_count:{
          background: "#EAF6F0",
          imgSrc: logo1,
          title: "Total Catalogue",
          value: "",
        },
        total_sales_count:{
          background: "#F0ECFD",
          imgSrc: logo4,
          title: "Total Sales",
          value: "",
          fontFamily: "roboto",
        },
        new_orders_count:{
          background: "#E9F6FC",
          imgSrc: logo2,
          title: "New Orders",
          value: "",
        },
        new_inquiry_count:{
          background: "#F6F1EA",
          imgSrc: logo3,
          title: "New Inquiry",
          value: "",
        },
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getDashboardOverViewDataAPI();
    this.getOrdersDataAPI();
    this.getRecentReviewsApi();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors && !webResponseJson.error) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            dashboardData: webResponseJson.data.attributes.sub_attributres,
            totalCandidates: webResponseJson.data.attributes.total_candidates,
            type: webResponseJson.data.type,
            errorMsg: "",
            loading: false
          });
        }
      } else if (webResponseJson && (webResponseJson.errors || !webResponseJson.error)) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
        }
      }
      if (webApiRequestCallId === this.apiDashboardOverviewCallId) {
        this.handleDashboardOverViewResponse(webResponseJson)
      }
      if (webApiRequestCallId === this.getOrdersDataCallId) {
        this.handleGetOrdersDataResponse(webResponseJson)
      }
      if (webApiRequestCallId === this.getRecentReviewsCallId) {
        this.handleRecentReviewsResponse(webResponseJson)
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.overViewtimeSpan!==this.state.overViewtimeSpan){
      this.getDashboardOverViewDataAPI();
    }
  }

  handleDashboardOverViewResponse=(webResponseJson:any)=>{
    if (webResponseJson && !webResponseJson.errors && !webResponseJson.error) {
      const updatedResponse = { ...this.state.overViewData };  
  
      Object.keys(webResponseJson).forEach(key => {
        if (updatedResponse[key]) {
          updatedResponse[key].value = webResponseJson[key];
        }
      });

      this.setState({
        overViewData: updatedResponse,
        loading: false
      });
    }
  }

  getDashboardOverViewDataAPI = async () => {
    this.setState({ loading: true });
    this.apiDashboardOverviewCallId = await apiCall({
      endPoint: `bx_block_dashboard/vendor_dashboards?filters=${this.state.overViewtimeSpan}`,
      method: "GET",
      token: this.state.token,
    });
  }

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClick2 = (event: any) => {
    this.props.navigation.navigate("ReverseAuctionList");
  };
  
  getCardsData = () => {
    this.setState({ cardsData: true })
  }
 
  toShowCards = () => {
    this.setState({ showCard: true })
  }
  handleClose = () => {
    this.setState({ anchorEl: null });
  }; 

  getOrdersDataAPI = async () => {
    this.setState({ loading: true });
    this.getOrdersDataCallId = await apiCall({
      endPoint: `bx_block_order_management/orders/fetch_vendor_orders`,
      method: "GET",
      token: this.state.token,
    });
  }

  handleGetOrdersDataResponse = (response: { data: any[] }) => {
    if (response.data) {
      const orders = response.data.map((item) => {
        const order = item.attributes;
        return {
          id: order.id,
          code: order.order_number,
          date: moment(order.placed_at).format("DD MMM, YYYY"),
          quantity: order.order_items_count,
          price: `₹${order.total_vendor_price}`,
          paid: order.paid,
          status: order.status,
          address: order.address,
          location: order.address?.data?.attributes?.address,
        }
      });
      this.setState({ ordersData: orders });
    } else {
      this.setState({ ordersData: [] });
    }
    this.setState({ loading: false });
  }

  getRecentReviewsApi = async () => {
    this.setState({ loading: true });
    this.getRecentReviewsCallId = await apiCall({
      endPoint: `bx_block_reviews/vendor_review/recent_reviews`,
      method: "GET",
      token: this.state.token,
    });
  }

  handleRecentReviewsResponse = (response: { reviews: {data: ReviewsResponse[]}, error: string }) => {
    if (response.reviews.data) {
      const reviews = response.reviews.data.map((item) => {
        const review = item.attributes;
        const reviewer = review.reviewer.data.attributes;
        return {
          id: review.id,
          name: reviewer.full_name,
          avatarPic: reviewer.profile_picture || "https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg",
          time: moment(review.created_at).format("DD MMM, YYYY"),
          reviewText: review.description,
          rating: 8.3,
        }
      });
      this.setState({ recentReviews: reviews });
    } else {
      this.setState({ recentReviews: [] });
    }
    this.setState({ loading: false });
  }
  // Customizable Area End
}
