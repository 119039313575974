import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
import { Box, Typography as Text, Grid, FormLabel, TextField, Autocomplete, Button as BTN } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Loader from "../../../components/src/AppLoader.web";
import { styled } from '@mui/material';
import CustomDatePicker from "../../../components/src/datePicker";
const PlanOurEventsBanner = require("../assets/loveCelebrateImg.png");
const rightArrow = require("../assets/rightArrow.png");
interface ColorOption {
  label: string;
  value: number;
}
// Customizable Area End

import PlanController, {
  Props,
  configJSON,
} from "./PlanController.web";

export default class Planyourevents extends PlanController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <HeaderFooterLayout navigation={this.props.navigation}>
      <Loader isLoading={false} />
      <BannerStyle>
        <Box>
          <Text className='plan_out_mission'>Plan your wedding with love, celebrate it with</Text>
          <Text className='plan_out_mission'>joy, and cherish it forever.</Text>
          <Text className='trusted_reviews'>Create the wedding of your dreams with perfect planning.</Text>
        </Box>
      </BannerStyle>
      <PlanOurEventsCelebrateDiv>
        <Box className='Plan-Our-Events_heading_box'>
          <Text className='Plan-Our-Events_heading_celebrate_txt'>Plan Your Events</Text>
            <Box className="Plan-Our-Events-Breadcrumbs"><div>Wedding & Related Events <img src={rightArrow} alt="arrow" className="arrow-img" /></div><div>Weddings</div></Box>
        </Box>
        <Box className='Plan-Our-Events_box'>
         <Text className='Planning-txt'>Hey Maria James, What are you planning?</Text>
            <Box className="Planning-details">
              <Grid container spacing={2}>
              <Grid item xs={12}>
                  <FormLabel className="form-label-txt">
                    Event
                  </FormLabel>
                  <Autocomplete popupIcon={<ExpandMoreIcon style={{ color: "#0F172A" }} />} disableCloseOnSelect multiple sx={{ width: { xs: "100%" } }} id="tags-checkbox" fullWidth options={[]} value={[]} data-test-id="handleChangeEvent" 
                    onChange={(event, newValue) => {
                      // this.handleSelectServiceChange(newValue, setFieldValue)
                    }}                  
                    renderTags={() => null}                    
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        data-test-id="handleChangeService"
                        placeholder="Select Event "
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel className="form-label-txt">
                    Event Date
                  </FormLabel>
                 <CustomDatePicker placeholder="DD/MM/YYYY" NotShowCalenderHeader={true}/>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel className="form-label-txt">
                    Top Cities
                  </FormLabel>
                  <div style={{ marginBottom: "5px" }}>
                    <Autocomplete
                      multiple sx={{ width: { xs: "100%" } }}
                      id="tags-checkbox"
                      fullWidth
                      options={[]}
                      value={[]}
                      data-test-id="handleChangeEventTopCities"
                      onChange={(event, newValue) => {
                        // this.handleSelectServiceChange(newValue, setFieldValue)
                      }}
                      disableCloseOnSelect
                      renderTags={() => null}
                      // renderOption={(props, option: ColorOption) => {
                      //   const isSelected = Array.isArray(this.state.selectedServices) && this.state.selectedServices.some(selected => selected.value == option.value);
                      //   return (
                      //     <MenuItem
                      //       {...props}
                      //       key={option && option.value}
                      //       data-test-id="menuItemsService"
                      //       onClick={() => {
                      //         this.toggleServiceOption(option);
                      //         this.handleServiceOption(option, isSelected, setFieldValue);
                      //       }}
                      //       style={{ borderBottom: '1px solid #E7E5E4' }}
                      //     ><Checkbox checked={isSelected} style={{ marginRight: 8 }} />
                      //       <ListItemText
                      //         primary={
                      //           option && option.label}
                      //       /></MenuItem>
                      //   );
                      // }}
                      popupIcon={<ExpandMoreIcon style={{ color:"#0F172A" }} />}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          data-test-id="handleChangeService"
                          placeholder="Select the city"
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                        // value={values && values.selectedService}
                        // error={this.isError(touched?.selectedService, errors?.selectedService)}
                        // helperText={this.helperText(
                        //   touched?.selectedService,
                        //   errors?.selectedService
                        // )}
                        />
                      )}
                    // disabled={!this.state.isEditing}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel className="form-label-txt">
                  Type of Venue
                  </FormLabel>
                  <div style={{ marginBottom: "5px" }}>
                    <Autocomplete
                      multiple sx={{ width: { xs: "100%" } }}
                      id="tags-checkbox"
                      fullWidth
                      options={[]}
                      value={[]}
                      data-test-id="handleChangeTypeOfVenue"
                      onChange={(event, newValue) => {
                        // this.handleSelectServiceChange(newValue, setFieldValue)
                      }}
                      disableCloseOnSelect
                      renderTags={() => null}
                      // renderOption={(props, option: ColorOption) => {
                      //   const isSelected = Array.isArray(this.state.selectedServices) && this.state.selectedServices.some(selected => selected.value == option.value);
                      //   return (
                      //     <MenuItem
                      //       {...props}
                      //       key={option && option.value}
                      //       data-test-id="menuItemsService"
                      //       onClick={() => {
                      //         this.toggleServiceOption(option);
                      //         this.handleServiceOption(option, isSelected, setFieldValue);
                      //       }}
                      //       style={{ borderBottom: '1px solid #E7E5E4' }}
                      //     ><Checkbox checked={isSelected} style={{ marginRight: 8 }} />
                      //       <ListItemText
                      //         primary={
                      //           option && option.label}
                      //       /></MenuItem>
                      //   );
                      // }}
                      popupIcon={<ExpandMoreIcon style={{ color:"#0F172A" }} />}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          data-test-id="handleChangeService"
                          placeholder="Select the venue"
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                        // value={values && values.selectedService}
                        // error={this.isError(touched?.selectedService, errors?.selectedService)}
                        // helperText={this.helperText(
                        //   touched?.selectedService,
                        //   errors?.selectedService
                        // )}
                        />
                      )}
                    // disabled={!this.state.isEditing}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <FormLabel className="form-label-txt">
                    Guest Counts
                  </FormLabel>
                  <TextField
                    // key={field_name}
                    // placeholder={`Enter ${field_name}`}
                    // required={required}
                    placeholder="Enter guest count"
                    fullWidth
                    margin="normal"
                    value={""}
                    // onChange={(e) => this.handleChange(field_name, e.target.value)}
                    style={{ marginTop: "0px" }}
                  // data-test-id= {`${field_name}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormLabel className="form-label-txt">
                    Overall Budget
                  </FormLabel>
                  <TextField
                    // key={field_name}
                    placeholder={"Enter budget"}
                    // required={required}
                    fullWidth
                    margin="normal"
                    // value={formData[field_name] || ""}
                    // onChange={(e) => this.handleChange(field_name, e.target.value)}
                    style={{ marginTop: "0px" }}
                  // data-test-id= {`${field_name}`}
                  />
                </Grid>
                <Grid item xs={12} justifyContent="flex-end" display="flex" gap={"24"}>
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="flex-end"
                    spacing={2}
                  >
                    <Grid item>
                      <BTN className="cancelBtn" data-test-id="cancelBtn">
                        Cancel
                      </BTN>
                    </Grid>
                    <Grid item>
                      <BTN className="nextBtn" data-test-id="nextBtn" onClick={()=>this.navigateToSelectPlan()}>
                        Next
                      </BTN>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
        </Box>
      </PlanOurEventsCelebrateDiv>
    </HeaderFooterLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BannerStyle = styled(Box)({
  width: "100%",
  height: "31.25rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "end",
  background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 99.8%), url(${PlanOurEventsBanner}) no-repeat center center`,
  backgroundSize: "cover",
  backgroundPosition: "center 20%",
  "& .plan_out_mission": {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "40px",
    lineHeight: "52px",
    letterSpacing: "0%",
    textAlign: "center",
    textTransform: "capitalize",
    color: "#FFFFFF"
  },
  "& .trusted_reviews": {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "34px",
    letterSpacing: "0%",
    textAlign: "center",
    textTransform: "capitalize",
    color: "#FFFFFF",
    marginBottom: "46px"
  }
});

const PlanOurEventsCelebrateDiv = styled(Box)({
  "& .Plan-Our-Events_heading_box": {
    marginTop:"28px",
    marginBottom:"28px",
    display:"flex",
    position:"relative",
    marginLeft:"38px",
    marginRight:"38px"
  },
  "& .Plan-Our-Events_heading_celebrate_txt": {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "40px",
    lineHeight: "52px",
    letterSpacing: "0%",
    textAlign: "center",
    textTransform: "capitalize",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  "& .Plan-Our-Events-Breadcrumbs":{
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "0%",
    verticalAlign: "middle",
    color: "#475569",
    display: "flex",
    alignItems: "center"
  },
  "& .Plan-Our-Events-Breadcrumbs div": {
    position: "relative", // Required for ::after to position correctly
    "& .arrow-img":{
      position: "absolute",
      right: "-36px", // Moves triangle to the right
      top:  "50%",
      transform: "translateY(-50%)",
      zIndex:3
    },
    "&:first-child": {
      backgroundColor: "#7f1187",
      borderRadius: "6px",
      padding: "4px 6px",
      color: "rgba(255, 255, 255, 1)",
      zIndex:"2",
      "&::after": {  
        content: '""',
        position: "absolute",
        right: "-16px", // Moves triangle to the right
        top:  "50%",
        transform: "translateY(-50%)",
        width: "0",
        height: "0",
        borderTop: "16px solid transparent",
        borderBottom: "15px solid transparent",
        borderLeft: "20px solid rgb(127, 17, 135)"
      },
    },
    "&:last-child": {
      backgroundColor: "#ab17b5",
      borderRadius: "6px",
      padding: "4px 6px",
      color: "rgba(255, 255, 255, 1)",
      width: "140px",
      textAlign: "right",
      zIndex:"1",
      marginLeft: "-10px",
      "&::after": {  
        content: '""',
        position: "absolute",
        right: "-16px", // Moves triangle to the right
        top:  "50%",
        transform: "translateY(-50%)",
        width: "0",
        height: "0",
        borderTop: "15px solid transparent",
        borderBottom: "15px solid transparent",
        borderLeft: "20px solid #ab17b5"
      },
      "&::before": {  
        content: '""',
        position: "absolute",
        right: "-16px", // Moves triangle to the right
        top:  "50%",
        transform: "translateY(-50%)",
        width: "0",
        height: "0",
        borderTop: "15px solid transparent",
        borderBottom: "15px solid transparent",
        borderLeft: "20px solid #white"
      },
    },
  },
  "& .Plan-Our-Events_box": {
    margin: "38px",
    borderRadius: "8px",
    padding: "30px",
    border: "1px solid #D6D3D1"
  },
  "& .Planning-txt": {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "28px",
    letterSpacing: "0%"
  },
  "& .Planning-details": {
    marginTop: "30px",
  },
  "& .cancelBtn": {
    width: "180px",
    height: "44px",
    gap: "8px",
    paddingTop: "10px",
    paddingRight: "16px",
    paddingBottom: "10px",
    paddingLeft: "16px",
    borderRadius: "30px",
    border: "1px solid #7F1187",
    color: "#7F1287",
    textTransform: "capitalize",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: " rgba(25, 118, 210, 0.04)"
    }
  },
  "& .nextBtn": {
    width: "180px",
    height: "44px",
    borderRadius: "30px",
    gap: "8px",
    paddingTop: "10px",
    paddingRight: "16px",
    paddingBottom: "10px",
    paddingLeft: "16px",
    background: "#7F1187",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0%",
    color:"white",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#7F1187"
    }
  },
  "& .required": {
    color: "red",
  },
  "& .common-form-label": {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "4px",
    display: "block",
    "& span": {
      color: "#DC2626",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "22px",
    },
  },
  "& .MuiInputBase-input": {
    padding: "10.5px 8px ",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#0F172A",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#A8A29E",
    opacity: 1,
  },
  "& .MuiInputBase-root.Mui-error": {
    borderRadius: "8px",
  },
  "& .MuiInputBase-root": {
    borderRadius: "0px",
    backgroundColor: "#FFFFFF",
  },
  "& .MuiFormHelperText-root": {
    color: "#DC2626",
    margin: "8px 0 0",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
  },
  "& .Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171 !important",
    borderWidth: "1px !important",
    borderStyle: "solid",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderLeft: "0px",
    borderBottom: "1px",
    borderRight: "0px",
    borderTop: "0px",
    borderStyle: "solid",
    borderColor: "#E2E8F0",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
  {
    borderRadius: "8px",
    borderWidth: "1px",
  },
  "& .form-label-txt": {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0%",
    verticalAlign: "middle",
    color:"#334155"
  }
});
// Customizable Area End
