import React from "react";

import {
  // Customizable Area Start
Typography,Box,Button,Card,CardContent
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
const Venueone = require("../assets/one.jpg");
// Customizable Area End

import SelectedVendorsController, {
  Props,
  configJSON,
} from "./SelectedVendorsController";

export default class Selectedvendors extends SelectedVendorsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <>
          <>
        <HeaderFooterLayout navigation={this.props.navigation}>
          <Box sx={webStyle.container}>
            
            <Typography sx={webStyle.heading}>Venue Comparison</Typography>
            <Typography sx={webStyle.subheading}>
              Showing Comparison Between 3 Venues
            </Typography>
           

              <Box sx={webStyle.content}>
                <Card sx={webStyle.referenceCard}>
                  <img src={Venueone} style={{ width: "100%", borderRadius: "8px 8px 0 0" ,opacity: 0,}} />
                  <CardContent>
                  <Typography sx={{...webStyle.sidebarHeading,marginBottom:"10px"}}>Venue Name</Typography>
                    <Typography sx={webStyle.textStyleTwo}>Address</Typography>
                    <Typography sx={webStyle.textStyleTwo}>Rating</Typography>
                    <Typography sx={webStyle.textStyleTwo}>Price</Typography>
                    <Typography sx={webStyle.textStyleTwo}>Property Type</Typography>
                    <Typography sx={webStyle.textStyleTwo}>Venue Type</Typography>
                    <Typography sx={webStyle.textStyleTwo}>Located at</Typography>
                    <Typography sx={webStyle.textStyleTwo}>Rates</Typography>
                    <Typography sx={webStyle.textStyleTwo}>Events For</Typography>

                  </CardContent>
                </Card>

                <Box sx={webStyle.vendorGrid}>
                  
                  {this.state.selectedVendors.map((vendor: any) => (
                    <Card key={vendor.id} sx={{ position: "relative", boxShadow: "0px 8px 32px 0px #0000000F" }}>

                      <img src={vendor.imgURL} alt={vendor.name} style={{ width: "100%", borderRadius: "8px 8px 0 0" }} />
                      <CardContent>
                      <Typography sx={{fontWeight:700,marginBottom:"10px"}} >{vendor.name}</Typography>
                        <Typography sx={webStyle.textStyleTwo}>{vendor.location}</Typography>
                        <Box sx={webStyle.ratingContainer}>
                          <img src={vendor.ratingImage} style={{marginBottom:"13px"}} />
                          <Typography sx={webStyle.textStyleTwo}>{vendor.rating}</Typography>
                        </Box>
                        <Typography sx={webStyle.textStyle} >{vendor.price}</Typography>
                        <Typography sx={webStyle.textStyleTwo}>{vendor.propertyType}</Typography>
                        <Typography sx={webStyle.textStyleTwo}>{vendor.venueType}</Typography>
                        <Typography sx={webStyle.textStyleTwo}>{vendor.locatedat}</Typography>
                        <Typography sx={webStyle.textStyleTwo}>{vendor.Rates}</Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px", marginBottom:"20px"}}>
                          <Typography sx={webStyle.textStyleEventsFor}>Wedding Halls</Typography>
                          <Typography sx={webStyle.textStyleEventsFor}>Banquet halls</Typography>
                          <Typography sx={webStyle.textStyleEventsFor}>Small function hall</Typography>
                          <Typography sx={webStyle.textStyleEventsFor}>Conference meeting room</Typography>
                        </Box>
                        <Box sx={webStyle.viewMoreBox} >
                          <Button sx={webStyle.viewMoreButton}>{configJSON.viewMore}</Button>
                        </Box>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              </Box>

            <Box sx={webStyle.buttonContainer}>
              <Button sx={webStyle.nextButton}>Next</Button>
            </Box>
          </Box>
        </HeaderFooterLayout>
      </>
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    container: {
      padding: 4,
      maxWidth: "1280px",
      margin: "auto",
    },
    heading: {
      textAlign: "center",
      fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "36px",
        lineHeight: "22px",
        letterSpacing: "0%",
        verticalAlign: "bottom",
    },
    subheading: {
      marginTop:"14px",
        textAlign: "center",
        color: "#57534E",
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "22px",
        letterSpacing: "0%",
        verticalAlign: "bottom",
    },
    content: {
      display: "flex",
      gap: 2,
      marginTop: 4,
    },
    sidebar: {
      flex: 1,
      minWidth: "250px",
    },
    sidebarHeading: {
      variant: "h6",
      fontWeight: 600,
    },
    sidebarText: {
      color: "gray",
    },
    vendorGrid: {
      flex: 3,
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gap: 2,
    },
    buttonContainer: {
        position:"relative",
        left:"160px",
      textAlign: "center",
      marginTop: 4,
    },
    nextButton: {
        border: "2px solid #800080",
        width: "17%",
        borderRadius: "25px",
        background: "#7F1187",
        color: "#ffffff",
        padding: "8px 16px",
        fontSize: "14px",
        fontWeight: "600",
        textTransform: "none",
        "&:hover": {
          background: "#7F1187",
        },
    },
    viewMoreButton:{
        border: "2px solid #800080",
        width: "56%",
        borderRadius: "25px",
        background: "#ffffff",
        color: " #7F1187",
        padding: "8px 16px",
        fontSize: "14px",
        fontWeight: "600",
        textTransform: "none",
        "&:hover": {
          background: "none",
        },
    },
    viewMoreBox:{
        position:"relative",
        left: "64px",
    },
    textStyle: {
        fontFamily: "Roboto",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        letterSpacing: "0%",
        verticalAlign: "bottom",
        mb:1.5,
      },

    textStyleTwo: {
        color:"#1C1917",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        letterSpacing: "0%",
        verticalAlign: "bottom",
        mb:1.5,
    },

    textStyleEventsFor: {
      color:"#1C1917",
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "22px",
      letterSpacing: "0%",
      verticalAlign: "bottom",
  },
    ratingContainer: {
        display: "flex",
        alignItems: "center",
        gap: "4px",
      },

      referenceCard: {
        width: "292px",
         position: "relative",
          boxShadow: "0px 8px 32px 0px #0000000F" 
      }
  };
// Customizable Area End
