import React from "react";

// Customizable Area Start
import { Box, Typography, styled, Button, Divider, Select, MenuItem } from "@mui/material";
import Loader from "../../../components/src/AppLoader.web";
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
// Customizable Area End
import PaymentScuccessPageController, { Props, OrderItem, ProductAttributes } from './PaymentScuccessPageController.web';
import { checkIcon } from "./assets";

export default class PaymentScuccessPage extends PaymentScuccessPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const {
            myCartData,
            isLoading,
            cartOrderItems,
            orderId,
            paymentMethod,
            address,
            customerData,
        } = this.state;

        return (
            // Customizable Area Start
            <HeaderFooterLayout navigation={this.props.navigation}>
                <Loader isLoading={isLoading} />
                <SuccessPageStyle id="inspiration" component="section">
                    {cartOrderItems.length > 0 &&
                        <Box className="firstSection">
                            <Box display={"flex"} flexDirection={"row"} justifyContent={"start"} alignItems={"start"} paddingLeft={"4%"}>
                                <Box>
                                    <img style={{ width: "86px", height: "86px" }} src={checkIcon} alt="" />
                                </Box>
                                <Box paddingLeft={"20px"}>
                                    <Typography className="purchaseTitle">Thank you for your purchase</Typography>
                                    <Typography className="orderIdText">Order ID:
                                        <Typography className="orderId" component={"span"}>#{orderId}</Typography>
                                    </Typography>
                                    <Typography className="emailConfirmationSent">Confirmation email has been sent to you</Typography>
                                </Box>
                            </Box>
                            <Box className="cartSection">

                                <Box className="cartData">
                                    {cartOrderItems.map((cart: OrderItem, index: number) => {
                                        const product: ProductAttributes = cart.attributes.product.attributes;
                                        const variantImage = product.product_variants.attributes.product_color.attributes?.color_image;
                                        const variantColor = product.product_variants.attributes.product_color.attributes?.name;
                                        const variantSize = product.product_variants.attributes.product_size.attributes?.size_label;
                                        return (
                                            <>
                                                <Box className="cartBox" key={index}>
                                                    <Box className="detailsBox">
                                                        <img alt="product-image" src={this.getProductImage(variantImage)} className="image" />
                                                        <Box className="detailsSection">
                                                            <Box>
                                                                <Typography component={"p"} className="productName">{product.name}</Typography>
                                                                <Typography component={"p"} className="location">{this.getLocationText(product.service_locations)}</Typography>
                                                            </Box>
                                                            <Box marginBottom={"20px"} columnGap={"20px"} display={"flex"} flexDirection={"row"}>
                                                                <Typography className="productSize" component={"span"}>Size:
                                                                    <Typography component={"span"} style={{ color: "#000", fontWeight: 600, marginLeft: "10px" }}>{variantSize}</Typography>
                                                                </Typography>
                                                                <Typography className="productSize" component={"span"}>Color:
                                                                    <Typography component={"span"} style={{ color: "#000", fontWeight: 600, marginLeft: "10px" }}>{variantColor}</Typography>
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography component={"span"} className="quantity" >Qty</Typography>
                                                                <Select
                                                                    className="quantity"
                                                                    data-test-id={`quantityDropdown${index}`}
                                                                    displayEmpty
                                                                    labelId="quantity-select"
                                                                    value={cart.attributes.quantity}
                                                                    disabled
                                                                    sx={{
                                                                        fontSize: "16px",
                                                                        border: "1px solid white",
                                                                        fontFamily: "inter",
                                                                        fontWeight: 700,
                                                                        '& .MuiOutlinedInput-notchedOutline': { border: "none" },
                                                                    }}
                                                                >
                                                                    {[...Array(10).keys()].map((num) => (
                                                                        <MenuItem key={num + 1} value={num + 1}>
                                                                            {num + 1}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                        <Typography className="price"><CurrencyRupeeIcon fontSize="small" />{cart.attributes.price}</Typography>
                                                        {cart.attributes.discount && <Typography className="offer">{cart.attributes.discount}% Off</Typography>}
                                                    </Box>
                                                </Box>
                                                <Divider className="divider" />
                                            </>
                                        )
                                    })}

                                    {address && customerData &&
                                        <Box marginTop={"40px"} paddingLeft={"4%"}>
                                            <Typography className="billingAddressTitle">Billing Address</Typography>
                                            <Typography className="customerName">{address.name}
                                                <Typography className="addressType" component={"span"}>{address.address_type}</Typography>
                                            </Typography>
                                            <Typography className="addressText">{address.address}</Typography>
                                            <Typography className="addressText">{address.city}, {address.state}</Typography>
                                            <Typography className="customerMobile">Mobile number:
                                                <Typography style={{ color: "#000000", marginLeft: "10px" }} component={"span"}>+91 {address.phone_number}</Typography>
                                            </Typography>
                                        </Box>
                                    }
                                    <Box marginTop={"40px"} paddingLeft={"4%"}>
                                        <Button data-test-id="backToHomeButton" onClick={this.handleBacktoHome} className="backToHomeBtn">Back to Home</Button>
                                    </Box>
                                </Box>

                                {myCartData &&
                                    <Box className="cartDetails">
                                        <Box>
                                            <Typography className="couponHead" style={{ marginBottom: "20px" }}>Billing Summary</Typography>
                                            <Box className="offerSection">
                                                <Box className="space-between offerDetails">
                                                    <Typography className="discountOpt">Total MRP</Typography>
                                                    <Typography className="deliver offerDetails"><CurrencyRupeeIcon fontSize="small" />{myCartData.total_mrp}</Typography>
                                                </Box>
                                                <Box className="space-between offerDetails">
                                                    <Typography className="discountOpt">Discount on MRP</Typography>
                                                    <Typography className="saved offerDetails">-<CurrencyRupeeIcon fontSize="small" />{myCartData.discount_on_mrp}</Typography>
                                                </Box>
                                                <Box className="space-between offerDetails">
                                                    <Typography className="discountOpt">Coupon Discount </Typography>
                                                    <Typography className="saved offerDetails">-<CurrencyRupeeIcon fontSize="small" />{0}</Typography>
                                                </Box>
                                                <Box className="space-between offerDetails">
                                                    <Typography className="discountOpt offerDetails">Shipping Fee
                                                    </Typography>
                                                    <Typography className="discountOpt offerDetails"><CurrencyRupeeIcon fontSize="small" />0</Typography>
                                                </Box>
                                                <Divider className="divider" />
                                                <Box className="offerDetails space-between">
                                                    <Typography className="deliver">Total Amount</Typography>
                                                    <Typography className="deliver offerDetails"><CurrencyRupeeIcon fontSize="small" />{myCartData.total_amount}</Typography>
                                                </Box>
                                                <Divider className="divider" />
                                                <Typography className="paymentMethodText">Amount paid via {paymentMethod}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    }
                    {!isLoading && cartOrderItems.length === 0 &&
                        <Box className="firstSection">
                            <Box className="cartSection" height={"50vh"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                <Typography style={{ color: 'red', fontWeight: 600, fontFamily: 'Poppins' }} align="center">Order details not found!</Typography>
                            </Box>
                        </Box>
                    }
                </SuccessPageStyle>
            </HeaderFooterLayout >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const SuccessPageStyle = styled(Box)({
    padding: "56px 0 0",
    "& .firstSection": {
        margin: "auto",
        width: "90%",
        flexDirection: "column",
        gap: "32px",
        display: "flex",
    },
    "& .cartSection": {
        width: "100%",
        display: 'flex',
    },
    "& .cartData": {
        width: "70%",
        borderRight: "2px solid #D6D3D1",
        padding: "0px 24px 24px 0px",
    },
    "& .cartDetails": {
        padding: "5px 20px",
        width: "30%",
        flexDirection: "column",
        gap: "20px",
        display: 'flex',
    },
    "& .price": {
        color: '#801188',
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamilt: "Poppins",
        alignItems: "center",
        display: "flex",
        paddingBottom: "5px",
        gap: "2px",
    },
    "& .offer": {
        backgroundColor: "#FF0101",
        padding: "5px 0px",
        fontSize: "12px",
        color: "#FAFAF9",
        fontFamilt: "Poppins",
        fontWeight: 600,
        width: "70px",
        lineHeight: "24px",
        textAlign: "center",
    },
    "& .image": {
        height: "100%",
        width: "195px",
        objectFit: 'cover',
        borderRadius: "8px",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    },
    "& .cartBox": {
        margin: 'auto',
        width: "90%",
        justifyContent: "space-between",
        display: "flex",
        padding: "30px 0px",
    },
    '& .detailsSection': {
        flexDirection: "column",
        display: 'flex',
        paddingTop: "10px",
        gap: "35px",
    },
    "& .detailsBox": {
        gap: "24px",
        display: "flex",
    },
    "& .productName": {
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Poppins",
        textTransform: 'capitalize',
        color: "#292524",
    },
    "& .location": {
        fontSize: "14px",
        fontFamily: "Poppins",
        fontWeight: 400,
        color: "#78716C",
        lineHeight: "22px",
        textTransform: "capitalize",
    },
    '& .deliver': {
        fontSize: "16px",
        fontFamily: "Poppins",
        lineHeight: "22px",
        fontWeight: 500,
    },
    "& .couponHead": {
        fontWeight: 500,
        fontSize: "20px",
        fontFamily: "Poppins",
        color: "#000000",
        lineHeight: "32px",
    },
    "& .offerDetails": {
        display: 'flex',
        alignItems: 'center',
    },
    "& .space-between": {
        justifyContent: "space-between",
    },
    "& .saved": {
        fontSize: "16px",
        fontFamily: "Poppins",
        fontWeight: 500,
        color: "#10B981",
        lineHeight: "24px",
        paddingLeft: "40px",
    },
    "& .discountOpt": {
        fontSize: "16px",
        fontFamily: "Poppins",
        fontWeight: 400,
        color: "#78716C",
        lineHeight: "24px",
    },
    "& .offerSection": {
        display: 'flex',
        gap: "25px",
        flexDirection: "column",
    },
    '& .quantity': {
        fontSize: "16px",
        fontFamily: "inter",
        fontWeight: 700,
        lineHeight: "22px",
    },
    '& .purchaseTitle': {
        fontFamily: 'Poppins',
        lineHeight: '36px',
        fontSize: '24px',
        color: '#292524',
        fontWeight: 600,
        marginBottom: "20px",
    },
    '& .orderIdText': {
        fontFamily: 'Poppins',
        lineHeight: '24px',
        fontSize: '16px',
        color: '#000',
        fontWeight: 600,
        marginBottom: "15px",
    },
    '& .orderId': {
        fontFamily: 'Poppins',
        lineHeight: '24px',
        fontSize: '16px',
        color: '#7B7B7B',
        fontWeight: 600,
        paddingLeft: "7px",
    },
    '& .emailConfirmationSent': {
        fontFamily: 'Poppins',
        lineHeight: '22px',
        fontSize: '14px',
        color: '#000',
        fontWeight: 400,
    },
    '& .paymentMethodText': {
        fontSize: '14px',
        fontFamily: 'Poppins',
        fontWeight: 400,
        lineHeight: '22px',
        marginTop: '-15px',
        color: '#059669',
    },
    "& .backToHomeBtn": {
        color: "#7F1187",
        fontSize: "16px",
        fontFamily: "Poppins",
        lineHeight: "24px",
        fontWeight: "500",
        textTransform: "capitalize",
        padding: "10px 0px",
        background: "#FFF",
        width: "320px",
        borderRadius: "30px",
        border: "1px solid #7F1187",
        cursor: "pointer",
        "&:hover": {
            background: "#FFF"
        },
        "&:disabled": {
            color: "#ababab",
            background: "#808080",
        }
    },
    "& .billingAddressTitle": {
        fontFamily: 'Poppins',
        fontSize: '20px',
        lineHeight: '32px',
        fontWeight: 500,
        color: '#000000',
    },
    "& .customerName": {
        fontFamily: 'Poppins',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
        color: '#292524',
        marginTop: "25px",
        marginBottom: "15px",
    },
    "& .addressType": {
        fontFamily: 'Poppins',
        fontSize: '14px',
        lineHeight: '22px',
        fontWeight: 500,
        color: '#059669',
        width: "max-content",
        height: "max-content",
        padding: "5px 10px",
        borderRadius: "4px",
        border: "1px solid #059669",
        marginLeft: "15px",
    },
    "& .addressText": {
        fontFamily: 'Poppins',
        fontSize: '14px',
        lineHeight: '22px',
        fontWeight: 400,
        color: '#78716C',
    },
    "& .customerMobile": {
        fontFamily: 'Poppins',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        color: '#A8A29E',
        marginTop: "25px",
    },
    "& .divider": {
        color: "#D6D3D1",
        width: "95%",
        position: "relative",
        left: "4%",
    }
});
// Customizable Area End
