import React, { useEffect, useState } from "react";
import { AppContextProvider } from "./context/AppContext";
import { CometChatHome } from "./components/CometChatHome/CometChatHome";
import "./styles/App.css";
import { CometChatUIKit } from "../../../blocks/chat/src/components/UiKitImports";
import Loader from "../AppLoader.web";

export const CometChat = (props: { appId: string, region: string, authKey: string, uid: string }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [loggedInUser, setLoggedInUser] = useState<any>(null);

    useEffect(() => {
        checkAndLogin();
    }, []);

    const checkAndLogin = async () => {
        setIsLoading(true);
        const uid = props.uid;
        try {
            const loggedInUser: any = await CometChatUIKit.login(uid);
            if (loggedInUser) {
                console.log("Login successful, loggedInUser:", loggedInUser);
                setLoggedInUser(loggedInUser);
            } else {
                console.warn("Login failed, no user returned.");
                setLoggedInUser(null);
            }
        } catch (error) {
            console.error("Error checking or logging in user:", error);
            setLoggedInUser(null);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <AppContextProvider>
            {!isLoading &&
                <Loader isLoading={isLoading} />
            }
            {!isLoading && loggedInUser &&
                <CometChatHome appId={props.appId} region={props.region} authKey={props.authKey} />
            }
            {!isLoading && !loggedInUser &&
                <div style={styles.errorBox}>
                    <p>Unable to login!</p>
                    <button onClick={checkAndLogin} style={styles.retryButton}>Retry</button>
                </div>
            }
        </AppContextProvider>
    );
}

const styles = {
    errorBox: {
        height: "60vh",
        marginTop: "3%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        border: "1px solid #DCDCDC"
    },
    retryButton: {
        width: "100px",
        color: "#FFFFFF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        textTransform: "capitalize",
        background: "#7F1187",
        padding: "10px 0px",
        borderRadius: "30px",
        cursor: "pointer",
        border: "none",
        "&:hover": {
            background: "#7F1287"
        },
    }
} as const;