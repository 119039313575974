import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import { apiCall } from "../../../components/src/utils";
import { toast } from "react-toastify";
// Customizable Area Start

export const baseURL = require("../../../framework/src/config.js");

const timer = 1 * 60;
interface CarouselSliderData {
  id: number;
  attributes: {
    id: number;
    img_vid_url: {
      url:string; 
      id: 23,
      type: string;    
    }
}
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isLoading: boolean;
  listCarousel:CarouselSliderData[]
  email: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShowVerificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpSendApiCallId: string = "";
  getCarouselSliderCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isLoading: false,
      email: "",
      listCarousel:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.carouselSlider(message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.otpSendApiCallId === apiCallId) {
        if (webResponseJson.message && webResponseJson.otp_token) {
          toast.success("OTP sent successfully");
          await setStorageData("otp_token", webResponseJson.otp_token[0]);
          this.redirectToOtpVerification();
        }
      }

      this.setState({
        isLoading: false,
      });
    }

    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    this.carouselsSliderImagesApi();
    this.checkUserIsLoggedIn();
    this.getToken();
  };

  checkUserIsLoggedIn = async () => {
    const email = await getStorageData("email");
    if (email) {
      this.setState({
        email,
      });
    } else {
      this.props.navigation.goBack();
    }
  };

  getToken = async () => {
    const token = await getStorageData("authToken");
    if (token) {
      this.setState({
        token,
      });
    } else {
      this.props.navigation.goBack();
    }
  };

  sendOtpInEmail = async () => {
    this.setState({
      isLoading: true,
    });
    this.otpSendApiCallId = await apiCall({
      endPoint: "account_block/accounts/send_otp",
      method: "POST",
      contentType: "application/json",
      token: this.state.token,
    });
  };

  carouselSlider= (message:Message)=>{
    if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.getCarouselSliderCallId &&
			this.getCarouselSliderCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson.data){
        this.setState({listCarousel:responseJson.data})
			}
		}
  }

  carouselsSliderImagesApi = async () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.carouselGetApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); this.getCarouselSliderCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  redirectToOtpVerification = () => {
    this.props.navigation.navigate("ShowOtp");
  };
  // Customizable Area End
}
