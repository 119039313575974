import React from "react";
// Customizable Area Start
import { Box, Button, Typography, IconButton, Menu, styled, Select, MenuItem, Card, Grid, Table, TableHead, TableRow, TableBody, TableCell, Avatar, Rating, Link }
  from "@mui/material";
import HeaderVendorFooter from "../../../../packages/components/src/HeaderVendorFooter";

import InfoIcon from '@mui/icons-material/Info';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import MoreVertIcon from '@mui/icons-material//MoreVert';
import Loader from "../../../components/src/AppLoader.web";


// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    window.scrollTo(0, 0)
    // Customizable Area End
  }
  // Customizable Area Start
  getPaidBackground = (paid: string): string => {
    if (paid.toLocaleLowerCase() === 'yes') {
      return '#0596691C';
    } else {
      return '#DC26261A';
    }
  }

  getPaidTextColor = (paid: string): string => {
    if (paid.toLocaleLowerCase() === 'yes') {
      return '#059669';
    } else {
      return '#DC2626';
    }
  }

  getStatusBackground = (paid: string): string => {
    if (paid.toLocaleLowerCase() === 'completed') {
      return '#0596691C';
    } else {
      return '#DC26261A';
    }
  }

  getStatusTextColor = (paid: string): string => {
    if (paid.toLocaleLowerCase() === 'completed') {
      return '#059669';
    } else {
      return '#DC2626';
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const open = Boolean(this.state.anchorEl);
    return (
      //Merge Engine DefaultContainer
      <HeaderVendorFooter navigation={this.props.navigation}>
        <Loader isLoading={this.state.loading} />
        <CustomBox>
          <Box className="mainContainer" marginBottom={"30px"}>
            <Box className="overviewBox">
              <Typography className="overviewText">Overview</Typography>
              <Select
                className="overviewSelect"
                data-test-id="time-period-select"
                labelId="time-period-select"
                displayEmpty
                defaultValue="for_this_week"
                value={this.state.overViewtimeSpan}
                onChange={(e) => {
                  this.setState({
                    overViewtimeSpan: e.target.value
                  })
                }}
              >
                <MenuItem value="for_this_week">This week</MenuItem>
                <MenuItem value="for_this_month">This month</MenuItem>
                <MenuItem value="for_this_year">This year</MenuItem>
              </Select>
            </Box>
          </Box>
          <Grid container className="mainGrid" spacing={6}>
            {/* <Box className="cardBox"> */}
            {Object.keys(this.state.overViewData).map((cardKey, index) => {
              const card = this.state.overViewData[cardKey];
              return (
                <Grid key={cardKey} item xs={12} sm={12} md={3} lg={3} xl={3} style={{ width: "100%" }}>
                  <Card
                    style={{
                      background: card.background,
                      width: "100%",
                      borderRadius: '12px',
                    }}
                  >
                    <Box sx={{ padding: "1rem" }}>
                      <img className="header-logo" src={card.imgSrc} alt={`logo${index + 1}`} />
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* {card.title=="New Inquiry" && (
                          <a>press</a>
                        )} */}

                        {card.title === "New Inquiry" ? (
                          <Box display="flex" alignItems="center">
                            <Typography style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Poppins", marginTop: '3px', cursor: "pointer" }} onClick={this.handleClick2}>Reverse Auction</Typography>
                          </Box>) : (
                          <Box display="flex" alignItems="center">
                            <Typography style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Poppins" }}>
                              {card.title}
                            </Typography>
                            <InfoIcon style={{ width: "13.33px", height: "13.33px", padding: "1rem" }} />
                          </Box>
                        )}


                      </Box>
                      <Typography style={{ fontSize: "36px", fontFamily: card.fontFamily || "Poppins", fontWeight: 600 }}>
                        {card.title.toLowerCase() === 'total sales' ? `₹ ${card.value}` : `${card.value}`}
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
              );
            })}
            {/* </Box> */}
          </Grid>

          <Box className="orderManagementBox">
            <Grid container className="mainGrid" spacing={6}>
              <Grid item xs={12} md={12} lg={8}>
                <Box className="orderManagementInnerBox">
                  <Typography className="orderManagementText">Order Management</Typography>
                  <Box sx={{ overflow: "auto", borderRadius: "12px", border: "1px solid #d3d3d3" }}>
                    <Table aria-label="simple table" className="table" style={{ border: "none" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" className="tableHeaderText">#</TableCell>
                          <TableCell align="center" className="tableHeaderText">Order Id</TableCell>
                          <TableCell align="center" className="tableHeaderText">Date</TableCell>
                          <TableCell align="center" className="tableHeaderText">Name</TableCell>
                          <TableCell align="center" className="tableHeaderText">Price</TableCell>
                          <TableCell align="center" className="tableHeaderText">Paid</TableCell>
                          <TableCell align="center" className="tableHeaderText">Address</TableCell>
                          <TableCell align="center" className="tableHeaderText">Status</TableCell>
                          <TableCell align="center" className="tableHeaderText">Actions</TableCell>
                        </TableRow>
                      </TableHead>

                      {this.state.ordersData.length > 0 ?
                        <TableBody>
                          {this.state.ordersData.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell align="center" className="tableRowText">{row.id}</TableCell>
                              <TableCell align="center" className="tableRowText">{row.code}</TableCell>
                              <TableCell align="center" className="tableRowText">{row.date}</TableCell>
                              <TableCell align="center" className="tableRowText">{row.quantity}</TableCell>
                              <TableCell align="center" className="tableRowText">{row.price}</TableCell>
                              <TableCell align="center" className="tableRowText">
                                <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                                  <Box sx={{ width: "max-content", borderRadius: "8px", padding: "10px 15px", background: this.getPaidBackground(row.paid), color: this.getPaidTextColor(row.paid) }}>
                                    {row.paid}
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell align="center" className="tableRowText">{row.location}</TableCell>
                              <TableCell align="center" className="tableRowText">
                                <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                                  <Box sx={{ width: "max-content", borderRadius: "8px", padding: "10px 15px", background: this.getStatusBackground(row.status), color: this.getStatusTextColor(row.status) }}>
                                    {row.status}
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell align="center" className="tableRowText">
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                  <ChatBubbleOutlineIcon />
                                  <IconButton onClick={this.handleClick}>
                                    <MoreVertIcon />
                                  </IconButton>
                                </Box>

                                <Menu
                                  anchorEl={this.state.anchorEl}
                                  open={open}
                                  onClose={this.handleClose}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                >
                                  <MenuItem onClick={this.handleClose}>Edit</MenuItem>
                                  <MenuItem onClick={this.handleClose}>Delete</MenuItem>
                                </Menu>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        :
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={9} style={{ textAlign: "center", height: "300px" }}>
                              <Typography>No Orders Found!</Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      }
                    </Table>
                  </Box>

                </Box>
              </Grid>

              <Grid item xs={12} md={12} lg={4}>
                <Box className="reviewBox">
                  <Box className="reviewInnerBox" display="flex" justifyContent="space-between" alignItems="center">
                    <Typography className="reviewText">Latest Reviews</Typography>
                    <Button className="seeallBtn">See all</Button>
                  </Box>
                  {this.state.recentReviews.length > 0 &&
                    <Box>
                      {this.state.recentReviews.map((review) => (
                        <Box key={review.id} className="reviewCard">
                          <Box className="reviewInnerCard" display="flex" justifyContent="space-between" alignItems="center">
                            <Box display="flex" alignItems="center" gap="12px">
                              <Avatar src={review.avatarPic} />
                              <Typography className="reviewCardname">{review.name}</Typography>
                            </Box>
                            <Typography className="reviewCardTime">{review.time}</Typography>
                          </Box>

                          <Box className="commentBox" display="flex">
                            <Typography className="commentext">{review.reviewText}</Typography>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }} >
                              <Rating value={1} max={1} precision={1} readOnly />
                              <Typography className="rating">{review.rating}/10</Typography>
                            </Box>
                          </Box>
                          <Typography className="reviewDescription">
                            {review.description}
                          </Typography>
                        </Box>
                      ))}
                    </Box>}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CustomBox>
      </HeaderVendorFooter>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" }
};

const CustomBox = styled(Box)({
  padding: "0 4% 4% 4%",
  overflow: "hidden",
  "& .mainContainer": {
    display: "flex",
    justifyConetent: "center",
    alignItems: "center",
    width: "100vw",
    height: "auto",
  },
  "& .tableHeaderText": {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "poppins",
    color: "#78716C",
  },
  "& .tableRowText": {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#44403C",
    borderBottom: "transparent"
  },
  "& .overviewBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90vw"
  },
  "& .overviewText": {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "poppins"
  },
  "& .overviewSelect": {
    width: "124px",
    height: "40px",
    color: "#6F767E"
  },
  "& .cardBox": {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    gap: "1rem",
    paddingTop: "2rem",
    flexWrap: "wrap",
    "@media (min-width: 768px)": {
      flexDirection: "row",
    },
    "@media (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  "& .orderManagementBox": {
    flexWrap: "Wrap",
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    marginTop: "30px",
    "@media (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  "& .orderManagementInnerBox": {
    maxWidth: "100%",
    gap: "1rem",
    padding: "1rem 0rem",
    display: "flex",
    flexDirection: "column",
  },
  "& .orderManagementText": {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "poppins"
  },
  "& .reviewBox": {
    paddingTop: "1rem",
    "@media (max-width: 1510px)": {
      paddingLeft: "0rem",
      paddingRight: "0rem"
    },
    "@media (max-width: 768px)": {
      width: "90vw",
      paddingLeft: "2rem",
      paddingRight: "2rem"
    },
  },
  "& .reviewInnerBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .reviewText": {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "poppins"
  },
  "& .table": {
    border: "1px solid lightgrey",
    borderRadius: "12px",
  },
  "& .seeallBtn": {
    color: "#7F1187",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "poppins"
  },
  "& .reviewCard": {
    borderBottom: "1px solid #CBD5E1",
    paddingBottom: "1rem",
  },
  "& .reviewInnerCard": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "1rem"
  },
  "& .reviewCardname": {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#292524"
  },
  "& .reviewCardTime": {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#292524"
  },
  "& .commentBox": {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "1rem"
  },
  "& .commentText": {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#292524"
  },
  "& .rating": {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "poppins",
    color: "#78716C"
  },
  "& .reviewDescription": {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#A8A29E",
    padding: "0.7rem 0"
  }

})
// Customizable Area End
