export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const card1 = require("../assets/card1.png");
export const star = require("../assets/view_.png");

export const ratingIcon = require("../assets/rating.svg")
export const quizcard = require("../assets/quiz.png")
export const card = require("../assets/card2.png")
export const quizBanner = require("../assets/QuizBanner.png")
export const card3 = require("../assets/banner3.png")
export const stackIcon = require("../assets/stackImage.svg")
