import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface Vendor {
    ratingImage:string,
    imgURL: string,
    id: number;
    name: string;
    location: string;
    rating: string;
    price: string;
    propertyType : string,
    venueType: string,
    Rates: string,
    eventsFor: string,
    locatedat: string,
  }

const Venueone = require("../assets/one.jpg");
const Venuetwo = require("../assets/two.svg");
const Venuethree = require("../assets/three.svg");
import { ratingIcon } from "./assets";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
    selectedVendors: Vendor[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SelectedVendorsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedVendors: [
        {ratingImage: ratingIcon ,
          imgURL: Venueone,
          id: 1,
          name: "Eventshoppe",
          location: "Juhu, Mumbai",
          rating: "7.5 (678 Ratings)",
          price: "₹ 46,000",
          propertyType : "Resort",
          venueType: "Beach resorts",
          locatedat: "Beach Side",
          Rates: "5000 - 10000",
          eventsFor: "Wedding Halls Banquet halls Small fuction hall Confression meting room"
        },
        {
            ratingImage: ratingIcon ,
          imgURL: Venuetwo,
          id: 2,
          name: "Thakkar Decorators",
          location: "Juhu, Mumbai",
          rating: "7.5 (678 Ratings)",
          price: "₹ 46,000",
          propertyType : "Resort",
          venueType: "Beach resorts",
          locatedat: "Beach Side",
          Rates: "5000 - 10000",
          eventsFor: "Wedding Halls Banquet halls Small fuction hall Confression meting room"



          
        },
        {
          ratingImage: ratingIcon ,
          imgURL: Venuethree,
          id: 3,
          name: "Yours Decorator",
          location: "Juhu, Mumbai",
          rating: "7.5 (678 Ratings)",
          price: "₹ 46,000",
          propertyType : "Resort",
          venueType: "Beach resorts",
          locatedat: "Beach Side",
           Rates: "5000 - 10000",
          eventsFor: "Wedding Halls Banquet halls Small fuction hall Confression meting room"
        },
      ]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
    }
  };

  // Customizable Area End
}
