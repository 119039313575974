import React from "react";

// Customizable Area Start
import { Box, Typography } from "@mui/material";
// Customizable Area End

import ViewChatController, {
  configJSON,
  Props,
} from "./ViewChatController";
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
import HeaderVendorFooter from "../../../components/src/HeaderVendorFooter";
import Loader from "../../../components/src/AppLoader.web";
import { CometChat } from "../../../components/src/comet/CometChat";

// Customizable Area Start
// Customizable Area End

export default class ViewChat extends ViewChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  loadCometChat = () => {
    const { cometChatUID } = this.state;
    return (
      <CometChat appId={configJSON.APP_ID} region={configJSON.REGION} authKey={configJSON.AUTH_KEY} uid={cometChatUID} />
    );
  }

  noAccountError = () => {
    return (
      <Box sx={styles.errorBox}>
        <Typography>Chat account not found!</Typography>
      </Box>
    )
  }

  loadChatUi = () => {
    const { isLoading, cometChatUID } = this.state;
    return (
      <>
        <Box width={"100%"} height={"100%"} padding={"1% 3%"} overflow={"hidden"} position={"relative"} zIndex={999}>
          {cometChatUID && !isLoading ?
            <>
              <Box sx={{ display: { sm: "block", xm: "block", md: "none", lg: "none", xl: "none" } }} height={"100vh"} position={"relative"}>
                {this.loadCometChat()}
              </Box>
              <Box sx={{ display: { sm: "none", xm: "none", md: "block", lg: "block", xl: "block" } }}>
                {this.loadCometChat()}
              </Box>
            </>
            :
            this.noAccountError()
          }
        </Box>
      </>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { role } = this.state;
    return (
      <>
        {
          role === 'vendor' &&
          <HeaderVendorFooter navigation={this.props.navigation}>
            {this.loadChatUi()}
          </HeaderVendorFooter>
        }
        {
          (role === 'user' || !role) &&
          <HeaderFooterLayout navigation={this.props.navigation}>
            {this.loadChatUi()}
          </HeaderFooterLayout>
        }
        <Loader isLoading={this.state.isLoading} />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  errorBox: {
    width: "100%",
    height: "60vh",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #D6D3D1",
    marginTop: "3%",
  }
} as const;
// Customizable Area End
