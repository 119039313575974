import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  styled,
  Box,
  Typography,
  IconButton,
  Button,
  OutlinedInput,
  InputAdornment,
  Popover,
  Drawer,
  Badge,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";

import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { clearStorageData } from "./utils";
import { getStorageData } from "../../framework/src/Utilities";
import { useRunEngine } from "../../../packages/blocks/utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../../packages/blocks/utilities/src/hooks/useBlockHelpers";
import MessageEnum, { getName } from "../../../packages/framework/src/Messages/MessageEnum";
import { Message } from "../../../packages/framework/src/Message";
import eventEmitter from "./EventEmitter";
import { CometChatUIKit } from "../../../packages/blocks/chat/src/components/UiKitImports";
import PhoneIcon from "@mui/icons-material/Phone";
import { workImage } from "../../../packages/blocks/landingpage/src/assets";


const headerLogo = require("./assets/logo.png");
const crossIcon = require("./assets/cross.svg");
const cartIcon = require("./assets/cart.svg");
const notificationIcon = require("./assets/notification.svg");
const defaultLogo = require("./assets/user.png");

const Link = [
  {
    path: "/discover-vendor",
    name: "Discover Vendors",
  },
  {
    path: "/venue",
    name: "Venue",
  },
  {
    path: "/inspirations",
    name: "Inspirations",
  },
  {
    path: "/real-events",
    name: "Real Events",
  },
  {
    path: "/plan-my-event",
    name: "Plan My Event",
  },
  {
    path: "/shop",
    name: "Shop",
  },
  {
    path: "/reverse-auction",
    name: "Reverse Auction",
  },
];
interface IHeaderProps {
  navigation: any;
  isOtherPage?: boolean;
  className?: string;
}
const Header: React.FC<IHeaderProps> = ({ navigation, className }) => {
  const [isLoggedInUser, setIsLoggedInUser] = useState(true);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [fullName, setFullName] = useState<string>("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [windowWidth, setWindowWidth] = useState(0);
  const [value, setValue] = useState<any>(null);
  const [cartCounter, setCartCounter] = useState(0);
  const carouselListCallId = useRef("");
  const userProfileCallId = useRef("");
  const [userProfile, setUserProfile] =useState<any>([])

  const { extractNetworkResponse } =
    useBlockHelpers();

  const {
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    debugLog,
    unsubscribeFromMessage,

  } = useRunEngine();

  useEffect(() => {
    // Listen for the event
    const handleUpdate = (data: number) => {
      setCartCounter(cartCounter + data);
    };
    eventEmitter.on('updateCartCounter', handleUpdate);

    // Cleanup listener on component unmount
    return () => {
      eventEmitter.removeListener('updateCartCounter', handleUpdate);
    };
  }, [cartCounter]);

  useEffect(() => {
    checkUserIsLoggedIn();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 980) {
      setIsDrawerOpen(false);
    } else {
      setIsDrawerOpen(isDrawerOpen);
    }
  }, [windowWidth]);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const goToUserSettings = () => {
    navigation.navigate("UserProfileBasicBlock");
  }

  const goToUserProfile = () => {
    navigation.navigate("UserBio");
  }

  const goToWishlist = () => {
    navigation.navigate("Wishlist");
  }

  const goToMyBookings = () => {
    navigation.navigate("MyBookings");
  }
  
  const goToChats = () => {
    navigation.navigate("ViewChat");
  }

  const goToCart = () => {
    navigation.navigate("Cart");
  }

  const checkUserIsLoggedIn = async () => {
    const isLoggedIn = await getStorageData("authToken");
    setIsLoggedInUser(isLoggedIn);
  };

  const redirectToLoginPage = () => {
    navigation.navigate("LoginEmail");
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loggedOutClick = async () => {
    CometChatUIKit.getLoggedinUser().then((user: CometChat.User | null) => {
      if (user) {
        //Logout user
        CometChatUIKit.logout().then(() => {
          console.log("User logged out")
        });
      }
    });

    toast.success("User logged out successfully");
    await clearStorageData();
    setIsLoggedInUser(false);
    navigation.navigate("LandingPage");
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const redirectToHomePage = () => {
    navigation.navigate("LandingPageWeb");
  };

  const subscribedMessages = [
    MessageEnum.RestAPIResponceMessage,
    MessageEnum.SessionResponseMessage,
  ];

  const getCarosalData = () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": "application/json",
      token
    };
    sendNetworkRequest(
      carouselListCallId,
      "GET",
      "/bx_block_landingpage2/why_choose_us/show_contact_info",
      header
    );
  };
  const getUserProfile = () => {
    let token = localStorage.getItem("authToken")
    const header = {
      "Content-Type": "application/json",
      token
    };
    sendNetworkRequest(
      userProfileCallId,
      "GET",
      "/account_block/accounts/id",
      header
    );
  };
  const handlNameGet = () => {
    const storedData = localStorage.getItem("user");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (parsedData.account?.full_name) {
        setFullName(parsedData?.account.full_name);
      }
    }
  }

  useEffect(() => {
    handlNameGet();
    setReceiveCallback(receive);
    subscribedMessages.forEach((message) => subscribe(message));
    getCarosalData();
    getUserProfile();
    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
    };
  }, []);

  const receive = (from: string, message: Message) => {
    debugLog("API Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } =
        extractNetworkResponse(message);
      if (responseJson) {
        if (
          apiRequestCallId === carouselListCallId.current &&
          responseJson?.data
        ) {
          setValue(responseJson.data)
        }
        if (
          apiRequestCallId === userProfileCallId.current &&
          responseJson?.data
        ) {
          setUserProfile(responseJson?.data.attributes)
        }
      }
    }
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsDrawerOpen(open);
    };

  const dropDownIconAndMenu = () => {
    return (
      <>
        <IconButton className="profile-icon-button" onClick={handleClick}>
          <img
            className="user-profile-picture"
            alt="profile"
            src={userProfile?.profile_picture || defaultLogo}
          />
        </IconButton>
        <PopoverStyle
          id={Boolean(anchorEl) ? "simple-popover" : undefined}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            transform: "translateX(-6px)",
          }}
        >
          <Box className="profile-dropdown">
            <Box className="profile-picture-container">
              <img
                className="user-profile-picture-dropdown"
                alt="profile"
                src={userProfile?.profile_picture || defaultLogo}
              />
              <Box className="user-name-container">
                <Typography className="user-name">{userProfile?.full_name}</Typography>
                <Typography className="user-text" onClick={goToUserProfile}>Profile</Typography>
              </Box>
            </Box>
            <Box className="profile-link-container">
              <Typography className="profile-link" onClick={goToChats}>Chats</Typography>
              <Typography className="profile-link" onClick={goToMyBookings}>My bookings</Typography>
              <Typography className="profile-link" onClick={goToWishlist}>Wishlist</Typography>
              <Typography className="profile-link">Subscriptions</Typography>
              <Typography className="profile-link">Offers</Typography>
              <Typography className="profile-link" onClick={goToUserSettings}>Settings</Typography>
              <Typography className="profile-link" onClick={loggedOutClick}>
                Logout
              </Typography>
            </Box>
          </Box>
        </PopoverStyle>
      </>
    );
  };

  const sideDrawerContent = () => {
    return (
      <>
        <IconButton
          className="ham-burger"
          aria-label="menu"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        <DrawerStyle
          anchor="left"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
        >
          <Box className="drawer-container">
            <Box className="drawer-header">
              <img
                onClick={redirectToHomePage}
                src={headerLogo}
                className="drawer-header-logo"
                alt="header"
              />
              <IconButton
                className="header-close-button"
                onClick={handleDrawerClose}
              >
                <img src={crossIcon} alt="cross" />
              </IconButton>
            </Box>
            <Box className="drawer-content">
              {Link.map((data, index) => (
                <NavLink
                  to={data.path}
                  exact={data.path === "/"}
                  key={index}
                  isActive={(match, location) => {
                    if (data.path === "/") {
                      return !!match?.isExact;
                    }
                    return location.pathname.startsWith(data.path);
                  }}
                  className="drawer-link"
                >
                  {data.name}
                </NavLink>
              ))}
            </Box>
          </Box>
        </DrawerStyle>
      </>
    );
  };

  return (
    <HeaderStyle position="sticky" className={className}>
      <Box className="second-row-phone-number">      
        <Typography className="phone-number-text">
          <PhoneIcon />
          <Typography component="span" className="phone-number">
            {value?.attributes.user_country_code}-{value?.attributes.user_phone_number}
          </Typography>
        </Typography>
        <Box display={"flex"} flexDirection={"row"} gap={"20px"} color={"#FFF"}>
          <NavLink
            to={"/faq"}
            exact={true}
            className="topLinks"
          >
            FAQ's
          </NavLink>
          <NavLink
            to={"/contact-us"}
            exact={true}
            className="topLinks"
          >
            Contact us
          </NavLink>      
          {!isLoggedInUser &&
            <NavLink
              to={"/VendorSignup"}
              exact={true}
              className="areYouVendor"
            >
              <img src={workImage} alt="work" /> Are you a vendor ?
            </NavLink>}
        </Box>
      </Box>
      <Box className="first-row">
        <Box className="image-link-container">
          <img
            className="header-logo"
            onClick={redirectToHomePage}
            src={headerLogo}
            alt="logo"
          />
          <Box className="link-container">
            {Link.map((data, index) => (
              <NavLink
                to={data.path}
                exact={data.path === "/"}
                key={index}
                isActive={(match, location) => {
                  if (data.path === "/") {
                    return !!match?.isExact;
                  }
                  return location.pathname.startsWith(data.path);
                }}
              >
                {data.name}
              </NavLink>
            ))}
          </Box>
          <Box className="mobile-device-hamburger">{sideDrawerContent()}</Box>
        </Box>
        {!isLoggedInUser && (
          <Box className="search-login-cart-container">
            <OutlinedInput
              className="search-input"
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
            <Button
              className="login-button"
              onClick={() => redirectToLoginPage()}
            >
              <FavoriteBorderIcon /> Login
            </Button>
            <IconButton onClick={() => goToCart()} className="shopping-button">
              <img src={cartIcon} alt="cart" />
            </IconButton>
          </Box>
        )}
        {isLoggedInUser && (
          <Box className="logged-in-user-container">
            <Box className="search-input-container">
              <OutlinedInput
                className="search-input-logged-user"
                placeholder="Search"
                fullWidth
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Box>
            {dropDownIconAndMenu()}
            <Box className="cart-image-container">
              <IconButton onClick={() => {}} className="shopping-button">
                <Badge badgeContent={0} color="error">
                  <img src={notificationIcon} alt="cart" />
                </Badge>
              </IconButton>
              <IconButton onClick={() => goToCart()} className="shopping-button">
                <Badge badgeContent={cartCounter} color="error">
                  <img src={cartIcon} alt="cart" />
                </Badge>
              </IconButton>
            </Box>
          </Box>
        )}
        <Box className="tablet-device-hamburger">{sideDrawerContent()}</Box>
      </Box>
    </HeaderStyle>
  );
};

export default Header;

const HeaderStyle = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#ffffff",
  color: "unset",
  padding: "0px",
  flex: "0 0 auto",
  [theme.breakpoints.down("lg")]: {
    padding: "0px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0px",
  },
  "& .topLinks":{
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#FFF",
    textDecoration: "none",
  },
  "& .areYouVendor": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#FFF", 
    textDecoration: "none",
    display: "flex",
    alignItems: " center",
    gap: "10px",
    padding: "0px 12px",
    borderLeft: "1px solid #FFF"
  },
  "& .header-logo": {
    width: "132px",
    cursor: "pointer",
  },
  "& .image-link-container": {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    gap: "20px",
    "@media(min-width:1280px) and (max-width:1380px)": {
      gap: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
      width: "fit-content",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .search-input": {
    borderRadius: "30px",
    paddingLeft: "16px",
    maxWidth: "200px",
    minWidth: "200px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
      width: "100%",
    },
    "& ::placeholder": {
      color: "#94A3B8",
      opacity: 1,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#7F1287 !important",
    },
    "& svg": {
      color: "#94A3B8",
    },
    "& .MuiInputBase-input": {
      padding: "11px 16px 10px 0",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "24px",
      color: "#7F1287",
    },
  },
  "& .search-input-container": {
    padding: "0px 10px 0 26px",
    "@media(min-width:1280px) and (max-width:1380px)": {
      padding: "0px 18px 0 13px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px 0 0",
      width: "100%",
    },
  },
  "& .search-input-logged-user": {
    borderRadius: "30px",
    paddingLeft: "16px",
    maxWidth: "241px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
    },
    "& ::placeholder": {
      color: "#94A3B8",
      opacity: 1,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#7F1287 !important",
    },
    "& svg": {
      color: "#94A3B8",
    },
    "& .MuiInputBase-input": {
      padding: "11px 16px 10px 0",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "24px",
      color: "#7F1287",
    },
  },
  "& .vertical-line": {
    minWidth: "2px",
    backgroundColor: "#801187",
    height: "38px",
  },
  "& .profile-icon-button": {
    padding: "6px",
  },
  "& .link-container": {
    display: "flex",
    gap: "24px",
    alignItems: "center",
    "@media(min-width:1280px) and (max-width:1380px)": {
      gap: "14px",
    },
    [theme.breakpoints.down("lg")]: {
      gap: "18px",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "& a": {
      fontFamily: "Poppins",
      fontSize: "12px",
      lineHeight: "18px",
      padding: "8px 6px",
      boxSizing: "border-box",
      textDecoration: "none",
      textAlign: "center",
      maxWidth: "fit-content",
      color: "#334155",
      fontWeight: 400,
      whiteSpace: "nowrap",
      "&:hover": {
        color: "#7f1187",
      },
      "&.active": {
        fontWeight: 700,
        color: "#7f1187",
      },
    },
  },

  "& .shopping-button": {
    padding: "10px",
    color: "#44403C",
  },
  "& .login-button": {
    padding: "10px 15px",
    borderRadius: "30px",
    backgroundColor: "#7F1287 !important",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    gap: "8px",
    color: "#ffffff",
    minWidth: "max-content",
    textTransform: "none",
  },
  "& .first-row": {
    padding: "10px 50px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    height: "76px",
  },
  "& .search-login-cart-container": {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    "@media(min-width:1280px) and (max-width:1380px)": {
      gap: "14px",
    },
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "flex-end",
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      gap: "12px",
    },
  },
  "& .second-row-phone-number": {
    textAlign: "right",
    padding: "10px 50px",
    background: "#7F1187",
    height: "44px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .phone-number-text": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "300",
    lineHeight: "24px",
    color: "#FFFFFF",
    marginRight: "28.5px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      marginTop: "8px",
      marginRight: "0px",
    },
  },
  "& .phone-number": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#FFF",
    marginLeft: "10.5px",
  },
  "& .user-profile-picture": {
    height: "44px",
    width: "44px",
    minWidth: "44px",
    borderRadius: "50%",
    border: "1px solid #F0E5FF",
    boxSizing: "border-box",
    cursor: "pointer",
  },
  "& .cart-image-container": {
    display: "flex",
    alignItems: "center",
  },
  "& .logged-in-user-container": {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "flex-end",
      width: "100%",
    },
  },
  "& .ham-burger": {
    display: "none",
    color: "rgb(68, 64, 60)",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    "& svg": {
      height: "28px",
      width: "28px",
    },
  },
  "& .tablet-device-hamburger": {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "& .mobile-device-hamburger": {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));

const PopoverStyle = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    boxShadow: "0px 2px 4.6px 0px #0000001C",
    borderRadius: "18px",
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    padding: "40px",
    width: "321px",
    maxWidth: "321px",
    [theme.breakpoints.down("xs")]: {
      width: "70%",
      maxWidth: "70%",
    },
  },
  "& .profile-dropdown": {
    "& .user-profile-picture-dropdown": {
      height: "56px",
      width: "56px",
      minWidth: "56px",
      borderRadius: "50%",
      border: "1px solid #F0E5FF",
      boxSizing: "border-box",
    },
    "& .profile-picture-container": {
      display: "flex",
      gap: "14px",
    },
    "& .user-name-container": {
      width: "100%",
      overflow: "hidden",
    },
    "& .user-name": {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "24px",
      textAlign: "left",
      color: "#1C1917",
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .user-text": {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "24px",
      textAlign: "left",
      color: "#44403C",
      cursor: "pointer",
    },
    "& .profile-link-container": {
      display: "flex",
      flexDirection: "column",
      gap: "11px",
      marginTop: "26px",
    },
    "& .profile-link": {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "24px",
      color: "#78716C",
      width: "fit-content",
      cursor: "pointer",
    },
  },
}));

const DrawerStyle = styled(Drawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "350px",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  "& .drawer-container": {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  "& .drawer-header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: "0 0 auto",
  },
  "& .drawer-content": {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    flex: "1 1 auto",
    boxSizing: "border-box",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E2E8F0",
      borderRadius: "10px",
      margin: "0",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#801187",
      borderRadius: "10px",
    },
  },
  "& .header-close-button": {
    color: "rgb(68, 64, 60)",
    "& img": {
      height: "24px",
      width: "24px",
    },
  },
  "& .drawer-link": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    textAlign: "left",
    color: "#334155",
    textDecoration: "none",
    width: "fit-content",
    "&:hover": {
      color: "#7f1187",
    },
    "&.active": {
      fontWeight: 700,
      color: "#7f1187",
    },
  },
}));
