// Customizable Area Start
import React, { Component } from "react";
import { Box, Typography, styled, Grid, Button } from "@mui/material";

import RealWeddingCard from "../../../../components/src/RealWeddingCard.web";
import MainTitle from "./MainTitle.web";
import {
  rightArrowButton,
} from "../assets";
import { RealWedding, RealWeddingProfile } from "../LandingPageController";
interface Props {
  realWeddingData: RealWedding;
  realWeddingProfileData:RealWeddingProfile[];
  redirectToRealWeddingItemsPage:(id:number) => void;
  goToRealWeddingPage:() => void
}
interface State {}

export default class RealWeddingSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {realWeddingData, realWeddingProfileData, redirectToRealWeddingItemsPage, goToRealWeddingPage} = this.props
    return (
      <RealWeddingContainer component="section" id="real-wedding">
        <MainTitle title={realWeddingData.attributes.title} />
        <Typography className="real-wedding-description">
          {realWeddingData.attributes.description}
        </Typography>
        <Grid spacing={4} container className="real-wedding-container">
          {realWeddingProfileData.slice(0, 3).map((data, index) => (
              <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                <RealWeddingCard
                onCardClick={redirectToRealWeddingItemsPage}
                 image_url={[
                  data?.attributes?.image?.url,
                ]}
                  location={data.attributes.description}
                  couple_name={data.attributes.title}
                  id={data.attributes.id}
                />
              </Grid>
            ))}
        </Grid>
        <Box className="real-wedding-button-container">
          <MoreItemsButton onClick={goToRealWeddingPage}>
            Explore more real events
            <img alt="right" src={rightArrowButton} />
          </MoreItemsButton>
        </Box>
      </RealWeddingContainer>
    );
  }
}

const RealWeddingContainer = styled(Box)({
  padding: "30px 0 20px",
  "& .real-wedding-description": {
    textAlign: "center",
    margin: "18px 0 40px",
    lineHeight: "34px",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    color: "#57534E",
  },
  "& .real-wedding-container": {
    width: "calc(100% + 32px)",
    justifyContent: "center",
    "& > .MuiGrid-item": {
      width: "100%",
    },
  },
  "& .real-wedding-button-container": {
    textAlign: "center",
    marginTop: "24px",
  },
});

const MoreItemsButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "24px",
  textAlign: "left",
  color: "#7F1287",
  textTransform: "none",
  border: "1px solid #7F1287",
  borderRadius: "30px",
  padding: "9px 11px",
  gap: "8px",
  "& img": {
    height: "24px",
    width: "24px",
  },
});
// Customizable Area End
